<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M36.941,37.757c1.539-0.086,3.226-0.425,4.845-0.22c3.139,0.396,4.02,1.493,3.834,4.089
        c-0.271,3.8-2.142,6.79-5.091,8.856c-5.405,3.787-8.131,8.74-7.885,15.343c0.103,2.75,0.116,5.503,0.165,8.255
        c0.017,0.945,0.466,1.66,1.323,1.955c0.696,0.239,1.51,0.43,2.212,0.306c3.262-0.574,6.51-1.232,9.749-1.926
        c1.765-0.379,2.306-1.131,2.322-2.958c0.004-0.517-0.106-1.034-0.112-1.552c-0.063-4.988,0.666-9.595,4.557-13.362
        c3.229-3.126,5.909-6.778,6.295-11.541c0.13-1.59,0.146-3.196,0.105-4.791c-0.083-3.369-0.912-6.478-3.362-8.978
        c-0.699-0.713-1.346-1.479-2.011-2.225c-1.635-1.831-3.538-3.411-5.998-3.707c-3.594-0.433-7.23-0.686-10.847-0.675
        c-1.821,0.005-3.692,0.606-5.446,1.213c-2.796,0.968-4.566,3.204-6.346,5.467c-3.965,5.043-5.503,10.648-4.313,16.959
        c0.387,2.052,1.708,3.094,3.821,3.068c2.759-0.034,5.35-0.878,7.869-1.902c1.573-0.639,2.364-1.944,2.147-3.643
        c-0.3-2.351-0.161-4.617,0.807-6.803c0.015-0.034-0.08-0.116-0.316-0.438c-0.498,0.679-1.124,1.221-1.342,1.896
        c-0.452,1.396-0.605,2.889-1.046,4.291c-0.227,0.723-0.698,1.785-1.245,1.916c-2.008,0.48-4.09,0.664-6.153,0.879
        c-0.294,0.031-0.873-0.338-0.915-0.589c-0.243-1.45-0.52-2.922-0.495-4.382c0.068-3.949,1.618-7.448,3.556-10.807
        c1.553-2.692,4.014-4.028,6.923-4.688c3.356-0.76,6.762-0.565,10.146-0.337c2.491,0.167,4.946,0.667,6.857,2.473
        c1.071,1.013,2.181,2.041,3.003,3.246c0.951,1.394,1.873,2.916,2.315,4.519c0.468,1.696,0.601,3.573,0.449,5.333
        c-0.357,4.165-1.684,7.954-5.177,10.644c-1.011,0.779-1.997,1.592-2.992,2.391c-1.334,1.071-2.461,2.324-2.968,3.983
        c-0.583,1.908-1.199,3.828-1.517,5.789c-0.31,1.91-0.262,3.879-0.379,5.974c-2.72,0.533-5.492,1.103-8.28,1.581
        c-0.257,0.044-0.865-0.471-0.883-0.752c-0.201-3.26-0.582-6.54-0.396-9.785c0.267-4.657,3.075-7.918,6.741-10.529
        c3.815-2.717,5.914-6.283,5.605-11.116c-0.098-1.53-0.777-2.583-2.01-3.319C43.136,36.006,38.571,36.437,36.941,37.757z
        M93.359,26.773c-0.349-0.91-0.486-2.122-1.161-2.799c-0.723-0.726-1.911-1.113-2.965-1.363c-4.73-1.121-9.484-2.143-14.231-3.194
        c-2.071-0.459-4.299,0.264-4.699,2.594c-0.162,0.941-0.544,1.849-0.669,2.793c-0.636,4.787-1.192,9.584-1.827,14.372
        c-1.246,9.385-2.525,18.765-3.793,28.146c-0.189,1.408,0.594,3.341,1.881,3.87c2.057,0.845,4.194,1.507,6.325,2.155
        c0.91,0.276,1.753-0.085,2.33-0.867c0.852-1.156,1.713-2.31,2.495-3.513c2.56-3.933,4.635-8.119,6.281-12.508
        c1.881-5.011,3.734-10.033,5.51-15.083c1.218-3.462,2.299-6.974,3.404-10.475C92.646,29.618,92.949,28.301,93.359,26.773z
        M74.172,84.129c0.362-4.861-3.059-6.786-6.429-7.154c-4.202-0.458-8.767,3.011-8.654,7.777c0.112,4.727,3.492,7.509,7.799,7.827
        C70.058,92.814,74.521,89.479,74.172,84.129z M45.563,78.626c-2.358,0.228-4.69,0.599-6.3,2.364
        c-2.171,2.383-2.842,5.359-2.133,8.503c0.876,3.882,4.752,5.826,8.394,4.262c1.271-0.547,2.52-1.229,3.631-2.049
        c4.312-3.183,3.501-10.267-1.419-12.55C47.072,78.848,46.29,78.796,45.563,78.626z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M36.941,37.757c1.629-1.32,6.195-1.751,8.124-0.599
        c1.232,0.736,1.912,1.789,2.01,3.319c0.309,4.833-1.79,8.399-5.605,11.116c-3.666,2.61-6.474,5.872-6.741,10.529
        c-0.186,3.245,0.195,6.525,0.396,9.785c0.018,0.281,0.626,0.796,0.883,0.752c2.788-0.479,5.56-1.048,8.28-1.581
        c0.118-2.095,0.069-4.063,0.379-5.974c0.318-1.961,0.935-3.881,1.517-5.789c0.506-1.659,1.633-2.912,2.968-3.983
        c0.996-0.799,1.981-1.612,2.992-2.391c3.493-2.69,4.82-6.479,5.177-10.644c0.151-1.761,0.019-3.638-0.449-5.333
        c-0.442-1.603-1.364-3.125-2.315-4.519c-0.822-1.205-1.932-2.233-3.003-3.246c-1.911-1.807-4.367-2.306-6.857-2.473
        c-3.385-0.228-6.791-0.423-10.146,0.337c-2.909,0.659-5.37,1.996-6.923,4.688c-1.938,3.359-3.488,6.858-3.556,10.807
        c-0.025,1.46,0.252,2.933,0.495,4.382c0.042,0.251,0.62,0.62,0.915,0.589c2.062-0.215,4.145-0.399,6.153-0.879
        c0.547-0.131,1.018-1.193,1.245-1.916c0.44-1.402,0.594-2.894,1.046-4.291c0.218-0.674,0.844-1.216,1.342-1.896
        c0.236,0.321,0.331,0.404,0.316,0.438c-0.968,2.186-1.107,4.452-0.807,6.803c0.216,1.699-0.574,3.004-2.147,3.643
        c-2.519,1.023-5.109,1.868-7.869,1.902c-2.113,0.026-3.434-1.016-3.821-3.068c-1.19-6.311,0.348-11.916,4.313-16.959
        c1.779-2.263,3.55-4.499,6.346-5.467c1.753-0.607,3.625-1.208,5.446-1.213c3.617-0.011,7.253,0.242,10.847,0.675
        c2.46,0.296,4.363,1.876,5.998,3.707c0.666,0.746,1.312,1.511,2.011,2.225c2.45,2.5,3.279,5.609,3.362,8.978
        c0.04,1.595,0.024,3.201-0.105,4.791c-0.387,4.763-3.067,8.415-6.295,11.541c-3.891,3.767-4.62,8.374-4.557,13.362
        c0.006,0.518,0.117,1.035,0.112,1.552c-0.016,1.827-0.557,2.579-2.322,2.958c-3.239,0.693-6.487,1.352-9.749,1.926
        c-0.703,0.124-1.517-0.066-2.212-0.306c-0.856-0.295-1.306-1.01-1.323-1.955c-0.048-2.752-0.062-5.505-0.165-8.255
        c-0.247-6.604,2.48-11.557,7.885-15.343c2.949-2.066,4.819-5.056,5.091-8.856c0.186-2.596-0.695-3.692-3.834-4.089
        C40.167,37.332,38.48,37.67,36.941,37.757z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M93.359,26.773c-0.41,1.527-0.713,2.845-1.119,4.129
        c-1.105,3.501-2.187,7.013-3.404,10.475c-1.775,5.049-3.629,10.071-5.51,15.083c-1.646,4.389-3.722,8.575-6.281,12.508
        c-0.782,1.203-1.644,2.356-2.495,3.513c-0.577,0.782-1.42,1.144-2.33,0.867c-2.131-0.648-4.269-1.311-6.325-2.155
        c-1.287-0.529-2.07-2.462-1.881-3.87c1.268-9.382,2.547-18.762,3.793-28.146c0.635-4.787,1.191-9.584,1.827-14.372
        c0.125-0.944,0.507-1.852,0.669-2.793c0.4-2.33,2.628-3.054,4.699-2.594c4.747,1.052,9.501,2.073,14.231,3.194
        c1.054,0.25,2.242,0.637,2.965,1.363C92.873,24.652,93.011,25.863,93.359,26.773z M74.24,22.552
        c-0.164,0.482-0.319,0.848-0.411,1.229c-0.706,2.909-1.53,5.798-2.071,8.738c-1.475,8.011-2.863,16.038-4.212,24.071
        c-0.581,3.463-1.064,6.948-1.399,10.442c-0.082,0.86,0.329,2.229,0.963,2.605c1.478,0.877,3.223,1.313,4.879,1.872
        c0.209,0.07,0.649-0.117,0.78-0.318c0.611-0.932,1.183-1.894,1.713-2.875c2.61-4.827,4.609-9.916,6.479-15.065
        c2.399-6.604,4.459-13.337,7.591-19.665c0.917-1.853,1.653-3.812,2.29-5.781c0.622-1.922,0.186-2.557-1.794-2.885
        C84.164,24.109,79.272,23.353,74.24,22.552z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M74.172,84.129c0.35,5.35-4.114,8.685-7.284,8.45
        c-4.307-0.318-7.687-3.101-7.799-7.827c-0.112-4.767,4.452-8.235,8.654-7.777C71.113,77.343,74.534,79.268,74.172,84.129z
        M70.893,84.023c-0.113-0.043-0.226-0.086-0.338-0.13c0-0.359,0.02-0.721-0.004-1.079c-0.137-2.164-1.281-3.575-3.187-4.557
        c-1.042-0.538-1.611,0.294-2.248,0.607c-2.188,1.077-3.702,2.738-3.754,5.388c-0.036,1.889,1.151,3.816,2.909,4.562
        c2.362,1.002,3.524,0.9,5.046-1.043C70.123,86.742,70.385,85.287,70.893,84.023z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M45.563,78.626c0.727,0.17,1.509,0.222,2.173,0.53
        c4.92,2.283,5.732,9.367,1.419,12.55c-1.111,0.82-2.36,1.502-3.631,2.049c-3.641,1.564-7.518-0.38-8.394-4.262
        c-0.709-3.144-0.039-6.12,2.133-8.503C40.873,79.225,43.205,78.854,45.563,78.626z M38.828,86.026
        c0.069,5.089,2.637,6.615,6.2,4.611c0.347-0.194,0.704-0.382,1.021-0.619c1.387-1.042,2.673-2.233,2.157-4.167
        c-0.609-2.28-1.947-4.138-4.15-5.114c-0.603-0.268-1.637-0.153-2.208,0.207C39.676,82.312,38.884,84.476,38.828,86.026z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M74.24,22.552c5.032,0.801,9.924,1.558,14.808,2.368
        c1.979,0.329,2.416,0.963,1.794,2.885c-0.637,1.969-1.373,3.929-2.29,5.781c-3.132,6.327-5.191,13.061-7.591,19.665
        c-1.87,5.149-3.869,10.238-6.479,15.065c-0.53,0.981-1.102,1.943-1.713,2.875c-0.131,0.201-0.571,0.389-0.78,0.318
        c-1.656-0.559-3.401-0.995-4.879-1.872c-0.634-0.377-1.045-1.745-0.963-2.605c0.335-3.494,0.818-6.979,1.399-10.442
        c1.349-8.033,2.737-16.06,4.212-24.071c0.541-2.94,1.365-5.829,2.071-8.738C73.921,23.399,74.076,23.034,74.24,22.552z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M70.893,84.023c-0.508,1.264-0.77,2.719-1.575,3.748
        c-1.521,1.943-2.684,2.045-5.046,1.043c-1.758-0.745-2.945-2.673-2.909-4.562c0.052-2.649,1.565-4.311,3.754-5.388
        c0.637-0.313,1.206-1.146,2.248-0.607c1.905,0.981,3.05,2.393,3.187,4.557c0.023,0.358,0.004,0.72,0.004,1.079
        C70.667,83.937,70.779,83.98,70.893,84.023z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M38.828,86.026c0.056-1.55,0.848-3.714,3.019-5.082
        c0.571-0.36,1.606-0.475,2.208-0.207c2.203,0.977,3.541,2.834,4.15,5.114c0.516,1.934-0.77,3.125-2.157,4.167
        c-0.317,0.237-0.673,0.425-1.021,0.619C41.465,92.641,38.896,91.115,38.828,86.026z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TFragenIcon'
}
</script>
