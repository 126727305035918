<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="10 10 93.28 93.28" enable-background="new 10 10 93.28 93.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M113.76,0.24c0,37.76,0,75.521,0,113.28c-37.84,0-75.68,0-113.52,0 M57.7,93.761
        c0-1.552-0.002-2.985,0.001-4.42c0.002-0.57,0.13-0.901,0.853-1.007c2.642-0.383,5.297-0.761,7.885-1.393
        c1.14-0.277,1.649,0.028,2.243,0.854c0.858,1.192,1.78,2.347,2.77,3.431c0.223,0.244,0.836,0.133,1.269,0.185
        c0.031-0.441,0.111-0.887,0.071-1.321c-0.019-0.201-0.289-0.37-0.413-0.573c-0.802-1.315-1.594-2.636-2.442-4.043
        c0.461-0.213,0.806-0.404,1.172-0.536c3.155-1.126,6.023-2.733,8.556-4.947c0.239-0.209,0.836-0.249,1.141-0.104
        c1.65,0.792,3.26,1.667,4.886,2.511c0.318,0.164,0.646,0.44,0.967,0.438c0.432-0.003,1.032-0.101,1.245-0.39
        c0.216-0.294,0.156-0.907,0.015-1.31c-0.118-0.339-0.491-0.628-0.813-0.849c-1.437-0.982-2.896-1.933-4.09-2.725
        c1.661-2.667,3.322-5.372,5.042-8.039c0.184-0.284,0.695-0.464,1.073-0.498c1.985-0.181,3.983-0.245,5.961-0.481
        c0.398-0.048,0.729-0.668,1.09-1.024c-0.395-0.373-0.75-0.809-1.2-1.09c-0.221-0.138-0.62,0.012-0.938,0.029
        c-1.544,0.084-3.089,0.167-4.414,0.238c0.319-2.621,0.61-5.221,0.984-7.809c0.049-0.334,0.482-0.781,0.82-0.874
        c1.733-0.473,3.498-0.828,5.241-1.265c0.666-0.167,1.318-0.589,0.936-1.335c-0.191-0.374-0.93-0.538-1.452-0.644
        c-0.313-0.063-0.681,0.195-1.032,0.262c-1.43,0.269-2.862,0.519-4.325,0.782c-0.435-3.757-1.213-7.167-2.81-10.344
        c-0.369-0.735-0.262-1.191,0.44-1.68c1.603-1.116,3.172-2.286,4.676-3.529c0.317-0.262,0.297-0.931,0.432-1.413
        c-0.51-0.079-1.098-0.356-1.51-0.191c-0.685,0.273-1.229,0.874-1.879,1.26c-1.281,0.761-2.593,1.47-3.628,2.051
        c-2.707-3.006-5.207-5.782-7.773-8.63c0.119-0.168,0.359-0.481,0.57-0.813c0.835-1.313,1.701-2.609,2.475-3.958
        c0.405-0.708,0.257-1.446-0.512-1.854c-0.723-0.384-1.311-0.042-1.759,0.567c-0.189,0.257-0.346,0.539-0.533,0.797
        c-0.885,1.22-1.774,2.436-2.522,3.46c-3.362-1.678-6.561-3.274-9.833-4.908c0.018-0.09,0.078-0.319,0.109-0.551
        c0.194-1.422,0.422-2.842,0.558-4.27c0.073-0.782-0.238-1.458-1.119-1.585c-0.855-0.123-1.229,0.437-1.36,1.186
        c-0.096,0.548-0.127,1.108-0.216,1.658c-0.204,1.262-0.425,2.521-0.637,3.773c-0.926-0.377-1.572-0.837-2.242-0.876
        c-1.89-0.108-3.792-0.111-5.683-0.018c-0.875,0.043-1.446,0.06-1.58-1.015c-0.148-1.187-0.458-2.353-0.684-3.531
        c-0.126-0.659-0.284-1.384-1.135-1.235c-0.955,0.167-0.759,0.951-0.631,1.634c0.27,1.442,0.534,2.885,0.824,4.45
        c-2.232,0.426-4.33,0.826-6.577,1.254c-0.493-1.741-0.98-3.382-1.418-5.036c-0.401-1.521-0.915-2.088-1.83-1.9
        c-0.956,0.196-1.212,1.014-0.776,2.596c0.462,1.676,0.95,3.346,1.362,4.792c-3.347,1.85-6.605,3.616-9.824,5.453
        c-0.882,0.503-1.346,0.302-1.814-0.515c-0.655-1.142-1.368-2.253-2.121-3.333c-0.155-0.222-0.597-0.242-0.907-0.355
        c-0.068,0.325-0.216,0.659-0.185,0.975c0.035,0.345,0.196,0.695,0.371,1.005c0.705,1.247,1.436,2.479,2.149,3.704
        c-0.566,0.396-1.19,0.707-1.651,1.177c-2.509,2.557-4.621,5.388-6.041,8.716c-0.867,2.035-0.93,1.998-3.134,1.532
        c-0.609-0.128-1.233-0.185-1.85-0.274c0.822,1.669,2.43,1.437,3.715,1.95c-0.894,3.935-1.431,7.841-1.243,11.815
        c0.033,0.709-0.317,0.896-0.926,0.937c-0.956,0.062-1.917,0.115-2.859,0.269c-0.412,0.066-0.785,0.37-1.175,0.565
        c0.379,0.203,0.759,0.579,1.138,0.578c1.228-0.001,2.456-0.14,3.824-0.235c0.474,3.936,1.284,7.772,3.39,11.208
        c-1.286,0.659-2.454,1.186-3.536,1.852c-0.425,0.262-0.657,0.839-0.976,1.272c0.536,0.027,1.084,0.133,1.605,0.057
        c0.417-0.062,0.803-0.339,1.198-0.53c0.819-0.396,1.634-0.8,2.126-1.041c2.376,2.148,4.532,4.142,6.746,6.069
        c0.575,0.499,0.743,0.884,0.425,1.61c-0.688,1.574-1.327,3.175-1.87,4.803c-0.148,0.445,0.014,1.074,0.23,1.523
        c0.087,0.18,0.796,0.257,1.06,0.104c0.332-0.194,0.538-0.652,0.726-1.032c0.751-1.513,1.474-3.039,2.092-4.32
        c3.283,1.269,6.24,2.411,8.998,3.476c-0.091,1.873-0.196,3.689-0.26,5.508c-0.025,0.705,0.03,1.364,1.055,1.358
        c1.003-0.006,1.106-0.639,1.27-1.394c0.313-1.438,0.675-2.871,1.127-4.271c0.09-0.28,0.694-0.606,1.021-0.568
        c2.649,0.315,5.288,0.713,8.035,1.1c0,1.614,0,3.31,0,5.062C56.275,93.761,56.979,93.761,57.7,93.761z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M57.7,93.761c-0.721,0-1.425,0-2.173,0c0-1.752,0-3.447,0-5.062
        c-2.747-0.387-5.386-0.784-8.035-1.1c-0.327-0.038-0.931,0.288-1.021,0.568c-0.452,1.4-0.814,2.833-1.127,4.271
        c-0.164,0.755-0.267,1.388-1.27,1.394c-1.025,0.006-1.08-0.653-1.055-1.358c0.063-1.818,0.168-3.635,0.26-5.508
        c-2.757-1.064-5.715-2.207-8.998-3.476c-0.618,1.281-1.341,2.808-2.092,4.32c-0.188,0.38-0.395,0.838-0.726,1.032
        c-0.264,0.153-0.973,0.076-1.06-0.104c-0.217-0.449-0.379-1.078-0.23-1.523c0.543-1.628,1.182-3.229,1.87-4.803
        c0.317-0.727,0.149-1.111-0.425-1.61c-2.214-1.928-4.37-3.921-6.746-6.069c-0.492,0.241-1.307,0.645-2.126,1.041
        c-0.395,0.191-0.782,0.469-1.198,0.53c-0.521,0.076-1.069-0.029-1.605-0.057c0.319-0.434,0.551-1.011,0.976-1.272
        c1.082-0.666,2.25-1.192,3.536-1.852c-2.106-3.436-2.917-7.272-3.39-11.208c-1.368,0.096-2.596,0.234-3.824,0.235
        c-0.379,0.001-0.759-0.375-1.138-0.578c0.391-0.195,0.764-0.499,1.175-0.565c0.943-0.153,1.904-0.206,2.859-0.269
        c0.609-0.04,0.959-0.228,0.926-0.937c-0.188-3.975,0.349-7.88,1.243-11.815c-1.285-0.513-2.894-0.281-3.715-1.95
        c0.617,0.089,1.241,0.146,1.85,0.274c2.205,0.466,2.267,0.502,3.134-1.532c1.42-3.328,3.532-6.159,6.041-8.716
        c0.461-0.47,1.085-0.781,1.651-1.177c-0.714-1.225-1.445-2.457-2.149-3.704c-0.175-0.31-0.336-0.66-0.371-1.005
        c-0.032-0.316,0.116-0.649,0.185-0.975c0.31,0.113,0.752,0.133,0.907,0.355c0.753,1.079,1.466,2.19,2.121,3.333
        c0.468,0.817,0.932,1.018,1.814,0.515c3.219-1.836,6.478-3.603,9.824-5.453c-0.412-1.446-0.9-3.116-1.362-4.792
        c-0.436-1.582-0.18-2.4,0.776-2.596c0.915-0.188,1.428,0.379,1.83,1.9c0.438,1.654,0.925,3.294,1.418,5.036
        c2.247-0.429,4.345-0.829,6.577-1.254c-0.29-1.565-0.554-3.008-0.824-4.45c-0.128-0.683-0.324-1.468,0.631-1.634
        c0.851-0.148,1.009,0.577,1.135,1.235c0.226,1.178,0.536,2.344,0.684,3.531c0.134,1.075,0.706,1.058,1.58,1.015
        c1.891-0.093,3.793-0.09,5.683,0.018c0.67,0.039,1.316,0.499,2.242,0.876c0.212-1.251,0.433-2.511,0.637-3.773
        c0.089-0.55,0.12-1.109,0.216-1.658c0.132-0.749,0.505-1.309,1.36-1.186c0.881,0.127,1.192,0.803,1.119,1.585
        c-0.136,1.428-0.363,2.848-0.558,4.27c-0.031,0.232-0.092,0.461-0.109,0.551c3.272,1.633,6.471,3.229,9.833,4.908
        c0.748-1.024,1.638-2.24,2.522-3.46c0.188-0.258,0.344-0.54,0.533-0.797c0.448-0.608,1.036-0.951,1.759-0.567
        c0.769,0.408,0.917,1.146,0.512,1.854c-0.773,1.349-1.64,2.645-2.475,3.958c-0.211,0.332-0.451,0.645-0.57,0.813
        c2.566,2.849,5.066,5.625,7.773,8.63c1.035-0.582,2.347-1.29,3.628-2.051c0.649-0.386,1.194-0.986,1.879-1.26
        c0.412-0.165,1,0.112,1.51,0.191c-0.135,0.481-0.114,1.151-0.432,1.413c-1.504,1.243-3.073,2.413-4.676,3.529
        c-0.702,0.489-0.81,0.945-0.44,1.68c1.597,3.177,2.375,6.586,2.81,10.344c1.463-0.263,2.896-0.513,4.325-0.782
        c0.352-0.066,0.719-0.325,1.032-0.262c0.522,0.106,1.261,0.27,1.452,0.644c0.383,0.746-0.27,1.168-0.936,1.335
        c-1.743,0.437-3.508,0.792-5.241,1.265c-0.338,0.093-0.771,0.54-0.82,0.874c-0.374,2.588-0.665,5.188-0.984,7.809
        c1.325-0.071,2.87-0.154,4.414-0.238c0.318-0.018,0.718-0.167,0.938-0.029c0.45,0.281,0.806,0.717,1.2,1.09
        c-0.361,0.356-0.691,0.977-1.09,1.024c-1.978,0.236-3.976,0.301-5.961,0.481c-0.378,0.034-0.89,0.214-1.073,0.498
        c-1.72,2.667-3.381,5.372-5.042,8.039c1.194,0.792,2.653,1.742,4.09,2.725c0.322,0.221,0.695,0.51,0.813,0.849
        c0.142,0.402,0.201,1.016-0.015,1.31c-0.213,0.289-0.813,0.387-1.245,0.39c-0.321,0.002-0.648-0.274-0.967-0.438
        c-1.626-0.844-3.235-1.719-4.886-2.511c-0.305-0.146-0.901-0.105-1.141,0.104c-2.532,2.214-5.4,3.821-8.556,4.947
        c-0.366,0.132-0.711,0.323-1.172,0.536c0.849,1.407,1.641,2.728,2.442,4.043c0.124,0.203,0.395,0.372,0.413,0.573
        c0.04,0.435-0.04,0.88-0.071,1.321c-0.433-0.052-1.046,0.06-1.269-0.185c-0.989-1.084-1.911-2.238-2.77-3.431
        c-0.594-0.825-1.104-1.131-2.243-0.854c-2.588,0.632-5.243,1.01-7.885,1.393c-0.723,0.105-0.851,0.437-0.853,1.007
        C57.698,90.776,57.7,92.209,57.7,93.761z M26.007,47.593c0.751,0.068,1.303,0.06,1.826,0.176c1.395,0.311,2.814,0.571,4.15,1.054
        c0.875,0.316,1.402,1.106,0.976,2.138c-0.354,0.854-1.349,1.123-2.371,0.686c-0.22-0.094-0.427-0.218-0.635-0.338
        c-1.398-0.809-2.794-1.62-4.381-2.541c-0.989,4.02-1.5,7.845-1.353,12.086c1.095-0.32,2.006-0.609,2.929-0.851
        c0.836-0.218,1.683-0.518,2.531-0.538c0.931-0.021,1.619,0.55,1.614,1.564c-0.004,0.973-0.706,1.472-1.572,1.477
        c-0.776,0.005-1.69-0.067-2.3-0.473c-1.107-0.735-2.089-0.373-3.195-0.151c0.34,3.416,0.912,6.673,2.623,9.433
        c1.554-0.714,2.918-1.476,4.374-1.969c1.266-0.43,2.504,0.628,2.098,1.729c-0.204,0.552-0.917,1.065-1.514,1.291
        c-1.179,0.445-2.438,0.678-4.097,1.111c2.034,1.972,3.799,3.683,5.66,5.487c0.384-0.749,0.605-1.217,0.859-1.666
        c0.275-0.487,0.545-0.983,0.882-1.426c0.512-0.673,1.208-0.994,1.991-0.525c0.777,0.464,0.68,1.183,0.335,1.899
        c-0.329,0.685-0.668,1.364-0.978,2.058c-0.665,1.492-0.609,2.036,0.828,2.688c2.099,0.95,4.306,1.661,6.47,2.464
        c0.092,0.034,0.223-0.033,0.448-0.074c0.132-0.886,0.274-1.783,0.398-2.684c0.186-1.356,0.721-1.941,1.709-1.847
        c0.974,0.093,1.5,0.795,1.448,2.111c-0.041,1.026-0.202,2.047-0.333,3.286c2.273,0.301,4.479,0.56,6.674,0.896
        c0.894,0.138,1.185-0.18,1.087-1.014c-0.051-0.436-0.131-0.871-0.13-1.307c0.001-0.635-0.053-1.307,0.14-1.889
        c0.135-0.406,0.708-1.003,0.976-0.951c0.425,0.084,0.802,0.604,1.113,1.006c0.149,0.192,0.108,0.537,0.14,0.814
        c0.117,1.057,0.229,2.114,0.379,3.502c2.924-0.499,5.75-0.98,8.813-1.503c-0.501-0.772-0.808-1.229-1.099-1.696
        c-0.527-0.847-1.172-1.651-1.514-2.568c-0.174-0.467,0.036-1.457,0.378-1.64c0.455-0.244,1.25-0.039,1.816,0.183
        c0.347,0.136,0.559,0.65,0.798,1.017c0.692,1.062,1.369,2.134,2.051,3.199c3.215-0.975,6.09-2.513,8.734-4.66
        c-0.893-0.518-1.673-0.922-2.402-1.403c-0.748-0.494-1.12-1.222-0.628-2.058c0.481-0.817,1.28-0.693,2.024-0.375
        c0.917,0.394,1.79,0.91,2.734,1.209c0.421,0.133,1.165,0.038,1.424-0.254c1.162-1.309,2.244-2.695,3.27-4.115
        c0.56-0.776,0.948-1.675,1.574-2.808c-1.461,0-2.535,0.044-3.602-0.026c-0.327-0.021-0.634-0.354-0.95-0.545
        c0.197-0.315,0.324-0.768,0.609-0.916c0.441-0.229,0.985-0.319,1.494-0.353c3.491-0.231,3.522-0.217,4.078-3.69
        c0.234-1.464,0.309-2.954,0.47-4.579c-1.308,0.169-2.285,0.359-3.27,0.404c-0.925,0.041-1.686-0.353-1.788-1.383
        c-0.1-1.008,0.589-1.42,1.46-1.563c1.093-0.179,2.193-0.315,3.442-0.491c-0.33-3.27-1.155-6.319-2.685-9.197
        c-1.263,0.732-2.354,1.395-3.476,2.005c-0.771,0.42-1.576,0.494-2.119-0.349c-0.533-0.828-0.175-1.546,0.513-2.098
        c0.465-0.373,0.996-0.662,1.498-0.987c0.686-0.445,1.371-0.889,2.105-1.365c-1.995-2.919-4.127-5.415-6.82-7.481
        c-0.762,1.11-1.408,2.17-2.188,3.121c-0.267,0.324-0.928,0.592-1.3,0.49c-0.395-0.108-0.88-0.663-0.926-1.068
        c-0.06-0.534,0.186-1.156,0.449-1.662c0.488-0.938,1.086-1.818,1.832-3.04c-2.776-1.294-5.364-2.507-7.959-3.705
        c-0.805-0.372-1.104,0.015-1.314,0.793c-0.28,1.032-0.682,2.038-1.121,3.016c-0.137,0.304-0.563,0.479-0.858,0.712
        c-0.168-0.368-0.485-0.739-0.478-1.103c0.026-1.148,0.175-2.293,0.265-3.314c-1.459-0.632-2.798-1.226-4.151-1.786
        c-0.245-0.101-0.565-0.136-0.821-0.074c-0.987,0.236-1.963,0.523-2.949,0.793c0.137,1.155,0.303,2.262,0.374,3.375
        c0.019,0.291-0.216,0.809-0.416,0.853c-0.317,0.069-0.812-0.071-1.032-0.308c-0.278-0.298-0.417-0.769-0.506-1.188
        c-0.179-0.843-0.284-1.702-0.435-2.652c-2.072,0.439-4.006,0.85-6.124,1.298c0.342,0.999,0.626,1.817,0.903,2.638
        c0.306,0.908,0.683,1.803,0.864,2.736c0.064,0.332-0.353,0.758-0.551,1.141c-0.372-0.224-0.79-0.399-1.097-0.692
        c-0.173-0.164-0.141-0.529-0.237-0.79c-0.544-1.487-1.099-2.971-1.771-4.78c-3.319,1.764-6.451,3.428-9.627,5.116
        c0.783,0.932,1.356,1.594,1.905,2.274c0.551,0.683,1.059,1.4,1.62,2.075c0.744,0.897,0.806,1.715,0.13,2.332
        c-0.679,0.62-1.748,0.568-2.444-0.27c-0.709-0.854-1.289-1.818-1.901-2.75c-0.449-0.683-0.857-1.391-1.363-2.217
        C29.834,40.139,27.803,43.625,26.007,47.593z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M26.007,47.593c1.796-3.968,3.828-7.455,7.51-10.007
        c0.506,0.827,0.915,1.535,1.363,2.217c0.612,0.932,1.191,1.896,1.901,2.75c0.696,0.838,1.765,0.889,2.444,0.27
        c0.676-0.617,0.614-1.435-0.13-2.332c-0.561-0.675-1.069-1.393-1.62-2.075c-0.549-0.681-1.122-1.342-1.905-2.274
        c3.176-1.688,6.308-3.352,9.627-5.116c0.672,1.81,1.227,3.293,1.771,4.78c0.096,0.261,0.064,0.626,0.237,0.79
        c0.307,0.292,0.725,0.468,1.097,0.692c0.198-0.383,0.615-0.809,0.551-1.141c-0.182-0.933-0.559-1.828-0.864-2.736
        c-0.277-0.821-0.561-1.64-0.903-2.638c2.118-0.449,4.052-0.859,6.124-1.298c0.151,0.95,0.256,1.809,0.435,2.652
        c0.089,0.42,0.229,0.891,0.506,1.188c0.22,0.237,0.714,0.377,1.032,0.308c0.2-0.044,0.435-0.562,0.416-0.853
        c-0.071-1.113-0.237-2.219-0.374-3.375c0.985-0.27,1.961-0.558,2.949-0.793c0.256-0.062,0.576-0.027,0.821,0.074
        c1.354,0.56,2.692,1.154,4.151,1.786c-0.09,1.021-0.238,2.167-0.265,3.314c-0.008,0.364,0.31,0.735,0.478,1.103
        c0.295-0.233,0.722-0.408,0.858-0.712c0.439-0.978,0.841-1.984,1.121-3.016c0.211-0.778,0.51-1.166,1.314-0.793
        c2.595,1.198,5.183,2.411,7.959,3.705c-0.746,1.222-1.344,2.102-1.832,3.04c-0.264,0.506-0.509,1.128-0.449,1.662
        c0.046,0.405,0.531,0.959,0.926,1.068c0.372,0.103,1.033-0.166,1.3-0.49c0.779-0.951,1.426-2.011,2.188-3.121
        c2.693,2.066,4.825,4.562,6.82,7.481c-0.734,0.476-1.42,0.92-2.105,1.365c-0.502,0.326-1.033,0.615-1.498,0.987
        c-0.688,0.551-1.046,1.27-0.513,2.098c0.543,0.842,1.349,0.769,2.119,0.349c1.121-0.61,2.213-1.272,3.476-2.005
        c1.529,2.878,2.354,5.927,2.685,9.197c-1.249,0.176-2.35,0.312-3.442,0.491c-0.871,0.143-1.56,0.555-1.46,1.563
        c0.103,1.03,0.863,1.424,1.788,1.383c0.984-0.045,1.962-0.235,3.27-0.404c-0.161,1.625-0.235,3.115-0.47,4.579
        c-0.556,3.474-0.587,3.459-4.078,3.69c-0.509,0.033-1.053,0.123-1.494,0.353c-0.285,0.148-0.412,0.601-0.609,0.916
        c0.316,0.19,0.623,0.523,0.95,0.545c1.066,0.07,2.141,0.026,3.602,0.026c-0.626,1.133-1.015,2.031-1.574,2.808
        c-1.025,1.42-2.107,2.807-3.27,4.115c-0.259,0.292-1.003,0.387-1.424,0.254c-0.944-0.299-1.817-0.815-2.734-1.209
        c-0.744-0.318-1.543-0.442-2.024,0.375c-0.492,0.836-0.12,1.563,0.628,2.058c0.729,0.481,1.51,0.886,2.402,1.403
        c-2.645,2.147-5.52,3.686-8.734,4.66c-0.682-1.065-1.358-2.138-2.051-3.199c-0.239-0.366-0.451-0.881-0.798-1.017
        c-0.566-0.222-1.361-0.427-1.816-0.183c-0.342,0.183-0.552,1.173-0.378,1.64c0.342,0.917,0.986,1.722,1.514,2.568
        c0.291,0.467,0.598,0.924,1.099,1.696c-3.063,0.522-5.89,1.004-8.813,1.503c-0.149-1.388-0.262-2.445-0.379-3.502
        c-0.031-0.277,0.01-0.622-0.14-0.814c-0.311-0.402-0.688-0.922-1.113-1.006c-0.267-0.052-0.84,0.545-0.976,0.951
        c-0.193,0.582-0.139,1.254-0.14,1.889c-0.001,0.436,0.08,0.871,0.13,1.307c0.098,0.834-0.193,1.151-1.087,1.014
        c-2.194-0.337-4.401-0.596-6.674-0.896c0.13-1.239,0.292-2.26,0.333-3.286c0.052-1.316-0.474-2.019-1.448-2.111
        c-0.988-0.095-1.523,0.49-1.709,1.847c-0.124,0.9-0.266,1.798-0.398,2.684c-0.226,0.041-0.356,0.108-0.448,0.074
        c-2.165-0.803-4.372-1.514-6.47-2.464c-1.437-0.651-1.493-1.195-0.828-2.688c0.309-0.693,0.649-1.373,0.978-2.058
        c0.344-0.717,0.442-1.436-0.335-1.899c-0.784-0.469-1.479-0.147-1.991,0.525c-0.337,0.442-0.607,0.938-0.882,1.426
        c-0.254,0.449-0.475,0.917-0.859,1.666c-1.861-1.805-3.626-3.516-5.66-5.487c1.659-0.434,2.918-0.666,4.097-1.111
        c0.598-0.226,1.311-0.739,1.514-1.291c0.406-1.102-0.833-2.159-2.098-1.729c-1.456,0.493-2.82,1.255-4.374,1.969
        c-1.711-2.76-2.282-6.017-2.623-9.433c1.106-0.222,2.087-0.584,3.195,0.151c0.61,0.405,1.524,0.478,2.3,0.473
        c0.866-0.005,1.567-0.504,1.572-1.477c0.005-1.015-0.684-1.586-1.614-1.564c-0.848,0.021-1.695,0.32-2.531,0.538
        c-0.923,0.241-1.833,0.53-2.929,0.851c-0.147-4.241,0.364-8.066,1.353-12.086c1.586,0.92,2.983,1.732,4.381,2.541
        c0.208,0.12,0.415,0.244,0.635,0.338c1.021,0.437,2.017,0.168,2.371-0.686c0.426-1.031-0.101-1.821-0.976-2.138
        c-1.336-0.483-2.755-0.744-4.15-1.054C27.31,47.653,26.758,47.661,26.007,47.593z M60.312,50.125
        c0.473,0.458,0.854,1.128,1.259,1.143c0.487,0.018,1.278-0.412,1.446-0.84c1.407-3.583,2.712-7.207,4.006-10.833
        c0.105-0.293-0.095-0.695-0.153-1.046c-0.397,0.075-0.946,0.014-1.161,0.253c-0.467,0.52-0.812,1.169-1.119,1.807
        c-0.86,1.789-1.691,3.593-2.507,5.403C61.497,47.308,60.954,48.625,60.312,50.125z M66.667,67.568
        c-0.739,0.06-1.183-0.009-1.523,0.147c-0.488,0.224-0.902,0.611-1.349,0.929c0.413,0.349,0.773,0.868,1.249,1.016
        c1.748,0.543,3.525,1.001,5.309,1.415c1.314,0.306,2.649,0.599,3.987,0.671c0.446,0.024,1.232-0.594,1.314-1.022
        c0.086-0.452-0.419-1.083-0.795-1.528c-0.189-0.225-0.658-0.235-1.008-0.301c-1.171-0.219-2.348-0.411-3.519-0.628
        C69.003,68.021,67.676,67.761,66.667,67.568z M50.633,48.04c-0.256-0.546-0.372-0.989-0.631-1.319
        c-1.87-2.383-3.752-4.758-5.681-7.094c-0.218-0.264-0.75-0.27-1.137-0.395c-0.113,0.392-0.326,0.787-0.312,1.174
        c0.013,0.376,0.202,0.78,0.412,1.11c1.047,1.647,2.105,3.288,3.204,4.9c0.537,0.789,1.101,1.586,1.79,2.232
        c0.337,0.315,1.02,0.455,1.49,0.369C50.103,48.956,50.346,48.386,50.633,48.04z M40.38,76.094
        c0.342,0.354,0.699,1.038,1.155,1.111c0.478,0.076,1.327-0.294,1.535-0.709c1.191-2.374,2.28-4.804,3.301-7.257
        c0.184-0.442-0.11-1.084-0.183-1.633c-0.533,0.232-1.082,0.436-1.592,0.712c-0.188,0.103-0.303,0.364-0.42,0.57
        c-1.104,1.933-2.21,3.863-3.292,5.808C40.693,75.037,40.612,75.435,40.38,76.094z M53.279,42.672
        c0.188,1.549,0.403,3.407,0.642,5.263c0.117,0.906,0.59,1.578,1.569,1.402c0.981-0.176,1.14-1.028,1.038-1.88
        c-0.114-0.95-0.337-1.887-0.45-2.837c-0.215-1.821-0.344-3.653-0.611-5.466c-0.052-0.356-0.565-0.645-0.865-0.964
        c-0.301,0.316-0.788,0.592-0.867,0.956C53.507,40.194,53.445,41.276,53.279,42.672z M66.618,54.724
        c2.421-1.225,4.847-2.398,7.196-3.708c0.287-0.16,0.402-1.192,0.175-1.557c-0.231-0.37-1.014-0.617-1.504-0.55
        c-0.638,0.086-1.246,0.5-1.831,0.837c-1.518,0.875-3.05,1.735-4.497,2.717c-0.357,0.243-0.519,0.917-0.562,1.412
        C65.576,54.075,66.114,54.324,66.618,54.724z M43.096,53.5c0.368-0.354,0.986-0.685,0.982-1.007
        c-0.006-0.501-0.308-1.186-0.711-1.46c-1.314-0.895-2.71-1.679-4.117-2.427c-0.91-0.484-1.866-0.929-2.855-1.194
        c-0.41-0.11-0.958,0.296-1.444,0.468c0.239,0.403,0.381,0.951,0.733,1.187c1.887,1.264,3.818,2.462,5.751,3.655
        C41.899,53.008,42.426,53.19,43.096,53.5z M52.053,74.853c-0.124-1.196-0.183-2.477-0.427-3.72
        c-0.082-0.416-0.635-0.74-0.973-1.105c-0.307,0.362-0.849,0.705-0.881,1.091c-0.181,2.177-0.278,4.363-0.319,6.549
        c-0.016,0.807,0.494,1.358,1.36,1.351c0.846-0.008,1.183-0.612,1.227-1.347C52.094,76.759,52.053,75.841,52.053,74.853z
        M73.804,61.141c0.886-0.104,1.993-0.239,3.101-0.366c0.814-0.093,1.421-0.451,1.336-1.352c-0.084-0.88-0.774-1.185-1.554-1.166
        c-0.713,0.018-1.425,0.159-2.135,0.265c-1.771,0.264-3.547,0.513-5.309,0.834c-0.263,0.048-0.461,0.453-0.688,0.693
        c0.284,0.225,0.546,0.604,0.855,0.647C70.792,60.89,72.187,60.988,73.804,61.141z M35.873,57.893
        c1.896-0.264,3.746-0.534,5.6-0.773c0.814-0.105,1.582-0.306,1.5-1.308c-0.083-1.023-0.973-1.338-1.76-1.201
        c-2.147,0.375-4.271,0.898-6.389,1.423c-0.265,0.066-0.636,0.523-0.607,0.756c0.039,0.318,0.362,0.676,0.659,0.869
        C35.154,57.839,35.557,57.828,35.873,57.893z M57.074,71.569c-0.024,0.004-0.048,0.007-0.072,0.011
        c0.263,1.77,0.496,3.544,0.801,5.306c0.145,0.837,0.688,1.39,1.591,1.279c0.965-0.119,1.254-0.887,1.072-1.685
        c-0.447-1.972-0.973-3.928-1.567-5.859c-0.1-0.323-0.744-0.717-1.065-0.663c-0.302,0.052-0.538,0.64-0.745,1.022
        C57.005,71.133,57.074,71.371,57.074,71.569z M36.537,66.906c2.045-0.402,3.633-1.386,5.042-2.622
        c0.653-0.574,0.297-1.601-0.545-1.76c-0.48-0.091-1.103-0.081-1.499,0.155c-1.259,0.752-2.471,1.595-3.632,2.49
        c-0.266,0.205-0.443,0.839-0.323,1.141C35.697,66.609,36.243,66.739,36.537,66.906z M59.889,57.039
        c-0.002,0.023-0.004,0.047-0.006,0.07c-0.517,0-1.056-0.091-1.542,0.028c-0.405,0.099-0.749,0.446-1.12,0.684
        c0.286,0.383,0.501,0.992,0.87,1.108c1.439,0.449,2.918,0.793,4.401,1.071c0.789,0.148,1.61-0.043,1.703-1.04
        c0.09-0.978-0.598-1.426-1.467-1.566C61.787,57.243,60.836,57.155,59.889,57.039z M51.859,61.802
        c0.695,1.479,1.306,2.941,2.063,4.324c0.403,0.736,1.185,1.023,1.952,0.478c0.739-0.526,0.57-1.249,0.157-1.921
        c-0.711-1.158-1.405-2.328-2.175-3.446c-0.235-0.342-0.696-0.529-1.053-0.787C52.529,60.84,52.257,61.232,51.859,61.802z
        M52.41,55.372c-0.323-0.52-0.462-1.003-0.684-1.044c-0.418-0.079-0.978-0.036-1.31,0.197c-0.537,0.378-0.885,1.018-1.41,1.422
        c-0.664,0.512-1.022,1.065-0.505,1.81c0.562,0.807,1.486,0.853,2.083,0.262C51.334,57.278,51.815,56.265,52.41,55.372z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M60.312,50.125c0.643-1.5,1.186-2.817,1.771-4.115
        c0.815-1.81,1.646-3.614,2.507-5.403c0.308-0.638,0.652-1.287,1.119-1.807c0.215-0.239,0.764-0.178,1.161-0.253
        c0.059,0.352,0.259,0.753,0.153,1.046c-1.294,3.626-2.599,7.25-4.006,10.833c-0.168,0.429-0.959,0.858-1.446,0.84
        C61.165,51.252,60.784,50.582,60.312,50.125z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M66.667,67.568c1.009,0.193,2.336,0.453,3.666,0.698
        c1.171,0.217,2.348,0.409,3.519,0.628c0.35,0.065,0.818,0.076,1.008,0.301c0.376,0.445,0.881,1.076,0.795,1.528
        c-0.082,0.429-0.868,1.047-1.314,1.022c-1.338-0.072-2.673-0.365-3.987-0.671c-1.783-0.414-3.561-0.872-5.309-1.415
        c-0.476-0.147-0.836-0.667-1.249-1.016c0.446-0.317,0.86-0.705,1.349-0.929C65.484,67.559,65.928,67.627,66.667,67.568z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M50.633,48.04c-0.287,0.346-0.53,0.916-0.866,0.977
        c-0.471,0.086-1.153-0.054-1.49-0.369c-0.689-0.646-1.253-1.443-1.79-2.232c-1.099-1.613-2.157-3.253-3.204-4.9
        c-0.21-0.33-0.399-0.733-0.412-1.11c-0.014-0.387,0.199-0.782,0.312-1.174c0.387,0.125,0.919,0.131,1.137,0.395
        c1.929,2.336,3.811,4.71,5.681,7.094C50.261,47.051,50.377,47.494,50.633,48.04z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M40.38,76.094c0.232-0.659,0.313-1.058,0.502-1.397
        c1.083-1.944,2.188-3.875,3.292-5.808c0.118-0.206,0.232-0.468,0.42-0.57c0.51-0.276,1.059-0.479,1.592-0.712
        c0.073,0.549,0.367,1.19,0.183,1.633c-1.021,2.453-2.109,4.883-3.301,7.257c-0.208,0.415-1.057,0.785-1.535,0.709
        C41.08,77.132,40.722,76.449,40.38,76.094z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M53.279,42.672c0.166-1.396,0.228-2.479,0.455-3.526
        c0.079-0.364,0.566-0.64,0.867-0.956c0.3,0.319,0.813,0.608,0.865,0.964c0.267,1.813,0.396,3.646,0.611,5.466
        c0.113,0.95,0.336,1.887,0.45,2.837c0.103,0.852-0.057,1.704-1.038,1.88c-0.979,0.176-1.453-0.496-1.569-1.402
        C53.683,46.08,53.467,44.222,53.279,42.672z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M66.618,54.724c-0.504-0.4-1.042-0.649-1.023-0.848
        c0.044-0.495,0.205-1.169,0.562-1.412c1.447-0.982,2.979-1.842,4.497-2.717c0.585-0.337,1.193-0.75,1.831-0.837
        c0.49-0.067,1.272,0.18,1.504,0.55c0.228,0.364,0.112,1.397-0.175,1.557C71.465,52.326,69.039,53.5,66.618,54.724z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M43.096,53.5c-0.67-0.31-1.197-0.493-1.661-0.779
        c-1.933-1.193-3.865-2.391-5.751-3.655c-0.352-0.235-0.494-0.783-0.733-1.187c0.485-0.172,1.034-0.578,1.444-0.468
        c0.989,0.265,1.945,0.709,2.855,1.194c1.406,0.749,2.802,1.532,4.117,2.427c0.403,0.274,0.705,0.958,0.711,1.46
        C44.083,52.815,43.464,53.146,43.096,53.5z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M52.053,74.853c0,0.988,0.041,1.906-0.013,2.818
        c-0.044,0.734-0.381,1.339-1.227,1.347c-0.867,0.008-1.376-0.544-1.36-1.351c0.041-2.186,0.138-4.372,0.319-6.549
        c0.032-0.386,0.575-0.729,0.881-1.091c0.338,0.365,0.892,0.689,0.973,1.105C51.87,72.376,51.929,73.657,52.053,74.853z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M73.804,61.141c-1.617-0.153-3.012-0.251-4.394-0.444
        c-0.31-0.044-0.571-0.423-0.855-0.647c0.228-0.24,0.426-0.646,0.688-0.693c1.762-0.321,3.537-0.57,5.309-0.834
        c0.71-0.105,1.422-0.247,2.135-0.265c0.779-0.019,1.47,0.286,1.554,1.166c0.085,0.9-0.521,1.259-1.336,1.352
        C75.797,60.902,74.689,61.037,73.804,61.141z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M35.873,57.893c-0.316-0.065-0.719-0.054-0.997-0.233
        c-0.297-0.193-0.62-0.551-0.659-0.869c-0.028-0.233,0.342-0.69,0.607-0.756c2.118-0.525,4.241-1.048,6.389-1.423
        c0.787-0.138,1.676,0.177,1.76,1.201c0.082,1.002-0.686,1.203-1.5,1.308C39.618,57.359,37.769,57.629,35.873,57.893z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M57.074,71.569c0-0.198-0.069-0.436,0.014-0.589
        c0.207-0.383,0.443-0.971,0.745-1.022c0.321-0.054,0.966,0.34,1.065,0.663c0.595,1.932,1.12,3.888,1.567,5.859
        c0.182,0.798-0.107,1.565-1.072,1.685c-0.902,0.11-1.446-0.442-1.591-1.279c-0.305-1.762-0.538-3.536-0.801-5.306
        C57.026,71.576,57.05,71.573,57.074,71.569z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M36.537,66.906c-0.294-0.167-0.84-0.297-0.958-0.596
        c-0.12-0.302,0.058-0.936,0.323-1.141c1.162-0.896,2.373-1.738,3.632-2.49c0.396-0.236,1.019-0.246,1.499-0.155
        c0.843,0.159,1.199,1.186,0.545,1.76C40.17,65.52,38.582,66.503,36.537,66.906z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M59.889,57.039c0.947,0.116,1.898,0.204,2.84,0.356
        c0.869,0.14,1.557,0.588,1.467,1.566c-0.093,0.997-0.914,1.188-1.703,1.04c-1.483-0.278-2.962-0.622-4.401-1.071
        c-0.369-0.116-0.584-0.726-0.87-1.108c0.371-0.237,0.715-0.584,1.12-0.684c0.486-0.12,1.025-0.028,1.542-0.028
        C59.885,57.085,59.887,57.062,59.889,57.039z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M51.859,61.802c0.398-0.57,0.67-0.962,0.943-1.353
        c0.357,0.258,0.818,0.445,1.053,0.787c0.77,1.118,1.464,2.288,2.175,3.446c0.413,0.672,0.583,1.395-0.157,1.921
        c-0.767,0.546-1.549,0.259-1.952-0.478C53.165,64.744,52.554,63.281,51.859,61.802z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M52.41,55.372c-0.595,0.893-1.076,1.906-1.825,2.647
        c-0.598,0.591-1.521,0.545-2.083-0.262c-0.518-0.744-0.159-1.298,0.505-1.81c0.524-0.404,0.873-1.044,1.41-1.422
        c0.332-0.233,0.892-0.276,1.31-0.197C51.948,54.369,52.087,54.852,52.41,55.372z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CoronaIcon'
}
</script>
