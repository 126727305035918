<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M55.346,13.142c-0.724,0.438-1.486,0.78-2.106,1.295c-1.873,1.553-3.637,3.242-5.543,4.751
        c-2.912,2.306-6.049,4.347-8.839,6.787c-5.415,4.735-10.639,9.688-15.976,14.513c-2.12,1.917-4.372,3.689-6.464,5.634
        c-0.471,0.438-0.745,1.33-0.73,2.005c0.071,3.465,0.276,6.927,0.415,10.391c0.174,4.351,0.268,8.706,0.512,13.053
        c0.208,3.707,0.511,7.411,0.902,11.104c0.446,4.206,1.036,8.397,1.565,12.595c0.141,1.118,0.827,1.75,1.9,1.828
        c2.988,0.218,5.98,0.522,8.97,0.512c5.111-0.02,10.221-0.246,15.332-0.397c10.423-0.308,20.847-0.589,31.268-0.955
        c3.507-0.124,7.004-0.5,10.509-0.71c2.027-0.122,4.085-0.366,6.085-0.147c2.073,0.227,2.969-0.307,2.887-2.398
        c-0.276-7.027-0.545-14.055-0.829-21.081c-0.058-1.437-0.104-2.878-0.262-4.306c-0.68-6.148-1.405-12.292-2.088-18.44
        c-0.032-0.292,0.106-0.679,0.3-0.908c0.918-1.088,0.931-1.878-0.11-2.91c-0.937-0.928-1.913-1.817-2.895-2.699
        c-3.567-3.205-6.972-6.623-10.764-9.537c-4.029-3.096-8.025-6.204-11.777-9.633c-3.48-3.18-7.022-6.294-10.562-9.409
        C56.616,13.702,56.027,13.509,55.346,13.142z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M55.346,13.142c0.682,0.368,1.271,0.56,1.698,0.936
        c3.54,3.114,7.082,6.229,10.562,9.409c3.752,3.429,7.748,6.537,11.777,9.633c3.792,2.914,7.196,6.332,10.764,9.537
        c0.981,0.882,1.958,1.771,2.895,2.699c1.041,1.032,1.028,1.822,0.11,2.91c-0.193,0.229-0.332,0.616-0.3,0.908
        c0.683,6.148,1.408,12.292,2.088,18.44c0.158,1.428,0.204,2.869,0.262,4.306c0.284,7.026,0.553,14.054,0.829,21.081
        c0.082,2.092-0.813,2.625-2.887,2.398c-2-0.219-4.058,0.025-6.085,0.147c-3.505,0.21-7.002,0.586-10.509,0.71
        c-10.421,0.366-20.845,0.647-31.268,0.955c-5.11,0.151-10.221,0.378-15.332,0.397c-2.99,0.011-5.982-0.294-8.97-0.512
        c-1.073-0.078-1.759-0.71-1.9-1.828c-0.529-4.197-1.119-8.389-1.565-12.595c-0.391-3.692-0.694-7.396-0.902-11.104
        c-0.245-4.347-0.338-8.702-0.512-13.053c-0.139-3.464-0.344-6.926-0.415-10.391c-0.014-0.675,0.26-1.567,0.73-2.005
        c2.092-1.945,4.344-3.718,6.464-5.634c5.336-4.825,10.561-9.777,15.976-14.513c2.791-2.44,5.927-4.481,8.839-6.787
        c1.906-1.51,3.67-3.198,5.543-4.751C53.859,13.922,54.622,13.579,55.346,13.142z M92.13,93.93c0-0.45,0.022-0.689-0.003-0.923
        c-0.766-6.93-0.68-13.886-0.652-20.841c0.007-1.558-0.104-3.115-0.151-4.673c-0.149-4.87-0.289-9.74-0.441-14.609
        c-0.045-1.445,0.563-2.988-0.349-4.307c-0.736-1.065-1.625-2.045-2.552-2.957c-3.65-3.587-7.29-7.188-11.031-10.679
        c-4.238-3.953-8.568-7.808-12.885-11.676c-2.173-1.947-4.413-3.817-6.6-5.749c-0.72-0.636-1.303-0.772-1.999,0.05
        c-0.563,0.666-1.196,1.305-1.9,1.812c-3.468,2.5-7.029,4.874-10.444,7.443c-3.063,2.306-5.988,4.796-8.958,7.224
        c-4.579,3.744-9.151,7.495-13.712,11.261c-0.959,0.792-1.245,1.795-1.122,3.093c0.282,2.979,0.464,5.974,0.507,8.965
        c0.055,3.792-0.14,7.587-0.095,11.379c0.05,4.155,0.216,8.311,0.397,12.463c0.165,3.793,0.4,7.583,0.662,11.371
        c0.023,0.342,0.443,0.919,0.716,0.941c2.903,0.241,5.812,0.518,8.722,0.54c5.354,0.042,10.709-0.134,16.063-0.123
        c7.118,0.014,14.236,0.106,21.354,0.193c1.513,0.019,3.024,0.228,4.536,0.227c4.47-0.001,8.939-0.035,13.409-0.113
        C87.741,94.208,89.88,94.042,92.13,93.93z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M92.13,93.93c-2.25,0.112-4.389,0.277-6.528,0.314
        c-4.47,0.078-8.939,0.112-13.409,0.113c-1.512,0.001-3.023-0.208-4.536-0.227c-7.117-0.087-14.235-0.18-21.354-0.193
        c-5.354-0.011-10.709,0.165-16.063,0.123c-2.91-0.022-5.819-0.299-8.722-0.54c-0.273-0.022-0.693-0.6-0.716-0.941
        c-0.262-3.788-0.497-7.578-0.662-11.371c-0.181-4.152-0.347-8.308-0.397-12.463c-0.045-3.792,0.149-7.587,0.095-11.379
        c-0.043-2.991-0.225-5.986-0.507-8.965c-0.123-1.298,0.163-2.3,1.122-3.093c4.561-3.766,9.133-7.518,13.712-11.261
        c2.971-2.428,5.895-4.918,8.958-7.224c3.415-2.569,6.976-4.944,10.444-7.443c0.705-0.508,1.337-1.147,1.9-1.812
        c0.696-0.822,1.279-0.687,1.999-0.05c2.187,1.932,4.427,3.802,6.6,5.749c4.316,3.868,8.646,7.723,12.885,11.676
        c3.741,3.49,7.381,7.091,11.031,10.679c0.927,0.911,1.815,1.891,2.552,2.957c0.912,1.318,0.304,2.861,0.349,4.307
        c0.152,4.869,0.292,9.739,0.441,14.609c0.047,1.558,0.158,3.115,0.151,4.673c-0.027,6.955-0.113,13.911,0.652,20.841
        C92.152,93.241,92.13,93.48,92.13,93.93z M36.966,59.588c0-0.56-0.005-1.119,0.001-1.679c0.006-0.599,0.037-1.198,0.034-1.797
        c-0.008-1.516-0.878-2.644-2.203-2.877c-1.354-0.238-2.468,0.5-2.958,2.032c-0.181,0.565-0.309,1.17-0.326,1.761
        c-0.062,2.157-0.227,4.329-0.056,6.472c0.272,3.405,1.04,6.754,2.75,9.736c2.431,4.237,6.011,7.35,10.51,9.205
        c4.149,1.712,8.493,2.54,12.985,1.353c5.091-1.346,9.751-3.539,13.65-7.152c2.181-2.019,3.828-4.413,4.575-7.317
        c0.436-1.692,0.689-3.432,1.018-5.15c0.18-0.941,0.363-1.882,0.514-2.828c0.318-1.997,0.624-3.996,0.914-5.997
        c0.062-0.428,0.187-0.95,0.008-1.282c-0.203-0.381-0.711-0.6-1.087-0.889c-0.21,0.38-0.517,0.74-0.604,1.147
        c-0.098,0.448,0.101,0.96,0.006,1.409c-0.648,3.079-1.37,6.144-2.011,9.225c-0.806,3.876-2.873,7.003-5.765,9.583
        c-2.606,2.325-5.578,4.047-8.826,5.435c-3.66,1.564-7.295,2.245-11.162,1.273c-0.902-0.227-1.944-0.541-2.553-1.172
        c-2.239-2.319-4.387-4.736-6.43-7.23c-0.708-0.865-1.122-2.024-1.483-3.111C37.376,66.449,36.851,63.064,36.966,59.588z
        M63.594,49.909c-0.018-2.251-1.377-3.833-3.271-3.806c-1.601,0.024-2.623,1.281-2.619,3.221c0.006,2.214,1.31,3.73,3.199,3.718
        C62.562,53.032,63.608,51.813,63.594,49.909z M53.315,50.224c0.027-1.953-1.43-3.602-3.212-3.633
        c-1.472-0.025-2.655,1.118-2.706,2.614c-0.063,1.847,1.522,3.543,3.309,3.542C52.197,52.746,53.294,51.684,53.315,50.224z"/>
      <path fill-rule="evenodd" fill="currentColor"  clip-rule="evenodd" d="M36.966,59.588c-0.116,3.476,0.41,6.86,1.501,10.147
        c0.361,1.087,0.774,2.246,1.483,3.111c2.043,2.494,4.191,4.911,6.43,7.23c0.608,0.631,1.651,0.945,2.553,1.172
        c3.867,0.972,7.502,0.291,11.162-1.273c3.248-1.388,6.22-3.109,8.826-5.435c2.892-2.58,4.959-5.707,5.765-9.583
        c0.641-3.081,1.362-6.146,2.011-9.225c0.095-0.449-0.104-0.961-0.006-1.409c0.088-0.407,0.395-0.767,0.604-1.147
        c0.376,0.289,0.884,0.507,1.087,0.889c0.179,0.333,0.055,0.854-0.008,1.282c-0.29,2.001-0.596,4-0.914,5.997
        c-0.15,0.946-0.334,1.887-0.514,2.828c-0.328,1.719-0.582,3.458-1.018,5.15c-0.747,2.904-2.395,5.299-4.575,7.317
        c-3.899,3.613-8.56,5.807-13.65,7.152c-4.492,1.188-8.836,0.359-12.985-1.353c-4.499-1.855-8.079-4.968-10.51-9.205
        c-1.711-2.982-2.478-6.331-2.75-9.736c-0.171-2.143-0.006-4.314,0.056-6.472c0.017-0.591,0.145-1.196,0.326-1.761
        c0.491-1.532,1.604-2.27,2.958-2.032c1.325,0.233,2.195,1.361,2.203,2.877c0.003,0.599-0.027,1.198-0.034,1.797
        C36.961,58.469,36.966,59.029,36.966,59.588z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M63.594,49.909c0.015,1.905-1.031,3.123-2.69,3.134
        c-1.89,0.012-3.193-1.504-3.199-3.718c-0.004-1.94,1.019-3.197,2.619-3.221C62.217,46.076,63.576,47.657,63.594,49.909z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M53.315,50.224c-0.021,1.46-1.118,2.521-2.609,2.523
        c-1.787,0.001-3.372-1.695-3.309-3.542c0.051-1.496,1.234-2.639,2.706-2.614C51.885,46.623,53.342,48.271,53.315,50.224z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SchuleIcon'
}
</script>
