<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentContainer
      :themeColor="computedTopicColor"
      title='Sonderpädagogischer Dienst'
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Unsere Zielgruppe"
        :textArray="[
          'Der Sonderpädagogische Dienst berät und unterstützt die allgemeine Schule im Umgang mit Schüler*innen mit langanhaltenden bzw. chronischen Erkrankungen. Dabei ist die Einbeziehung des sonderpädagogischen Dienstes immer mit konkreten Fragestellungen verbunden und zeitlich befristet.',
          'Der sonderpädagogische Dienst berät und unterstützt Schüler*innen, Eltern, Schulleitungen und Lehrkräfte.'
        ]"
        noteText="Ziel des sonderpädagogischen Dienstes ist die Optimierung von Bildungs- und Lernprozessen und somit die Stärkung von Aktivität und Teilhabe bei Erkrankung oder Verunfallung."
      />
      <ContentImageTile
        embedded
        imageSource="/img/grafiken/beratung.jpg"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Unsere Aufgabenfelder"
        :textArray="[]"
        downloadText='Informationen zum "Sonderpädagogischen Dienst"'
        downloadFileName="Hans-Lebrecht-Schule_SD.pdf"
      >
        <ul
          slot="content"
        >
          <li>
            Beratung hinsichtlich der Auswirkungen der Krankheit auf den Schulalltag und Unterricht
          </li>
          <li>
            Fallbezogene Beratung (zu Nachteilsausgleich, Hausunterricht, Medikamentengabe...)
          </li>
          <li>
            Beratung in Bezug auf individuelle Bildungsangebote und Bildungswege (Schullaufbahnberatung)
          </li>
          <li>
            Unterstützung zu Teilhabe und Aktivität am gesamten schulischen Leben
          </li>
          <li>
            Beratung in Bezug auf außerschulische Netzwerke und Unterstützungssysteme
          </li>
          <li>
            Information und Beratung zu Krankheitsbildern
          </li>
          <li>
            Teilnahme an Expert*innenrunden
          </li>
        </ul>
      </ContentTile>
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title='„Was ist denn schon normal?“'
      subtitle="Mein Aufenthalt in der Klinik für Kinder- und Jugendpsychiatrie und Psychotherapie am Universitätsklinikum Ulm"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Über falsche Vorstellungen"
        :textArray="[
          'Tom trägt einen blauen Kapuzenpulli und weiß, was alle denken: Er war in der „Klapse“ – vollgepumpt mit Medikamenten, in Zwangsjacke und Gummizelle…',
          'So stellen sich viele die Kinder- und Jugendpsychiatrie vor.',
          'Auch Tom wusste nicht, was ihn erwartet, als er wegen seiner Wut- und Angstattacken in die Klinik kam.',
          'Damit es anderen nicht so geht, erklärt Comicfigur Tom in diesem einzigartigen Comicbilderbuch anderen Kindern, was die Kinder- und Jugendpsychiatrie eigentlich ist und was er dort gemacht hat.'
        ]"
        downloadText='Buch "Was ist denn schon normal?"'
        downloadFileName="Hans-Lebrecht-Schule_Widsn.pdf"
      />
      <ContentImageTile
        embedded
        imageSource="/img/projekte/was_ist_schon_normal/cover.jpg"
      />
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title='Informationen und Broschüren'
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        :title="null"
        :textArray="[]"
        downloadText='Informationen zum Unterricht'
        downloadFileName="Hans-Lebrecht-Schule_Unterricht.pdf"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        :title="null"
        :textArray="[]"
        downloadText='Informationen zu unseren Grundprinzipien'
        downloadFileName="Hans-Lebrecht-Schule_Prinzipien.pdf"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        :title="null"
        :textArray="[]"
        downloadText='Antworten auf häufige Fragen'
        downloadFileName="Hans-Lebrecht-Schule_FAQ.pdf"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        :title="null"
        :textArray="[]"
        downloadText='Informationen zu den Vorsichtsmaßnahmen'
        downloadFileName="Hans-Lebrecht-Schule_Corona.pdf"
      />
    </ContentContainer>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'

import ContentContainer from '@/views/components/ContentContainer.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'

export default {
  name: 'Service',
  components: {
    ContentTile,
    ContentContainer,
    ContentFrame,
    ContentImageTile
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.service.color
    },
    computedTitle () {
      return this.menuData.service.title
    }
  }
}
</script>

<style scoped>
</style>
