<template>
  <v-col
    cols="12"
    md="6"
  >
    <v-sheet
      light
      :color="embedded ? '#e0e0e0' : '#f0f0f0'"
      class="pa-4"
      height="100%"
    >
      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          :class="{
            'text-h6 text-sm-h5 text-md-h4': !embedded,
            'text-h6 text-md-h5': embedded
          }"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ title }}
        </v-col>
        <v-col
          v-if="subtitle"
          class="text-subtitle-1"
          cols="12"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ subtitle }}
        </v-col>
      </v-row>
      <v-row
        align="center"
      >
        <v-col
          cols="12"
        >
          <div id="video-wrapper">
            <Media
              :kind="'video'"
              :src="videoSources"
              :poster="posterSource"
              :type="videoType"
              :autoplay="false"
              :controls="true"
              :controlsList="['nodownload']"
              :loop="false"
              :muted="false"
              disablepictureinpicture
              preload="metadata"
              style="width: 100%; outline: none !important;"
            >
            </Media>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>
import Media from './VideoPlayer.vue'

export default {
  name: 'ContentVideoTile',
  components: {
    Media
  },
  props: {
    title: String,
    subtitle: String,
    videoSource: String,
    posterSource: { type: String, default: null },
    videoType: String,
    embedded: { type: Boolean, default: false }
  },
  data () {
    return {
      videoSources: (this.posterSource === null) ? [this.videoSource + '#t=0.5'] : [this.videoSource]
    }
  },
  mounted () { },
  methods: { },
  computed: { }
}
</script>
