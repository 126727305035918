<template>
  <v-dialog
    v-model="isVisible"
    transition="dialog-top-transition"
    scrollable
    content-class="corona-dialog"
  >
    <v-card
      :color="themeColor"
      class="dialog-card"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-btn
          color="red"
          fab
          @click="closeFunction"
          :elevation="hover ? 12 : 3"
          small
          class="dialog-close-button"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-hover>
      <v-card-title
        class='
          text-h5
          text-xl-h2
          text-lg-h3
          text-sm-h4
          py-2
          py-sm-3
          py-md-5
          py-lg-7
          py-xl-8
          pl-6
        '
      >
        Corona Virus
      </v-card-title>
      <v-card-subtitle>
        Vorsichtsmaßnahmen
      </v-card-subtitle>
      <v-card-text
        class="dialog-card-content card-content"
      >
        <v-row>
          <v-col
            v-for="(item, i) in items"
            :key="i"
            cols="12"
            md="6"
            xl="3"
          >
            <v-sheet
              light
              color="#f0f0f0"
              class="pa-4"
              height="100%"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  align="center"
                >
                  <v-img
                    contain
                    :src="item.image"
                    width="50%"
                    min-width="80"
                  >
                  </v-img>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class='
                        font-weight-bold
                        text-h6
                        text-sm-h5
                        text-md-h4
                      '
                      :style="{
                        'color': themeColor
                      }"
                      :align="$vuetify.breakpoint.xs ? 'center' : 'start'"
                    >
                      {{ item.title }}
                    </v-col>
                    <v-col
                      v-if="item.subtitle !== null"
                      cols="12"
                      :align="$vuetify.breakpoint.xs ? 'center' : 'start'"
                    >
                    {{ item.subtitle }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col
            cols="12"
          >
            <v-row
              justify="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <DownloadAlert
                  :color="themeColor"
                  text='Informationen zu Vorsichtsmaßnahmen'
                  fileName="Hans-Lebrecht-Schule_Corona.pdf"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <span
        class="card-content-border"
        :style="{
          'background-color': themeColor
        }"
      >
      </span>
    </v-card>
  </v-dialog>
</template>

<script>
import DownloadAlert from '@/views/components/DownloadAlert.vue'

export default {
  name: 'CoronaDialog',
  components: {
    DownloadAlert
  },
  props: {
    isVisible: Boolean,
    closeFunction: Function
  },
  data () {
    return {
      themeColor: this.$vuetify.theme.currentTheme.warning,
      items: [
        {
          image: '/img/corona/haende.png',
          title: 'Kein Händeschütteln',
          subtitle: null
        },
        {
          image: '/img/corona/umarm.png',
          title: 'Keine Umarmungen',
          subtitle: null
        },
        {
          image: '/img/corona/nies.png',
          title: 'Kein offenes Niesen',
          subtitle: 'Notfalls in die Ellenbeuge'
        },
        {
          image: '/img/corona/hust.png',
          title: 'Kein offenes Husten',
          subtitle: 'Notfalls in die Ellenbeuge'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style>
  .corona-dialog {
    overflow: visible !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .corona-dialog::before {
    content: " ";
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    backdrop-filter: blur(10px);
    z-index: -1;
  }

  .dialog-close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(50%, -50%);
  }

  .dialog-card-content {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

</style>
