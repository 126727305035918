<template>
  <v-col
    cols="12"
    md="6"
  >
    <v-sheet
      light
      :color="embedded ? '#e0e0e0' : '#f0f0f0'"
      class="pa-4"
      height="100%"
    >
      <v-row>
        <v-col
          class="font-weight-bold"
          cols="12"
          :class="{
            'text-h6 text-sm-h5 text-md-h4': !embedded,
            'text-h6 text-md-h5': embedded,
            'pb-0': subtitle != null
          }"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ title }}
        </v-col>
        <v-col
          v-if="subtitle"
          class="text-subtitle-1"
          :class="{
            'pt-0': title != null
          }"
          cols="12"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ subtitle }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(text, i) in textArray"
          :key="i"
          cols="12"
        >
          {{ text }}
        </v-col>
        <v-col
          v-if="hasContentSlot()"
          cols="12"
        >
          <slot name="content" />
        </v-col>
        <v-col
          v-if="noteText != null"
          cols="12"
        >
          <v-alert
            type="info"
            dense
            :color="themeColor"
            border="left"
            outlined
            class="content-tile-alert"
            :class="{
              'text-caption': $vuetify.breakpoint.xs
            }"
          >
            {{ noteText }}
          </v-alert>
        </v-col>
        <v-col
          v-if="downloadFileName != null"
          cols="12"
        >
          <template
            v-if="Array.isArray(downloadFileName)"
          >
            <DownloadAlert
              v-for="(download, i) in downloadFileName"
              :key="i"
              :color="themeColor"
              :text="downloadText[i]"
              :fileName="downloadFileName[i]"
            />
          </template>
          <template
            v-else
          >
          <DownloadAlert
            :color="themeColor"
            :text="downloadText"
            :fileName="downloadFileName"
          />
          </template>
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>
import DownloadAlert from '@/views/components/DownloadAlert.vue'

export default {
  name: 'ContentTile',
  components: {
    DownloadAlert
  },
  props: {
    themeColor: String,
    title: String,
    subtitle: String,
    textArray: Array,
    downloadFileName: String,
    downloadText: String,
    noteText: String,
    embedded: { type: Boolean, default: false }
  },
  data () {
    return { }
  },
  mounted () { },
  methods: {
    hasContentSlot () {
      return !!this.$slots.content
    }
  },
  computed: { }
}
</script>

<style scoped>
.content-tile-alert {
  background: white !important;
}
</style>
