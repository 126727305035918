<template>
  <v-container>
    <v-card
      :color="themeColor"
    >
      <v-card-title
        class='
          text-h5
          text-xl-h2
          text-lg-h3
          text-sm-h4
          py-4
          py-md-5
          py-lg-7
          py-xl-8
          pl-3
          pl-sm-6
        '
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        class="card-content neutral--text px-3 px-sm-6"
      >
        <span
          class="card-content-border"
          :style="{
            'background-color': themeColor
          }"
        >
        </span>
        <v-row
          class="py-6"
        >
          <slot>
          </slot>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'ContentFrame',
  components: { },
  props: {
    themeColor: String,
    title: String
  },
  data () {
    return { }
  },
  mounted () { },
  methods: { },
  computed: { },
  watch: { }
}
</script>
