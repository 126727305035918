<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M71.082,81.281c0.073,1.558,0.162,2.872,0.192,4.187c0.044,1.934,0.917,2.905,2.885,2.838
        c2.543-0.087,5.082-0.319,7.621-0.521c0.839-0.066,1.27-0.615,1.347-1.434c0.216-2.3,0.472-4.598,0.604-6.902
        c0.046-0.787,0.222-1.255,1.001-1.519c3.442-1.163,5.938-3.639,8.077-6.377c2.838-3.634,4.916-7.667,6.076-12.236
        c1.396-5.493,0.827-10.638-1.788-15.553c-2.248-4.227-4.497-8.51-8.196-11.711c-1.58-1.367-3.253-2.803-5.141-3.597
        c-3.294-1.386-6.752-2.432-10.208-3.377c-3.728-1.02-7.513-1.859-11.312-2.576c-1.623-0.307-3.416-0.429-5.004-0.07
        c-4.589,1.037-8.888,2.867-12.685,5.715c-1.042,0.781-1.983,1.694-3.108,2.664c-0.18-0.583-0.281-1.042-0.46-1.467
        c-0.756-1.792-1.397-3.651-2.342-5.338c-1.06-1.894-2.423-1.78-3.488,0.146c-2.147,3.882-3.203,8.065-3.379,12.485
        c-0.019,0.474,0.027,1.014-0.18,1.406c-1.769,3.342-3.784,6.498-7.004,8.675c-1.534,1.038-3.063,1.915-5.066,1.813
        c-2.644-0.134-3.79,0.696-4.578,3.262c-0.95,3.092-1.203,6.268-0.412,9.447c0.766,3.074,3.385,5.104,6.532,5.048
        c1.416-0.025,2.829-0.232,4.218-0.354c2.188,4.636,5.463,8.329,10.215,10.551c1.47,0.687,3.073,1.112,4.645,1.554
        c1.018,0.286,1.443,0.817,1.402,1.878c-0.093,2.43-0.114,4.862-0.169,7.295c-0.03,1.314,0.518,2.36,1.797,2.645
        c2.398,0.533,4.827,1.132,7.264,1.239c3.249,0.143,3.67-1.337,3.526-3.542c-0.129-1.967-0.027-3.948-0.027-6.147
        C59.763,82.445,65.376,82.499,71.082,81.281z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M71.082,81.281c-5.706,1.218-11.319,1.164-17.143,0.124
        c0,2.199-0.102,4.181,0.027,6.147c0.145,2.205-0.277,3.685-3.526,3.542c-2.437-0.107-4.865-0.706-7.264-1.239
        c-1.279-0.284-1.827-1.33-1.797-2.645c0.055-2.433,0.076-4.865,0.169-7.295c0.041-1.061-0.385-1.592-1.402-1.878
        c-1.571-0.441-3.174-0.867-4.645-1.554c-4.752-2.222-8.028-5.915-10.215-10.551c-1.389,0.122-2.802,0.329-4.218,0.354
        c-3.147,0.056-5.767-1.974-6.532-5.048c-0.792-3.179-0.538-6.355,0.412-9.447c0.789-2.566,1.934-3.396,4.578-3.262
        c2.003,0.102,3.532-0.775,5.066-1.813c3.22-2.177,5.236-5.333,7.004-8.675c0.207-0.392,0.162-0.932,0.18-1.406
        c0.177-4.42,1.232-8.603,3.379-12.485c1.065-1.926,2.428-2.04,3.488-0.146c0.945,1.688,1.586,3.546,2.342,5.338
        c0.179,0.425,0.28,0.884,0.46,1.467c1.125-0.97,2.066-1.883,3.108-2.664c3.797-2.848,8.096-4.678,12.685-5.715
        c1.588-0.359,3.381-0.237,5.004,0.07c3.799,0.717,7.584,1.557,11.312,2.576c3.456,0.945,6.914,1.991,10.208,3.377
        c1.888,0.794,3.561,2.23,5.141,3.597c3.699,3.201,5.948,7.485,8.196,11.711c2.615,4.915,3.184,10.06,1.788,15.553
        c-1.16,4.569-3.238,8.603-6.076,12.236c-2.139,2.738-4.635,5.214-8.077,6.377c-0.779,0.264-0.955,0.731-1.001,1.519
        c-0.133,2.305-0.389,4.603-0.604,6.902c-0.077,0.818-0.508,1.367-1.347,1.434c-2.539,0.202-5.078,0.435-7.621,0.521
        c-1.968,0.067-2.841-0.904-2.885-2.838C71.244,84.153,71.155,82.838,71.082,81.281z M50.38,87.646
        c0.356-2.68,0.701-5.203,1.023-7.73c0.148-1.163,0.653-1.622,1.814-1.571c1.47,0.065,2.94,0.13,4.41,0.197
        c1.272,0.06,2.56,0.274,3.815,0.154c3.408-0.327,6.805-0.79,10.204-1.21c1.823-0.226,2.632,0.568,2.447,2.378
        c-0.178,1.744-0.295,3.495-0.465,5.24c-0.079,0.814,0.286,1.096,1.045,0.995c1.696-0.226,3.389-0.487,5.09-0.66
        c0.658-0.066,0.903-0.348,0.949-0.957c0.155-2.024,0.484-4.05,0.446-6.068c-0.035-1.804,0.688-2.837,2.251-3.541
        c0.975-0.438,1.962-0.942,2.782-1.614c3.915-3.213,6.707-7.268,8.431-12.006c1.769-4.861,2.406-9.818,0.076-14.689
        c-1.638-3.421-3.382-6.803-5.296-10.075c-1.275-2.18-2.46-4.594-4.876-5.79c-1.996-0.989-4.1-1.783-6.203-2.533
        c-5.177-1.845-10.622-2.213-16.031-2.691c-1.569-0.139-3.285-0.159-4.748,0.326c-5.281,1.752-10.436,3.792-14.297,8.124
        c-1.325,1.486-3.203,1.074-3.916-0.75c-0.478-1.223-0.817-2.5-1.208-3.755c-0.31-0.996-0.603-1.996-0.92-3.047
        c-0.294,0.17-0.397,0.192-0.423,0.249c-1.821,4.014-3.116,8.135-2.272,12.617c0.201,1.066-0.112,1.878-0.579,2.819
        c-1.74,3.505-4.984,5.401-7.909,7.631c-1.788,1.363-3.809,2.195-6.135,1.861c-1.108-0.159-1.655,0.436-1.914,1.372
        c-0.71,2.565-1.104,5.183-0.331,7.781c0.358,1.201,1.035,2.489,2.485,2.628c1.12,0.107,2.28-0.187,3.42-0.328
        c0.67-0.083,1.33-0.269,2.001-0.315c1.03-0.071,1.649,0.418,1.832,1.475c0.101,0.586,0.186,1.233,0.494,1.716
        c2.003,3.137,4.472,5.882,7.81,7.585c1.931,0.984,4.134,1.422,6.159,2.242c0.612,0.248,1.46,0.939,1.478,1.452
        c0.075,2.178-0.121,4.365-0.221,6.549c-0.058,1.261-0.124,2.521-0.194,3.94C45.518,87.646,47.934,87.646,50.38,87.646z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M50.38,87.646c-2.447,0-4.862,0-7.476,0
        c0.07-1.419,0.136-2.68,0.194-3.94c0.101-2.184,0.296-4.371,0.221-6.549c-0.018-0.513-0.866-1.204-1.478-1.452
        c-2.025-0.82-4.229-1.258-6.159-2.242c-3.338-1.703-5.806-4.448-7.81-7.585c-0.308-0.482-0.393-1.13-0.494-1.716
        c-0.182-1.057-0.801-1.546-1.832-1.475c-0.671,0.047-1.332,0.232-2.001,0.315c-1.14,0.142-2.3,0.436-3.42,0.328
        c-1.45-0.139-2.127-1.427-2.485-2.628c-0.774-2.598-0.379-5.215,0.331-7.781c0.259-0.936,0.805-1.531,1.914-1.372
        c2.326,0.333,4.348-0.498,6.135-1.861c2.925-2.23,6.169-4.126,7.909-7.631c0.467-0.941,0.779-1.753,0.579-2.819
        c-0.843-4.482,0.451-8.603,2.272-12.617c0.026-0.057,0.129-0.079,0.423-0.249c0.317,1.051,0.61,2.051,0.92,3.047
        c0.391,1.256,0.73,2.533,1.208,3.755c0.713,1.824,2.591,2.236,3.916,0.75c3.861-4.331,9.016-6.372,14.297-8.124
        c1.463-0.485,3.179-0.465,4.748-0.326c5.409,0.478,10.854,0.846,16.031,2.691c2.104,0.75,4.207,1.544,6.203,2.533
        c2.416,1.196,3.601,3.61,4.876,5.79c1.914,3.272,3.658,6.654,5.296,10.075c2.33,4.871,1.692,9.828-0.076,14.689
        c-1.724,4.738-4.516,8.793-8.431,12.006c-0.82,0.672-1.808,1.176-2.782,1.614c-1.562,0.704-2.286,1.737-2.251,3.541
        c0.038,2.019-0.291,4.044-0.446,6.068c-0.046,0.609-0.291,0.891-0.949,0.957c-1.701,0.173-3.394,0.435-5.09,0.66
        c-0.759,0.101-1.124-0.181-1.045-0.995c0.17-1.745,0.287-3.496,0.465-5.24c0.185-1.81-0.624-2.604-2.447-2.378
        c-3.399,0.42-6.796,0.883-10.204,1.21c-1.256,0.12-2.543-0.095-3.815-0.154c-1.47-0.067-2.94-0.132-4.41-0.197
        c-1.161-0.051-1.666,0.408-1.814,1.571C51.081,82.443,50.737,84.966,50.38,87.646z M61.675,29.762
        c-2.815,0.483-6.229,1.05-9.634,1.672c-0.53,0.097-1.04,0.405-1.514,0.691c-1.028,0.619-1.327,1.422-0.862,2.29
        c0.526,0.98,1.368,1.085,2.363,0.825c1.341-0.352,2.688-0.685,4.046-0.959c1.052-0.212,2.142-0.248,3.185-0.49
        c2.703-0.628,5.413-0.485,8.053,0.133c1.837,0.43,3.578,1.288,5.344,2c0.792,0.32,1.565,0.584,2.143-0.254
        c0.556-0.808,0.338-1.639-0.318-2.283c-0.417-0.409-0.969-0.689-1.48-0.993C69.698,30.434,66.001,30.114,61.675,29.762z
        M43.718,45.852c0.04-1.052-0.673-1.825-1.741-1.888c-1.04-0.062-1.869,0.691-1.895,1.72c-0.025,1.033,0.716,1.823,1.764,1.88
        C42.944,47.623,43.677,46.953,43.718,45.852z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M61.675,29.762c4.326,0.352,8.023,0.672,11.324,2.632
        c0.512,0.304,1.063,0.584,1.48,0.993c0.656,0.644,0.874,1.475,0.318,2.283c-0.577,0.839-1.351,0.574-2.143,0.254
        c-1.766-0.712-3.507-1.57-5.344-2c-2.64-0.619-5.35-0.762-8.053-0.133c-1.043,0.243-2.133,0.278-3.185,0.49
        c-1.358,0.274-2.705,0.607-4.046,0.959c-0.995,0.261-1.837,0.156-2.363-0.825c-0.465-0.867-0.167-1.671,0.862-2.29
        c0.474-0.286,0.983-0.594,1.514-0.691C55.445,30.812,58.859,30.245,61.675,29.762z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M43.718,45.852c-0.041,1.101-0.773,1.771-1.872,1.712
        c-1.048-0.057-1.79-0.847-1.764-1.88c0.026-1.029,0.855-1.782,1.895-1.72C43.044,44.027,43.757,44.8,43.718,45.852z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MithelfenIcon'
}
</script>
