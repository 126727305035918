<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="10 10 93.28 93.28" enable-background="new 10 10 93.28 93.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M77.302,43.803c-0.108-1.303-0.243-2.488-0.299-3.678c-0.202-4.322-1.293-8.407-3.733-11.979
        c-3.354-4.909-8.484-7.225-13.946-8.903c-0.727-0.223-1.614-0.167-2.372,0.003c-2.374,0.532-4.741,1.115-7.073,1.808
        c-1.561,0.464-3.272,0.854-4.542,1.786c-4.863,3.566-8.658,7.946-9.33,14.279c-0.155,1.464-0.17,2.958-0.078,4.428
        c0.06,0.953-0.33,1.183-1.128,1.242c-1.474,0.108-2.944,0.26-4.417,0.381c-1.48,0.122-1.961,0.614-2.019,2.112
        c-0.015,0.399-0.025,0.8-0.01,1.2c0.178,4.825,0.463,9.648,0.519,14.475c0.072,6.269-0.068,12.539-0.046,18.81
        c0.006,1.712,0.289,3.422,0.405,5.136c0.117,1.723,0.096,1.72,1.734,1.804c0.237,0.012,0.47,0.107,0.708,0.124
        c1.47,0.104,2.946,0.335,4.41,0.272c6.3-0.269,12.582-0.809,18.905-0.255c4.294,0.376,8.619,0.398,12.933,0.524
        c2.798,0.082,5.599,0.037,8.396,0.103c1.876,0.044,3.749,0.24,5.624,0.26c1.537,0.017,2.779-1.165,2.807-2.542
        c0.045-2.31,0.102-4.621,0.076-6.931c-0.035-3.075-0.176-6.148-0.214-9.224c-0.072-5.678-0.067-11.356-0.168-17.034
        c-0.04-2.274-0.277-4.545-0.422-6.818c-0.07-1.11-0.744-1.532-1.762-1.516c-0.598,0.009-1.193,0.114-1.791,0.127
        C79.44,43.819,78.413,43.803,77.302,43.803z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M77.302,43.803c1.111,0,2.139,0.016,3.166-0.006
        c0.598-0.013,1.193-0.118,1.791-0.127c1.018-0.016,1.691,0.405,1.762,1.516c0.145,2.273,0.382,4.543,0.422,6.818
        c0.101,5.678,0.096,11.356,0.168,17.034c0.038,3.075,0.179,6.148,0.214,9.224c0.025,2.31-0.031,4.621-0.076,6.931
        c-0.027,1.377-1.27,2.559-2.807,2.542c-1.875-0.02-3.748-0.216-5.624-0.26c-2.798-0.065-5.599-0.021-8.396-0.103
        c-4.313-0.126-8.639-0.148-12.933-0.524c-6.324-0.554-12.605-0.014-18.905,0.255c-1.463,0.062-2.94-0.168-4.41-0.272
        c-0.238-0.017-0.47-0.112-0.708-0.124c-1.638-0.084-1.617-0.081-1.734-1.804c-0.116-1.714-0.399-3.424-0.405-5.136
        c-0.022-6.271,0.118-12.541,0.046-18.81c-0.056-4.827-0.34-9.65-0.519-14.475c-0.015-0.399-0.005-0.8,0.01-1.2
        c0.058-1.498,0.539-1.99,2.019-2.112c1.473-0.122,2.943-0.273,4.417-0.381c0.798-0.059,1.188-0.289,1.128-1.242
        c-0.092-1.47-0.077-2.964,0.078-4.428c0.671-6.333,4.467-10.713,9.33-14.279c1.271-0.932,2.981-1.322,4.542-1.786
        c2.332-0.693,4.699-1.275,7.073-1.808c0.758-0.17,1.646-0.226,2.372-0.003c5.462,1.678,10.593,3.994,13.946,8.903
        c2.44,3.573,3.531,7.658,3.733,11.979C77.059,41.315,77.193,42.5,77.302,43.803z M30.582,85.544
        c2.335-0.117,4.516-0.196,6.692-0.342c3.824-0.256,7.642-0.62,11.469-0.812c3.83-0.191,7.668-0.315,11.502-0.312
        c6.629,0.005,13.258,0.126,19.887,0.215c0.722,0.01,0.981-0.256,0.977-0.981c-0.022-3.347-0.039-6.695,0.004-10.043
        c0.111-8.753,0.254-17.505,0.383-26.258c0.007-0.458,0.001-0.916,0.001-1.167c-4.271,0-8.374-0.04-12.476,0.011
        c-4.076,0.051-8.153,0.311-12.228,0.267c-5.824-0.064-11.646-0.409-17.471-0.453c-2.365-0.018-4.733,0.44-7.212,0.693
        c0,0.562-0.009,0.993,0.001,1.423c0.047,1.952,0.234,3.912,0.122,5.855c-0.244,4.218-0.736,8.423-0.948,12.643
        c-0.276,5.504-0.375,11.017-0.558,16.525C30.7,83.675,30.637,84.54,30.582,85.544z M39.544,39.809c0,0.479,0.043,0.964-0.01,1.438
        c-0.095,0.849,0.255,1.127,1.085,1.091c5.605-0.24,11.155,0.508,16.727,0.921c4.735,0.351,9.486,0.493,14.231,0.711
        c0.433,0.02,0.927,0.025,1.292-0.16c0.333-0.168,0.778-0.63,0.749-0.919c-0.251-2.496-0.394-5.031-0.972-7.458
        c-0.636-2.667-1.457-5.395-3.517-7.379c-3.177-3.062-7.021-5.103-11.112-6.666c-0.618-0.236-1.443-0.169-2.11,0.001
        c-2.465,0.632-4.961,1.215-7.34,2.101c-3.196,1.191-5.529,3.56-7.002,6.563C40.08,33.086,39.459,36.399,39.544,39.809z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M30.582,85.544c0.055-1.005,0.118-1.869,0.147-2.735
        c0.182-5.509,0.282-11.021,0.558-16.525c0.211-4.22,0.704-8.425,0.948-12.643c0.112-1.943-0.075-3.903-0.122-5.855
        c-0.01-0.431-0.001-0.862-0.001-1.423c2.479-0.253,4.848-0.711,7.212-0.693c5.825,0.043,11.646,0.389,17.471,0.453
        c4.074,0.044,8.151-0.216,12.228-0.267c4.102-0.051,8.204-0.011,12.476-0.011c0,0.251,0.006,0.709-0.001,1.167
        c-0.129,8.752-0.271,17.505-0.383,26.258c-0.043,3.348-0.026,6.696-0.004,10.043c0.005,0.726-0.255,0.991-0.977,0.981
        c-6.629-0.089-13.258-0.21-19.887-0.215c-3.834-0.003-7.672,0.121-11.502,0.312c-3.827,0.191-7.645,0.556-11.469,0.812
        C35.098,85.348,32.917,85.427,30.582,85.544z M51.89,66.115c0,0.078,0.028,0.171-0.004,0.232c-1.889,3.606-1.931,7.64-2.641,11.516
        c-0.219,1.198,0.243,1.649,1.439,1.609c0.876-0.03,1.751-0.122,2.624-0.218c1.465-0.161,2.924-0.413,4.394-0.507
        c2.087-0.134,4.186-0.093,6.27-0.252c1.32-0.101,1.791-0.668,1.63-1.987c-0.144-1.173-0.432-2.365-0.879-3.457
        c-0.914-2.236-1.979-4.412-2.978-6.614c2.225-1.104,3.281-1.886,3.594-3.271c0.357-1.582,0.706-3.262,0.548-4.848
        c-0.391-3.906-2.512-6.669-6.988-7.738c-2.723-0.65-8.227,2.013-9.448,4.129C47.147,58.7,47.997,62.999,51.89,66.115z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M39.544,39.809c-0.085-3.41,0.536-6.723,2.022-9.755
        c1.473-3.003,3.806-5.372,7.002-6.563c2.378-0.886,4.875-1.469,7.34-2.101c0.667-0.171,1.492-0.238,2.11-0.001
        c4.091,1.563,7.936,3.604,11.112,6.666c2.06,1.985,2.881,4.712,3.517,7.379c0.578,2.427,0.721,4.962,0.972,7.458
        c0.029,0.29-0.416,0.751-0.749,0.919c-0.365,0.185-0.859,0.18-1.292,0.16c-4.745-0.218-9.496-0.36-14.231-0.711
        c-5.571-0.414-11.122-1.161-16.727-0.921c-0.83,0.036-1.18-0.243-1.085-1.091C39.587,40.773,39.544,40.289,39.544,39.809z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M51.89,66.115c-3.894-3.116-4.743-7.415-2.439-11.405
        c1.221-2.116,6.725-4.779,9.448-4.129c4.477,1.069,6.598,3.832,6.988,7.738c0.158,1.586-0.19,3.266-0.548,4.848
        c-0.312,1.385-1.369,2.167-3.596,3.271c1.001,2.201,2.065,4.377,2.979,6.613c0.447,1.092,0.735,2.284,0.879,3.457
        c0.161,1.319-0.31,1.887-1.63,1.987c-2.084,0.159-4.183,0.118-6.27,0.252c-1.47,0.094-2.929,0.346-4.394,0.507
        c-0.872,0.096-1.748,0.188-2.624,0.218c-1.197,0.04-1.659-0.411-1.439-1.609c0.71-3.876,0.752-7.909,2.641-11.516
        C51.918,66.286,51.89,66.193,51.89,66.115z M62.684,76.488c-0.258-0.776-0.528-1.586-0.795-2.396
        c-0.583-1.772-1.168-3.545-1.744-5.319c-0.293-0.902-0.53-1.825-0.867-2.711c-0.261-0.684-0.155-1.111,0.524-1.461
        c2.004-1.032,2.992-2.789,3.387-4.938c0.544-2.963-1.197-5.22-4.164-5.476c-1.769-0.152-3.41,0.223-4.975,1.002
        c-2.028,1.01-3.104,2.976-2.817,5.216c0.247,1.923,1.307,3.363,2.995,4.223c0.882,0.449,0.989,1.032,0.857,1.826
        c-0.065,0.393-0.181,0.777-0.286,1.161c-0.884,3.212-1.771,6.424-2.669,9.681C55.566,77.033,59.033,76.767,62.684,76.488z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M62.684,76.488c-3.65,0.279-7.118,0.545-10.554,0.809
        c0.898-3.257,1.786-6.469,2.669-9.681c0.105-0.384,0.221-0.769,0.286-1.161c0.131-0.794,0.025-1.377-0.857-1.826
        c-1.688-0.859-2.748-2.3-2.995-4.223c-0.287-2.24,0.789-4.207,2.817-5.216c1.564-0.779,3.206-1.155,4.975-1.002
        c2.967,0.256,4.708,2.512,4.164,5.476c-0.395,2.148-1.383,3.905-3.387,4.938c-0.68,0.35-0.785,0.777-0.524,1.461
        c0.337,0.886,0.574,1.809,0.867,2.711c0.576,1.774,1.161,3.547,1.744,5.319C62.155,74.902,62.426,75.711,62.684,76.488z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DatenschutzIcon'
}
</script>
