<template>
  <v-col
    cols="12"
  >
    <v-sheet
      light
      :color="embedded ? '#e0e0e0' : '#f0f0f0'"
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          class="font-weight-bold"
          :class="{
            'text-h6 text-sm-h5 text-md-h4': !embedded,
            'text-h6 text-md-h5': embedded,
            'pb-0': subtitle != null
          }"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ title }}
        </v-col>
        <v-col
          v-if="subtitle"
          cols="12"
          class="font-weight-bold neutral--text"
          :class="{
            'pt-0': title != null
          }"
        >
          {{ subtitle }}
        </v-col>
      </v-row>
      <v-row>
        <slot></slot>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>

export default {
  name: 'ContentTile',
  components: {
  },
  props: {
    themeColor: String,
    title: String,
    subtitle: String,
    embedded: { type: Boolean, default: false }
  },
  data () {
    return { }
  },
  mounted () { },
  methods: { },
  computed: {
  }
}
</script>
