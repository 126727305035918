<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M70.194,66.211c0.062,0.504,0.136,0.842,0.141,1.181c0.074,4.659-0.354,9.256-1.735,13.733
        c-0.407,1.32,0.062,2.035,1.431,2.089c0.612,0.022,1.337-0.036,1.837-0.341c3.386-2.062,6.519-4.344,8.879-7.697
        c4.645-6.603,5.94-13.688,3.727-21.394c-0.359-1.251-1.201-2.418-2.028-3.458c-0.469-0.592-0.591-0.969-0.336-1.652
        c2.834-7.592,4.929-15.374,5.832-23.444c0.338-3.01,0.056-5.992-0.805-8.893c-0.818-2.765-2.759-3.665-5.365-2.458
        c-1.872,0.867-3.685,1.872-5.479,2.894c-3.466,1.974-7.045,3.794-10.309,6.064c-3.529,2.457-6.752,5.355-10.11,8.06
        c-0.268,0.215-0.554,0.406-0.937,0.686c-0.728-1.276-1.884-1.397-3.074-1.349c-1.314,0.055-2.635,0.115-3.938,0.279
        c-3.927,0.493-7.787,1.41-11.024,3.737c-2.243,1.611-4.293,3.591-6.101,5.688c-2.894,3.355-5.611,6.892-6.841,11.276
        c-0.113,0.402,0.171,0.917,0.271,1.378c0.484-0.141,1.03-0.186,1.439-0.444c0.627-0.396,1.094-1.074,1.744-1.404
        c4.139-2.108,8.349-4.054,12.968-4.89c1.33-0.241,2.671-0.423,4.007-0.633c0.043,0.121,0.086,0.243,0.129,0.364
        c-0.254,0.394-0.524,0.777-0.76,1.183c-2.196,3.798-4.32,7.641-6.607,11.384c-0.985,1.613-0.787,3.06,0.116,4.466
        c0.745,1.159,0.335,1.997-0.35,2.916c-0.812,1.088-1.65,2.167-2.333,3.335c-0.284,0.484-0.424,1.271-0.241,1.779
        c1.541,4.27,4.505,7.311,8.525,9.293c1.048,0.519,2.286,0.731,3.462,0.864c0.528,0.06,1.279-0.277,1.648-0.688
        c0.959-1.063,1.796-2.245,2.62-3.423c1.154-1.648,1.659-1.771,3.389-0.658c0.166,0.105,0.291,0.351,0.456,0.372
        c0.824,0.107,1.908,0.536,2.442,0.175c3.137-2.126,6.177-4.4,9.184-6.71C67.473,68.791,68.733,67.518,70.194,66.211z
        M23.153,92.214c-0.005,1.476,1.021,2.115,1.986,1.509c0.804-0.505,1.581-1.105,2.229-1.797c2.701-2.883,5.355-5.811,8.014-8.733
        c0.319-0.352,0.662-0.743,0.801-1.179c0.145-0.444,0.249-1.139,0.012-1.41c-0.298-0.342-0.981-0.538-1.46-0.478
        c-0.444,0.056-0.898,0.472-1.243,0.831c-3.149,3.277-6.284,6.569-9.396,9.884C23.652,91.312,23.353,91.918,23.153,92.214z
        M32.682,100.648c0.253-0.219,0.83-0.533,1.14-1.021c2.577-4.04,5.197-8.058,7.117-12.478c0.627-1.442,0.629-2.502-0.193-2.893
        c-0.897-0.427-1.676,0.056-2.329,1.646c-1.497,3.646-3.517,6.985-5.743,10.218c-0.564,0.818-1.086,1.688-1.477,2.6
        C30.775,99.708,31.36,100.595,32.682,100.648z M18.274,88.056c0.604-0.391,1.092-0.647,1.509-0.993
        c0.645-0.533,1.229-1.137,1.856-1.69c3.136-2.775,6.28-5.543,9.417-8.318c0.624-0.552,1.063-1.254,0.377-1.955
        c-0.731-0.747-1.422-0.27-2.059,0.346c-1.317,1.275-2.632,2.562-4.027,3.748c-2.521,2.147-5.125,4.201-7.634,6.363
        c-0.368,0.316-0.6,1.027-0.545,1.519C17.208,87.416,17.839,87.693,18.274,88.056z"/>
      <path fill="currentColor" d="M70.194,66.211c-1.461,1.307-2.722,2.579-4.13,3.661c-3.007,2.31-6.047,4.584-9.184,6.71
        c-0.534,0.361-1.618-0.066-2.442-0.175c-0.165-0.021-0.29-0.267-0.456-0.372c-1.729-1.113-2.233-0.99-3.389,0.658
        c-0.824,1.178-1.661,2.358-2.62,3.423c-0.369,0.409-1.121,0.746-1.648,0.688c-1.177-0.133-2.415-0.347-3.463-0.864
        c-4.021-1.982-6.983-5.023-8.524-9.293c-0.185-0.509-0.043-1.295,0.241-1.779c0.683-1.168,1.521-2.247,2.333-3.335
        c0.685-0.918,1.095-1.757,0.35-2.916c-0.902-1.406-1.102-2.853-0.116-4.466c2.287-3.743,4.411-7.585,6.607-11.384
        c0.233-0.405,0.506-0.79,0.76-1.183c-0.043-0.121-0.086-0.243-0.129-0.364c-1.336,0.209-2.677,0.392-4.007,0.633
        c-4.62,0.836-8.829,2.781-12.968,4.89c-0.65,0.331-1.117,1.007-1.744,1.404c-0.409,0.26-0.955,0.304-1.439,0.444
        c-0.1-0.462-0.384-0.976-0.271-1.378c1.229-4.386,3.948-7.921,6.841-11.276c1.808-2.097,3.857-4.076,6.101-5.688
        c3.238-2.327,7.098-3.244,11.024-3.737c1.305-0.164,2.625-0.225,3.938-0.279c1.19-0.049,2.347,0.072,3.074,1.349
        c0.382-0.279,0.668-0.471,0.937-0.686c3.358-2.703,6.581-5.603,10.11-8.06c3.264-2.271,6.842-4.091,10.309-6.064
        c1.795-1.021,3.606-2.026,5.479-2.894c2.606-1.207,4.547-0.307,5.365,2.458c0.859,2.9,1.143,5.883,0.805,8.893
        c-0.903,8.07-2.998,15.853-5.832,23.444c-0.255,0.684-0.133,1.061,0.336,1.652c0.827,1.041,1.669,2.208,2.028,3.458
        c2.215,7.706,0.918,14.791-3.727,21.394c-2.36,3.354-5.493,5.636-8.88,7.697c-0.5,0.305-1.224,0.363-1.836,0.341
        c-1.368-0.054-1.838-0.768-1.431-2.089c1.381-4.479,1.81-9.074,1.735-13.733C70.329,67.053,70.256,66.715,70.194,66.211z
        M85.349,19.348c-3.559,0.358-10.397,2.411-13.131,3.872c-2.536,1.355-5.07,2.714-7.607,4.067
        c-5.304,2.829-9.582,6.896-13.486,11.363c-1.613,1.845-2.865,3.998-4.396,5.919c-3.275,4.11-5.572,8.741-7.431,13.615
        c-0.129,0.339-0.223,0.844-0.064,1.115c1.5,2.576,2.763,5.288,5.08,7.34c2.952,2.614,6.028,4.976,9.684,6.542
        c1.418,0.608,2.501,0.647,3.898-0.322c4.456-3.09,8.724-6.356,12.079-10.667c1.448-1.858,2.773-3.812,4.219-5.676
        c3.902-5.027,6.755-10.584,8.126-16.803c1.15-5.222,2.091-10.493,2.993-15.764C85.561,22.49,85.349,20.949,85.349,19.348z
        M72.032,79.579c5.161-3.145,8.856-7.139,10.125-12.805c1.043-4.656-0.938-8.949-2.354-13.514
        c-2.057,2.786-3.906,5.279-5.741,7.782c-0.847,1.152-1.476,2.085-1.069,3.897c0.604,2.702,0.382,5.633,0.219,8.447
        C73.096,75.349,72.489,77.281,72.032,79.579z M27.836,47.937c0.38-0.202,0.766-0.394,1.14-0.605
        c2.687-1.524,5.456-2.88,8.503-3.471c2.494-0.481,5.021-0.917,7.548-1.04c0.976-0.047,1.459-0.203,1.99-0.964
        c1.796-2.578,3.673-5.101,5.509-7.65c0.101-0.14,0.115-0.337,0.241-0.733c-1.625,0.125-3.097,0.164-4.546,0.365
        c-5.023,0.697-9.921,1.678-13.715,5.518C31.95,41.943,29.615,44.686,27.836,47.937z M39.261,65.99
        c-0.62,0.923-1.262,1.93-1.958,2.896c-0.499,0.694-0.639,1.41-0.143,2.104c1.271,1.776,2.544,3.558,3.91,5.262
        c1.155,1.439,2.82,2.053,4.588,2.375c0.274,0.05,0.725,0.007,0.858-0.17c1.05-1.394,2.044-2.829,2.968-4.129
        C46.006,71.492,42.679,68.777,39.261,65.99z"/>
      <path fill="currentColor" d="M23.153,92.214c0.199-0.296,0.499-0.902,0.941-1.373c3.112-3.313,6.248-6.605,9.396-9.884
        c0.345-0.359,0.799-0.775,1.243-0.831c0.479-0.062,1.162,0.136,1.46,0.478c0.237,0.271,0.132,0.966-0.012,1.41
        c-0.14,0.436-0.481,0.827-0.801,1.179c-2.658,2.924-5.312,5.852-8.014,8.733c-0.647,0.69-1.425,1.292-2.229,1.797
        C24.173,94.33,23.148,93.69,23.153,92.214z"/>
      <path fill="currentColor" d="M32.682,100.648c-1.322-0.053-1.906-0.938-1.484-1.927c0.391-0.911,0.912-1.78,1.477-2.6
        c2.227-3.231,4.246-6.572,5.743-10.218c0.653-1.591,1.431-2.072,2.329-1.646c0.823,0.391,0.82,1.449,0.193,2.893
        c-1.92,4.42-4.541,8.438-7.117,12.478C33.512,100.115,32.935,100.429,32.682,100.648z"/>
      <path fill="currentColor" d="M18.274,88.056c-0.435-0.363-1.065-0.641-1.104-0.982c-0.054-0.49,0.177-1.201,0.545-1.519
        c2.51-2.162,5.112-4.216,7.634-6.363c1.396-1.188,2.709-2.473,4.027-3.748c0.637-0.614,1.327-1.093,2.059-0.346
        c0.687,0.701,0.246,1.403-0.377,1.955c-3.137,2.775-6.281,5.543-9.417,8.318c-0.627,0.555-1.213,1.157-1.856,1.69
        C19.365,87.408,18.876,87.666,18.274,88.056z"/>
      <path fill="transparent" d="M85.349,19.348c0,1.603,0.212,3.143-0.038,4.604c-0.902,5.271-1.843,10.542-2.993,15.764
        c-1.371,6.219-4.224,11.774-8.126,16.803c-1.444,1.862-2.771,3.816-4.219,5.676c-3.355,4.311-7.623,7.577-12.079,10.667
        c-1.397,0.971-2.48,0.932-3.898,0.322c-3.655-1.566-6.731-3.928-9.684-6.542c-2.316-2.052-3.58-4.764-5.08-7.34
        c-0.157-0.271-0.064-0.776,0.064-1.115c1.858-4.874,4.154-9.504,7.431-13.615c1.531-1.921,2.784-4.074,4.396-5.919
        c3.904-4.468,8.184-8.534,13.486-11.363c2.537-1.354,5.071-2.712,7.607-4.067C74.95,21.759,81.79,19.706,85.349,19.348z
        M61.244,39.256c0.004,2.388,0.965,5.193,2.366,5.888c1.518,0.751,3.174,1.455,4.83,1.646c4.236,0.487,7.83-1.985,9.18-6.288
        c0.854-2.726,1.204-5.669-1.037-7.925c-2.287-2.304-5.1-3.514-8.514-2.666C64.187,30.874,61.158,34.914,61.244,39.256z"/>
      <path fill="transparent" d="M72.032,79.579c0.456-2.298,1.062-4.229,1.178-6.19c0.163-2.814,0.386-5.745-0.219-8.447
        c-0.405-1.812,0.224-2.745,1.069-3.897c1.835-2.503,3.686-4.996,5.741-7.782c1.417,4.564,3.397,8.857,2.354,13.514
        C80.89,72.441,77.194,76.435,72.032,79.579z"/>
      <path fill="transparent" d="M27.836,47.937c1.779-3.25,4.114-5.993,6.671-8.581c3.794-3.84,8.692-4.82,13.715-5.518
        c1.449-0.201,2.921-0.239,4.546-0.365c-0.126,0.396-0.141,0.594-0.241,0.733c-1.836,2.55-3.713,5.072-5.509,7.65
        c-0.53,0.762-1.015,0.917-1.99,0.964c-2.527,0.123-5.054,0.558-7.548,1.04c-3.048,0.591-5.817,1.946-8.503,3.471
        C28.602,47.543,28.216,47.735,27.836,47.937z"/>
      <path fill="transparent" d="M39.261,65.99c3.417,2.786,6.745,5.502,10.224,8.339c-0.924,1.3-1.917,2.735-2.968,4.129
        c-0.134,0.177-0.584,0.22-0.858,0.17c-1.768-0.322-3.433-0.936-4.588-2.375c-1.365-1.704-2.64-3.484-3.91-5.262
        c-0.496-0.693-0.356-1.409,0.143-2.104C38,67.919,38.642,66.913,39.261,65.99z"/>
      <path fill="currentColor" d="M61.244,39.256c-0.086-4.343,2.941-8.382,6.825-9.346c3.414-0.848,6.227,0.362,8.514,2.666
        c2.241,2.256,1.891,5.2,1.037,7.925c-1.35,4.303-4.942,6.775-9.18,6.288c-1.656-0.191-3.312-0.896-4.83-1.646
        C62.209,44.45,61.248,41.644,61.244,39.256z M74.886,34.953c-0.321-1.461-0.82-2.332-2.196-2.589
        c-2.389-0.447-4.455-0.04-6.354,1.582c-2.418,2.063-3.312,5.774-1.812,8.545c0.25,0.461,0.774,0.773,1.172,1.154
        c0.088-0.083,0.175-0.166,0.263-0.25c-0.882-2.736-0.139-5.162,1.768-7.078C69.645,34.387,72.079,33.74,74.886,34.953z"/>
      <path fill="transparent" d="M74.886,34.953c-2.807-1.214-5.241-0.566-7.163,1.364c-1.905,1.916-2.648,4.341-1.768,7.078
        c-0.088,0.083-0.176,0.167-0.263,0.25c-0.396-0.381-0.922-0.693-1.172-1.154c-1.499-2.771-0.604-6.48,1.812-8.545
        c1.899-1.621,3.967-2.029,6.354-1.582C74.065,32.622,74.564,33.492,74.886,34.953z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'StartseiteIcon'
}
</script>
