<template>
  <v-breadcrumbs
    :items="breadCrumbs"
    class="pa-0"
  >
    <template
      v-slot:item="{ item }"
    >
      <v-breadcrumbs-item
        :to="item.href"
        :disabled="item.disabled"
        exact
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            :color="item.disabled ? '#CDBBABDD' : item.color"
            class="breadcrumb-card pointer"
            :elevation="item.disabled ? 0 : (hover ? 4 : 2)"
          >
            <v-card-text
              class="white--text py-4"
            >
              <v-row
                class="py-0"
              >
                <v-col
                  cols="12"
                  :class="{
                    'py-0': !computedVerticalPaddingExists,
                    'py-1': computedVerticalPaddingExists,
                  }"
                >
                  <v-avatar
                    :size="computedAvatarSize"
                    color="#fff"
                    class="mr-1"
                  >
                    <v-icon
                      :size="computedAvatarSize - 3"
                      :color="item.color"
                    >
                      {{ getIcon(item.icon) }}
                    </v-icon>
                  </v-avatar>
                  {{ item.text }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BreadCrumbs',
  props: {
    scrolled: Number
  },
  data () {
    return {
      breadCrumbs: []
    }
  },
  mounted () {
    if (this.$route.meta.isErrorPage ?? false) {
      this.buildErrorBreadCrumbs()
    } else {
      const pathDataArray = this.$route.meta.pathData || []
      this.buildBreadCrumbs(pathDataArray)
    }
  },
  methods: {
    buildBreadCrumbs (pathDataArray) {
      const rawBreadCrumbs = pathDataArray.map((pathData) => {
        return {
          icon: this.menuData[pathData.id].icon,
          text: this.menuData[pathData.id].title,
          disabled: false, // (pathArray[pathArray.length - 1] === id),
          href: '/' + pathData.pathSegment,
          color: this.menuData[pathData.id].color
        }
      })
      rawBreadCrumbs.unshift(
        {
          icon: this.menuData.main.icon,
          text: this.menuData.main.title,
          disabled: false,
          href: '/',
          color: this.menuData.main.color
        }
      )
      rawBreadCrumbs[rawBreadCrumbs.length - 1].disabled = true
      this.breadCrumbs = rawBreadCrumbs
    },
    buildErrorBreadCrumbs () {
      this.breadCrumbs = [
        {
          icon: this.menuData.main.icon,
          text: this.menuData.main.title,
          disabled: false,
          href: '/',
          color: this.menuData.main.color
        }
      ]
    },
    getIcon (key) {
      return '$vuetify.icons.' + key
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.isErrorPage ?? false) {
        this.buildErrorBreadCrumbs()
      } else {
        const pathDataArray = to.meta.pathData || []
        this.buildBreadCrumbs(pathDataArray)
      }
    }
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedAvatarSize () {
      if (this.scrolled >= 0.5) {
        return 19
      }
      if (this.$vuetify.breakpoint.name === 'xl') {
        return 32
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return 29
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return 24
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return 21
      } else {
        return 19
      }
    },
    computedVerticalPaddingExists () {
      if (this.scrolled >= 0.5) {
        return false
      }
      if (this.$vuetify.breakpoint.name === 'xl') {
        return true
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
  .v-breadcrumbs .v-breadcrumbs__divider {
    display: none !important;
  }

  .v-breadcrumbs .v-card {
    border-radius: 0px !important;
    transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .v-breadcrumbs li:first-of-type .v-card {
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }
  .v-breadcrumbs li:last-of-type .v-card {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  .v-breadcrumbs li {
    position: relative;
  }
  /* .v-breadcrumbs li:not(:last-of-type)::after {
    content: " ";
    display: block;
    position: absolute;
    width: 2px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: white;
    opacity: 0.3;
  } */
</style>
