<template>
  <v-alert
    type="info"
    :color="color"
    border="left"
    dense
    style="word-break: break-word;"
  >
    <template v-slot:prepend>
      <v-hover
        v-slot="{ hover }"
      >
        <v-btn
          fab
          small
          color="white"
          class="mr-3"
          :elevation="hover ? 12 : 3"
          :href="'/flyers/' + fileName"
          target="_blank"
        >
          <v-icon
            :color="color"
          >
            mdi-download
          </v-icon>
        </v-btn>
      </v-hover>
    </template>
    <span
      class="text-caption"
    >
      Download
    </span>
    <br />
    <span
      class="font-weight-bold"
      :class="{
        'text-body-2': $vuetify.breakpoint.xs
      }"
    >
      {{ text }}
    </span>
  </v-alert>
</template>

<script>

export default {
  name: 'DownloadAlert',
  props: {
    color: String,
    text: String,
    fileName: String
  },
  data () {
    return { }
  },
  mounted () { },
  methods: { },
  computed: {
  }
}

</script>

<style>
</style>
