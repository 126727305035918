var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"light":"","color":_vm.embedded ? '#e0e0e0' : '#f0f0f0'}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",class:{
          'text-h6 text-sm-h5 text-md-h4': !_vm.embedded,
          'text-h6 text-md-h5': _vm.embedded,
          'pb-0': _vm.subtitle != null
        },style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c('v-col',{staticClass:"font-weight-bold neutral--text",class:{
          'pt-0': _vm.title != null
        },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),_c('v-row',[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }