var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"light":"","color":_vm.embedded ? '#e0e0e0' : '#f0f0f0',"height":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",class:{
          'text-h6 text-sm-h5 text-md-h4': !_vm.embedded,
          'text-h6 text-md-h5': _vm.embedded
        },style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c('v-col',{staticClass:"text-subtitle-1",style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"video-wrapper"}},[_c('Media',{staticStyle:{"width":"100%","outline":"none !important"},attrs:{"kind":'video',"src":_vm.videoSources,"poster":_vm.posterSource,"type":_vm.videoType,"autoplay":false,"controls":true,"controlsList":['nodownload'],"loop":false,"muted":false,"disablepictureinpicture":"","preload":"metadata"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }