<template>
  <v-col
    cols="12"
    md="6"
  >
    <v-sheet
      light
      :color="computedBackgroundColor"
      class="pa-3 fill-height"
    >
      <v-row
        v-if="title"
      >
        <v-col
          class="font-weight-bold"
          cols="12"
          :class="{
            'text-h6 text-sm-h5 text-md-h4': !embedded,
            'text-h6 text-md-h5': embedded
          }"
          :style="{
            'color': (backgroundColor !== null) ? 'white' : (embedded ? $vuetify.theme.currentTheme.neutral : themeColor),
            'background-color': (backgroundColor !== null) ? '#00000023' : 'transparent'
          }"
        >
          {{ title }}
        </v-col>
      </v-row>
      <v-row
        v-if="imageSource"
        align="center"
      >
        <v-col
          cols="12"
          justify="center"
        >
          <v-img
            contain
            class="pa-4"
            :src="imageSource"
          >
          </v-img>
        </v-col>
        <v-col
          v-if="caption"
          cols="12"
          class="text-caption"
          align="center"
        >
          {{ caption }}
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>

export default {
  name: 'ContentImageTile',
  components: { },
  props: {
    title: String,
    themeColor: String,
    imageSource: String,
    caption: String,
    backgroundColor: { type: String, default: null },
    embedded: { type: Boolean, default: false }
  },
  data () {
    return { }
  },
  mounted () { },
  methods: { },
  computed: {
    computedBackgroundColor () {
      if (this.backgroundColor !== null) {
        return this.backgroundColor
      } else {
        if (this.embedded) {
          return '#e0e0e0'
        } else {
          return '#f0f0f0'
        }
      }
    }
  }
}
</script>
