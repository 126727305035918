<template>
  <a
    :href="href"
    target="_blank"
    class="no-text-decoration"
  >
    <v-chip
      class="contact-chip text-caption"
      color="neutral"
      text-color="white"
      small
    >
      <v-hover
        v-if="href !== null"
        v-slot="{ hover }"
      >
          <v-btn
            class="mr-2"
            :elevation="hover ? 12 : 3"
            fab
            height="22"
            width="22"
          >
            <v-icon
              color="neutral"
              size="15"
            >
              {{ icon }}
            </v-icon>
          </v-btn>
      </v-hover>
      <v-avatar
        v-else
        class="mr-2"
        elevation="0"
        height="22"
        width="22"
        max-height="22"
        max-width="22"
        min-height="22"
        min-width="22"
        color="white"
        style="
            width: 22px !important;
            height: 22px !important;
            min-width: 22px !important;
            max-width: 22px !important;
            min-height: 22px !important;
            max-height: 22px !important;
        "
      >
        <v-icon
          color="neutral"
          size="15"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>
      <span
      style="text-overflow: ellipsis; overflow: hidden;"
      >
        {{ value }}
      </span>
    </v-chip>
  </a>
</template>

<script>

export default {
  name: 'ContactChip',
  props: {
    icon: String,
    value: String,
    href: String
  },
  data () {
    return { }
  },
  mounted () { },
  methods: { },
  computed: { }
}
</script>

<style scoped>
  .contact-chip {
    overflow: visible !important;
    padding-left: 1px !important;
  }
  a.no-text-decoration {
    text-decoration: none;
  }
  a.no-text-decoration, a.no-text-decoration * {
    cursor: pointer !important;
  }
</style>
