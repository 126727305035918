var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentFrame',{attrs:{"themeColor":_vm.computedTopicColor,"title":_vm.computedTitle}},[_c('ContentContainer',{attrs:{"themeColor":_vm.computedTopicColor,"title":"\"Unterricht am Krankenbett\""}},[_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Der Unterricht","textArray":[
        'Unterrichtsfächer sind vorrangig Mathematik, Deutsch und Fremdsprachen. Der Unterricht orientiert sich am Bildungsplan bzw. Stoffverteilungsplan der jeweiligen Stammklasse. Der Unterricht erfolgt überwiegend als Einzelunterricht in enger Absprache mit der Heimatschule.'
      ],"noteText":"Wir helfen bei der Beantragung von Hausunterricht."}}),_c('ContentImageTile',{attrs:{"embedded":"","imageSource":"/img/schulhaus/uniklinikum.jpg","caption":"Uniklinikum Ulm (Foto: Armin Buhl)"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Die Ziele und Aufgaben","textArray":[
        'Ziel ist es, nach dem Krankenhausaufenthalt einen guten Übergang in die Heimatschule zu begleiten. Daher arbeiten wir eng mit den Heimatschulen aller Bundesländer zusammen.',
        'Um den Schüler*innen eine optimale Förderung zu ermöglichen, werden regelmäßig mit allen an den Therapien beteiligten Fachkräften Informationen ausgetauscht.',
        'Falls ein Schulwechsel nach dem Klinikaufenthalt nötig ist, wird dieser von uns fachlich begleitet.'
      ],"downloadText":"Informationen zu \"Unterricht am Krankenbett\"","downloadFileName":"Flyer Abteilung Somatik-2023-2024.pdf"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Nachteilsausgleich","textArray":[
        'Eine Benachteiligung durch erschwerte Lebensbedingungen, die (chronische) Krankheiten mit sich bringen, soll in allen Schularten ausgeglichen werden und die Chancengleichheit für alle wahren.',
        'Die Fördermaßnahmen sind eng gebunden an die Lebensumstände der einzelnen Kinder und Jugendlichen. Art und Umfang des gewährten Nachteilsausgleichs sind eine pädagogische Entscheidung.',
        'Details und mögliche Formen im Sinne des Nachteilsausgleichs können im folgenden Dokument nachgeschlagen werden.'
      ],"downloadText":"Informationen zum \"Nachteilsausgleich\"","downloadFileName":"Hans-Lebrecht-Schule_Nachteilsausgleich.pdf"}})],1),_c('ContentContainer',{attrs:{"themeColor":_vm.computedTopicColor,"title":"\"Kinder- und Jugendpsychiatrie/-psychotherapie\""}},[_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Der Unterricht","textArray":[
        'Wir unterrichten in kleinen schulart- und jahrgangsstufenübergreifenden Lerngruppen, mit maximal vier Schüler*innen, in einem eigenen Schulhaus neben der Klinik.',
        'Vorrangig werden die Fächer Deutsch, Mathematik, Fremdsprachen und naturwissenschaftliches Arbeiten sowie Lernen lernen unterrichtet. Ergänzt wird das Unterrichtsangebot durch fächer- und schulartübergreifende Lernangebote und Projekte, deren Ergebnisse in der Großgruppe präsentiert werden.'
      ]}}),_c('ContentImageTile',{attrs:{"embedded":"","imageSource":"/img/schulhaus/04.jpg","caption":"Klassenzimmer der Hans-Lebrecht-Schule (Foto: Armin Buhl)"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Die Ziele und Aufgaben","textArray":[
        'Neben den schulischen Lerninhalten liegt der Schwerpunkt auf der Stärkung des Selbstwertgefühls. Der Besuch der Klinikschule ermöglicht schulische Beständigkeit und Alltagsstruktur während der Behandlung in der Klinik.',
        'Wir bereiten unsere Schüler*nnen auf Abschlussprüfungen vor und führen diese, in Absprache mit den Heimatschulen, auch vor Ort durch.',
        'In Familiengesprächen im therapeutischen Rahmen und Hilfeplangesprächen mit dem Jugendamt bringen wir uns beratend ein.',
        'Sofern eine Schullaufbahnveränderung notwendig ist, wird diese von uns fachlich begleitet.'
      ],"downloadText":"Informationen zu \"KJPP\"","downloadFileName":"Hans-Lebrecht-Schule_KJP.pdf"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }