<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M67.911,66.856c0.054-0.449,0.124-0.873,0.15-1.3c0.064-1.031,0.218-2.073,0.138-3.095
        c-0.214-2.699-0.522-5.39-0.815-8.083c-0.086-0.795,0.272-1.105,0.957-1.517c1.412-0.849,2.88-1.777,3.935-3.007
        c1.414-1.648,2.373-3.676,3.68-5.427c1.596-2.136,1.967-4.533,1.689-7.039c-0.213-1.931-0.544-3.888-1.145-5.729
        c-1.562-4.781-3.71-9.038-9.076-10.797c-3.408-1.117-6.736-2.336-10.345-1.514c-2.472,0.563-5.11,1.012-7.268,2.231
        c-6.116,3.457-10.408,8.633-12.172,15.502c-0.542,2.108-0.534,4.689,0.18,6.717c1.364,3.875,3.765,7.214,7.542,9.29
        c0.315,0.173,0.693,0.505,0.753,0.818c0.208,1.093,0.411,2.212,0.393,3.318c-0.047,2.871-0.228,5.739-0.321,8.61
        c-0.024,0.738-0.34,0.949-1.039,0.923c-3.03-0.113-6.061-0.196-9.092-0.279c-2.551-0.071-5.104-0.105-7.654-0.203
        c-0.779-0.03-1.307,0.2-1.624,0.923c-0.433,0.985-0.839,1.987-1.342,2.938c-2.02,3.808-4.096,7.586-6.093,11.406
        c-0.413,0.789-0.76,1.696-0.816,2.57c-0.16,2.473-0.107,4.958-0.226,7.435c-0.124,2.574,0.855,3.468,3.403,3.12
        c1.495-0.204,3.015-0.226,4.523-0.339c0.874-0.065,1.745-0.159,2.619-0.228c2.306-0.181,4.609-0.414,6.918-0.512
        c1.587-0.067,3.193,0.19,4.773,0.068c3.698-0.284,7.377-0.83,11.077-1.043c3.77-0.217,7.556-0.226,11.334-0.211
        c6.55,0.027,13.1,0.186,19.648,0.203c3.19,0.009,6.381-0.217,9.572-0.247c1.287-0.012,2.073-0.525,2.366-1.735
        c0.421-1.742,0.867-3.487,1.128-5.256c0.115-0.785,0.003-1.735-0.352-2.435c-1.239-2.447-2.522-4.886-3.992-7.198
        c-1.582-2.489-3.44-4.801-5.089-7.251c-0.996-1.481-2.32-1.823-3.948-1.562c-1.062,0.171-2.128,0.48-3.188,0.461
        c-3.59-0.067-7.177-0.252-10.764-0.396C68.252,66.987,68.179,66.944,67.911,66.856z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M67.911,66.856c0.268,0.088,0.341,0.131,0.417,0.134
        c3.587,0.144,7.174,0.328,10.764,0.396c1.06,0.02,2.126-0.29,3.188-0.461c1.628-0.262,2.952,0.08,3.948,1.562
        c1.648,2.45,3.507,4.762,5.089,7.251c1.47,2.312,2.753,4.751,3.992,7.198c0.354,0.699,0.467,1.649,0.352,2.435
        c-0.261,1.769-0.707,3.514-1.128,5.256c-0.293,1.21-1.079,1.724-2.366,1.735c-3.191,0.03-6.382,0.256-9.572,0.247
        c-6.549-0.018-13.099-0.176-19.648-0.203c-3.778-0.015-7.564-0.006-11.334,0.211c-3.7,0.213-7.379,0.759-11.077,1.043
        c-1.58,0.122-3.186-0.136-4.773-0.068c-2.309,0.098-4.612,0.331-6.918,0.512c-0.874,0.068-1.745,0.162-2.619,0.228
        c-1.508,0.113-3.028,0.135-4.523,0.339c-2.548,0.348-3.527-0.546-3.403-3.12c0.119-2.477,0.066-4.962,0.226-7.435
        c0.057-0.874,0.404-1.781,0.816-2.57c1.997-3.82,4.074-7.599,6.093-11.406c0.503-0.95,0.909-1.952,1.342-2.938
        c0.317-0.723,0.845-0.953,1.624-0.923c2.55,0.098,5.103,0.132,7.654,0.203c3.031,0.083,6.062,0.166,9.092,0.279
        c0.699,0.026,1.015-0.185,1.039-0.923c0.093-2.871,0.274-5.739,0.321-8.61c0.018-1.106-0.186-2.225-0.393-3.318
        c-0.06-0.313-0.438-0.645-0.753-0.818c-3.776-2.076-6.178-5.415-7.542-9.29c-0.713-2.027-0.721-4.608-0.18-6.717
        c1.765-6.869,6.056-12.045,12.172-15.502c2.158-1.219,4.795-1.667,7.268-2.231c3.608-0.822,6.937,0.397,10.345,1.514
        c5.366,1.759,7.515,6.016,9.076,10.797c0.601,1.841,0.932,3.798,1.145,5.729c0.277,2.506-0.094,4.903-1.689,7.039
        c-1.307,1.75-2.266,3.778-3.68,5.427c-1.055,1.23-2.522,2.158-3.935,3.007c-0.685,0.412-1.043,0.722-0.957,1.517
        c0.293,2.692,0.602,5.383,0.815,8.083c0.08,1.021-0.073,2.063-0.138,3.095C68.035,65.983,67.965,66.407,67.911,66.856z
        M50.259,58.736c-0.051,0.002-0.103,0.004-0.154,0.007c0,1.078,0.046,2.159-0.009,3.234c-0.141,2.745-0.358,5.487-0.455,8.233
        c-0.014,0.379,0.403,0.985,0.761,1.125c1.251,0.489,2.565,0.817,3.854,1.212c3.161,0.968,6.246,0.217,9.322-0.458
        c0.31-0.068,0.71-0.622,0.73-0.971c0.211-3.489,0.612-6.97,0.14-10.48c-0.366-2.722-0.493-5.475-0.769-8.209
        c-0.147-1.464,0.219-1.833,1.586-2.309c1.289-0.448,2.65-1.019,3.649-1.906c2.06-1.83,3.267-4.322,4.335-6.82
        c0.484-1.133,0.991-2.469,0.831-3.622c-0.387-2.785-0.948-5.587-1.828-8.253c-1.28-3.877-4.228-6.25-8.07-7.386
        c-2.65-0.783-5.357-0.836-8.115-0.156c-3.505,0.865-6.733,2.119-9.491,4.559c-3.447,3.05-5.222,6.958-5.911,11.35
        c-0.332,2.119-0.524,4.36,0.62,6.442c1.604,2.923,3.672,5.312,6.85,6.581c0.993,0.397,1.677,1.082,1.754,2.219
        C50.016,54.998,50.136,56.867,50.259,58.736z M45.806,70.143c-0.301-0.043-0.456-0.08-0.611-0.085
        c-4.744-0.153-9.488-0.303-14.231-0.455c-0.439-0.014-0.899,0.03-1.311-0.088c-0.739-0.211-1.072,0.126-1.419,0.713
        c-0.691,1.166-1.51,2.256-2.197,3.423c-1.274,2.167-2.534,4.344-3.712,6.562c-0.527,0.992,0.007,1.752,1.1,1.69
        c3.705-0.209,7.408-0.45,11.111-0.682c0.159-0.01,0.316-0.05,0.475-0.062c4.209-0.327,8.42-0.938,12.627-0.921
        c10.14,0.039,20.289-0.508,30.42,0.396c3.295,0.294,6.603,0.45,9.903,0.679c1.294,0.09,2.586,0.203,3.892,0.307
        c0-0.199,0.028-0.291-0.004-0.35c-1.934-3.531-3.717-7.142-6.39-10.233c-0.854-0.988-1.519-1.05-2.728-0.9
        c-2.792,0.343-5.626,0.41-8.444,0.461c-1.866,0.033-3.735-0.177-5.604-0.234c-0.65-0.02-1.204,0.237-1.228,1.015
        c-0.04,1.277-0.833,2.017-1.812,2.643c-2.433,1.555-5.197,2.224-7.976,2.133c-2.512-0.082-5.084-0.615-7.478-1.408
        C48.098,74.053,45.736,73.183,45.806,70.143z M21.99,90.905c2.2,0,4.188,0.02,6.175-0.005c2.587-0.031,5.206-0.371,7.754-0.083
        c3.401,0.386,6.764,0.21,10.144,0.055c2.828-0.13,5.651-0.409,8.479-0.474c2.618-0.059,5.24,0.102,7.86,0.126
        c4.158,0.04,8.316,0.033,12.474,0.077c2.156,0.022,4.327-0.035,6.462,0.207c3.241,0.368,6.42,0.111,9.691-0.397
        c0.251-2.195,0.498-4.353,0.751-6.569c-2.449,0-4.671,0.008-6.892-0.002c-3.871-0.017-7.741-0.07-11.611-0.062
        c-12.694,0.027-25.391-0.238-38.07,0.843c-4.079,0.348-8.213,0.041-12.322,0.033c-0.306,0-0.611,0-0.896,0
        C21.99,86.782,21.99,88.803,21.99,90.905z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M50.259,58.736c-0.123-1.869-0.243-3.738-0.37-5.607
        c-0.077-1.137-0.761-1.822-1.754-2.219c-3.178-1.27-5.246-3.658-6.85-6.581c-1.144-2.083-0.952-4.324-0.62-6.442
        c0.689-4.392,2.464-8.299,5.911-11.35c2.757-2.44,5.985-3.694,9.491-4.559c2.757-0.68,5.464-0.627,8.115,0.156
        c3.843,1.135,6.79,3.509,8.07,7.386c0.88,2.667,1.441,5.468,1.828,8.253c0.16,1.152-0.347,2.489-0.831,3.622
        c-1.068,2.498-2.275,4.99-4.335,6.82c-0.999,0.888-2.36,1.458-3.649,1.906c-1.367,0.475-1.733,0.845-1.586,2.309
        c0.275,2.735,0.402,5.488,0.769,8.209c0.473,3.511,0.071,6.991-0.14,10.48c-0.021,0.349-0.421,0.902-0.73,0.971
        c-3.076,0.675-6.161,1.426-9.322,0.458c-1.289-0.395-2.603-0.723-3.854-1.212c-0.358-0.14-0.774-0.746-0.761-1.125
        c0.097-2.746,0.314-5.488,0.455-8.233c0.055-1.075,0.009-2.156,0.009-3.234C50.157,58.74,50.208,58.738,50.259,58.736z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M45.806,70.143c-0.07,3.04,2.292,3.91,4.383,4.604
        c2.394,0.793,4.965,1.326,7.478,1.408c2.778,0.091,5.543-0.578,7.976-2.133c0.979-0.626,1.772-1.365,1.812-2.643
        c0.023-0.777,0.577-1.034,1.228-1.015c1.869,0.058,3.738,0.268,5.604,0.234c2.818-0.051,5.652-0.118,8.444-0.461
        c1.209-0.149,1.873-0.088,2.728,0.9c2.673,3.092,4.456,6.702,6.39,10.233c0.032,0.059,0.004,0.15,0.004,0.35
        c-1.306-0.104-2.598-0.217-3.892-0.307c-3.301-0.229-6.608-0.385-9.903-0.679c-10.131-0.904-20.28-0.357-30.42-0.396
        c-4.208-0.017-8.418,0.594-12.627,0.921c-0.159,0.012-0.316,0.052-0.475,0.062c-3.704,0.231-7.406,0.473-11.111,0.682
        c-1.093,0.062-1.626-0.698-1.1-1.69c1.178-2.219,2.438-4.396,3.712-6.562c0.687-1.167,1.505-2.257,2.197-3.423
        c0.348-0.587,0.681-0.924,1.419-0.713c0.412,0.118,0.872,0.074,1.311,0.088c4.744,0.152,9.487,0.302,14.231,0.455
        C45.351,70.063,45.505,70.1,45.806,70.143z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M21.99,90.905c0-2.102,0-4.123,0-6.251c0.285,0,0.59,0,0.896,0
        c4.109,0.008,8.243,0.314,12.322-0.033c12.679-1.081,25.375-0.815,38.07-0.843c3.87-0.009,7.74,0.045,11.611,0.062
        c2.221,0.01,4.442,0.002,6.892,0.002c-0.253,2.217-0.5,4.374-0.751,6.569c-3.271,0.509-6.45,0.766-9.691,0.397
        c-2.135-0.242-4.306-0.185-6.462-0.207c-4.157-0.044-8.315-0.037-12.474-0.077c-2.62-0.024-5.242-0.185-7.86-0.126
        c-2.829,0.064-5.652,0.344-8.479,0.474c-3.379,0.155-6.742,0.331-10.144-0.055c-2.549-0.288-5.167,0.052-7.754,0.083
        C26.178,90.924,24.189,90.905,21.99,90.905z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'KlinikSchulVereinIcon'
}
</script>
