<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentTile
      :themeColor="computedTopicColor"
      title="Der Verein"
      :textArray="[
        'Die seelische Gesundheit von Kindern und Jugendlichen ist zunehmend in Gefahr, sei es durch gesellschaftlich bedingte Entwicklungen oder veränderte soziale Strukturen in den Familien.',
        'Die Kinder- und Jugendpsychiatrie der Universitätsklinik Ulm hilft genau solchen jungen Menschen und deren Angehörigen.',
        'Alle Kinder und Jugendliche, die sich in stationärer oder teilstationärer Behandlung befinden, werden in der Hans-Lebrecht-Schule, einer Schule ausschließlich für Schüler*innen in längerer Krankenhausbehandlung am Universitätsklinikum Ulm, unterrichtet.',
        'Alle diese Kinder und Jugendlichen benötigen spezielle zusätzliche Angebote im täglichen Unterricht und im milieutherapeutischen Alltag, in der Kunst-, Ergo-, Musik- und Arbeitstherapie.',
        'Der 2002 gegründete KlinikSchulVerein Ulm e.V. hat es sich unter Vorsitz von Prof. Dr.Jörg M. Fegert zur Aufgabe gemacht, diese Maßnahmen zu unterstützen.'
      ]"
      downloadText='Informationen als Download'
      downloadFileName="KlinikSchulVerein.pdf"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Die Schwerpunkte"
      :textArray="[
        'Der KlinikSchulVerein Ulm e. V. hat sich folgende Schwerpunkte gesetzt:'
      ]"
    >
      <ul slot="content">
        <li>
          Unterstützung von Kindern und Jugendlichen und deren Eltern bei Heimfahrten oder Besuchen in der Klinik
        </li>
        <li>
          Unterstützung bei der Anschaffung von Kleidung
        </li>
        <li>
          Unterstützung der Stationen und der Tagesklinik bei besonderen Anschaffungen für die Kinder und Jugendlichen
        </li>
        <li>
          Finanzierung innovativer Fördermaterialien für den Unterricht
        </li>
        <li>
          Bereitstellung von Arbeitsmaterial für den täglichen Unterricht
        </li>
        <li>
          Unterstützung besonderer Projekte in den Co-Therapien
        </li>
        <li>
          Unterstützung spezieller psychiatrischer Fort- und Weiterbildungsveranstaltungen für Lehrkräfte der Klinikschule und Co-Therapeut*innen der Klinik
        </li>
        <li>
          Unterstützung von Informationsveranstaltungen und Öffentlichkeitsarbeit, mit dem Ziel, die Gesellschaft für die besondere Situation psychiatrisch erkrankter Kinder und Jugendlicher und ihrer Familien zu sensibilisieren
        </li>
      </ul>
    </ContentTile>
    <ContentImageTile
      imageSource="/img/grafiken/mithilfe-1.jpg"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Ihre Spende hilft"
      :textArray="[
        'Für unsere Arbeit benötigt der Verein regelmäßige finanzielle Unterstützung. Deshalb unsere Bitte an Sie:',
        'Helfen Sie dem KlinikSchulVerein mit einer Spende oder Mitgliedschaft ab einem jährlichen Beitrag von 25 €.'
      ]"
      :downloadText="applicationFormText"
      :downloadFileName="applicationFormFileName"
    >
      <v-card slot="content">
        <v-card-text>
          <p class="text-subtitle-1">
            Bankverbindung
          </p>
          KlinikSchulVerein Ulm e.V.
          <br>IBAN: DE07 6305 0000 0002 1803 06
          <br>BIC: SOLADES1ULM
        </v-card-text>
      </v-card>
    </ContentTile>
  </ContentFrame>
</template>

<script>
import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'KlinikSchulVerein',
  components: {
    ContentTile,
    ContentFrame,
    ContentImageTile
  },
  data () {
    return {
      applicationFormFileName: 'KlinikSchulVerein_Beitrittserklaerung.pdf',
      applicationFormText: 'Beitrittserklärung als Download'
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.klinikschulverein.color
    },
    computedTitle () {
      return this.menuData.klinikschulverein.title
    }
  },
  watch: { }
}
</script>
