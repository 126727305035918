var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadCrumbs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"to":item.href,"disabled":item.disabled,"exact":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"breadcrumb-card pointer",attrs:{"color":item.disabled ? '#CDBBABDD' : item.color,"elevation":item.disabled ? 0 : (hover ? 4 : 2)}},[_c('v-card-text',{staticClass:"white--text py-4"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{class:{
                  'py-0': !_vm.computedVerticalPaddingExists,
                  'py-1': _vm.computedVerticalPaddingExists,
                },attrs:{"cols":"12"}},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":_vm.computedAvatarSize,"color":"#fff"}},[_c('v-icon',{attrs:{"size":_vm.computedAvatarSize - 3,"color":item.color}},[_vm._v(" "+_vm._s(_vm.getIcon(item.icon))+" ")])],1),_vm._v(" "+_vm._s(item.text)+" ")],1)],1)],1)],1)]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }