<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M57.565,17.755c-4.453,0.111-8.589,0.942-12.723,1.913c-6.481,1.521-11.716,5.054-16.017,9.949
        c-1.68,1.912-2.78,4.336-4.123,6.54c-0.665,1.09-1.48,2.143-1.878,3.329c-1.066,3.177-2.198,6.367-2.838,9.643
        c-1.063,5.443,0.034,10.843,1.248,16.133C22.596,71.2,25.061,76.686,29,81.438c3.554,4.288,8.165,7.023,13.273,8.939
        c4.688,1.76,9.586,2.75,14.556,3.381c0.411,0.053,0.89,0.406,1.138,0.759c0.601,0.858,1.352,0.924,2.025,0.104
        c0.201-0.245,0.488-0.5,0.781-0.574c3.317-0.848,6.738-1.398,9.946-2.548c4.654-1.668,8.874-4.186,12.32-7.854
        c4.813-5.126,7.638-11.33,9.374-17.998c1.098-4.221,1.499-8.616,1.367-13.013c-0.054-1.754-0.043-3.537-0.357-5.251
        c-1.016-5.532-3.386-10.58-6.141-15.407c-2.464-4.318-6.267-7.448-10.414-10.083c-1.595-1.013-3.419-1.702-5.199-2.377
        C67.057,17.763,62.15,18.284,57.565,17.755z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M57.565,17.755c4.585,0.529,9.491,0.008,14.104,1.759
        c1.78,0.676,3.604,1.365,5.199,2.377c4.147,2.636,7.95,5.765,10.414,10.083c2.755,4.827,5.125,9.875,6.141,15.407
        c0.314,1.715,0.304,3.498,0.357,5.251c0.132,4.396-0.27,8.792-1.367,13.013c-1.736,6.668-4.561,12.872-9.374,17.998
        c-3.446,3.669-7.666,6.187-12.32,7.854c-3.208,1.149-6.629,1.7-9.946,2.548c-0.293,0.074-0.58,0.329-0.781,0.574
        c-0.674,0.82-1.425,0.755-2.025-0.104c-0.248-0.353-0.727-0.706-1.138-0.759c-4.97-0.631-9.868-1.621-14.556-3.381
        c-5.108-1.916-9.719-4.651-13.273-8.939c-3.939-4.752-6.404-10.238-7.767-16.177c-1.214-5.29-2.311-10.69-1.248-16.133
        c0.64-3.276,1.772-6.466,2.838-9.643c0.398-1.187,1.214-2.239,1.878-3.329c1.343-2.204,2.443-4.628,4.123-6.54
        c4.301-4.895,9.536-8.428,16.017-9.949C48.976,18.697,53.112,17.866,57.565,17.755z M59.966,92.686
        c0.783-0.061,1.341-0.061,1.883-0.152c4.005-0.679,7.771-2.022,11.438-3.785c3.94-1.895,7.178-4.564,9.972-7.87
        c3.529-4.176,5.633-9.068,7.269-14.202c1.188-3.727,1.213-7.605,1.558-11.614c-2.761,0-5.387-0.021-8.011,0.035
        c-0.232,0.005-0.593,0.473-0.654,0.774c-0.189,0.937-0.239,1.901-0.387,2.849c-0.903,5.788-3.163,10.834-7.429,15.041
        c-4.245,4.188-9.307,6.641-14.946,8.128c-0.698,0.185-0.9,0.528-0.882,1.161c0.067,2.233,0.135,4.467,0.187,6.701
        C59.983,90.66,59.966,91.57,59.966,92.686z M21.329,56.203c0,0.473-0.035,0.865,0.005,1.249c0.513,4.831,1.512,9.557,3.379,14.054
        c2.611,6.287,6.53,11.496,12.551,14.952c3.889,2.232,8.036,3.715,12.426,4.557c2.647,0.508,5.293,1.023,8.087,1.564
        c0-3.528,0-6.742,0-10.055c-1.395-0.139-2.782-0.231-4.155-0.422c-2.762-0.385-5.592-0.559-8.251-1.32
        c-2.176-0.624-4.27-1.724-5.942-3.448c-3.121-3.221-4.77-7.266-6.353-11.313c-0.879-2.248-1.349-4.661-1.932-7.018
        c-0.264-1.068-0.354-2.179-0.522-3.259C27.49,55.898,24.436,56.049,21.329,56.203z M56.812,20.521
        c-1.257,0.129-2.402,0.205-3.534,0.37c-5.593,0.817-11.133,1.833-16.009,4.956c-3.072,1.967-6.072,4.052-8.176,7.107
        c-1.31,1.901-2.425,3.936-3.629,5.91c-2.626,4.304-4.167,8.938-4.429,14.002c-0.065,1.261,0.497,1.71,1.544,1.668
        c2.511-0.099,5.02-0.27,7.523-0.486c0.271-0.023,0.667-0.507,0.721-0.825c0.211-1.256,0.114-2.592,0.487-3.788
        c1.138-3.65,2.952-6.989,5.254-10.038c2.459-3.256,5.47-5.948,9.053-7.888c3.274-1.771,6.642-3.436,10.589-2.663
        c0.295,0.058,0.61,0.008,1.109,0.008C57.147,26.084,56.988,23.439,56.812,20.521z M83.554,53.586
        c0.459,0.037,0.846,0.093,1.233,0.095c2.039,0.008,4.079-0.04,6.116,0.021c0.956,0.028,1.237-0.3,1.217-1.238
        c-0.084-3.805-0.703-7.472-2.524-10.868c-1.285-2.394-2.526-4.812-3.856-7.18c-2.687-4.779-6.96-7.871-11.576-10.5
        c-4.296-2.446-9.043-2.859-13.859-2.602c-0.269,2.197-0.513,4.335-0.798,6.467c-0.127,0.956,0.202,1.366,1.197,1.413
        c3.227,0.154,6.09,1.541,8.948,2.846c2.942,1.343,5.479,3.302,7.492,5.847c1.552,1.962,3.14,3.934,4.393,6.086
        C83.203,46.835,83.419,50.121,83.554,53.586z M33.02,53.795c2.906-0.142,5.772-0.254,8.632-0.459
        c0.328-0.024,0.746-0.515,0.905-0.883c0.71-1.644,1.219-3.385,2.032-4.972c1.198-2.34,2.967-4.121,5.286-5.529
        c1.547-0.939,2.964-1.945,4.789-2.233c0.704-0.111,1.406-0.313,2.068-0.577c0.307-0.123,0.732-0.489,0.736-0.75
        c0.03-2.541,0.005-5.084-0.074-7.624c-0.008-0.258-0.437-0.672-0.723-0.725c-2.111-0.39-4.189-0.336-6.24,0.458
        c-6.418,2.487-11.338,6.748-14.875,12.598C33.601,46.329,32.349,49.789,33.02,53.795z M32.999,55.415
        c0.075,0.76,0.079,1.281,0.184,1.782c1.334,6.373,3.801,12.28,7.49,17.642c1.683,2.445,4.059,4.14,6.968,4.737
        c3.038,0.624,6.146,0.902,9.222,1.347c0.892,0.129,1.207-0.217,1.191-1.115c-0.043-2.426-0.015-4.854-0.015-7.378
        c-1.119-0.081-2.144-0.149-3.167-0.229c-5.763-0.449-10.612-4.657-11.727-9.768c-0.525-2.409-0.907-4.85-1.402-7.539
        C39.02,55.056,36.098,55.23,32.999,55.415z M59.691,80.698c1.449-0.488,2.547-0.929,3.682-1.23
        c5.369-1.427,9.469-4.797,12.936-8.878c3.542-4.17,5.065-9.259,5.514-14.679c0.057-0.68-0.184-0.993-0.832-0.99
        c-1.757,0.007-3.518-0.017-5.269,0.093c-0.368,0.023-0.873,0.494-1.025,0.875c-0.665,1.665-1.083,3.44-1.848,5.052
        c-2.561,5.398-6.213,9.643-12.335,11.147c-0.331,0.081-0.776,0.611-0.786,0.942C59.661,75.458,59.691,77.889,59.691,80.698z
        M81.838,53.528c0.661-7.08-2.343-12.555-7.401-17.033c-4.219-3.736-9.184-5.882-14.802-6.316
        c-0.188,2.854-0.377,5.478-0.51,8.104c-0.013,0.247,0.293,0.708,0.498,0.737c1.336,0.189,2.689,0.248,4.028,0.421
        c2.084,0.271,4.13,0.75,5.681,2.283c3.078,3.046,5.452,6.484,5.669,11.019c0.013,0.262,0.116,0.521,0.178,0.785
        C77.445,53.528,79.631,53.528,81.838,53.528z M52.613,54.818c-2.696,0-5.287,0.056-7.873-0.024
        c-1.179-0.037-1.521,0.471-1.373,1.489c0.655,4.485,2.46,8.339,6.274,11.013c2.526,1.771,5.422,2.042,8.362,2.062
        c0-2.686-0.011-5.194,0.006-7.702c0.006-0.793-0.472-0.942-1.118-1.094c-2.4-0.565-3.808-2.118-4.102-4.421
        C52.735,55.706,52.674,55.272,52.613,54.818z M58.949,40.022c-0.229,2.375-0.443,4.634-0.663,6.892
        c-0.096,0.989,0.653,0.816,1.208,0.864c2.977,0.259,5.086,1.875,5.972,4.704c0.207,0.66,0.495,0.872,1.166,0.879
        c1.796,0.021,3.593,0.104,5.387,0.193c1.411,0.069,1.495-0.037,1.438-1.406c-0.093-2.167-0.74-4.188-1.811-6.017
        c-1.5-2.561-3.366-4.833-6.541-5.362C63.108,40.437,61.085,40.275,58.949,40.022z M59.602,69.016
        c6.459-0.771,13.483-8.303,13.195-14.034c-2.005-0.09-4.029-0.166-6.052-0.279c-0.769-0.043-1.093,0.228-1.294,1.016
        c-0.709,2.781-2.498,4.475-5.373,4.947c-0.34,0.056-0.677,0.13-1.104,0.213C59.187,63.63,59.391,66.281,59.602,69.016z
        M43.87,53.086c2.895,0,5.717,0.016,8.538-0.033c0.211-0.004,0.524-0.444,0.604-0.732c0.562-2.047,1.944-3.323,3.775-4.236
        c0.295-0.147,0.67-0.521,0.677-0.798c0.055-2.3,0.03-4.603,0.03-6.927C50.579,41.148,45.047,46.199,43.87,53.086z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M59.966,92.686c0-1.116,0.018-2.026-0.003-2.936
        c-0.052-2.234-0.119-4.468-0.187-6.701c-0.019-0.633,0.184-0.977,0.882-1.161c5.64-1.487,10.701-3.94,14.946-8.128
        c4.266-4.207,6.525-9.253,7.429-15.041c0.147-0.947,0.197-1.912,0.387-2.849c0.062-0.301,0.422-0.769,0.654-0.774
        c2.624-0.055,5.25-0.035,8.011-0.035c-0.345,4.009-0.37,7.888-1.558,11.614c-1.636,5.134-3.739,10.026-7.269,14.202
        c-2.794,3.306-6.031,5.976-9.972,7.87c-3.668,1.763-7.434,3.106-11.438,3.785C61.307,92.625,60.749,92.625,59.966,92.686z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M21.329,56.203c3.107-0.154,6.162-0.305,9.293-0.459
        c0.167,1.08,0.258,2.19,0.522,3.259c0.583,2.356,1.053,4.77,1.932,7.018c1.583,4.048,3.232,8.093,6.353,11.313
        c1.672,1.725,3.766,2.824,5.942,3.448c2.659,0.762,5.489,0.936,8.251,1.32c1.373,0.19,2.76,0.283,4.155,0.422
        c0,3.312,0,6.526,0,10.055c-2.794-0.541-5.439-1.057-8.087-1.564c-4.39-0.842-8.537-2.324-12.426-4.557
        c-6.021-3.456-9.939-8.665-12.551-14.952c-1.867-4.497-2.866-9.223-3.379-14.054C21.293,57.068,21.329,56.676,21.329,56.203z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M56.812,20.521c0.176,2.918,0.336,5.563,0.503,8.333
        c-0.499,0-0.814,0.05-1.109-0.008c-3.947-0.773-7.315,0.891-10.589,2.663c-3.583,1.939-6.594,4.631-9.053,7.888
        c-2.302,3.049-4.116,6.388-5.254,10.038c-0.373,1.196-0.275,2.532-0.487,3.788c-0.054,0.318-0.449,0.801-0.721,0.825
        c-2.503,0.216-5.013,0.387-7.523,0.486c-1.048,0.042-1.61-0.408-1.544-1.668c0.263-5.065,1.803-9.699,4.429-14.002
        c1.205-1.974,2.32-4.008,3.629-5.91c2.104-3.055,5.104-5.14,8.176-7.107c4.876-3.123,10.416-4.139,16.009-4.956
        C54.409,20.726,55.555,20.65,56.812,20.521z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M83.554,53.586c-0.135-3.466-0.351-6.751-2.018-9.613
        c-1.253-2.152-2.841-4.124-4.393-6.086c-2.013-2.544-4.55-4.503-7.492-5.847c-2.858-1.305-5.722-2.692-8.948-2.846
        c-0.995-0.047-1.324-0.457-1.197-1.413c0.285-2.132,0.529-4.27,0.798-6.467c4.816-0.257,9.563,0.156,13.859,2.602
        c4.616,2.628,8.89,5.72,11.576,10.5c1.33,2.368,2.571,4.786,3.856,7.18c1.821,3.396,2.44,7.063,2.524,10.868
        c0.021,0.938-0.261,1.267-1.217,1.238c-2.037-0.061-4.077-0.012-6.116-0.021C84.399,53.68,84.013,53.623,83.554,53.586z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M33.02,53.795c-0.671-4.006,0.581-7.466,2.535-10.697
        c3.537-5.85,8.457-10.111,14.875-12.598c2.051-0.794,4.129-0.848,6.24-0.458c0.287,0.053,0.715,0.467,0.723,0.725
        c0.079,2.54,0.104,5.083,0.074,7.624c-0.004,0.261-0.429,0.627-0.736,0.75c-0.662,0.264-1.364,0.466-2.068,0.577
        c-1.825,0.289-3.242,1.294-4.789,2.233c-2.318,1.408-4.088,3.189-5.286,5.529c-0.813,1.587-1.321,3.329-2.032,4.972
        c-0.159,0.368-0.577,0.859-0.905,0.883C38.792,53.541,35.925,53.653,33.02,53.795z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M32.999,55.415c3.099-0.185,6.021-0.358,8.744-0.521
        c0.495,2.689,0.876,5.13,1.402,7.539c1.115,5.11,5.964,9.318,11.727,9.768c1.023,0.079,2.048,0.147,3.167,0.229
        c0,2.524-0.028,4.952,0.015,7.378c0.016,0.898-0.299,1.244-1.191,1.115c-3.076-0.444-6.184-0.723-9.222-1.347
        c-2.909-0.598-5.285-2.292-6.968-4.737c-3.689-5.362-6.156-11.27-7.49-17.642C33.078,56.696,33.074,56.174,32.999,55.415z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M59.691,80.698c0-2.809-0.03-5.239,0.036-7.668
        c0.01-0.331,0.455-0.861,0.786-0.942c6.122-1.505,9.774-5.749,12.335-11.147c0.765-1.612,1.183-3.388,1.848-5.052
        c0.152-0.381,0.657-0.852,1.025-0.875c1.751-0.109,3.512-0.085,5.269-0.093c0.648-0.003,0.889,0.311,0.832,0.99
        c-0.448,5.42-1.972,10.509-5.514,14.679c-3.467,4.081-7.566,7.451-12.936,8.878C62.238,79.769,61.141,80.209,59.691,80.698z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M81.838,53.528c-2.207,0-4.393,0-6.659,0
        c-0.062-0.264-0.165-0.522-0.178-0.785c-0.217-4.535-2.591-7.973-5.669-11.019c-1.551-1.534-3.597-2.012-5.681-2.283
        c-1.339-0.174-2.692-0.232-4.028-0.421c-0.205-0.029-0.511-0.49-0.498-0.737c0.133-2.626,0.321-5.25,0.51-8.104
        c5.618,0.434,10.583,2.58,14.802,6.316C79.495,40.973,82.499,46.448,81.838,53.528z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M52.613,54.818c0.061,0.455,0.122,0.888,0.177,1.322
        c0.294,2.303,1.701,3.855,4.102,4.421c0.646,0.151,1.124,0.301,1.118,1.094c-0.017,2.508-0.006,5.017-0.006,7.702
        c-2.939-0.02-5.836-0.29-8.362-2.062c-3.814-2.674-5.619-6.527-6.274-11.013c-0.148-1.019,0.194-1.526,1.373-1.489
        C47.327,54.874,49.917,54.818,52.613,54.818z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M58.949,40.022c2.136,0.253,4.159,0.415,6.155,0.748
        c3.175,0.528,5.041,2.801,6.541,5.362c1.07,1.829,1.718,3.85,1.811,6.017c0.058,1.369-0.026,1.476-1.438,1.406
        c-1.794-0.089-3.591-0.173-5.387-0.193c-0.671-0.007-0.959-0.219-1.166-0.879c-0.886-2.83-2.995-4.445-5.972-4.704
        c-0.555-0.048-1.304,0.125-1.208-0.864C58.506,44.656,58.721,42.397,58.949,40.022z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M59.602,69.016c-0.211-2.735-0.415-5.386-0.627-8.137
        c0.427-0.083,0.764-0.157,1.104-0.213c2.875-0.473,4.664-2.166,5.373-4.947c0.201-0.788,0.525-1.059,1.294-1.016
        c2.022,0.113,4.047,0.189,6.052,0.279C73.085,60.713,66.061,68.246,59.602,69.016z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M43.87,53.086c1.177-6.887,6.709-11.938,13.624-12.727
        c0,2.325,0.024,4.627-0.03,6.927c-0.007,0.277-0.382,0.651-0.677,0.798c-1.831,0.914-3.213,2.189-3.775,4.236
        c-0.079,0.288-0.393,0.729-0.604,0.732C49.586,53.102,46.765,53.086,43.87,53.086z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ZieleIcon'
}
</script>
