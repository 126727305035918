var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentFrame',{attrs:{"themeColor":_vm.computedTopicColor,"title":_vm.computedTitle}},[_c('ContentContainer',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Sonderpädagogischer Dienst"}},[_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Unsere Zielgruppe","textArray":[
        'Der Sonderpädagogische Dienst berät und unterstützt die allgemeine Schule im Umgang mit Schüler*innen mit langanhaltenden bzw. chronischen Erkrankungen. Dabei ist die Einbeziehung des sonderpädagogischen Dienstes immer mit konkreten Fragestellungen verbunden und zeitlich befristet.',
        'Der sonderpädagogische Dienst berät und unterstützt Schüler*innen, Eltern, Schulleitungen und Lehrkräfte.'
      ],"noteText":"Ziel des sonderpädagogischen Dienstes ist die Optimierung von Bildungs- und Lernprozessen und somit die Stärkung von Aktivität und Teilhabe bei Erkrankung oder Verunfallung."}}),_c('ContentImageTile',{attrs:{"embedded":"","imageSource":"/img/grafiken/beratung.jpg"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Unsere Aufgabenfelder","textArray":[],"downloadText":"Informationen zum \"Sonderpädagogischen Dienst\"","downloadFileName":"Hans-Lebrecht-Schule_SD.pdf"}},[_c('ul',{attrs:{"slot":"content"},slot:"content"},[_c('li',[_vm._v(" Beratung hinsichtlich der Auswirkungen der Krankheit auf den Schulalltag und Unterricht ")]),_c('li',[_vm._v(" Fallbezogene Beratung (zu Nachteilsausgleich, Hausunterricht, Medikamentengabe...) ")]),_c('li',[_vm._v(" Beratung in Bezug auf individuelle Bildungsangebote und Bildungswege (Schullaufbahnberatung) ")]),_c('li',[_vm._v(" Unterstützung zu Teilhabe und Aktivität am gesamten schulischen Leben ")]),_c('li',[_vm._v(" Beratung in Bezug auf außerschulische Netzwerke und Unterstützungssysteme ")]),_c('li',[_vm._v(" Information und Beratung zu Krankheitsbildern ")]),_c('li',[_vm._v(" Teilnahme an Expert*innenrunden ")])])])],1),_c('ContentContainer',{attrs:{"themeColor":_vm.computedTopicColor,"title":"„Was ist denn schon normal?“","subtitle":"Mein Aufenthalt in der Klinik für Kinder- und Jugendpsychiatrie und Psychotherapie am Universitätsklinikum Ulm"}},[_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":"Über falsche Vorstellungen","textArray":[
        'Tom trägt einen blauen Kapuzenpulli und weiß, was alle denken: Er war in der „Klapse“ – vollgepumpt mit Medikamenten, in Zwangsjacke und Gummizelle…',
        'So stellen sich viele die Kinder- und Jugendpsychiatrie vor.',
        'Auch Tom wusste nicht, was ihn erwartet, als er wegen seiner Wut- und Angstattacken in die Klinik kam.',
        'Damit es anderen nicht so geht, erklärt Comicfigur Tom in diesem einzigartigen Comicbilderbuch anderen Kindern, was die Kinder- und Jugendpsychiatrie eigentlich ist und was er dort gemacht hat.'
      ],"downloadText":"Buch \"Was ist denn schon normal?\"","downloadFileName":"Hans-Lebrecht-Schule_Widsn.pdf"}}),_c('ContentImageTile',{attrs:{"embedded":"","imageSource":"/img/projekte/was_ist_schon_normal/cover.jpg"}})],1),_c('ContentContainer',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Informationen und Broschüren"}},[_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":null,"textArray":[],"downloadText":"Informationen zum Unterricht","downloadFileName":"Hans-Lebrecht-Schule_Unterricht.pdf"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":null,"textArray":[],"downloadText":"Informationen zu unseren Grundprinzipien","downloadFileName":"Hans-Lebrecht-Schule_Prinzipien.pdf"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":null,"textArray":[],"downloadText":"Antworten auf häufige Fragen","downloadFileName":"Hans-Lebrecht-Schule_FAQ.pdf"}}),_c('ContentTile',{attrs:{"embedded":"","themeColor":_vm.computedTopicColor,"title":null,"textArray":[],"downloadText":"Informationen zu den Vorsichtsmaßnahmen","downloadFileName":"Hans-Lebrecht-Schule_Corona.pdf"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }