var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentFrame',{attrs:{"themeColor":_vm.computedTopicColor,"title":_vm.computedTitle}},[_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Der Verein","textArray":[
      'Dazugehören e.V. wurde 2017 in Berlin als interdisziplinäre Fachgesellschaft für Partizipation und Teilhabe gegründet. Ziel des Vereins ist die verbesserte Teilhabe von Kindern und Jugendlichen mit (drohender) seelischer Beeinträchtigung und anderen Beeinträchtigungsformen, die generelle Verbesserung der Teilhabe von Kindern und Jugendlichen aus belasteten oder benachteiligten Familien sowie die Förderung von Integration und Inklusion.',
      'Der Verein betreibt Aufklärungs- und Öffentlichkeitsarbeit. Darüber hinaus unterstützt er Forschungsvorhaben und andere Aktivitäten gegen Diskriminierung, Stigmatisierung und Mobbing sowie Exklusion und gruppenbezogene Menschenfeindlichkeit insbesondere bei Kindern und Jugendlichen'
    ]}},[_c('v-row',{attrs:{"slot":"content"},slot:"content"},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('ExternalLinkButton',{attrs:{"themeColor":_vm.computedTopicColor,"href":_vm.homepageLink,"text":_vm.homepageLinkText}})],1)],1)],1),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Die Ziele","textArray":[
      'Erklärte Ziele des Vereins sind:'
    ]}},[_c('ul',{attrs:{"slot":"content"},slot:"content"},[_c('li',[_vm._v(" Aufklärungsarbeit mithilfe fachlich fundierter Newsletter, um Fachkräfte, aber auch ehrenamtliche Engagierte, die mit Kindern und Jugendlichen arbeiten, zu Themen des Kinderschutzes zu informieren ")]),_c('li',[_vm._v(" Verbesserte Teilhabe von Kindern und Jugendlichen mit (drohender) seelischer Beeinträchtigung und anderen Beeinträchtigungsformen ")]),_c('li',[_vm._v(" Generelle Verbesserung der Teilhabe von Kindern und Jugendlichen aus belasteten oder benachteiligten Familien sowie die Förderung von Integration und Inklusion ")]),_c('li',[_vm._v(" Betrieb von Aufklärungs- und Öffentlichkeitsarbeit ")]),_c('li',[_vm._v(" Unterstützung von Forschungsvorhaben und andere Aktivitäten gegen Diskriminierung, Stigmatisierung und Mobbing sowie Exklusion und gruppenbezogene Menschenfeindlichkeit insbesondere bei Kindern und Jugendlichen ")])])]),_c('ContentImageTile',{attrs:{"imageSource":"/img/grafiken/mithilfe-2.jpg"}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Ihre Spende hilft","textArray":[
      'Sie können unsere Aktivitäten durch eine Spende und/oder Mitgliedschaft im Verein Dazugehoren e.V. unterstützen.'
    ]}},[_c('v-card',{attrs:{"slot":"content"},slot:"content"},[_c('v-card-text',[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Bankverbindung ")]),_vm._v(" Dazugehoeren e.V. "),_c('br'),_vm._v("IBAN: DE36 1007 0124 0139 3636 00 "),_c('br'),_vm._v("BIC: DEUTDEDB101 ")])],1)],1),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Newsletter","textArray":[
      'Mit dem Newsletter informieren wir Sie über die Aktivitäten des Vereins Dazugehören e.V. sowie über Veranstaltungen und Projekte der Klinik für Kinder- und Jugendpsychiatrie/-psychotherapie Ulm.',
      'Sie erhalten außerdem fachliche Infos, Neuigkeiten und Hinweise zu den Themen psychische Erkrankungen von Kindern und Jugendlichen, Schutz von Kindern und Jugendlichen, Kinderrechte sowie Prävention von (sexualisierter) Gewalt an Kindern und Jugendlichen.'
    ]}},[_c('v-row',{attrs:{"slot":"content"},slot:"content"},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('ExternalLinkButton',{attrs:{"themeColor":_vm.computedTopicColor,"href":_vm.newsletterLink,"text":_vm.newsletterLinkText}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }