<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentFAQTile
      v-for="(faq, i) in faqs"
      :key="i"
      :color="faq.color"
      :question="faq.question"
      :answerArray="faq.answerArray"
      :graphic="faq.graphic"
    >
      <v-col
        v-if="faq.highlightText !== null"
        slot="content"
        cols="12"
      >
        <v-card
          flat
        >
          <v-card-text
            :style="{
              'color': faq.color
            }">
            {{ faq.highlightText }}
          </v-card-text>
        </v-card>
      </v-col>
    </ContentFAQTile>
    <ContentTile
      :themeColor="computedTopicColor"
      title="Haben Sie noch Fragen?"
      :textArray="[
        'Kontaktieren Sie uns!'
      ]"
      downloadText='Antworten auf häufige Fragen als Download'
      downloadFileName="Hans-Lebrecht-Schule_FAQ.pdf"
    >
      <v-row
        slot="content"
      >
        <v-col
          cols="12"
          align="center"
        >
          <InternalLinkButton
            :themeColor="computedTopicColor"
            :pageName="contactButtonPageName"
            :text="contactButtonText"
          />
        </v-col>
      </v-row>
    </ContentTile>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentFAQTile from '@/views/components/ContentFAQTile.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import InternalLinkButton from '@/views/components/InternalLinkButton.vue'

export default {
  name: 'TFAQ',
  components: {
    ContentFrame,
    ContentFAQTile,
    ContentTile,
    InternalLinkButton
  },
  data () {
    return {
      contactButtonPageName: 'Contact',
      contactButtonText: 'Zum Kontakt',
      faqs: [
        {
          color: '#015aaa',
          question: 'Was ist die Klinikschule?',
          answerArray: [
            'Die Klinikschule ist Teil des Klinikalltags und damit Übungsfeld für schulische Themen. Schulbesuch üben, Konzentrationsfähigkeit steigern, strukturiertes Arbeiten, Hausaufgaben machen, Teamarbeit - es gibt viele Möglichkeiten.',
            'In den ersten Tagen in der Klinikschule soll dem oder der Schüler*in die Gelegenheit gegeben werden, die Schule und die Lehrkräfte und Mitschüler*innen in Ruhe kennenzulernen.',
            'Die Schüler*innen treffen zum Beispiel gleich am ersten Tag in einer Kennenlernstunde ihre Bezugslehrkräfte. Sie erklären den Schüler*innen z.B. den Stundenplan, zeigen die Klassenzimmer und machen sie mit ihren Schulregeln vertraut.'
          ],
          highlightText: 'Wichtig ist: Die Schüler*innen stehen im Mittelpunkt und wir möchten, dass sie bei uns nicht nur viel lernen, sondern, dass es ihnen gut geht und sie Freude daran haben. Deshalb dürfen die Schüler*innen allen Lehrkräften auch jederzeit Fragen stellen zu Unklarheiten und/oder Unsicherheiten. Eine gute Möglichkeit dazu bietet die Bezugslehrkraftstunde.',
          graphic: '/img/fragen/haus.png'
        },
        {
          color: '#007ec5',
          question: 'Wie sehen die ersten Tage der Schüler*innen dort aus?',
          answerArray: [
            'Die Schüler*innen haben in den ersten Tagen "ganz normal" Unterricht, entsprechend wie es für sie und ihre persönliche Situation gut ist. Das ist vielleicht nur eine Stunde oder gleich ein ganzer Unterrichtstag, manchmal gibt es Einzelunterricht oder sie werden gemeinsam in der Gruppe beschult - eben so, wie es für die einzelnen Schüler*innen und ihre Situation passt und wie es der Therapieplan und die Schulstruktur erlauben.',
            'Sie lernen ihre Lehrkräfte und Mitschüler*innen im Unterricht kennen, wir tauschen uns mit ihnen darüber aus, was sie in den einzelnen Fächern schon gelernt haben oder wo sie besondere Unterstützung brauchen, welche Unterrichtsmaterialien sie benötigen, ob sie Klassenarbeiten schreiben werden und wie es während ihres Klinikaufenthaltes schulisch weitergeht. Dafür stehen wir in engem Kontakt mit ihren Heimatschulen, aber auch mit ihren Ansprechpartner*innen in der Klinik.'
          ],
          highlightText: null,
          graphic: '/img/fragen/erstetage.png'
        },
        {
          color: '#00aeef',
          question: 'Welche Lehrkräfte arbeiten an der Klinikschule?',
          answerArray: [
            'An der Klinikschule arbeiten Lehrkräfte mit unterschiedlicher Ausbildung in einem Team zusammen. So unterrichten bei uns Sonderpädagog*innen, Grund- und Hauptschullehrkräfte, Lehrkräfte der Gemeinschafts-, Real- oder Gymnasialschulen.',
            'Die Lehrkräfte nehmen regelmäßig an Fortbildungen teil. Sie kennen sich also mit seelischen Erkrankungen und deren Auswirkungen auf das Lernen in der Schule aus.',
            'Die Lehrkräfte verstehen wir als Teil des unterstützenden Netzwerks für unsere Schüler*innen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/lehrerinnen.png'
        },
        {
          color: '#009fb4',
          question: 'Wozu gibt es die Kennenlernstunde für die Schüler*innen?',
          answerArray: [
            'In dieser Stunde...',
            '• werden Schüler*innen und ihrer Bezugslehrkraft die Gelegenheit gegeben, sich gegenseitig kennenzulernen und Vertrauen aufzubauen.',
            '• erhälten die Schüler*innen Informationen über schulische Abläufe und Vorgehensweisen (z.B. Stundenplan).',
            '• lernen die Schüler*innen die Räumlichkeiten des Schulgebäudes, die Lehrkräfte und wichtige Schulregeln kennen.',
            '• berichten die Schüler*innen ihren Bezugslehrkräften etwas über ihren schulischen Werdegang und besprechen mit ihnen Erwartungen und Ziele für den Klinikaufenthalt.'
          ],
          highlightText: null,
          graphic: '/img/fragen/kennenlernen.png'
        },
        {
          color: '#5cc3b6',
          question: 'Was muss man über den Stundenplan wissen?',
          answerArray: [
            'An der Klinikschule gibt es die Unter-, Mittel- und Oberstufe.',
            'Die Lehrkräfte der Stufen erstellen wöchentlich einen neuen Stundenplan, bei dem der momentane Unterstützungsbedarf der Schüler*innen berücksichtigt wird.',
            'Tagesaktuelle Informationen oder Änderungen, die sich ergeben, erhalten die Schüler*innen von ihren Lehrkräften und Betreuer*innen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/stundenplan.png'
        },
        {
          color: '#00a895',
          question: 'Warum gibt es manchmal Einzelunterricht?',
          answerArray: [
            'Manchmal gibt es Einzelunterricht...',
            '• damit die Lehrkraft ganz gezielt mit den Schüler*innen arbeiten kann, zum Beispiel als intensive Vorbereitung für eine anstehende Klassenarbeit oder Prüfung.',
            '• weil die Schüler*innen es sich beispielsweise selbst gewünscht haben, weil sie zusätzliche Schüler*innen im Raum oder deren Unterrichtsthemen so sehr ablenken, dass sie selbst nicht konzentriert arbeiten können.',
            '• da es nicht möglich war, mehrere Schüler*innen in der betreffenden Stunde einzuplanen, weil sie z.B. Therapien haben oder anderweitig nicht zum Unterricht kommen können.'
          ],
          highlightText: null,
          graphic: '/img/fragen/einzel.png'
        },
        {
          color: '#80ca9c',
          question: 'Warum sind die Türen der Klassenräume in der Regel offen?',
          answerArray: [
            'Die Türen der Klassenräume in der Klinikschule sind offen...',
            '• damit sich die Schüler*innen nicht eingeengt fühlen.',
            '• damit sich die Lehrkräfte gegenseitig unterstützen können.',
            'Manchmal sind die Türen aber geschlossen, z.B. bei Präsentationen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/tueroffen.png'
        },
        {
          color: '#00a54f',
          question: 'Was ist eine Bezugslehrkraftstunde?',
          answerArray: [
            'In der Bezugslehrkraftstunde...',
            '• reflektieren die Schüler*innen ihr Lernverhalten.',
            '• planen Bezugslehrkräfte mit ihren Schüler*innen, woran sie in der Klinikschule weiter arbeiten wollen.',
            '• besprechen Bezugslehrkraft mit den Schüler*innen deren Wünsche und Sorgen bezüglich der Schule.',
            '• erfahren die Schüler*innen Neues aus Ihrer Heimatschule.',
            '• gibt es die Möglichkeit, dass sich Schüler*innen mit ihren Mitschüler*innen austauschen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/bericht.png'
        },
        {
          color: '#00854a',
          question: 'Wie sieht der Kontakt mit der Heimatschule aus und welche Informationen werden ausgetauscht?',
          answerArray: [
            'Sie erfahren von der aktuellen Teilnahme der Schüler*inen aktuell am Unterricht der Klinikschule.',
            'Wir tauschen uns regelmäßig mit Ihnen über die schulische Situation der Schüler*innen aus, erfragen Unterrichtsinhalte und bereiten die Rückkehr der Schüler*innen vor.'
          ],
          highlightText: 'Sie werden von uns weder über die Diagnose noch über Therapieinhalte informiert.',
          graphic: '/img/fragen/taube.png'
        },
        {
          color: '#71bf44',
          question: 'Welche Informationen werden mit den Mitarbeiter*innen der Klinik ausgetauscht?',
          answerArray: [
            'Die Lehrkräfte bzw. die Schulleitung der Klinikschule nehmen an den Visiten teil.',
            'Sie tauschen sich dort über den therapeutischen Prozess der Schüler*innen, ihre Diagnosen und aktuellen Übungsfelder aus und bringen ihre Beobachtungen über das Verhalten der Schüler*innen im Unterricht ein.'
          ],
          highlightText: null,
          graphic: '/img/fragen/klinik.png'
        },
        {
          color: '#bed630',
          question: 'Was ist der Abschlussbericht und wer bekommt diesen?',
          answerArray: [
            'Nach dem Aufenthalt der Schüler*innen verfassen die Lehrkräfte einen Abschlussbericht. Er enthält wichtige Informationen über das Lernverhalten, das Sozialverhalten und die bearbeiteten Lerninhalte.',
            'Informationen zur Diagnose und möglichen Medikamenten werden im Abschlussbericht nicht erwähnt.',
            'Der Abschlussbericht wird an Sie und auch an die Eltern/Sorgeberechtigten geschickt.'
          ],
          highlightText: null,
          graphic: '/img/fragen/bericht.png'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.tFaq.color
    },
    computedTitle () {
      return this.menuData.tFaq.title
    }
  }
}
</script>
