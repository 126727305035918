<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M31.307,55.483c-0.133,0.021-0.267,0.043-0.399,0.064c0.176,2.508,0.333,5.017,0.533,7.522
        c0.627,7.907,3.495,14.959,8.212,21.287c3.476,4.663,7.822,8.015,13.785,8.69c5.261,0.596,10.382-0.042,14.815-3.122
        c5.115-3.556,8.437-8.655,10.55-14.389c2.795-7.577,3.894-15.467,3.044-23.566c-0.069-0.663-0.107-1.399,0.105-2.009
        c1.322-3.789,1.7-7.709,1.703-11.677c0.002-2.998,0.735-3.115-3.308-2.952c-1.72,0.069-2.848-0.52-3.595-2.067
        c-2.267-4.692-5.377-8.44-10.322-10.675c-6.122-2.768-12.136-2.968-18.142-0.073c-2.394,1.154-4.568,2.781-6.783,4.281
        c-0.778,0.528-1.508,1.231-2.035,2.007c-2.041,3.005-3.853,6.144-5.229,9.524c-0.474,1.165-1.023,2.275-2.685,2.079
        c-0.406-0.048-1.273,0.562-1.28,0.885c-0.069,3.025-0.104,6.061,0.075,9.08C30.454,52.089,30.975,53.781,31.307,55.483z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M31.307,55.483c-0.332-1.702-0.853-3.394-0.955-5.11
        c-0.18-3.019-0.144-6.054-0.075-9.08c0.007-0.323,0.874-0.933,1.28-0.885c1.662,0.196,2.21-0.914,2.685-2.079
        c1.376-3.38,3.188-6.519,5.229-9.524c0.527-0.776,1.257-1.479,2.035-2.007c2.214-1.5,4.389-3.127,6.783-4.281
        c6.006-2.895,12.02-2.694,18.142,0.073c4.945,2.234,8.056,5.983,10.322,10.675c0.747,1.548,1.875,2.137,3.595,2.067
        c4.043-0.163,3.31-0.046,3.308,2.952c-0.003,3.967-0.381,7.888-1.703,11.677c-0.213,0.609-0.175,1.346-0.105,2.009
        c0.85,8.099-0.249,15.989-3.044,23.566c-2.113,5.733-5.435,10.833-10.55,14.389c-4.433,3.08-9.554,3.718-14.815,3.122
        c-5.963-0.676-10.31-4.027-13.785-8.69c-4.717-6.328-7.585-13.38-8.212-21.287c-0.199-2.506-0.356-5.015-0.533-7.522
        C31.041,55.526,31.174,55.504,31.307,55.483z M33.367,56.719c0,0.631-0.04,1.1,0.007,1.56c0.226,2.222,0.36,4.461,0.737,6.658
        c1.445,8.426,5.233,15.586,11.582,21.396c5.942,5.438,15.76,4.557,20.702,1.063c4.646-3.283,7.441-8.056,9.483-13.268
        c1.618-4.129,2.215-8.507,2.789-12.861c0.341-2.587,0.275-5.228,0.394-7.881c-1.578,0.489-2.911,1.063-4.301,1.299
        c-3.345,0.566-6.707,1.059-10.08,1.42c-3.158,0.338-6.295,0.196-9.193-1.457c-0.85,0.768-1.412,1.6-1.362,2.873
        c0.048,1.223-0.192,2.459-0.338,3.686c-0.31,2.603-0.64,5.201-0.968,7.851c2.605-0.393,5.078-0.775,7.556-1.133
        c0.742-0.107,1.571-0.123,1.685,0.851c0.117,0.998-0.672,1.212-1.482,1.338c-2.721,0.426-5.439,0.861-8.154,1.319
        c-1.2,0.202-1.634-0.168-1.483-1.424c0.253-2.102,0.566-4.197,0.809-6.3c0.24-2.084,0.424-4.175,0.624-6.163
        c-2.666,0.399-5.175,0.922-7.711,1.122C40.85,58.968,36.997,59.206,33.367,56.719z M35.938,39.918
        c13.538-0.734,26.544-2.322,39.667-4.195c-1.704-4.541-3.756-8.602-7.992-11.261c-3.784-2.375-7.882-2.744-12.11-2.333
        c-5.501,0.534-9.496,4.066-13.632,7.248c-0.301,0.232-0.489,0.621-0.704,0.954c-0.498,0.769-1.029,1.521-1.458,2.327
        C38.48,34.964,37.296,37.294,35.938,39.918z M57.458,43.091c0,2.331-0.016,4.524,0.008,6.717c0.01,0.975,0.227,1.923,1.431,1.996
        c1.706,0.103,3.431,0.239,5.127,0.101c2.622-0.214,5.231-0.611,7.836-1c2.344-0.349,4.599-1.231,5.559-3.469
        c0.779-1.818,0.861-3.944,1.16-5.948c0.038-0.257-0.433-0.763-0.755-0.859c-0.47-0.14-1.025-0.062-1.532,0.008
        c-4.352,0.606-8.698,1.253-13.052,1.843C61.392,42.73,59.528,42.875,57.458,43.091z M53.528,43.711
        c-0.514,0-0.865-0.024-1.212,0.003c-2.786,0.22-5.571,0.454-8.356,0.674c-2.867,0.228-5.737,0.424-8.601,0.693
        c-0.335,0.031-0.888,0.414-0.91,0.67c-0.153,1.771-0.355,3.566-0.219,5.329c0.13,1.684,1.451,2.631,2.957,3.122
        c0.894,0.292,1.893,0.431,2.832,0.383c2.669-0.138,5.341-0.336,7.995-0.649c1.751-0.207,3.232-0.934,4.228-2.585
        C53.686,48.956,53.235,46.348,53.528,43.711z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M33.367,56.719c3.63,2.486,7.483,2.249,11.292,1.948
        c2.536-0.2,5.045-0.723,7.711-1.122c-0.199,1.988-0.383,4.079-0.624,6.163c-0.242,2.103-0.555,4.198-0.809,6.3
        c-0.151,1.256,0.283,1.626,1.483,1.424c2.715-0.458,5.434-0.894,8.154-1.319c0.811-0.126,1.6-0.34,1.482-1.338
        c-0.113-0.974-0.942-0.958-1.685-0.851c-2.478,0.357-4.951,0.74-7.556,1.133c0.328-2.649,0.659-5.248,0.968-7.851
        c0.146-1.227,0.387-2.463,0.338-3.686c-0.05-1.273,0.512-2.105,1.362-2.873c2.898,1.653,6.035,1.795,9.193,1.457
        c3.373-0.361,6.735-0.854,10.08-1.42c1.39-0.235,2.723-0.81,4.301-1.299c-0.118,2.653-0.053,5.294-0.394,7.881
        c-0.574,4.354-1.171,8.732-2.789,12.861c-2.042,5.212-4.838,9.984-9.483,13.268c-4.941,3.493-14.76,4.374-20.702-1.063
        c-6.348-5.81-10.137-12.97-11.582-21.396c-0.377-2.197-0.511-4.437-0.737-6.658C33.327,57.819,33.367,57.35,33.367,56.719z
        M55.749,83.838c1.13-0.215,2.553-0.434,3.95-0.765c1.429-0.34,2.938-0.579,4.226-1.23c4.134-2.093,6.254-5.886,7.924-9.979
        c0.236-0.579,0.312-1.24,0.373-1.872c0.08-0.824-0.118-1.585-1.104-1.676c-0.957-0.09-1.084,0.624-1.271,1.39
        c-1,4.083-2.905,7.758-6.609,9.853c-3.242,1.832-6.893,2.575-10.724,1.559c-3.615-0.959-5.91-3.166-6.992-6.701
        c-0.176-0.573-0.299-1.166-0.515-1.723c-0.342-0.887-1.307-1.343-2.147-1.02c-0.969,0.372-1.192,1.136-0.899,2.053
        C44.227,80.84,48.903,83.155,55.749,83.838z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M35.938,39.918c1.358-2.624,2.542-4.954,3.77-7.261
        c0.429-0.806,0.961-1.558,1.458-2.327c0.215-0.333,0.403-0.722,0.704-0.954c4.137-3.182,8.131-6.714,13.632-7.248
        c4.228-0.411,8.326-0.042,12.11,2.333c4.236,2.659,6.288,6.72,7.992,11.261C62.482,37.597,49.477,39.184,35.938,39.918z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M57.458,43.091c2.07-0.215,3.934-0.36,5.781-0.611
        c4.354-0.59,8.7-1.237,13.052-1.843c0.507-0.071,1.062-0.148,1.532-0.008c0.322,0.096,0.793,0.602,0.755,0.859
        c-0.299,2.003-0.381,4.129-1.16,5.948c-0.96,2.238-3.215,3.12-5.559,3.469c-2.604,0.389-5.214,0.786-7.836,1
        c-1.696,0.138-3.421,0.002-5.127-0.101c-1.204-0.073-1.421-1.021-1.431-1.996C57.442,47.615,57.458,45.422,57.458,43.091z
        M60.713,48.207c1.285,0.043,2.139-0.658,2.185-1.433c0.047-0.784-0.671-1.464-1.547-1.466c-0.751-0.001-1.672,0.876-1.505,1.665
        C59.958,47.504,60.515,47.941,60.713,48.207z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M53.528,43.711c-0.292,2.637,0.158,5.244-1.288,7.64
        c-0.996,1.651-2.477,2.378-4.228,2.585c-2.654,0.313-5.325,0.511-7.995,0.649c-0.939,0.048-1.938-0.091-2.832-0.383
        c-1.505-0.491-2.827-1.438-2.957-3.122c-0.136-1.762,0.066-3.557,0.219-5.329c0.022-0.257,0.575-0.639,0.91-0.67
        c2.864-0.269,5.734-0.465,8.601-0.693c2.786-0.221,5.57-0.455,8.356-0.674C52.663,43.688,53.014,43.711,53.528,43.711z
        M49.413,46.19c-0.72,0.053-1.429,0.3-1.438,1.283c-0.01,1.01,0.628,1.523,1.565,1.634c0.916,0.108,1.435-0.372,1.528-1.278
        C51.154,46.983,50.446,46.252,49.413,46.19z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M55.749,83.838c-6.845-0.684-11.521-2.998-13.791-10.111
        c-0.293-0.917-0.07-1.681,0.899-2.053c0.84-0.323,1.805,0.133,2.147,1.02c0.216,0.557,0.339,1.149,0.515,1.723
        c1.082,3.535,3.377,5.742,6.992,6.701c3.831,1.017,7.481,0.273,10.724-1.559c3.704-2.095,5.609-5.77,6.609-9.853
        c0.188-0.766,0.314-1.479,1.271-1.39c0.986,0.091,1.185,0.852,1.104,1.676c-0.062,0.632-0.137,1.293-0.373,1.872
        c-1.67,4.093-3.79,7.886-7.924,9.979c-1.287,0.651-2.797,0.891-4.226,1.23C58.302,83.405,56.878,83.624,55.749,83.838z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M60.713,48.207c-0.198-0.266-0.755-0.703-0.867-1.234
        c-0.167-0.788,0.754-1.666,1.505-1.665c0.876,0.002,1.594,0.682,1.547,1.466C62.852,47.549,61.998,48.25,60.713,48.207z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M49.413,46.19c1.033,0.062,1.741,0.793,1.655,1.639
        c-0.093,0.906-0.612,1.387-1.528,1.278c-0.937-0.111-1.576-0.625-1.565-1.634C47.984,46.491,48.693,46.244,49.413,46.19z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AbteilungenIcon'
}
</script>
