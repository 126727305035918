var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"error-container pb-12",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"px-4 px-sm-5 px-md-6 px-lg-7 px-xl-8 pt-4 pb-4 pb-sm-5 pb-md-6 pb-lg-7 pb-xl-8",attrs:{"color":"#FFFFCC","elevation":"0"}},[_c('span',{staticClass:"error-text",style:({
        'font-size': _vm.computedErrorTextSize,
        'line-height': _vm.computedErrorTextSize
      })},[_vm._v(" OOPS! ")]),_c('div',{staticClass:"error-note pa-1 pa-sm-1 pa-md-2 pa-lg-3 pa-xl-3",style:({
        'font-size': _vm.computedErrorNoteSize
      })},[_c('center',[_c('div',[_vm._v(" 404 - Diese Seite haben wir nicht gefunden. ")]),_c('div',{style:({
            'font-size': _vm.computedErrorNoteSmallSize
          })},[_vm._v(" (hoffentlich macht das nicht Schule...) ")])])],1)]),_c('v-col',{staticClass:"pt-8",attrs:{"cols":"12","align":"center"}},[_c('InternalLinkButton',{attrs:{"themeColor":_vm.$vuetify.theme.currentTheme.neutral,"pageName":"Home","text":"Zur Startseite"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }