var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-self-center",staticStyle:{"position":"relative"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{class:{
        'menu-open': _vm.menuOpen
      },attrs:{"color":"neutral","dark":"","width":_vm.computedButtonWidth,"height":"33","min-width":"35","elevation":hover ? 12 : 3,"small":_vm.scrolled > 1.5},on:{"click":_vm.toggleMenu}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)]}}])}),(_vm.menuOpen)?_c('v-list',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.menuOpen = false; }),expression:"() => menuOpen = false"}],staticClass:"pa-0 menu-sheet rounded elevation-12",attrs:{"color":"transparent"}},_vm._l((_vm.breadCrumbs),function(item,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-list-item',{style:({
          'background-color': _vm.hexToRGB(item.disabled ? '#FFFFFF' : item.color, hover ? 1.0 : 0.9)
        }),attrs:{"color":item.disabled ? item.color : 'white',"to":item.href,"disabled":item.disabled,"exact":"","dark":""},on:{"click":_vm.toggleMenu}},[_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"30","color":"#fff"}},[_c('v-icon',{attrs:{"size":"25","color":item.color}},[_vm._v(" "+_vm._s(_vm.getIcon(item.icon))+" ")])],1)],1),(item.text !== null)?_c('v-list-item-title',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()],1)]}}],null,true)})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }