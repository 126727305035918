<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentTile
      :themeColor="computedTopicColor"
      title="Wer wir sind"
      :textArray="[
        'Wir unterrichten stationäre und teilstationäre Patient*innen am Universitätsklinikum Ulm in den beiden Abteilungen Unterricht am Krankenbett und Kinder- und Jugendpsychiatrie/-psychotherapie.',
        'Sonderschul-, Grund- und Hauptschul-, Realschul-, und Gymnasiallehrkräfte arbeiten bei uns in einem Team zusammen.',
        'Für die Hans-Lebrecht-Schule gilt die Ferienordnung des Landes Baden-Württemberg.'
      ]"
    />
    <ContentImageTile
      imageSource="/img/schulhaus/11.jpg"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Was wir tun"
      :textArray="[
        'Wir unterrichten und fördern die Schüler*innen gemäß ihres individuellen Entwicklungs- und Leistungsstandes und ihrer momentanen Lernmöglichkeiten unter Berücksichtigung ihrer Ressourcen.',
        'Wir passen Lerninhalte, Lerntempo der Schüler*innen in Schule oder Ausbildung durch Diagnostik und Beratung den individuellen Bedürfnissen und krankheitsbedingten Einschränkungen an.'
      ]"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Wie wir unsere Arbeit verstehen"
      :textArray="[
        'Um das Vertrauen in die eigene Lern- und Leistungsfähigkeit zu stärken, arbeiten wir in einem angstfreien Lernklima. Um unseren Schüler*innen eine optimale Förderung zu ermöglichen, tauschen wir regelmäßig mit allen an den Therapien beteiligten Fachkräften Informationen aus.',
        'Die intensive Zusammenarbeit mit den Heimatschulen ist uns wichtig, um für alle Schüler*innen pädagogische Ziele abzusprechen, Lerninhalte auszutauschen und einen erfolgreichen Übergang zu gestalten.',
        'Täglich besprechen wir in den Lehrer*innenteams das Sozial- und Lernverhalten unserer Schüler*innen, planen die zu erarbeitenden Lerninhalte und passen die Förderziele und Vorgehensweisen an.',
        'Im Rahmen unseres Schutzkonzeptes verpflichten wir uns an der Hans-Lebrecht-Schule, auf den Schutz der Kinder und Jugendlichen und die Einhaltung der Kinderrechte zu achten.'
      ]"
    />
    <ContentContainer
      v-if="computedPrinciples.length > 0"
      :themeColor="computedTopicColor"
      title='Prinzipien unserer Schule'
    >
      <ContentImageTile
        v-for="(principle, i) in computedPrinciples"
        :key="i"
        embedded
        :title="principle.title"
        :themeColor="principle.themeColor"
        :backgroundColor="principle.backgroundColor"
      />
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title='Die Standards'
      subtitle='im Förderschwerpunkt Schüler*innen in längerer Krankenhausbehandlung in Baden-Württemberg'
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Pädagogik bei Krankheit"
        :textArray="[
          'Krankheit kann von den betroffenen Schüler*innen als krisenhafte, teilweise existenzbedrohende Situation erlebt werden. Krankheit wirkt sich auf die Persönlichkeitsentwicklung aus. Sie beeinflusst die Lernvoraussetzungen und kann daher die Bildungsbiografie von Schüler*innen verändern.',
          'Erziehung und Unterricht sind für kranke Schüler*innen von besonderer Bedeutung. Sie unterstützen sowohl die Krankheitsbewältigung als auch den Heilungsprozess.',
          'Pädagogik bei Krankheit stellt die Schüler*innen mit ihrer Erkrankung in den Mittelpunkt der schulischen Erziehung und Bildung. Dabei ist ein individualisierter und ressourcenorientierter Ansatz handlungsweisend für die pädagogische und unterrichtliche Arbeit. Pädagogik bei Krankheit verfolgt dabei kontinuierlich Aspekte wie „Leben mit der Krankheit“ (kompetenter Umgang mit der Krankheit in Schule und Alltag), „Perspektiven entwickeln“ (Zukunft planen und gestalten), „Anschluss halten“ (Kompetenzen vermitteln) und „Verbunden bleiben“ (Integration in die Regelschule).',
          'Die Teilhabe an Bildung und an sozialen Kontexten wird angebahnt, ermöglicht und sichergestellt. Durch Erfahrungen positiver Selbstwirksamkeit wird die Persönlichkeit mit dem Ziel der größtmöglichen Partizipation und Autonomie gestärkt.'
        ]"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Weiterbildung"
        :textArray="[
          'Für die Lehrkräfte bedeutet dies, sich Kenntnisse über die unterschiedlichen Krankheitsbilder und deren Auswirkungen auf den Bildungs- und Erziehungsprozess anzueignen. Bei der Auswahl der Lerninhalte orientieren sich die Lehrkräfte an der individuellen Lernsituation, den Bildungsgängen und schulartspezifischen Bildungsplänen der jeweiligen Heimatschule.',
          'Aufgabe und Besonderheit der pädagogischen Förderung kranker Schüler*innen erfordern von den Lehrkräften ein hohes Maß an Flexibilität, Einfühlungsvermögen sowie Bereitschaft und Fähigkeit, zu einem schnellen Beziehungsaufbau und einer zeitlich begrenzten intensiven Beziehungsgestaltung.'
        ]"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Schulische Diagnostik"
        :textArray="[
          'Die schulische Diagnostik im Kontext von Pädagogik bei Krankheit ist eine informelle, auf die aktuelle Krankheitssituation bezogene Prozessdiagnostik.',
          'Sie beinhaltet unter anderem eine Erhebung des individuellen Lernstandes und der Leistungsfähigkeit, der subjektiven Lernstrategien sowie des besonderen und individuellen Förderbedarfs der kranken Schüler*innen.',
          'In die Diagnostik fließen die Informationen der anderen Fachdienste ein.'
        ]"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Bildung"
        :textArray="[
          'Das Sonderpädagogische Bildungs- und Beratungszentrum (SBBZ) mit dem Förderschwerpunkt Schüler*innen in längerer Krankenhausbehandlung vermittelt schulische Normalität im Klinikalltag auch mit dem Ziel der Reintegration in den Schulalltag.',
          'Auf der Grundlage einer schulischen Diagnostik werden individuelle Lern- und Förderpläne für die Schüler*innen unter Berücksichtigung ihrer spezifischen Erkrankung entwickelt.',
          'Die Schüler*innen werden im Einzelunterricht oder in jahrgangs- und schulartübergreifenden Kleingruppen unter Beachtung des Bezugslehrkraftprinzips unterrichtet.',
          'Die Lerninhalte und Unterrichtszeiten der Schüler*innen werden entsprechend dem individuellen Förderbedarf methodisch-didaktisch angepasst und mit dem Therapieangebot abgestimmt. Es wird ein differenziertes, individualisiertes und ressourcenorientiertes Lernangebot erstellt.',
          'Der Unterricht einer Pädagogik bei Krankheit berücksichtigt die aktuellen interdisziplinären Informationen und ermöglicht eine krankheitsorientierte Schwerpunktsetzung. Grundlage dafür sind die Bildungspläne aller Schularten.',
          'Unter dem Aspekt eines ganzheitlichen Bildungsverständnisses werden die Schüler*innen mit ihrer Persönlichkeit, ihrer besonderen Lebenslage und ihrer Krankheit gesehen, anerkannt und wertgeschätzt.'
        ]"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Beratung"
        :textArray="[
          'Die Ausgestaltung der Beziehung erfordert von Seiten der Lehrkraft ein hohes Maß an Reflexionsfähigkeit sowie die Fähigkeit zur Balance von unterstützender Empathie und professioneller Distanz. Das traditionelle Rollenverständnis von Lehrkräften und Schüler*innen wird dabei immer wieder überdacht und ggf. neu definiert.',
          'Das Sonderpädagogische Bildungs- und Beratungszentrum (SBBZ) mit dem Förderschwerpunkt „Schüler in längerer Krankenhausbehandlung“ berät Schüler*innen, Eltern, Lehrkräfte und Einrichtungen.',
          'In der Beratung stehen folgende inhaltliche Themen im Fokus:'
        ]"
      >
        <div
          slot="content"
        >
          <ul>
            <li>
              Reintegration
            </li>
            <li>
              weitere Schullaufbahn
            </li>
            <li>
              Lernortklärung
            </li>
            <li>
              Informationen zu Krankheitsbildern
            </li>
            <li>
              Gestaltung des Nachteilsausgleichs
            </li>
            <li>
              berufliche Perspektiven
            </li>
            <li>
              schulische Hilfs- und Unterstützungsmaßnahmen
            </li>
            <li>
              außerschulische Unterstützungsmöglichkeiten
            </li>
            <li>
              Sensibilisierung für die besonderen Belange der Pädagogik bei Krankheit
            </li>
          </ul>
          <br>
          Bildung und Beratung durch das Sonderpädagogische Bildungs- und Beratungszentrum (SBBZ) mit dem Förderschwerpunkt "Schüler in längerer Krankenhausbehandlung" erfolgt in einem inter- und multidisziplinären Team aus Ärzt*innen, Therapeut*innen und Lehrkräften.
          <br><br>
          Zur Erfüllung des Bildungs- und Beratungsauftrages bedarf es regionaler und überregionaler Systemkenntnisse der beteiligten Lehrkräfte.
          <br><br>
          Das Sonderpädagogische Bildungs- und Beratungszentrum (SBBZ) kooperiert mit schulischen und außerschulischen Partnern und baut ein tragfähiges Netzwerk auf.
        </div>
      </ContentTile>
    </ContentContainer>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'

import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentContainer from '@/views/components/ContentContainer.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'

export default {
  name: 'Goals',
  components: {
    ContentFrame,
    ContentContainer,
    ContentTile,
    ContentImageTile
  },
  data () {
    return { }
  },
  mounted () { },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.goals.color
    },
    computedTitle () {
      return this.menuData.goals.title
    },
    computedPrinciples () {
      return [
        {
          title: 'Respekt',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#015ccf'
        },
        {
          title: 'Wertschätzung',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#64cb00'
        },
        {
          title: 'Transparenz',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#01a0fe'
        },
        {
          title: 'Lösungsorientierung',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#abf000'
        },
        {
          title: 'Verlässlichkeit',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#5ec9a5'
        },
        {
          title: 'Offenheit',
          themeColor: this.menuData.goals.color,
          imageSource: '/img/prinzipien/verlaesslichkeit.png',
          backgroundColor: '#feff01'
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
