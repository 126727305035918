<template>
  <div
    style="position: relative;"
    class="align-self-center"
  >
    <v-hover
        v-slot="{ hover }"
      >
      <v-btn
        color="neutral"
        dark
        :width="computedButtonWidth"
        height="33"
        min-width="35"
        @click="toggleMenu"
        :elevation="hover ? 12 : 3"
        :small="scrolled > 1.5"
        :class="{
          'menu-open': menuOpen
        }"
      >
        <v-icon>
          {{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-hover>
    <v-list
      v-if="menuOpen"
      color="transparent"
      class="pa-0 menu-sheet rounded elevation-12"
      v-click-outside="() => menuOpen = false"
    >
      <v-hover
        v-for="(item, index) in breadCrumbs"
        :key="index"
        v-slot="{ hover }"
      >
        <v-list-item
          :color="item.disabled ? item.color : 'white'"
          :to="item.href"
          :disabled="item.disabled"
          @click="toggleMenu"
          exact
          dark
          :style="{
            'background-color': hexToRGB(item.disabled ? '#FFFFFF' : item.color, hover ? 1.0 : 0.9)
          }"
        >
          <v-list-item-avatar>
            <v-avatar
              size="30"
              color="#fff"
              class="mr-1"
            >
              <v-icon
                size="25"
                :color="item.color"
              >
                {{ getIcon(item.icon) }}
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-title
            v-if="item.text !== null"
            :color="item.color"
          >
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-hover>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BreadCrumbsMenu',
  props: {
    scrolled: Number
  },
  data () {
    return {
      menuOpen: false,
      breadCrumbs: []
    }
  },
  mounted () {
    if (this.$route.meta.isErrorPage ?? false) {
      this.buildErrorBreadCrumbs()
    } else {
      const pathDataArray = this.$route.meta.pathData || []
      this.buildBreadCrumbs(pathDataArray)
    }
  },
  methods: {
    buildBreadCrumbs (pathDataArray) {
      const rawBreadCrumbs = pathDataArray.map((pathData) => {
        return {
          icon: this.menuData[pathData.id].icon,
          text: this.menuData[pathData.id].title,
          disabled: false, // (pathArray[pathArray.length - 1] === id),
          href: '/' + pathData.pathSegment,
          color: this.menuData[pathData.id].color
        }
      })
      rawBreadCrumbs.unshift(
        {
          icon: this.menuData.main.icon,
          text: this.menuData.main.title || 'Startseite',
          disabled: false, // rawBreadCrumbs.length === 0,
          href: '/',
          color: this.menuData.main.color
        }
      )
      rawBreadCrumbs[rawBreadCrumbs.length - 1].disabled = true
      this.breadCrumbs = rawBreadCrumbs
    },
    buildErrorBreadCrumbs () {
      this.breadCrumbs = [
        {
          icon: this.menuData.main.icon,
          text: this.menuData.main.title || 'Startseite',
          disabled: false,
          href: '/',
          color: this.menuData.main.color
        }
      ]
    },
    hexToRGB (hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
      } else {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.0)'
      }
    },
    toggleMenu () {
      this.menuOpen = !this.menuOpen
    },
    getIcon (key) {
      return '$vuetify.icons.' + key
    }
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedButtonWidth () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 40
      } else {
        return 45
      }
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.isErrorPage ?? false) {
        this.buildErrorBreadCrumbs()
      } else {
        const pathDataArray = to.meta.pathData || []
        this.buildBreadCrumbs(pathDataArray)
      }
    }
  }
}
</script>

<style scoped>
  .v-btn.menu-open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .menu-sheet {
    position: absolute;
    top: 100%;
    right: 0px;
    background-color:rgba(0,0,0,0.1) !important;
    backdrop-filter: blur(10px);
    overflow: hidden;
    border-top-right-radius: 0px !important;
  }
  .v-list-item {
    transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .v-list-item:hover::before {
    opacity: 0.0 !important;
  }
</style>
