<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M101.012,71.853c-0.328-4.685-0.552-8.944-0.942-13.189c-0.405-4.405-0.97-8.796-1.468-13.192
        c-0.44-3.88-0.923-7.756-1.311-11.641c-0.191-1.926-1.035-2.827-3.009-2.769c-1.261,0.037-2.52,0.307-3.776,0.295
        c-4.154-0.036-8.309-0.191-12.463-0.223c-2.434-0.019-4.873,0.239-7.302,0.147c-4.701-0.177-9.396-0.515-14.093-0.782
        c-1.395-0.08-2.789-0.15-4.184-0.215c-1.474-0.068-2.948-0.137-4.423-0.184c-4.181-0.133-8.371-0.145-12.542-0.434
        c-1.851-0.128-3.665-0.729-5.56-0.352c-0.702,0.14-1.424,0.212-2.14,0.248c-3.703,0.183-7.411,0.298-11.111,0.527
        c-2.647,0.165-3.726,0.854-3.867,3.844c-0.24,5.066-0.286,10.142-0.319,15.215c-0.036,5.439,0.047,10.879,0.058,16.317
        c0.009,4.553-0.001,9.105-0.023,13.658c-0.006,1.234-0.145,2.47-0.112,3.701c0.034,1.271,0.755,1.708,1.887,1.468
        c0.865-0.184,1.778-0.152,2.671-0.191c0.794-0.035,1.621,0.102,2.38-0.072c6.264-1.433,12.666-1.699,19.028-2.013
        c5.935-0.291,11.9,0.057,17.852,0.126c0.199,0.003,0.399,0.024,0.596,0.056c5.569,0.888,11.174,0.468,16.759,0.354
        c4.982-0.102,9.958-0.488,14.937-0.758c0.914-0.05,1.825-0.171,2.74-0.203c2.262-0.079,4.524-0.168,6.787-0.168
        c1.045,0,1.728-0.407,1.894-1.564C100.358,77.062,100.698,74.253,101.012,71.853z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M101.012,71.853c-0.313,2.4-0.653,5.209-1.056,8.008
        c-0.166,1.157-0.849,1.564-1.894,1.564c-2.263,0-4.525,0.089-6.787,0.168c-0.915,0.032-1.826,0.153-2.74,0.203
        c-4.979,0.27-9.954,0.656-14.937,0.758c-5.585,0.114-11.189,0.534-16.759-0.354c-0.197-0.031-0.397-0.053-0.596-0.056
        c-5.952-0.069-11.917-0.417-17.852-0.126c-6.362,0.313-12.765,0.58-19.028,2.013c-0.759,0.174-1.586,0.037-2.38,0.072
        c-0.893,0.039-1.806,0.008-2.671,0.191c-1.132,0.24-1.854-0.196-1.887-1.468c-0.033-1.231,0.106-2.467,0.112-3.701
        c0.022-4.553,0.032-9.105,0.023-13.658c-0.011-5.438-0.094-10.878-0.058-16.317c0.034-5.073,0.079-10.149,0.319-15.215
        c0.142-2.991,1.22-3.68,3.867-3.844c3.7-0.229,7.408-0.345,11.111-0.527c0.716-0.036,1.438-0.108,2.14-0.248
        c1.895-0.377,3.709,0.224,5.56,0.352c4.17,0.289,8.36,0.3,12.542,0.434c1.475,0.047,2.949,0.115,4.423,0.184
        c1.395,0.064,2.79,0.135,4.184,0.215c4.698,0.267,9.392,0.604,14.093,0.782c2.429,0.091,4.868-0.166,7.302-0.147
        c4.154,0.031,8.309,0.187,12.463,0.223c1.257,0.011,2.516-0.258,3.776-0.295c1.974-0.059,2.817,0.843,3.009,2.769
        c0.388,3.885,0.87,7.761,1.311,11.641c0.498,4.396,1.062,8.787,1.468,13.192C100.46,62.909,100.684,67.168,101.012,71.853z
        M97.389,79.583c0.932-7.064-1.202-35.88-3.073-40.946c-0.847,0.922-1.584,1.85-2.445,2.641c-2.676,2.456-5.384,4.878-8.108,7.281
        c-2.924,2.579-5.863,5.14-8.825,7.674c-3.665,3.134-7.352,6.242-11.047,9.34c-1.281,1.073-2.595,2.114-3.953,3.089
        c-0.259,0.186-0.877,0.126-1.189-0.059c-1.718-1.016-3.451-2.023-5.067-3.19c-2.586-1.868-5.092-3.849-7.615-5.803
        c-1.538-1.191-3.069-2.393-4.562-3.641c-5.056-4.231-10.108-8.467-15.132-12.736c-2.769-2.353-5.494-4.759-8.206-7.178
        c-0.769-0.686-1.422-1.502-2.229-2.366c-0.258,16.187-0.514,32.18-0.771,48.251c0.924,0.101,2.088,0.432,3.201,0.308
        c3.326-0.373,6.62-1.05,9.95-1.374c4.009-0.39,8.038-0.691,12.063-0.754c4.67-0.073,9.345,0.134,14.017,0.264
        c1.711,0.048,3.418,0.285,5.129,0.328c4.591,0.114,9.186,0.301,13.773,0.212c4.622-0.09,9.238-0.486,13.858-0.729
        c2.23-0.117,4.464-0.171,6.694-0.292C95.036,79.839,96.216,79.692,97.389,79.583z M94.117,34.019
        c-0.858-0.343-1.392-0.733-1.936-0.747c-4.506-0.109-9.015-0.175-13.522-0.193c-1.511-0.006-3.026,0.286-4.533,0.233
        c-3.385-0.119-6.763-0.427-10.147-0.55c-4.981-0.181-9.965-0.312-14.949-0.368c-1.787-0.02-3.576,0.331-5.365,0.35
        c-2.83,0.03-5.662-0.049-8.491-0.145c-0.989-0.033-1.979-0.401-2.956-0.352c-2.547,0.127-5.089,0.38-7.629,0.622
        c-2.12,0.202-4.235,0.458-6.453,0.702c0.22,0.247,0.373,0.471,0.574,0.636c2.286,1.871,4.559,3.757,6.874,5.592
        c4.788,3.795,9.68,7.463,14.376,11.368c4.503,3.745,8.777,7.764,13.224,11.577c1.781,1.527,3.737,2.855,5.665,4.201
        c0.261,0.183,0.942,0.097,1.216-0.126c2.309-1.892,4.603-3.806,6.836-5.785c4.234-3.753,8.439-7.539,12.626-11.344
        c0.969-0.88,1.802-1.908,2.725-2.841c2.242-2.268,4.559-4.464,6.732-6.794C90.718,38.198,92.287,36.185,94.117,34.019z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M97.389,79.583c-1.173,0.108-2.353,0.256-3.537,0.32
        c-2.23,0.121-4.464,0.175-6.694,0.292c-4.62,0.242-9.236,0.639-13.858,0.729c-4.588,0.089-9.183-0.098-13.773-0.212
        c-1.711-0.043-3.417-0.28-5.129-0.328c-4.672-0.13-9.347-0.337-14.017-0.264c-4.025,0.062-8.054,0.364-12.063,0.754
        c-3.33,0.324-6.624,1.001-9.95,1.374c-1.113,0.124-2.276-0.207-3.201-0.308c0.257-16.071,0.512-32.064,0.771-48.251
        c0.807,0.864,1.46,1.68,2.229,2.366c2.712,2.419,5.437,4.825,8.206,7.178c5.024,4.269,10.076,8.505,15.132,12.736
        c1.492,1.248,3.023,2.45,4.562,3.641c2.523,1.954,5.029,3.935,7.615,5.803c1.616,1.167,3.349,2.175,5.067,3.19
        c0.312,0.185,0.931,0.244,1.189,0.059c1.358-0.975,2.672-2.016,3.953-3.089c3.695-3.098,7.382-6.206,11.047-9.34
        c2.962-2.534,5.901-5.096,8.825-7.674c2.725-2.403,5.433-4.825,8.108-7.281c0.861-0.792,1.599-1.719,2.445-2.641
        C96.187,43.703,98.32,72.519,97.389,79.583z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M94.117,34.019c-1.83,2.166-3.399,4.179-5.134,6.037
        c-2.174,2.33-4.49,4.526-6.732,6.794c-0.923,0.933-1.756,1.961-2.725,2.841c-4.187,3.805-8.392,7.591-12.626,11.344
        c-2.233,1.979-4.527,3.894-6.836,5.785c-0.273,0.223-0.955,0.309-1.216,0.126c-1.927-1.346-3.883-2.674-5.665-4.201
        c-4.447-3.813-8.721-7.833-13.224-11.577c-4.696-3.905-9.589-7.573-14.376-11.368c-2.314-1.835-4.588-3.721-6.874-5.592
        c-0.202-0.165-0.354-0.389-0.574-0.636c2.218-0.243,4.333-0.5,6.453-0.702c2.54-0.242,5.082-0.495,7.629-0.622
        c0.977-0.049,1.966,0.319,2.956,0.352c2.829,0.095,5.661,0.174,8.491,0.145c1.79-0.019,3.579-0.37,5.365-0.35
        c4.983,0.056,9.967,0.187,14.949,0.368c3.385,0.123,6.763,0.431,10.147,0.55c1.507,0.053,3.022-0.239,4.533-0.233
        c4.508,0.018,9.017,0.084,13.522,0.193C92.726,33.286,93.259,33.676,94.117,34.019z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'KontaktIcon'
}
</script>
