<template>
  <ContentFrame
  :themeColor="computedTopicColor"
  :title="computedTitle"
  >
    <ContentContainer
      :themeColor="computedTopicColor"
      title="Das Kalenderprojekt"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='Das Ich im Licht der Fenster'
        subtitle="des Ulmer Münsters"
        :textArray="[
          'Schüler*innen, die für eine längere Krankenhausbehandlung am Uniklinikum Ulm verweilen, erhalten in der Hans-Lebrecht-Schule Unterricht. Sie beschäftigen sich in ihrem Projekt mit dem Ulmer Münster, dessen 1885-90 vollendeter Westturm bis heute der höchste Kirchturm der Welt ist.',
          'Besonderes Augenmerk legen sie auf die Lichtwirkung in der gotischen Kirche, die von den hohen Buntglasfenstern ausgeht. Bei Erkundungen im Münster setzen sie sich mit der Entstehung der mittelalterlichen Chorfenster auseinander, analysieren ihre Symbolik, erfahren von ihrer kunsthistorischen Bedeutung und lernen die Maßnahmen kennen, die zu ihrem Erhalt nötig sind. Der Münsterbaumeister gewährt ihnen dabei einen Einblick in die Arbeit der Münsterbauhütte.'
        ]"
      />
      <ContentImageTile
        embedded
        imageSource="/img/projekte/denkmal_kalender/Weltgefaehrdung_JSchreiter_FotoMKesslerUlm.jpg"
        caption="Ausschnitt aus dem Fenster „Weltgefährdung“ von Johannes Schreiter im südlichen Seitenschiff des Münsters (Foto: M. Kessler, Ulm)"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Der Kalender"
        :textArray="[
          'Jede*r im Projektteam wählt dann ihr oder sein Münster-Fenster aus und beschreibt es. Für die fotografische Erfassung erhält das Team Unterstützung von professionellen Fotograf*innen. Zu den verschiedenen Fenstern entsteht ein Kalender mit Texten der Schüler*innen.'
        ]"
        downloadText="Kalender"
        downloadFileName="denkmal-aktiv-kalender.pdf"
      />
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title="Das Skulpturenprojekt"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='Ein Kunstrojekt zum Thema „Dazugehören“'
        subtitle="mit der Künstlerin Anna Santana"
        :textArray="[
          'In einem Kunstprojekt haben unsere Schüler*innen der Kinder- und Jugendpsychiatrie gemeinsam mit der Künstlerin Anna Sacher Santana eine Skulptur zum Thema „Dazugehören“ gestaltet, die im Klinikgarten aufgestellt wurde. Im Workshop wurden Ideen der Kinder und Jugendlichen zum Thema gesammelt und erste Entwürfe zur Skulptur in Form von Zeichnungen und Modellen aus Ton angefertigt. Aus diesem Input der Schüler*innen heraus hat die Künstlerin eine Skulptur entworfen und diese gemeinsam mit den Kindern und Jugendlichen umgesetzt.',
          'Unterstützt wurde das Projekt von der Otto-Kässbohrer-Stiftung und der Stadt Ulm. Die feierliche Enthüllung der Skulptur fand im Garten der Klinik für Kinder- und Jugendpsychiatrie statt.'
        ]"
      />
      <ContentVideoTile
        embedded
        title="Die Künstlerin im Interview"
        videoSource='/vid/skulptur_interview.m4v'
        videoType='video/mp4'
      />
      <ContentGalleryTile
        embedded
        v-if="photosSculpture.length > 0"
        :themeColor="computedTopicColor"
        title="Impressionen"
        :photos="photosSculpture"
      />
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title="Ein Tag auf der Jugendstation II"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='Das Filmprojekt'
        :textArray="[
          'Wir wir mit den Jugendlichen aus der Station II - alle im Alter zwischen 15 und 18 Jahren - ein Filmprojekt durchgeführt. Das ganze Projekt wurde von drei Lehrkräften und einem professionellen Team, bestehend aus einer Regisseurin, einem Puppen-Workshop-Leiter und einem Kamera-Team, begleitet. Aufgrund der erforderlichen Anonymität der Schüler*innen sind die Darsteller im Film Puppen, die von den Jugendlichen hergestellt und gespielt wurden. Auch die Szenen für das Drehbuch wurden von den Schüler*innen aus ihrem eigenen Erfahrungsschatz selbst ausgewählt und im Deutschunterricht als Szenenvorlage erarbeitet.',
          'Die Jugendlichen haben acht intensive Tage zusammen mit dem professionellen Team gearbeitet. In dieser Zeit sind mit vollem Engagement aller Beteiligten die Puppen entstanden und die im Unterricht erarbeiteten Szenen gedreht worden.',
          'Durch den selbst gedrehten Kurzfilm „Ein Tag auf der Jugendstation II“ soll Einblick in den Stationsalltag der offenen Jugendstation gegeben werden, um „Berührungsängste“ mit dem Bereich Kinder- und Jugendpsychiatrie abzubauen.'
        ]"
      />
      <ContentVideoTile
        embedded
        videoSource='/vid/Hans-Lebrecht-Schule_Film-Tag-auf-Jugendstation.mp4'
        posterSource='/img/projekte/tag_auf_jugendstation/thumbnail.jpg'
        videoType='video/mp4'
      />
      <ContentGalleryTile
        embedded
        v-if="photosJugendstation.length > 0"
        :themeColor="computedTopicColor"
        title="Impressionen"
        :photos="photosJugendstation"
      />
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title="Das Fotoprojekt"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='„Bedeutsames in Ulm“'
        :textArray="[
          'Dieses Kunstprojekt mit Jugendlichen aus der stationären Behandlung in der Kinder- und Jugendpsychiatrie/-psychotherapie wurde ebenfalls mit einem professionellen Künstler, dem Fotografen Armin Buhl durchgeführt.',
          'Es gibt Einblicke in das Erleben von Jugendlichen, die aufgrund besonderer emotionaler Belastungen in der Klinik für Kinder- und Jugendpsychiatrie behandelt werden und während dieser Zeit die Hans-Lebrecht-Schule besuchen.',
          'Bei diesem Fotoprojekt beschäftigen sich die Jugendlichen mit Bedeutsamem in ihrem Leben. Sie suchten sich Motive in Ulm, die für ihre Vorlieben, Ängste, Erlebnisse und Erfahrungen, ihre Weltanschauung und persönlichen Ziele stehen.'
        ]"
      />
      <ContentImageTile
        embedded
        imageSource="/img/projekte/bedeutsames_in_ulm/cover.jpg"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Download und Druckversion"
        :textArray="[
          'Wir schicken Ihnen gerne das Buch gegen eine kleine Spende an den KlinikSchulverein per Post zu. Bitte kontaktieren Sie uns hierfür einfach per Mail.'
        ]"
        downloadText='Buch "Bedeutsames in Ulm"'
        downloadFileName="Hans-Lebrecht-Schule_BiU.pdf"
      >
        <v-col
          slot="content"
          v-if="emailRequestAddress"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-email"
            :value="emailRequestAddress"
            :href="'mailto:' + emailRequestAddress + '?subject=' + emailRequestSubjectBiU"
          />
        </v-col>
      </ContentTile>
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title="Dazugehören"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='Bilder aus unserer Schule'
        :textArray="[
          '„Dazugehören“ ist ein Thema, das alle Kinder und Jugendlichen von früher Kindheit an in Bezug auf ihre Familie, Tagesstätte, Schule, Interessen- und Freundesgruppen begleitet. Unseren Schüler*innen der Hans-Lebrecht-Schule geht es ebenso. Es wurde ihnen deutlich, dass es ein erstrebtes „Dazugehören“ am Ende ihres Weges gibt, das sich gut anfühlt. In der augenblicklichen Situation jedoch der Weg selbst das Eigentliche, Existenzielle ist.',
          'Die Bilder dokumentieren, welch „harte Arbeit“ die Kinder und Jugendlichen im therapeutischen Prozess leisten. Dieser Prozess, der sich als das eigentlich Zentrale herauskristallisierte, braucht Anleitung und Begleitung, therapeutische und pädagogische Angebote, Strategien, Struktur und viel Zuspruch, um Schritt für Schritt weiter zu kommen, näher zum Ziel, ohne die Motivation zu verlieren oder entmutigt aufzugeben. Ein herzliches Dankeschön allen, die am Projekt teilgenommen und es unterstützt haben.'
        ]"
      />
      <ContentImageTile
        embedded
        imageSource="/img/projekte/dazugehoeren/cover.jpg"
      />
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title="Download und Druckversion"
        :textArray="[
          'Wir schicken Ihnen gerne das Buch gegen eine kleine Spende an den KlinikSchulverein per Post zu. Bitte kontaktieren Sie uns hierfür einfach per Mail.'
        ]"
        downloadText='Buch "Dazugehören!"'
        downloadFileName="Hans-Lebrecht-Schule_D.pdf"
      >
        <v-col
          slot="content"
          v-if="emailRequestAddress"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-email"
            :value="emailRequestAddress"
            :href="'mailto:' + emailRequestAddress + '?subject=' + emailRequestSubjectD"
          />
        </v-col>
      </ContentTile>
    </ContentContainer>
    <ContentContainer
      :themeColor="computedTopicColor"
      title="„Das alles kann Schule sein“"
    >
      <ContentTile
        embedded
        :themeColor="computedTopicColor"
        title='Mit Kunst zur Öffnung'
        :textArray="[
          'Die Schule als Thema begleitet alle Kinder und Jugendlichen. Auch die Schüler*innen der Hans-Lebrecht-Schule, die stationär oder teilstationär im geschützten Rahmen der Klinik für Kinder- und Jugendpsychiatrie/-psychotherapie am Universitätsklinikum in Ulm und in der Klinikschule begleitet werden, sind vor und nach ihrem Aufenthalt Teil des allgemeinen Schulsystems.',
          'Wir wollten vorhandenen Mustern und Prägungen nachspüren und diese Spuren auf Papier sich verbildlichen lassen.',
          'Unsere Schüler*innen haben im Rahmen dieses Kunstprojektes ihre Gedanken, Gefühle, Ängste, Freuden und Zweifel, die ihnen aus ihrer bisherigen Schulzeit zugänglich waren, gemalt und in einem Satz zusammengefasst.',
          'Höchst eindrucksvoll war, welche Reaktionen der Prozess der Entstehung dieser Werke auslöste. Oft gestaltete sich – meist bei den älteren Schüler*innen -  erst nach einstündigem Ringen mit eigenen Erfahrungen, ein Öffnen des eigenen Erlebens in Form und Farbe.'
        ]"
        downloadText='Buch "Das alles kann Schule sein!"'
        downloadFileName="Hans-Lebrecht-Schule_DakSs.pdf"
      />
      <ContentImageTile
        embedded
        imageSource="/img/projekte/das_alles_kann_schule_sein/cover.jpg"
      />
    </ContentContainer>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'

import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentContainer from '@/views/components/ContentContainer.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentVideoTile from '@/views/components/ContentVideoTile.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'
import ContentGalleryTile from '@/views/components/ContentGalleryTile.vue'
import ContactChip from '@/views/components/ContactChip.vue'

export default {
  name: 'Projects',
  components: {
    ContentContainer,
    ContentFrame,
    ContentTile,
    ContentVideoTile,
    ContentImageTile,
    ContentGalleryTile,
    ContactChip
  },
  data () {
    return {
      emailRequestAddress: 'info@hans-lebrecht-schule.de',
      emailRequestSubjectBiU: 'Buch Bedeutsames in Ulm',
      emailRequestSubjectD: 'Buch dazugehoeren',
      photosSculpture: [
        {
          src: '/img/projekte/skulpturen/01.jfif',
          description: 'Erste Entwürfe der Schüler*innen zur Skulptur in Form von Zeichnungen oder Skizzen sowie Modellen'
        },
        {
          src: '/img/projekte/skulpturen/02.jfif',
          description: 'Erste Entwürfe der Schüler*innen zur Skulptur in Form von Zeichnungen oder Skizzen sowie Modellen'
        },
        {
          src: '/img/projekte/skulpturen/03.jpg',
          description: 'Erste Entwürfe der Schüler*innen zur Skulptur in Form von Zeichnungen oder Skizzen sowie Modellen'
        },
        {
          src: '/img/projekte/skulpturen/04.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/05.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/06.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/07.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/08.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/09.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/10.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/11.jpg',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/12.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/13.jpg',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/14.jpg',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/15.jpg',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/16.jpg',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/17.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/18.jfif',
          description: 'Entstehungsprozess der Skulpturen'
        },
        {
          src: '/img/projekte/skulpturen/19.jpg',
          description: 'Musikalische Einlage von Thorsten Sukale und Band'
        },
        {
          src: '/img/projekte/skulpturen/20.jpg',
          description: 'Schild zur Skulptur'
        },
        {
          src: '/img/projekte/skulpturen/21.jpg',
          description: 'Skulptur zum Thema "Dazugehören"'
        }
      ],
      photosJugendstation: [
        {
          src: '/img/projekte/tag_auf_jugendstation/01.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/02.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/03.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/04.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/05.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/06.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/07.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/08.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/09.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/10.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/11.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/12.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/13.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/14.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/15.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/16.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/17.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/18.jpg',
          description: null
        },
        {
          src: '/img/projekte/tag_auf_jugendstation/19.jpg',
          description: null
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.projects.color
    },
    computedTitle () {
      return this.menuData.projects.title
    }
  }
}
</script>

<style scoped>
</style>
