<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M17.331,60.895c-0.108,0.007-0.215,0.014-0.323,0.021c0.25,4.825,0.46,9.653,0.765,14.476
        c0.213,3.387,0.492,6.772,0.856,10.146c0.398,3.688,0.938,7.362,1.396,11.045c0.152,1.222,0.835,1.938,2.148,1.974
        c5.823,0.16,11.615,0.777,17.477,0.374c7.41-0.509,14.854-0.521,22.285-0.742c5.788-0.172,11.579-0.284,17.364-0.52
        c2.908-0.119,5.804-0.49,8.71-0.679c2.026-0.132,4.085-0.373,6.085-0.155c2.095,0.229,2.986-0.289,2.898-2.382
        c-0.263-6.267-0.527-12.533-0.793-18.801c-0.093-2.195-0.078-4.401-0.307-6.583c-0.644-6.152-1.395-12.293-2.077-18.441
        c-0.033-0.294,0.095-0.685,0.287-0.913c0.952-1.134,0.967-1.915-0.175-2.993c-3.141-2.963-6.296-5.911-9.484-8.822
        c-1.327-1.211-2.743-2.326-4.138-3.46c-3.843-3.122-7.734-6.185-11.523-9.369c-1.535-1.29-2.784-2.915-4.292-4.241
        c-2.008-1.765-4.173-3.349-6.207-5.085c-1.362-1.162-2.468-1.28-3.82-0.118c-0.817,0.702-1.521,1.54-2.366,2.202
        c-3.24,2.534-6.648,4.869-9.751,7.559c-6.249,5.418-12.329,11.031-18.5,16.539c-2.132,1.903-4.367,3.692-6.468,5.629
        c-0.454,0.418-0.765,1.258-0.738,1.886C16.805,53.263,17.088,57.078,17.331,60.895z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M17.331,60.895c-0.243-3.817-0.526-7.632-0.691-11.453
        c-0.027-0.628,0.284-1.468,0.738-1.886c2.101-1.937,4.335-3.726,6.468-5.629c6.171-5.508,12.251-11.121,18.5-16.539
        c3.103-2.69,6.511-5.025,9.751-7.559c0.845-0.662,1.549-1.5,2.366-2.202c1.353-1.163,2.458-1.044,3.82,0.118
        c2.034,1.736,4.199,3.32,6.207,5.085c1.508,1.326,2.757,2.951,4.292,4.241c3.789,3.184,7.681,6.247,11.523,9.369
        c1.395,1.134,2.811,2.25,4.138,3.46c3.188,2.911,6.344,5.859,9.484,8.822c1.142,1.078,1.127,1.858,0.175,2.993
        c-0.192,0.229-0.32,0.619-0.287,0.913c0.683,6.148,1.434,12.289,2.077,18.441c0.229,2.182,0.214,4.388,0.307,6.583
        c0.266,6.268,0.53,12.534,0.793,18.801c0.088,2.093-0.804,2.61-2.898,2.382c-2-0.218-4.059,0.023-6.085,0.155
        c-2.906,0.188-5.802,0.56-8.71,0.679c-5.785,0.235-11.576,0.348-17.364,0.52c-7.43,0.221-14.875,0.233-22.285,0.742
        c-5.862,0.403-11.654-0.214-17.477-0.374c-1.312-0.036-1.996-0.752-2.148-1.974c-0.458-3.683-0.998-7.356-1.396-11.045
        c-0.364-3.374-0.643-6.76-0.856-10.146c-0.304-4.822-0.515-9.65-0.765-14.476C17.116,60.909,17.223,60.902,17.331,60.895z
        M21.815,94.901c3.362,0.219,6.504,0.563,9.649,0.597c5.753,0.062,11.509-0.104,17.263-0.095
        c5.039,0.007,10.079,0.096,15.117,0.209c1.538,0.034,3.072,0.39,4.607,0.384c1.97-0.007,3.938-0.313,5.908-0.343
        c3.754-0.055,7.511,0.026,11.266-0.01c2.458-0.023,4.914-0.152,7.427-0.236c0-0.566,0.019-0.958-0.003-1.347
        c-0.192-3.39-0.477-6.775-0.568-10.167c-0.109-4.076,0.023-8.16-0.095-12.236c-0.198-6.786-1.038-13.554-0.434-20.364
        c0.093-1.05-0.356-2.092-1.319-2.807c-0.703-0.523-1.349-1.13-1.982-1.738c-4.53-4.345-8.961-8.798-13.6-13.024
        c-4.632-4.221-9.479-8.207-14.209-12.32c-0.979-0.85-1.859-1.81-2.794-2.711c-0.456-0.439-0.878-0.412-1.335,0.062
        c-0.693,0.716-1.371,1.474-2.171,2.053c-3.464,2.506-7.064,4.832-10.438,7.451c-4.796,3.725-9.458,7.625-14.136,11.5
        c-2.95,2.443-5.819,4.985-8.741,7.461c-0.728,0.617-1.083,1.31-0.996,2.293c0.245,2.783,0.559,5.568,0.61,8.357
        c0.087,4.708-0.086,9.421-0.003,14.13c0.099,5.635,0.331,11.268,0.548,16.899C21.461,90.889,21.666,92.875,21.815,94.901z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M21.815,94.901c-0.149-2.026-0.354-4.012-0.431-6.001
        C21.167,83.268,20.935,77.635,20.836,72c-0.083-4.709,0.09-9.422,0.003-14.13c-0.052-2.789-0.365-5.575-0.61-8.357
        c-0.086-0.983,0.269-1.676,0.996-2.293c2.922-2.477,5.791-5.018,8.741-7.461c4.679-3.875,9.34-7.775,14.136-11.5
        c3.374-2.62,6.974-4.945,10.438-7.451c0.801-0.579,1.479-1.337,2.171-2.053c0.458-0.474,0.879-0.501,1.335-0.062
        c0.935,0.901,1.815,1.861,2.794,2.711c4.73,4.113,9.577,8.099,14.209,12.32c4.639,4.226,9.069,8.679,13.6,13.024
        c0.634,0.608,1.279,1.215,1.982,1.738c0.963,0.715,1.412,1.757,1.319,2.807c-0.604,6.81,0.235,13.578,0.434,20.364
        c0.118,4.076-0.015,8.16,0.095,12.236c0.092,3.392,0.376,6.777,0.568,10.167c0.021,0.389,0.003,0.78,0.003,1.347
        c-2.513,0.084-4.969,0.213-7.427,0.236c-3.755,0.036-7.512-0.045-11.266,0.01c-1.971,0.029-3.938,0.336-5.908,0.343
        c-1.535,0.006-3.069-0.35-4.607-0.384c-5.038-0.113-10.078-0.202-15.117-0.209c-5.754-0.009-11.51,0.156-17.263,0.095
        C28.319,95.464,25.177,95.12,21.815,94.901z M58.039,49.606c-0.092-0.458-0.058-1.125-0.345-1.594
        c-1.907-3.12-4.685-5.293-8.251-5.858c-2.794-0.444-5.713-0.104-8.576-0.121c-0.306-0.002-0.611-0.081-0.918-0.102
        c-1.003-0.069-2.086-0.401-2.996-0.138c-2.43,0.701-4.495,2.166-6.015,4.159c-0.868,1.138-1.308,2.623-1.85,3.987
        c-2.292,5.768,1.309,9.668,5.886,11.487c1.429,0.567,3.011,0.771,4.538,1.064c0.734,0.141,1.505,0.163,2.256,0.135
        c4.348-0.163,8.14-1.762,11.559-4.415C56.12,56.043,57.529,53.185,58.039,49.606z M72.532,50.619
        c-0.033-0.131-0.066-0.261-0.1-0.392c-1.489,0.483-3.262,0.605-4.414,1.515c-3.636,2.868-6.837,6.143-7.414,11.133
        c-0.384,3.326,0.47,6.203,2.938,8.474c2.354,2.166,5.334,2.993,8.402,3.465c0.921,0.143,1.981,0.178,2.828-0.14
        c3.568-1.336,6.803-3.052,8.39-6.938c1.478-3.62,2.186-7.174,0.545-10.89c-0.851-1.928-1.897-3.785-3.904-4.683
        C77.516,51.139,75.095,50.499,72.532,50.619z M35.296,79.57c0.012,2.965,0.742,5.478,1.912,7.317
        c1.902,2.992,4.674,4.444,8.087,4.983c1.766,0.278,3.409,0.279,4.935-0.794c0.915-0.644,1.923-1.162,2.796-1.854
        c1.902-1.506,3.687-3.204,4.178-5.68c0.77-3.877,0.891-7.69-0.753-11.518c-1.225-2.852-2.915-4.911-5.892-5.79
        c-0.454-0.134-1.012,0.086-1.522,0.143c0.285,0.323,0.514,0.738,0.865,0.954c1.481,0.91,3.285,1.394,4.141,3.112
        c0.462,0.927,0.811,1.909,1.256,2.845c1.636,3.442,0.707,6.884,0.018,10.324c-0.045,0.224-0.202,0.434-0.333,0.632
        c-1.679,2.547-4.149,4.172-6.699,5.688c-0.357,0.213-0.85,0.314-1.269,0.288c-2.78-0.175-5.331-0.854-7.263-3.104
        c-2.705-3.15-2.722-6.829-2.038-10.557c0.961-5.236,3.914-8.965,9.503-9.625c0.314-0.037,0.589-0.399,0.882-0.61
        c-0.399-0.288-0.786-0.805-1.199-0.827c-3.357-0.183-6.255,0.884-8.314,3.621C36.275,72.196,35.328,75.752,35.296,79.57z
        M39.689,78.642c0.315,1.47,0.56,3.093,1.025,4.65c0.52,1.738,3.103,3.803,4.735,3.537c2.417-0.393,4.713-1.281,6.205-3.457
        c0.519-0.758,1.063-1.505,1.492-2.312c0.217-0.409,0.238-0.939,0.265-1.42c0.005-0.095-0.511-0.337-0.545-0.298
        c-0.294,0.334-0.616,0.684-0.775,1.09c-0.983,2.495-2.616,4.287-5.311,4.882c-1.441,0.318-2.167-0.125-2.683-1.499
        c-0.252-0.671-0.552-1.36-0.614-2.061c-0.167-1.891-0.25-3.791-0.3-5.689c-0.024-0.927-0.021-1.861-1.125-2.096
        c-1.081-0.229-1.49,0.605-1.712,1.449C40.082,76.417,39.927,77.443,39.689,78.642z M46.876,75.6
        c0.91-0.007,1.619-0.834,1.595-1.861c-0.026-1.122-0.884-2.053-1.9-2.062c-0.928-0.009-1.624,0.803-1.588,1.85
        C45.022,74.668,45.88,75.608,46.876,75.6z M52.518,74.672c-0.852-0.663-1.306-1.271-1.864-1.391
        c-0.802-0.171-1.215,0.484-1.195,1.265c0.021,0.8,0.422,1.503,1.269,1.402C51.235,75.888,51.679,75.297,52.518,74.672z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M58.039,49.606c-0.51,3.579-1.919,6.437-4.711,8.603
        c-3.419,2.653-7.211,4.252-11.559,4.415c-0.75,0.028-1.522,0.006-2.256-0.135c-1.527-0.293-3.109-0.497-4.538-1.064
        c-4.577-1.818-8.178-5.719-5.886-11.487c0.542-1.365,0.981-2.849,1.85-3.987c1.52-1.993,3.584-3.458,6.015-4.159
        c0.91-0.263,1.992,0.069,2.996,0.138c0.307,0.021,0.612,0.1,0.918,0.102c2.863,0.018,5.782-0.323,8.576,0.121
        c3.566,0.565,6.344,2.738,8.251,5.858C57.981,48.482,57.947,49.148,58.039,49.606z M40.146,44.41
        c-0.019-0.071-0.037-0.142-0.055-0.212c-0.787,0.214-1.626,0.32-2.35,0.668c-1.112,0.535-2.16,1.211-3.208,1.87
        c-2.221,1.396-3.485,3.52-4.156,5.958c-0.583,2.116,0.777,4.891,2.555,6.193c1.47,1.077,3.143,1.398,4.81,1.939
        c3.269,1.059,6.374,0.544,9.368-0.602c3.109-1.188,6.062-2.813,7.501-6.114c0.708-1.624,1.428-3.534,0.438-5.077
        c-1.615-2.518-4.037-4.309-7.128-4.578C45.348,44.231,42.739,44.41,40.146,44.41z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M72.532,50.619c2.562-0.12,4.983,0.52,7.271,1.543
        c2.007,0.898,3.054,2.755,3.904,4.683c1.641,3.716,0.933,7.27-0.545,10.89c-1.587,3.887-4.821,5.603-8.39,6.938
        c-0.847,0.317-1.907,0.282-2.828,0.14c-3.068-0.472-6.048-1.299-8.402-3.465c-2.469-2.271-3.322-5.147-2.938-8.474
        c0.577-4.99,3.778-8.265,7.414-11.133c1.152-0.909,2.925-1.032,4.414-1.515C72.466,50.357,72.499,50.488,72.532,50.619z
        M81.594,63.075c0.066-2.673-0.606-4.871-2.017-6.747c-0.284-0.377-0.603-0.837-1.007-0.995c-1.86-0.724-3.673-1.501-5.75-1.66
        c-4.163-0.317-7.314,1.446-9.492,4.684c-3.135,4.66-1.917,11.333,3.452,13.634c1.24,0.532,2.544,0.938,3.848,1.299
        c2.716,0.751,7.612-1.095,8.905-3.59C80.637,67.57,81.924,65.403,81.594,63.075z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M35.296,79.57c0.032-3.817,0.979-7.374,3.292-10.449
        c2.059-2.737,4.957-3.804,8.314-3.621c0.414,0.022,0.8,0.539,1.199,0.827c-0.293,0.211-0.568,0.573-0.882,0.61
        c-5.59,0.66-8.542,4.389-9.503,9.625c-0.684,3.728-0.667,7.406,2.038,10.557c1.932,2.25,4.483,2.929,7.263,3.104
        c0.418,0.026,0.911-0.075,1.269-0.288c2.55-1.517,5.02-3.142,6.699-5.688c0.131-0.198,0.288-0.408,0.333-0.632
        c0.689-3.44,1.619-6.882-0.018-10.324c-0.445-0.936-0.794-1.918-1.256-2.845c-0.856-1.719-2.66-2.202-4.141-3.112
        c-0.351-0.216-0.58-0.631-0.865-0.954c0.51-0.057,1.068-0.276,1.522-0.143c2.978,0.879,4.667,2.938,5.892,5.79
        c1.644,3.827,1.522,7.641,0.753,11.518c-0.491,2.476-2.276,4.174-4.178,5.68c-0.874,0.691-1.882,1.21-2.796,1.854
        c-1.525,1.073-3.168,1.072-4.935,0.794c-3.413-0.539-6.185-1.991-8.087-4.983C36.039,85.047,35.308,82.535,35.296,79.57z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M39.689,78.642c0.238-1.199,0.392-2.226,0.655-3.224
        c0.222-0.844,0.632-1.679,1.712-1.449c1.105,0.234,1.101,1.169,1.125,2.096c0.05,1.898,0.133,3.799,0.3,5.689
        c0.062,0.7,0.362,1.39,0.614,2.061c0.517,1.374,1.242,1.817,2.683,1.499c2.695-0.595,4.327-2.387,5.311-4.882
        c0.16-0.406,0.481-0.756,0.775-1.09c0.034-0.039,0.55,0.203,0.545,0.298c-0.026,0.48-0.047,1.011-0.265,1.42
        c-0.428,0.808-0.973,1.555-1.492,2.312c-1.492,2.176-3.788,3.064-6.205,3.457c-1.632,0.266-4.215-1.799-4.735-3.537
        C40.25,81.735,40.005,80.112,39.689,78.642z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M46.876,75.6c-0.997,0.008-1.854-0.932-1.894-2.073
        c-0.035-1.047,0.661-1.858,1.588-1.85c1.017,0.009,1.875,0.939,1.9,2.062C48.496,74.766,47.787,75.593,46.876,75.6z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M52.518,74.672c-0.839,0.625-1.283,1.216-1.79,1.276
        c-0.847,0.101-1.249-0.603-1.269-1.402c-0.021-0.78,0.393-1.436,1.195-1.265C51.212,73.402,51.666,74.009,52.518,74.672z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M40.146,44.41c2.593,0,5.202-0.179,7.775,0.046
        c3.091,0.269,5.513,2.06,7.128,4.578c0.989,1.542,0.27,3.453-0.438,5.077c-1.438,3.301-4.392,4.926-7.501,6.114
        c-2.995,1.146-6.1,1.66-9.368,0.602c-1.667-0.541-3.34-0.862-4.81-1.939c-1.778-1.303-3.138-4.078-2.555-6.193
        c0.671-2.438,1.936-4.562,4.156-5.958c1.048-0.659,2.096-1.335,3.208-1.87c0.724-0.348,1.563-0.454,2.35-0.668
        C40.11,44.268,40.128,44.339,40.146,44.41z M41.422,59.039c1.384-0.238,2.787-0.499,4.195-0.72c4.083-0.641,6.178-4.81,7.018-7.741
        c0.088-0.308-0.08-0.814-0.311-1.048c-0.189-0.191-0.791-0.269-0.957-0.118c-0.222,0.202-0.278,0.674-0.27,1.027
        c0.066,2.564-1.042,4.419-3.253,5.696c-2.268,1.309-4.67,1.281-7.097,0.747c-1.566-0.346-2.868-1.178-3.683-2.648
        c-0.208-0.375-0.479-0.769-0.824-1.006c-0.835-0.574-1.767-0.631-2.546,0.062c-0.748,0.665-0.455,1.469,0.016,2.174
        c0.842,1.262,1.91,2.287,3.422,2.669C38.515,58.48,39.923,58.726,41.422,59.039z M38.564,48.263
        c-0.555,0.011-1.28,0.287-1.388,1.282c-0.084,0.785,0.812,1.477,1.842,1.502c1.017,0.025,1.795-0.573,1.784-1.37
        C40.79,48.877,39.964,48.265,38.564,48.263z M44.19,50.049c0.643-0.712,1.041-1.155,1.44-1.597
        c-0.461-0.337-0.919-0.933-1.385-0.939c-0.474-0.006-0.956,0.568-1.434,0.888C43.193,48.858,43.576,49.315,44.19,50.049z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M81.594,63.075c0.33,2.328-0.957,4.495-2.061,6.626
        c-1.293,2.495-6.189,4.341-8.905,3.59c-1.304-0.36-2.607-0.767-3.848-1.299c-5.369-2.301-6.587-8.974-3.452-13.634
        c2.178-3.238,5.329-5.001,9.492-4.684c2.077,0.159,3.89,0.936,5.75,1.66c0.404,0.158,0.723,0.618,1.007,0.995
        C80.987,58.204,81.66,60.402,81.594,63.075z M64.863,60.847c-0.181,0.004-0.361,0.009-0.541,0.013
        c-0.121,0.783-0.423,1.587-0.332,2.344c0.406,3.367,2.422,5.415,6.078,6.351c3.561,0.912,8.175-1.124,9.659-4.38
        c0.22-0.482,0.062-1.52-0.3-1.789c-0.489-0.364-1.43-0.464-2.023-0.25c-0.605,0.217-1.079,0.908-1.516,1.467
        c-0.848,1.087-1.882,1.847-3.258,2.041c-3.546,0.498-5.535-0.999-7.153-4.44C65.267,61.753,65.068,61.298,64.863,60.847z
        M70.64,59.507c0.231-0.287,0.806-0.726,0.982-1.288c0.238-0.76-0.64-1.628-1.491-1.638c-0.837-0.01-1.359,0.401-1.435,1.261
        C68.621,58.715,69.322,59.464,70.64,59.507z M73.955,60.969c0.743-0.115,1.352-0.534,1.313-1.445
        c-0.037-0.884-0.609-1.381-1.498-1.363c-0.889,0.017-1.454,0.513-1.414,1.422C72.395,60.461,72.944,60.937,73.955,60.969z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M41.422,59.039c-1.499-0.312-2.907-0.559-4.29-0.907
        c-1.512-0.383-2.58-1.408-3.422-2.669c-0.471-0.705-0.763-1.509-0.016-2.174c0.779-0.694,1.711-0.636,2.546-0.062
        c0.344,0.237,0.616,0.631,0.824,1.006c0.815,1.47,2.117,2.303,3.683,2.648c2.427,0.534,4.829,0.562,7.097-0.747
        c2.21-1.276,3.319-3.131,3.253-5.696c-0.009-0.353,0.048-0.825,0.27-1.027c0.166-0.15,0.767-0.073,0.957,0.118
        c0.231,0.234,0.399,0.741,0.311,1.048c-0.839,2.931-2.934,7.101-7.018,7.741C44.209,58.54,42.807,58.8,41.422,59.039z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M38.564,48.263c1.4,0.002,2.226,0.614,2.238,1.415
        c0.012,0.797-0.767,1.395-1.784,1.37c-1.03-0.025-1.926-0.718-1.842-1.502C37.284,48.549,38.009,48.274,38.564,48.263z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M44.19,50.049c-0.614-0.733-0.997-1.19-1.379-1.647
        c0.478-0.32,0.959-0.895,1.434-0.888c0.466,0.006,0.924,0.602,1.385,0.939C45.231,48.894,44.833,49.336,44.19,50.049z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M64.863,60.847c0.205,0.451,0.403,0.906,0.614,1.355
        c1.618,3.441,3.607,4.938,7.153,4.44c1.376-0.194,2.41-0.954,3.258-2.041c0.437-0.559,0.91-1.25,1.516-1.467
        c0.594-0.214,1.534-0.114,2.023,0.25c0.362,0.27,0.52,1.307,0.3,1.789c-1.484,3.256-6.099,5.292-9.659,4.38
        c-3.656-0.936-5.672-2.983-6.078-6.351c-0.091-0.757,0.211-1.561,0.332-2.344C64.502,60.856,64.683,60.851,64.863,60.847z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M70.64,59.507c-1.317-0.043-2.019-0.792-1.943-1.665
        c0.075-0.859,0.598-1.271,1.435-1.261c0.852,0.01,1.729,0.878,1.491,1.638C71.445,58.782,70.871,59.22,70.64,59.507z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M73.955,60.969c-1.011-0.032-1.561-0.508-1.599-1.387
        c-0.04-0.909,0.525-1.405,1.414-1.422c0.889-0.018,1.461,0.479,1.498,1.363C75.307,60.435,74.698,60.854,73.955,60.969z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SchulgemeinschaftIcon'
}
</script>
