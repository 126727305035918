<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M59.026,78.249c-0.624-0.91-1.167-1.702-1.577-2.3c0.228-1.594,0.761-3.167,0.588-4.659
        c-0.22-1.906-2.185-2.027-3.658-2.476c0.532-3.364,0.135-3.981-3.064-4.854c-0.231-0.062-0.464-0.124-0.7-0.165
        c-0.707-0.122-1.285-0.249-1.625-1.102c-0.351-0.88-1.253-1.096-2.236-0.993c-4.017,0.42-7.032,2.658-9.799,5.341
        c-0.888,0.861-1.668,1.832-2.547,2.704c-0.877,0.87-1.05,1.692-0.466,2.791c0.637,1.198,1.723,1.716,2.77,1.866
        c-0.058,1.042-0.417,2.122-0.09,2.924c0.451,1.104,1.349,2.062,2.185,2.961c0.822,0.886,1.588,1.72,1.781,2.992
        c0.135,0.887-0.331,1.812-1.192,1.765c-1.385-0.075-2.693-0.251-3.824-1.353c-2.122-2.066-3.146-4.739-4.116-7.359
        c-0.932-2.52-1.306-5.25-1.86-7.9c-0.159-0.759-0.18-1.571-0.115-2.348c0.232-2.785,1.913-4.766,3.842-6.561
        c1.278-1.189,2.09-2.569,2.397-4.305c0.428-2.418-0.294-4.604-1.338-6.671c-1.419-2.811-3.021-5.529-4.539-8.29
        c-0.577-1.049-1.114-2.121-1.715-3.155c-1.725-2.967-3.508-5.901-5.199-8.888c-0.859-1.519-1.12-1.643-2.446-0.485
        c-0.291,0.254-0.504,0.703-0.568,1.092c-0.204,1.242,0.163,2.378,0.871,3.398c2.187,3.146,4.401,6.274,6.569,9.433
        c1.104,1.61,2.13,3.273,3.196,4.91c0.741,1.137,1.613,2.208,2.208,3.416c1.428,2.897,1.848,5.97-1.19,8.533
        c-3.28,2.767-4.663,6.345-3.962,10.801c0.718,4.565,1.937,8.919,4.312,12.857c0.94,1.56,2.367,2.877,3.753,4.1
        c1.177,1.038,4.329,1.139,5.285,0.124c1.234-1.312,2.475-0.625,3.776-0.557c0,0.402-0.011,0.72,0.001,1.035
        c0.072,1.851,0.623,2.475,2.436,2.835c1.557,0.31,2.815-0.34,3.995-1.22c1.035-0.771,2.041-1.58,3.079-2.388
        c0.179,0.25,0.266,0.383,0.364,0.507c1.595,2.025,3.51,3.518,6.226,3.554c1.943,0.024,3.226-1.347,3.138-3.276
        c-0.026-0.59-0.072-1.18-0.114-1.842c0.277,0.074,0.468,0.111,0.647,0.176c1.516,0.55,2.84,0.2,3.973-0.892
        c1.092-1.052,1.321-2.306,0.827-3.739c-0.267-0.772-0.427-1.581-0.643-2.405c3.204,0.046,3.898-0.693,3.725-3.895
        c-0.04-0.742-0.21-1.478-0.317-2.194c2.626-1.025,3.479-2.492,3.095-5.237c-0.133-0.95-0.331-1.893-0.425-2.846
        c-0.035-0.357,0.038-0.806,0.236-1.092c2.182-3.144,4.21-6.419,6.647-9.354c3.842-4.628,7.945-9.042,11.993-13.496
        c0.547-0.602,0.408-1.049,0.007-1.412c-0.297-0.269-0.893-0.423-1.274-0.322c-0.417,0.11-0.772,0.543-1.102,0.886
        c-2.647,2.762-5.359,5.468-7.902,8.324c-2.859,3.211-5.574,6.552-8.325,9.858c-0.727,0.872-1.354,1.827-2.132,2.889
        c-1.174-1.734-2.131-3.356-3.293-4.816c-1.814-2.281-3.776-4.445-5.655-6.676c-0.336-0.399-0.573-0.881-0.843-1.304
        c1.884-1.371,3.672-2.58,5.354-3.922c1.87-1.491,3.703-3.041,5.442-4.68c1.708-1.61,3.284-3.361,4.908-5.06
        c1.997-2.088,3.983-4.187,5.984-6.291c-0.995-0.812-1.737-0.742-2.451,0.06c-0.425,0.477-0.926,0.884-1.367,1.347
        c-1.13,1.182-2.14,2.504-3.389,3.54c-2.64,2.189-5.388,4.249-8.116,6.328c-2.191,1.67-4.526,3.168-6.599,4.971
        c-1.803,1.568-3.239,1.648-5.084,0.074c-1.96-1.674-4.318-2.89-6.233-4.606c-4.22-3.784-6.595-8.887-9.28-13.743
        c-1.031-1.863-1.69-2.277-3.966-1.997c0.028,0.116,0.034,0.24,0.085,0.341c2.435,4.776,4.961,9.501,8.351,13.688
        c2.224,2.747,4.75,5.167,7.803,7.029c2.036,1.241,4.114,2.495,5.894,4.061c4.9,4.313,9.598,8.866,12.256,14.996
        c0.644,1.484,0.734,3.231,0.914,4.876c0.045,0.407-0.471,1.058-0.892,1.265c-0.253,0.125-0.925-0.299-1.227-0.638
        c-0.662-0.742-1.188-1.604-1.806-2.389c-1.356-1.728-2.701-3.468-4.119-5.146c-0.735-0.871-1.886-1.01-2.575-0.416
        c-0.83,0.713-0.773,1.609-0.145,2.353c2.09,2.467,4.168,4.951,6.405,7.282c1.407,1.467,2.044,3.879,1.041,6.007
        c-0.491-0.293-1.117-0.469-1.426-0.883c-1.478-1.984-2.854-4.044-4.299-6.054c-0.698-0.973-1.432-1.925-2.212-2.834
        c-0.76-0.885-1.945-1.059-2.678-0.435c-0.911,0.776-0.606,1.645-0.071,2.467c0.281,0.433,0.642,0.815,0.986,1.202
        c1.303,1.464,2.701,2.852,3.898,4.396c1.47,1.895,2.883,3.89,2.887,6.437c0.001,0.542-0.338,1.264-0.761,1.573
        c-0.371,0.271-1.255,0.316-1.608,0.053c-1.396-1.039-2.762-2.153-3.956-3.413C60.374,78.622,59.868,77.945,59.026,78.249z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M59.026,78.249c0.842-0.304,1.348,0.373,2.021,1.083
        c1.194,1.26,2.561,2.374,3.956,3.413c0.354,0.264,1.237,0.219,1.608-0.053c0.423-0.31,0.762-1.031,0.761-1.573
        c-0.004-2.547-1.417-4.542-2.887-6.437c-1.197-1.545-2.596-2.933-3.898-4.396c-0.345-0.387-0.705-0.77-0.986-1.202
        c-0.535-0.822-0.84-1.69,0.071-2.467c0.732-0.624,1.918-0.45,2.678,0.435c0.78,0.909,1.514,1.861,2.212,2.834
        c1.444,2.01,2.821,4.069,4.299,6.054c0.309,0.414,0.935,0.59,1.426,0.883c1.003-2.128,0.366-4.54-1.041-6.007
        c-2.237-2.331-4.315-4.815-6.405-7.282c-0.629-0.743-0.686-1.64,0.145-2.353c0.689-0.594,1.84-0.455,2.575,0.416
        c1.418,1.678,2.763,3.418,4.119,5.146c0.617,0.785,1.144,1.646,1.806,2.389c0.302,0.339,0.974,0.763,1.227,0.638
        c0.421-0.207,0.937-0.857,0.892-1.265c-0.18-1.645-0.271-3.392-0.914-4.876c-2.658-6.13-7.355-10.683-12.256-14.996
        c-1.779-1.566-3.858-2.819-5.894-4.061c-3.053-1.861-5.579-4.282-7.803-7.029c-3.39-4.187-5.916-8.912-8.351-13.688
        c-0.051-0.101-0.057-0.225-0.085-0.341c2.276-0.281,2.936,0.134,3.966,1.997c2.685,4.855,5.06,9.959,9.28,13.743
        c1.915,1.717,4.272,2.933,6.233,4.606c1.845,1.574,3.281,1.494,5.084-0.074c2.072-1.802,4.407-3.301,6.599-4.971
        c2.729-2.08,5.477-4.139,8.116-6.328c1.249-1.036,2.259-2.358,3.389-3.54c0.441-0.462,0.942-0.87,1.367-1.347
        c0.714-0.802,1.456-0.871,2.451-0.06c-2.001,2.104-3.987,4.202-5.984,6.291c-1.624,1.699-3.2,3.449-4.908,5.06
        c-1.739,1.64-3.572,3.189-5.442,4.68c-1.683,1.342-3.471,2.551-5.354,3.922c0.27,0.422,0.507,0.904,0.843,1.304
        c1.879,2.231,3.841,4.395,5.655,6.676c1.162,1.46,2.119,3.082,3.293,4.816c0.778-1.062,1.405-2.017,2.132-2.889
        c2.751-3.307,5.466-6.647,8.325-9.858c2.543-2.856,5.255-5.562,7.902-8.324c0.329-0.343,0.685-0.776,1.102-0.886
        c0.382-0.101,0.978,0.054,1.274,0.322c0.401,0.363,0.54,0.81-0.007,1.412c-4.048,4.454-8.151,8.868-11.993,13.496
        c-2.438,2.935-4.466,6.21-6.647,9.354c-0.198,0.286-0.271,0.734-0.236,1.092c0.094,0.953,0.292,1.896,0.425,2.846
        c0.385,2.745-0.469,4.212-3.095,5.237c0.107,0.717,0.277,1.452,0.317,2.194c0.174,3.201-0.521,3.94-3.725,3.895
        c0.216,0.824,0.376,1.633,0.643,2.405c0.494,1.434,0.265,2.688-0.827,3.739c-1.133,1.092-2.457,1.441-3.973,0.892
        c-0.18-0.064-0.37-0.102-0.647-0.176c0.042,0.662,0.088,1.252,0.114,1.842c0.088,1.93-1.194,3.301-3.138,3.276
        c-2.716-0.036-4.631-1.528-6.226-3.554c-0.098-0.124-0.185-0.257-0.364-0.507c-1.038,0.808-2.044,1.617-3.079,2.388
        c-1.18,0.88-2.438,1.529-3.995,1.22c-1.813-0.36-2.364-0.984-2.436-2.835c-0.012-0.315-0.001-0.633-0.001-1.035
        c-1.301-0.068-2.542-0.755-3.776,0.557c-0.956,1.015-4.107,0.914-5.285-0.124c-1.386-1.223-2.813-2.54-3.753-4.1
        c-2.375-3.938-3.594-8.292-4.312-12.857c-0.701-4.456,0.682-8.034,3.962-10.801c3.039-2.563,2.618-5.636,1.19-8.533
        c-0.595-1.208-1.467-2.279-2.208-3.416c-1.066-1.636-2.092-3.3-3.196-4.91c-2.168-3.159-4.383-6.286-6.569-9.433
        c-0.708-1.02-1.074-2.156-0.871-3.398c0.064-0.39,0.277-0.838,0.568-1.092c1.326-1.157,1.586-1.033,2.446,0.485
        c1.691,2.986,3.474,5.92,5.199,8.888c0.601,1.034,1.138,2.106,1.715,3.155c1.519,2.76,3.12,5.479,4.539,8.29
        c1.044,2.068,1.766,4.253,1.338,6.671c-0.307,1.736-1.119,3.116-2.397,4.305c-1.929,1.795-3.61,3.775-3.842,6.561
        c-0.065,0.776-0.044,1.589,0.115,2.348c0.554,2.65,0.928,5.381,1.86,7.9c0.969,2.62,1.994,5.293,4.116,7.359
        c1.131,1.102,2.438,1.277,3.824,1.353c0.862,0.048,1.327-0.878,1.192-1.765c-0.193-1.272-0.959-2.106-1.781-2.992
        c-0.836-0.899-1.734-1.857-2.185-2.961c-0.327-0.802,0.032-1.882,0.09-2.924c-1.047-0.15-2.133-0.668-2.77-1.866
        c-0.584-1.099-0.412-1.921,0.466-2.791c0.878-0.872,1.659-1.843,2.547-2.704c2.768-2.683,5.782-4.921,9.799-5.341
        c0.982-0.103,1.885,0.113,2.236,0.993c0.34,0.853,0.918,0.979,1.625,1.102c0.236,0.041,0.469,0.103,0.7,0.165
        c3.199,0.872,3.597,1.489,3.064,4.854c1.473,0.448,3.438,0.569,3.658,2.476c0.173,1.492-0.36,3.065-0.588,4.659
        C57.859,76.546,58.402,77.338,59.026,78.249z M37.802,76.912c0.003,1.279,0.664,1.964,2.061,2.166
        c1.372,0.199,2.463-0.425,3.568-1.11c3.688-2.289,6.228-5.719,8.938-8.972c0.398-0.479,0.505-1.24,0.621-1.894
        c0.053-0.299-0.115-0.731-0.332-0.965c-0.657-0.71-3.059-0.991-3.792-0.344c-1.491,1.315-2.913,2.714-4.297,4.142
        c-1.386,1.43-2.552,3.193-4.578,3.729C38.189,74.139,37.905,75.415,37.802,76.912z M56.67,72.665
        c-0.099-1.212-0.739-1.779-1.623-1.621c-1.274,0.229-2.569,0.403-3.545,1.615c-2.381,2.959-5.092,5.599-8.466,7.455
        c-0.727,0.4-1.31,2.162-0.954,2.892c0.473,0.971,1.58,1.393,2.669,0.917c0.834-0.363,1.643-0.809,2.414-1.296
        c2.859-1.804,5.293-4.103,7.44-6.689C55.421,74.953,55.989,73.761,56.67,72.665z M36.331,71.282
        c-0.348,1.272,0.396,1.491,1.299,1.469c1.606-0.04,3.13-0.468,4.228-1.676c2.027-2.228,3.96-4.541,5.906-6.842
        c0.169-0.199,0.278-0.641,0.17-0.828c-0.111-0.192-0.557-0.344-0.798-0.282c-1.305,0.329-2.748,0.478-3.852,1.161
        c-2.018,1.249-3.861,2.791-5.714,4.289C36.727,69.254,35.744,69.993,36.331,71.282z M57.161,79.197
        c-0.413-0.708-0.708-1.216-1.004-1.724c-0.441,0.263-0.99,0.434-1.308,0.804c-2.442,2.85-5.31,5.146-8.625,6.901
        c-0.273,0.145-0.578,0.493-0.615,0.779c-0.19,1.461,1.25,2.699,2.673,2.269c0.629-0.191,1.274-0.532,1.756-0.974
        c1.875-1.719,3.747-3.449,5.494-5.294C56.227,81.225,56.596,80.183,57.161,79.197z M55.384,84.994
        c0.412,0.479,0.79,1.146,1.358,1.529c1.134,0.766,2.31,1.556,3.585,1.997c1.237,0.428,2.223-0.375,2.132-1.651
        c-0.08-1.116-0.321-2.322-0.859-3.281c-0.743-1.322-1.833-2.45-2.586-3.419C57.817,81.759,56.575,83.411,55.384,84.994z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M37.802,76.912c0.103-1.497,0.388-2.772,2.189-3.248
        c2.026-0.535,3.192-2.299,4.578-3.729c1.384-1.428,2.807-2.826,4.297-4.142c0.733-0.647,3.135-0.366,3.792,0.344
        c0.217,0.233,0.385,0.666,0.332,0.965c-0.116,0.653-0.223,1.415-0.621,1.894c-2.71,3.253-5.25,6.683-8.938,8.972
        c-1.105,0.686-2.197,1.31-3.568,1.11C38.465,78.875,37.805,78.191,37.802,76.912z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M56.67,72.665c-0.681,1.097-1.249,2.288-2.066,3.272
        c-2.146,2.587-4.581,4.886-7.44,6.689c-0.771,0.487-1.579,0.933-2.414,1.296c-1.089,0.476-2.196,0.054-2.669-0.917
        c-0.355-0.729,0.227-2.491,0.954-2.892c3.375-1.856,6.085-4.496,8.466-7.455c0.976-1.212,2.271-1.387,3.545-1.615
        C55.931,70.885,56.571,71.453,56.67,72.665z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M36.331,71.282c-0.586-1.289,0.396-2.027,1.239-2.709
        c1.853-1.498,3.697-3.04,5.714-4.289c1.104-0.684,2.546-0.832,3.852-1.161c0.241-0.062,0.688,0.09,0.798,0.282
        c0.108,0.188-0.001,0.629-0.17,0.828c-1.946,2.301-3.879,4.614-5.906,6.842c-1.098,1.208-2.622,1.636-4.228,1.676
        C36.726,72.773,35.983,72.554,36.331,71.282z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M57.161,79.197c-0.565,0.986-0.934,2.028-1.628,2.762
        c-1.748,1.845-3.619,3.575-5.494,5.294c-0.482,0.441-1.126,0.782-1.756,0.974c-1.423,0.431-2.863-0.808-2.673-2.269
        c0.037-0.286,0.341-0.635,0.615-0.779c3.315-1.756,6.183-4.052,8.625-6.901c0.317-0.37,0.866-0.541,1.308-0.804
        C56.453,77.981,56.748,78.489,57.161,79.197z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M55.384,84.994c1.191-1.583,2.434-3.234,3.63-4.825
        c0.753,0.969,1.843,2.097,2.586,3.419c0.538,0.959,0.779,2.165,0.859,3.281c0.091,1.276-0.895,2.079-2.132,1.651
        c-1.275-0.441-2.451-1.231-3.585-1.997C56.173,86.139,55.796,85.472,55.384,84.994z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DazugehoerenIcon'
}
</script>
