<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentTile
      :themeColor="computedTopicColor"
      title="Besuche oder schreibe uns!"
      :textArray="[]"
    >
      <div slot="content">
        Hans-Lebrecht-Schule<br>
        Steinhövelstraße 7<br>
        89075 Ulm<br><br>
        <v-col
          v-if="phoneNumber"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-phone"
            :value="phoneNumber"
            :href="'tel:' + cleanPhoneNumber(phoneNumber)"
          />
        </v-col>
        <v-col
          v-if="faxNumber"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-fax"
            :value="faxNumber"
            :href="null"
          />
        </v-col>
        <v-col
          v-if="emailAddressHLS"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-email"
            :value="emailAddressHLS"
            :href="'mailto:' + emailAddressHLS"
          />
        </v-col>
        <v-col
          v-if="emailAddressULM"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-email"
            :value="emailAddressULM"
            :href="'mailto:' + emailAddressULM"
          />
        </v-col>
      </div>
    </ContentTile>
    <ContentTile
      :themeColor="computedTopicColor"
      title="Öffnungszeiten"
      :textArray="[
        'Das Sekretariat ist zu folgenden Zeiten geöffnet:'
      ]"
    >
      <v-data-table
        dense
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-header
        hide-default-footer
        slot="content"
        item-key="day"
        :headers="headers"
        :items="openingTimes"
      >
      </v-data-table>
        Mo, Di, Mi, Fr von 07:00 - 11:00 Uhr<br>
        Donnerstag von 09:30 - 13:30 Uhr
    </ContentTile>
    <ContentTile
      :themeColor="computedTopicColor"
      title="Standort"
      :textArray="[]"
    >
      <vl-map
        slot="content"
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326"
        style="height: 400px"
      >
        <vl-view
          :zoom.sync="zoom"
          :max-zoom.sync="maxZoom"
          :min-zoom.sync="minZoom"
          :center.sync="center"
          :rotation.sync="rotation"
        >
        </vl-view>
        <vl-layer-tile id="osm">
          <vl-source-osm></vl-source-osm>
        </vl-layer-tile>
        <vl-feature id="point" :properties="{prop: 'value', prop2: 'value'}">
          <vl-geom-point :coordinates="centerMarker"></vl-geom-point>
          <vl-style-box>
            <vl-style-circle :radius="radius">
              <vl-style-fill :color="fillColor"></vl-style-fill>
              <vl-style-stroke :color="strokeColor" :width="strokeWidth"></vl-style-stroke>
            </vl-style-circle>
          </vl-style-box>
        </vl-feature>
      </vl-map>
    </ContentTile>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'

import ContactChip from '@/views/components/ContactChip.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentFrame from '@/views/components/ContentFrame.vue'

export default {
  name: 'Contact',
  components: {
    ContactChip,
    ContentTile,
    ContentFrame
  },
  data () {
    return {
      zoom: 16,
      minZoom: 14,
      maxZoom: 18,
      rotation: 0,
      radius: 10,
      center: [
        10.003132,
        48.410526
      ],
      centerMarker: [
        10.003132,
        48.410526
      ],
      strokeWidth: 4,
      strokeColor: '#419AD4FF',
      fillColor: '#FFFFFF77',
      phoneNumber: '+49 731 500-69301',
      faxNumber: '+49 731 500-69302',
      emailAddressHLS: undefined, // 'info@hans-lebrecht-schule.de',
      emailAddressULM: 'kranke-son@ulm.de',
      headers: [
        {
          text: 'Tag',
          align: 'start',
          sortable: false,
          value: 'day'
        },
        {
          text: 'Zeiten',
          align: 'end',
          sortable: false,
          value: 'times'
        }
      ],
      openingTimes: [
        {
          day: 'Montag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Dienstag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Mittwoch',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Donnerstag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Freitag',
          times: '08:00 - 11:30 Uhr'
        },
        {
          day: 'Samstag',
          times: 'geschlossen'
        },
        {
          day: 'Sonntag',
          times: 'geschlossen'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    cleanPhoneNumber (phoneNumber) {
      let cleanPhoneNumber = phoneNumber.replace(' ', '')
      cleanPhoneNumber = phoneNumber.replace('/', '')
      cleanPhoneNumber = phoneNumber.replace('\\', '')
      cleanPhoneNumber = phoneNumber.replace('-', '')
      return cleanPhoneNumber
    }
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.contact.color
    },
    computedTitle () {
      return this.menuData.contact.title
    }
  }
}
</script>

<style scoped>
  .v-data-table {
    border-radius: 0px !important;
  }
</style>
