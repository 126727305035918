<template>
  <v-col
    cols="12"
  >
    <v-sheet
      light
      :color="embedded ? '#e0e0e0' : '#f0f0f0'"
      class="pa-4"
      height="100%"
    >
      <v-row>
        <v-col
          class="font-weight-bold"
          :class="{
            'text-h6 text-sm-h5 text-md-h4': !embedded,
            'text-h6 text-md-h5': embedded
          }"
          :style="{
            'color': embedded ? $vuetify.theme.currentTheme.neutral : themeColor
          }"
        >
          {{ title }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(photo, i) of photos"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <v-col
              color="#0000001A"
              class="pic-hover pointer"
              :class="{
                'elevation-12': hover,
                'elevation-3': !hover,
              }"
              rounded
            >
              <v-img
                cover
                :src="photo.src"
                @click="clickedPic = i"
                :aspect-ratio="1.7778"
              >
              </v-img>
            </v-col>
          </v-hover>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog
      fullscreen
      v-model="showClickedPic"
    >
      <v-sheet
        width="100%"
        height="100%"
        color="rgba(0,0,0,0.8)"
        class="d-flex"
        :class="{ 'pa-4': !$vuetify.breakpoint.mdAndUp, 'pa-12': $vuetify.breakpoint.mdAndUp }"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-btn
            color="red"
            fab
            @click="clickedPic = undefined"
            :elevation="hover ? 12 : 3"
            small
            class="gallery-dialog-close-button"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-hover>
        <v-img
          :src="clickedPic !== undefined ? photos[clickedPic].src : ''"
          max-width="100%"
          max-height="100%"
          contain
          position="center center"
        >
          <v-container
            class="fill-height pa-0"
            fluid
          >
            <div
              class="gallery-dialog-navigaion"
            >
              <v-col
                cols="6"
              >
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-btn
                    fab
                    height="36"
                    width="36"
                    @click="clickedPic--"
                    :disabled="clickedPic === undefined || clickedPic === 0"
                    color="#00000088"
                    :elevation="hover ? 12 : 3"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                </v-hover>
              </v-col>
              <v-col
                cols="6"
                class="text-right"
              >
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-btn
                    fab
                    height="36"
                    width="36"
                    @click="clickedPic++"
                    :disabled="clickedPic === undefined || clickedPic === photos.length - 1"
                    color="#00000088"
                    :elevation="hover ? 12 : 3"
                  >
                    <v-icon>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-hover>
              </v-col>
            </div>
            <v-row
              class="align-self-end"
            >
              <v-col
                class="text-center"
                color="red"
              >
                <span
                  style="display: inline-block; background-color: #00000088; padding: 6px 12px 6px 12px;"
                  :class="{
                    'text-body-2': $vuetify.breakpoint.xs
                  }"
                >
                  {{clickedPic !== undefined ? photos[clickedPic].description : ''}}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-sheet>
    </v-dialog>
  </v-col>
</template>

<script>

export default {
  name: 'ContentGalleryTile',
  components: {
  },
  props: {
    themeColor: String,
    title: String,
    photos: Array,
    embedded: { type: Boolean, default: false }
  },
  data () {
    return {
      clickedPic: undefined,
      showClickedPic: false
    }
  },
  mounted () { },
  methods: {
  },
  computed: {
  },
  watch: {
    clickedPic () {
      if (this.clickedPic !== undefined) {
        this.showClickedPic = true
      } else {
        this.showClickedPic = false
      }
    }
  }
}
</script>

<style scoped>
  .gallery-dialog-close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
  }
  .gallery-dialog-navigaion {
    position: absolute;
    display: flex;
    top: 50%; left: 0px;
    right: 0px;
    transform: translateY(-50%);
  }
</style>
