import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import de from 'vuetify/lib/locale/de'
import StartseiteIcon from '../views/icons/StartseiteIcon.vue'

import SchuleIcon from '../views/icons/SchuleIcon.vue'
import SchulgemeinschaftIcon from '../views/icons/SchulgemeinschaftIcon.vue'
import SchulhausIcon from '../views/icons/SchulhausIcon.vue'
import AbteilungenIcon from '../views/icons/AbteilungenIcon.vue'
import HistorischesIcon from '../views/icons/HistorischesIcon.vue'

import ElternIcon from '../views/icons/ElternIcon.vue'
import WerteIcon from '../views/icons/WerteIcon.vue'
import ProjekteIcon from '../views/icons/ProjekteIcon.vue'
import FragenIcon from '../views/icons/FragenIcon.vue'
import ServiceIcon from '../views/icons/ServiceIcon.vue'

import LehrkraefteIcon from '../views/icons/LehrkraefteIcon.vue'
import ZieleIcon from '../views/icons/ZieleIcon.vue'
import TFragenIcon from '../views/icons/TFragenIcon.vue'
import TServiceIcon from '../views/icons/TServiceIcon.vue'

import MithelfenIcon from '../views/icons/MithelfenIcon.vue'
import KlinikSchulVereinIcon from '../views/icons/KlinikSchulVereinIcon.vue'
import DazugehoerenIcon from '../views/icons/DazugehoerenIcon.vue'

import CoronaIcon from '../views/icons/CoronaIcon.vue'
import KontaktIcon from '../views/icons/KontaktIcon.vue'

import DatenschutzIcon from '../views/icons/DatenschutzIcon.vue'
import ImpressumIcon from '../views/icons/ImpressumIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0d5494',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        neutral: '#7F7A6E',
        paleGrey: '#CCCCCC',
        palette1: '#005caa',
        palette2: '#68acdf',
        palette3: '#5dc6f2',
        palette4: '#009cb4',
        palette5: '#67c0b5',
        palette6: '#00998a',
        palette7: '#8ec89a',
        palette8: '#009640',
        palette9: '#007b3d',
        palette10: '#76b729',
        palette11: '#c7d301',
        palette12: '#ffed00'
      },
      dark: {
        primary: '#0d5494',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        neutral: '#7F7A6E',
        paleGrey: '#CCCCCC',
        palette1: '#005caa',
        palette2: '#68acdf',
        palette3: '#5dc6f2',
        palette4: '#009cb4',
        palette5: '#67c0b5',
        palette6: '#00998a',
        palette7: '#8ec89a',
        palette8: '#009640',
        palette9: '#007b3d',
        palette10: '#76b729',
        palette11: '#c7d301',
        palette12: '#ffed00'
      }
    }
  },
  icons: {
    values: {
      startseite: {
        component: StartseiteIcon
      },
      schule: {
        component: SchuleIcon
      },
      schulgemeinschaft: {
        component: SchulgemeinschaftIcon
      },
      schulhaus: {
        component: SchulhausIcon
      },
      abteilungen: {
        component: AbteilungenIcon
      },
      historisches: {
        component: HistorischesIcon
      },
      eltern: {
        component: ElternIcon
      },
      werte: {
        component: WerteIcon
      },
      projekte: {
        component: ProjekteIcon
      },
      fragen: {
        component: FragenIcon
      },
      service: {
        component: ServiceIcon
      },
      lehrkraefte: {
        component: LehrkraefteIcon
      },
      ziele: {
        component: ZieleIcon
      },
      tfragen: {
        component: TFragenIcon
      },
      tservice: {
        component: TServiceIcon
      },
      mithelfen: {
        component: MithelfenIcon
      },
      klinikschulverein: {
        component: KlinikSchulVereinIcon
      },
      dazugehoeren: {
        component: DazugehoerenIcon
      },
      corona: {
        component: CoronaIcon
      },
      kontakt: {
        component: KontaktIcon
      },
      datenschutz: {
        component: DatenschutzIcon
      },
      impressum: {
        component: ImpressumIcon
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})
