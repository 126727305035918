<template>
  <v-col
    cols="12"
    md="6"
    class="col-parent"
  >
    <v-row
      class="faq-tile-parent fill-height ma-0 pa-0"
      @click="isOpen = !isOpen"
    >
      <v-col
        cols="12"
        class="faq-tile-front ma-0 pa-0"
        :class="{
          'faq-tile-open': isOpen,
          'faq-tile-closed': !isOpen && $vuetify.breakpoint.mdAndUp
        }"
      >
        <v-sheet
          light
          :color="color"
          class="py-4 pl-6 pr-2"
          height="100%"
          elevation="12"
        >
          <v-row>
            <v-col
              cols="12"
              class="font-weight-bold text-h6 text-sm-h5 text-md-h4 white--text"
            >
              {{ question }}
            </v-col>
            <v-col
              cols="12"
              align="center"
            >
              <v-img
                contain
                max-height="350px"
                class="pa-4"
                :src="graphic"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        class="faq-tile-back ma-0 pa-0"
      >
        <v-sheet
          light
          color="#f0f0f0"
          class="pa-4"
          height="100%"
        >
          <v-row>
            <v-col
              class="font-weight-bold text-h6 text-sm-h5 text-md-h4"
              :style="{
                'color': color
              }"
            >
              {{ question }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(answer, i) in answerArray"
              :key="i"
              cols="12"
            >
              {{ answer }}
            </v-col>
          </v-row>
          <v-row
            v-if="hasContentSlot()"
          >
            <slot name="content" />
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  name: 'ContentFAQTile',
  components: {
  },
  props: {
    color: String,
    question: String,
    answerArray: Array,
    graphic: String
  },
  data () {
    return {
      isOpen: false
    }
  },
  mounted () { },
  methods: {
    hasContentSlot () {
      return !!this.$slots.content
    }
  },
  computed: {
  }
}
</script>

<style scoped>
  .col-parent {
    overflow: hidden !important;
  }
  .faq-tile-parent {
    cursor: pointer;
    flex-wrap: nowrap !important;
    overflow: hidden !important;
  }
  .faq-tile-back {
    box-sizing: border-box !important;
  }
  .faq-tile-front {
    margin-left: -100% !important;
    box-sizing: border-box !important;
    transform: translateX(98%);
    transition: all 0.28s ease-in-out;
    z-index: 1;
  }
  .faq-tile-open {
    transform: translateX(0%);
  }
  .faq-tile-parent:hover .faq-tile-closed {
    transform: translateX(90%);
  }
</style>
