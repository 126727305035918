<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="5 5 103.28 103.28" enable-background="new 5 5 103.28 103.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M76.675,47.971c0.014-2.659-0.706-6.8-3.417-10.407c-3.388-4.505-7.926-7.189-13.307-8.62
        c-4.747-1.261-9.415-1.097-13.996,0.675c-0.764,0.295-1.474,0.792-2.122,1.305c-0.714,0.567-1.28,1.322-1.995,1.889
        c-3.447,2.734-5.361,6.391-6.41,10.563c-0.693,2.757-0.428,5.533-0.146,8.326c0.284,2.817,1.419,5.166,3.167,7.349
        c1.464,1.828,2.804,3.773,4.025,5.772c0.72,1.178,1.043,2.59,1.647,3.848c0.974,2.026,1.44,4.145,1.339,6.379
        c-0.083,1.822-0.065,2.089,1.69,2.395c4.136,0.721,8.324,1.216,12.511,0.659c2.156-0.286,4.284-0.943,6.357-1.636
        c1.42-0.475,1.832-1.9,0.838-2.718c-1.185-0.975-0.908-2.003-0.347-2.959c1.213-2.062,2.401-4.182,3.919-6.014
        C74.089,60.364,76.489,55.504,76.675,47.971z M57.004,92.048c2.17-0.223,5.062-0.489,7.945-0.833
        c0.818-0.098,1.636-0.354,2.408-0.656c0.959-0.375,1.492-1.091,1.152-2.177c-0.309-0.989-1.316-1.348-2.44-1.187
        c-3.339,0.479-6.684,1.082-10.042,1.21c-2.09,0.079-4.213-0.591-6.313-0.973c-1.016-0.186-2.002-0.586-3.022-0.682
        c-0.5-0.047-1.265,0.242-1.506,0.631c-0.245,0.394-0.143,1.171,0.089,1.643c0.222,0.45,0.751,0.887,1.239,1.046
        C49.688,91.109,52.877,92.125,57.004,92.048z M58.303,85.267c1.747-0.259,3.575-0.433,5.355-0.825
        c1.389-0.306,2.743-0.821,4.063-1.363c0.772-0.317,1.231-1.12,0.811-1.866c-0.318-0.562-1.054-0.948-1.683-1.25
        c-0.282-0.136-0.757,0.117-1.142,0.208c-1.736,0.413-3.452,1.02-5.212,1.196c-2.037,0.205-4.12,0.141-6.168-0.007
        c-1.686-0.122-3.352-0.527-5.023-0.827c-0.389-0.069-0.803-0.378-1.138-0.297c-0.636,0.154-1.441,0.353-1.777,0.819
        c-0.487,0.676-0.109,1.467,0.571,1.973c0.314,0.234,0.693,0.387,1.052,0.556C51.262,85.107,54.825,84.589,58.303,85.267z
        M59.781,98.223c2.558-0.675,5.301-1.351,8.012-2.137c1.24-0.36,1.683-1.198,1.377-2.131c-0.339-1.029-1.335-1.501-2.526-1.178
        c-2.307,0.625-4.61,1.262-6.918,1.885c-0.265,0.071-0.549,0.129-0.819,0.114c-3.294-0.174-6.587-0.404-9.884-0.504
        c-0.5-0.015-1.35,0.479-1.445,0.88c-0.123,0.513,0.248,1.25,0.613,1.734c0.243,0.321,0.838,0.481,1.293,0.521
        C52.847,97.702,56.215,97.947,59.781,98.223z M75.316,15.373c-0.06-1.857-1.737-2.6-3.074-1.584
        c-2.506,1.904-4.927,3.923-7.326,5.961c-0.522,0.444-1.002,1.126-1.144,1.777c-0.129,0.596,0.031,1.46,0.412,1.913
        c0.539,0.64,1.322,0.313,1.915-0.199c0.542-0.469,1.032-1.003,1.597-1.444c2.138-1.668,4.318-3.282,6.433-4.979
        C74.68,16.375,75.049,15.707,75.316,15.373z M27.243,79.681c0.13-0.053,0.442-0.133,0.706-0.294
        c1.738-1.062,3.466-2.138,5.196-3.213c1.354-0.842,2.732-1.649,4.049-2.547c0.781-0.531,1.076-1.334,0.484-2.184
        c-0.582-0.834-1.433-0.868-2.232-0.369c-2.774,1.733-5.542,3.479-8.261,5.298c-0.555,0.371-1.037,1.017-1.286,1.642
        C25.554,78.88,26.189,79.664,27.243,79.681z M88.812,72.163c-0.465-0.721-0.759-1.382-1.231-1.869
        c-1.19-1.229-2.456-2.386-3.7-3.562c-0.839-0.792-1.665-1.602-2.549-2.341c-0.653-0.547-1.463-1.08-2.23-0.303
        c-0.788,0.799-0.146,1.535,0.38,2.204c0.419,0.531,0.816,1.085,1.275,1.58c1.652,1.78,3.281,3.587,5.027,5.271
        c0.389,0.376,1.267,0.499,1.828,0.362C88.052,73.399,88.35,72.711,88.812,72.163z M34.284,16.455
        c-0.061,0.013-0.122,0.026-0.184,0.039c0.549,2.813,1.097,5.626,1.647,8.438c0.267,1.367,1.021,2.051,2.022,1.842
        c0.982-0.205,1.42-1.18,1.131-2.53c-0.042-0.195-0.106-0.385-0.16-0.576c-0.543-1.92-1.064-3.847-1.639-5.758
        c-0.274-0.914-0.573-1.839-1.02-2.673c-0.166-0.31-0.804-0.537-1.191-0.493c-0.237,0.027-0.445,0.626-0.593,0.998
        C34.217,15.948,34.284,16.215,34.284,16.455z M18.562,45.774c-0.279,0.166-0.715,0.423-1.151,0.681
        c0.28,0.433,0.512,0.91,0.856,1.284c0.229,0.249,0.601,0.409,0.938,0.51c2.477,0.744,4.953,1.492,7.447,2.176
        c0.847,0.232,1.614-0.003,1.913-0.958c0.263-0.84-0.255-1.731-1.294-1.957C24.473,46.901,21.657,46.384,18.562,45.774z
        M88.064,41.266c0-0.035,0.001-0.07,0.001-0.105c-0.956,0-1.914-0.043-2.866,0.016c-0.587,0.037-1.186,0.173-1.735,0.38
        c-0.229,0.086-0.492,0.489-0.469,0.721c0.022,0.215,0.39,0.542,0.63,0.566c3.644,0.359,7.29,0.705,10.94,0.982
        c0.78,0.059,1.427-0.389,1.392-1.293c-0.033-0.872-0.639-1.254-1.452-1.262C92.358,41.253,90.212,41.266,88.064,41.266z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M76.675,47.971c-0.186,7.533-2.586,12.393-6.244,16.808
        c-1.518,1.832-2.706,3.951-3.919,6.014c-0.562,0.956-0.838,1.984,0.347,2.959c0.994,0.817,0.582,2.243-0.838,2.718
        c-2.073,0.692-4.201,1.35-6.357,1.636c-4.187,0.557-8.375,0.062-12.511-0.659c-1.756-0.306-1.773-0.572-1.69-2.395
        c0.101-2.234-0.365-4.353-1.339-6.379c-0.604-1.258-0.928-2.67-1.647-3.848c-1.221-1.999-2.561-3.944-4.025-5.772
        c-1.749-2.183-2.884-4.532-3.167-7.349c-0.282-2.793-0.547-5.569,0.146-8.326c1.049-4.172,2.963-7.83,6.41-10.563
        c0.715-0.567,1.281-1.321,1.995-1.889c0.648-0.513,1.358-1.01,2.122-1.305c4.582-1.772,9.25-1.937,13.996-0.675
        c5.381,1.43,9.919,4.114,13.307,8.62C75.969,41.171,76.688,45.312,76.675,47.971z M47.365,74.77c2.371,0,4.7-0.196,6.982,0.048
        c3.156,0.336,6.114-0.308,9.089-1.144c0.823-0.23,0.938-0.647,0.951-1.463c0.021-1.363,0.046-2.846,0.576-4.056
        c0.952-2.176,2.161-4.266,3.474-6.252c2.808-4.249,4.542-8.792,4.32-13.951c-0.151-3.528-1.176-6.664-3.61-9.405
        c-2.387-2.686-5.322-4.407-8.628-5.37c-2.452-0.714-5.106-1.109-7.652-1.034c-3.415,0.1-6.484,1.483-8.878,4.081
        c-1.102,1.196-2.431,2.239-3.301,3.583c-2.267,3.501-3.354,7.329-3.058,11.599c0.186,2.677,0.949,4.939,2.612,7.028
        c1.384,1.74,2.611,3.62,3.761,5.526c0.609,1.01,0.787,2.274,1.366,3.309C46.632,69.526,47.117,71.958,47.365,74.77z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M57.004,92.048c-4.126,0.076-7.315-0.939-10.49-1.978
        c-0.487-0.159-1.017-0.596-1.239-1.046c-0.231-0.472-0.333-1.249-0.089-1.643c0.241-0.389,1.006-0.678,1.506-0.631
        c1.02,0.096,2.006,0.496,3.022,0.682c2.1,0.382,4.223,1.052,6.313,0.973c3.358-0.128,6.703-0.73,10.042-1.21
        c1.124-0.161,2.132,0.197,2.44,1.187c0.34,1.086-0.193,1.802-1.152,2.177c-0.772,0.302-1.59,0.559-2.408,0.656
        C62.066,91.559,59.174,91.826,57.004,92.048z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M58.303,85.267c-3.478-0.678-7.041-0.16-10.291-1.684
        c-0.359-0.169-0.737-0.321-1.052-0.556c-0.68-0.506-1.058-1.297-0.571-1.973c0.336-0.467,1.142-0.665,1.777-0.819
        c0.335-0.081,0.749,0.228,1.138,0.297c1.671,0.3,3.338,0.705,5.023,0.827c2.048,0.147,4.131,0.212,6.168,0.007
        c1.76-0.177,3.476-0.783,5.212-1.196c0.385-0.091,0.859-0.344,1.142-0.208c0.629,0.302,1.364,0.688,1.683,1.25
        c0.421,0.746-0.038,1.549-0.811,1.866c-1.32,0.542-2.675,1.058-4.063,1.363C61.878,84.834,60.05,85.008,58.303,85.267z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M59.781,98.223c-3.566-0.276-6.934-0.521-10.298-0.815
        c-0.455-0.039-1.05-0.199-1.293-0.521c-0.365-0.484-0.735-1.222-0.613-1.734c0.095-0.4,0.945-0.895,1.445-0.88
        c3.297,0.1,6.589,0.33,9.884,0.504c0.271,0.015,0.555-0.043,0.819-0.114c2.308-0.623,4.611-1.26,6.918-1.885
        c1.191-0.323,2.188,0.148,2.526,1.178c0.306,0.933-0.137,1.771-1.377,2.131C65.082,96.873,62.339,97.548,59.781,98.223z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M75.316,15.373c-0.268,0.334-0.637,1.002-1.188,1.443
        c-2.114,1.697-4.295,3.312-6.433,4.979c-0.564,0.44-1.055,0.975-1.597,1.444c-0.593,0.513-1.376,0.839-1.915,0.199
        c-0.381-0.453-0.541-1.316-0.412-1.913c0.142-0.65,0.621-1.333,1.144-1.777c2.399-2.038,4.82-4.057,7.326-5.961
        C73.579,12.773,75.257,13.516,75.316,15.373z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M27.243,79.681c-1.054-0.018-1.688-0.801-1.344-1.667
        c0.249-0.625,0.73-1.271,1.286-1.642c2.719-1.818,5.487-3.564,8.261-5.298c0.799-0.499,1.65-0.465,2.232,0.369
        c0.592,0.85,0.297,1.652-0.484,2.184c-1.317,0.897-2.694,1.705-4.049,2.547c-1.729,1.075-3.458,2.151-5.196,3.213
        C27.685,79.548,27.373,79.628,27.243,79.681z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M88.812,72.163c-0.462,0.549-0.76,1.236-1.2,1.344
        c-0.562,0.137-1.439,0.014-1.828-0.362c-1.746-1.685-3.375-3.491-5.027-5.271c-0.459-0.495-0.856-1.049-1.275-1.58
        c-0.526-0.669-1.168-1.405-0.38-2.204c0.768-0.777,1.577-0.244,2.23,0.303c0.884,0.739,1.71,1.549,2.549,2.341
        c1.244,1.176,2.51,2.332,3.7,3.562C88.053,70.781,88.347,71.442,88.812,72.163z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M34.284,16.455c0-0.24-0.067-0.506,0.015-0.713
        c0.148-0.372,0.355-0.971,0.593-0.998c0.387-0.044,1.025,0.184,1.191,0.493c0.446,0.834,0.745,1.759,1.02,2.673
        c0.575,1.911,1.096,3.837,1.639,5.758c0.054,0.191,0.118,0.381,0.16,0.576c0.289,1.35-0.149,2.325-1.131,2.53
        c-1.001,0.208-1.755-0.476-2.022-1.842c-0.551-2.812-1.098-5.625-1.647-8.438C34.162,16.481,34.223,16.468,34.284,16.455z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M18.562,45.774c3.095,0.61,5.912,1.127,8.708,1.736
        c1.039,0.226,1.557,1.117,1.294,1.957c-0.298,0.956-1.066,1.191-1.913,0.958c-2.494-0.684-4.97-1.432-7.447-2.176
        c-0.337-0.102-0.709-0.261-0.938-0.51c-0.344-0.375-0.576-0.851-0.856-1.284C17.846,46.197,18.282,45.939,18.562,45.774z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M88.064,41.266c2.147,0,4.294-0.013,6.44,0.006
        c0.813,0.008,1.419,0.39,1.452,1.262c0.035,0.904-0.611,1.352-1.392,1.293c-3.65-0.277-7.297-0.623-10.94-0.982
        c-0.24-0.024-0.607-0.351-0.63-0.566c-0.023-0.232,0.239-0.635,0.469-0.721c0.55-0.207,1.148-0.343,1.735-0.38
        c0.952-0.059,1.91-0.016,2.866-0.016C88.065,41.196,88.064,41.231,88.064,41.266z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M47.365,74.77c-0.248-2.812-0.732-5.244-1.997-7.499
        c-0.58-1.034-0.757-2.299-1.366-3.309c-1.149-1.906-2.376-3.786-3.761-5.526c-1.663-2.089-2.426-4.351-2.612-7.028
        c-0.296-4.271,0.791-8.098,3.058-11.599c0.87-1.343,2.199-2.386,3.301-3.583c2.394-2.598,5.463-3.981,8.878-4.081
        c2.546-0.075,5.2,0.319,7.652,1.034c3.306,0.963,6.241,2.684,8.628,5.37c2.435,2.741,3.459,5.876,3.61,9.405
        c0.222,5.159-1.513,9.702-4.32,13.951c-1.312,1.986-2.521,4.076-3.474,6.252c-0.53,1.21-0.555,2.692-0.576,4.056
        c-0.014,0.815-0.128,1.232-0.951,1.463c-2.975,0.836-5.933,1.479-9.089,1.144C52.064,74.574,49.735,74.77,47.365,74.77z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProjekteIcon'
}
</script>
