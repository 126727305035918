<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-btn
      height="30"
      :x-large="$vuetify.breakpoint.lg"
      :large="$vuetify.breakpoint.md"
      :small="$vuetify.breakpoint.sm"
      :x-small="$vuetify.breakpoint.xs"
      class="font-weight-bold white--text"
      :color="themeColor"
      @click="openPage"
      :elevation="hover ? 12 : 3"
    >
      {{ text }}
      <v-icon
        small
        right
        color="white"
      >
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </v-hover>
</template>

<script>

export default {
  name: 'InternalLinkButton',
  props: {
    text: String,
    pageName: String,
    themeColor: String
  },
  data () {
    return { }
  },
  mounted () { },
  methods: {
    openPage () {
      this.$router.push({ name: this.pageName })
    }
  },
  computed: { }
}
</script>

<style scoped>
</style>
