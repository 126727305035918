<template>
  <v-container
    class="px-0"
  >
    <v-row
      class="my-6 no-gutters"
    >
      <v-col
        class="px-3 px-md-0"
      >
        <v-alert
          light
          border="left"
          color="#5C7868"
          class="pa-8"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  class="text-h4 white--text"
                >
                  Herzlich Willkommen!
                </v-col>
                <v-col
                  cols="12"
                  class="text-h6 white--text"
                >
                  Auf dieser Seite findest du und finden Sie Informationen zu unserer Schule und schulnahen Themen.
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-sheet
                light
                color="#f0f0f0"
                class="pa-2"
              >
                <v-row
                  align="center"
                >
                  <v-col
                    cols="12"
                  >
                    <div id="video-wrapper">
                      <Media
                        :kind="'video'"
                        :src="[videoSource]"
                        :poster="posterSource"
                        :type="videoType"
                        :autoplay="false"
                        :controls="true"
                        :controlsList="['nodownload']"
                        :loop="false"
                        :muted="false"
                        @play="videoPlaying()"
                        @pause="videoStopped()"
                        disablepictureinpicture
                        preload="metadata"
                        style="width: 100%; outline: none !important;"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="text-subtitle-2"
                    cols="12"
                    :style="{
                      'color': '#5C7868'
                    }"
                  >
                    "Ein Tag auf der Jugendstation II" ist ein Filmprojekt unserer Schüler*innen und beschreibt, wie ein solcher Tag üblicherweise aussehen könnte.
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <Navigation
      :items="items"
    />
  </v-container>
</template>

<script>
import Navigation from '@/views/components/Navigation.vue'
import Media from '@/views/components/VideoPlayer.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Navigation,
    Media
  },
  data () {
    return {
      items: [],
      videoSource: '/vid/Hans-Lebrecht-Schule_Film-Tag-auf-Jugendstation.mp4',
      posterSource: '/img/projekte/tag_auf_jugendstation/thumbnail.jpg',
      videoType: 'video/mp4',
      videoIsPlaying: false
    }
  },
  mounted () {
    this.items = this.menuData.main.elements.map((elementId) => { return this.menuData[elementId] })
      .sort(function (a, b) {
        if (a.position < b.position) { return -1 }
        if (a.position > b.position) { return 1 }
        return 0
      })
  },
  methods: {
    videoPlaying () {
      this.videoIsPlaying = true
    },
    videoStopped () {
      this.videoIsPlaying = false
    }
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedAvatarSize () {
      if (this.$vuetify.breakpoint.name === 'lg') {
        return 100
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return 80
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return 60
      } else {
        return 40
      }
    },
    computedVideoIsPlaying () {
      return this.videoIsPlaying
    }
  }
}
</script>

<style scoped>
.welcome-video-anchor {
  position: relative;
}
.welcome-video-title {
  overflow: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  padding: 8px;
  color: white;
  background-color: #00000063;
  z-index: 10;
}
.welcome-video-title__show {
  opacity: 1;
}
.welcome-video-title__hide {
  opacity: 0;
  height: 0px;
}
</style>
