var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{style:({ 'background-color': _vm.computedAppBarBg}),attrs:{"app":"","flat":"","height":_vm.computedAppBarHeight}},[_c('v-col',{staticClass:"restricted align-self-center",class:{
        'px-5': _vm.$vuetify.breakpoint.smAndUp,
        'px-2': !_vm.$vuetify.breakpoint.smAndUp
      },attrs:{"cols":"12"}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center"}},[_c('img',{staticClass:"mr-4",class:{
            'pointer': _vm.$route.name !== 'Home'
          },attrs:{"src":"/img/logo_rubik.svg","height":_vm.computedAppBarImageHeight},on:{"click":_vm.goToStartpage}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',{staticClass:"flex-grow-1",style:({
            'font-size': _vm.computedTitleSize,
            'color': _vm.$vuetify.theme.currentTheme.neutral,
            'opacity': _vm.scrolled
          })},[_vm._v(" HANS LEBRECHT SCHULE ")]):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"pa-0"},[_c('v-row',{staticClass:"flex-nowrap no-gutters",attrs:{"justify":"end"}},[_c('span',{staticClass:"pa-1 pa-sm-2 pa-md-0 pa-lg-0 pa-xl-0 flex-nowrap",staticStyle:{"display":"inline-flex"},style:({
                'background-color': _vm.$vuetify.breakpoint.mdAndUp ? 'transparent' : 'rgba(0,0,0,0.1)'
              })},[_c('v-btn',{staticClass:"mr-2 my-1 font-weight-bold flex-shrink-1 flex-grow-0",attrs:{"right":"","height":"30","x-large":_vm.$vuetify.breakpoint.lg,"large":_vm.$vuetify.breakpoint.md,"small":_vm.$vuetify.breakpoint.sm,"x-small":_vm.$vuetify.breakpoint.xs,"elevation":_vm.computedAppBarElevation,"color":_vm.computedAppButtonsWarningBg},on:{"click":_vm.showDialog}},[(_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs)?_c('v-icon',[_vm._v(" $vuetify.icons.corona ")]):_c('span',[_vm._v(" CORONA ")])],1),_c('v-btn',{staticClass:"ml-2 my-1 font-weight-bold flex-shrink-1 flex-grow-0",attrs:{"right":"","height":"30","x-large":_vm.$vuetify.breakpoint.lg,"large":_vm.$vuetify.breakpoint.md,"small":_vm.$vuetify.breakpoint.sm,"x-small":_vm.$vuetify.breakpoint.xs,"elevation":_vm.computedAppBarElevation,"color":_vm.computedAppButtonsNeutralBg,"flex-shrink":"0"},on:{"click":function($event){return _vm.openPage('Contact')}}},[(_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs)?_c('v-icon',[_vm._v(" $vuetify.icons.kontakt ")]):_c('span',[_vm._v(" KONTAKT ")])],1),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('BreadCrumbsMenu',{staticClass:"ml-4",attrs:{"scrolled":_vm.scrolled}}):_vm._e()],1)])],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{staticClass:"pa-3 mt-8",staticStyle:{"background-color":"rgba(0,0,0,0.1)"},attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pa-0"},[_c('BreadCrumbs',{attrs:{"scrolled":_vm.scrolled}})],1)],1):_vm._e()],1)],1),_c('v-main',{staticStyle:{"overflow":"hidden !important"},attrs:{"id":"scrollingContent"}},[_c('v-container',{staticClass:"fill-height mt-4 restricted",class:{
        'pt-12': _vm.$route.name !== 'Home',
        'px-6': _vm.$vuetify.breakpoint.smAndUp,
        'px-3': !_vm.$vuetify.breakpoint.smAndUp
      }},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-0"},[_c('transition',{attrs:{"name":_vm.transitionName,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)],1),_c('v-footer',{staticClass:"pb-0 mb-0",attrs:{"color":"transparent"}},[_c('v-row',{staticClass:"pb-0 mb-0"},[_c('v-col',{staticClass:"restricted px-2 px-sm-5 px-md-2 px-lg-2 px-xl-2 pb-0 mb-0",attrs:{"cols":"12"}},[_c('v-container',{staticStyle:{"background-color":"rgba(0,0,0,0.1)"}},[_c('v-row',[_c('v-col',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-row',[_c('v-col',{staticClass:"align-self-center py-1",class:{
                      'text-caption': _vm.$vuetify.breakpoint.xs
                    },attrs:{"cols":"12","md":"6","align":_vm.$vuetify.breakpoint.mdAndUp ? 'start' : 'center'}},[_vm._v(" © Hans-Lebrecht-Schule Ulm 2021 ")]),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6","align":_vm.$vuetify.breakpoint.mdAndUp ? 'end' : 'center'}},[_c('v-btn',{attrs:{"text":"","x-small":_vm.$vuetify.breakpoint.xs,"small":_vm.$vuetify.breakpoint.sm},on:{"click":function($event){return _vm.openPage('Imprint')}}},[_vm._v(" Impressum ")]),_c('v-btn',{attrs:{"text":"","x-small":_vm.$vuetify.breakpoint.xs,"small":_vm.$vuetify.breakpoint.sm},on:{"click":function($event){return _vm.openPage('Privacy')}}},[_vm._v(" Datenschutz ")])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('CoronaDialog',{attrs:{"isVisible":_vm.dialogIsVisible,"closeFunction":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }