<template>
  <v-row
    class="error-container pb-12"
    justify="center"
  >
    <v-card
      color="#FFFFCC"
      class="px-4 px-sm-5 px-md-6 px-lg-7 px-xl-8 pt-4 pb-4 pb-sm-5 pb-md-6 pb-lg-7 pb-xl-8"
      elevation="0"
    >
      <span
        class="error-text"
        :style="{
          'font-size': computedErrorTextSize,
          'line-height': computedErrorTextSize
        }"
      >
        OOPS!
      </span>
      <div
        class="error-note pa-1 pa-sm-1 pa-md-2 pa-lg-3 pa-xl-3"
        :style="{
          'font-size': computedErrorNoteSize
        }"
      >
        <center>
          <div>
            404 - Diese Seite haben wir nicht gefunden.
          </div>
          <div
            :style="{
              'font-size': computedErrorNoteSmallSize
            }"
          >
            (hoffentlich macht das nicht Schule...)
          </div>
        </center>
      </div>
    </v-card>
    <v-col
      cols="12"
      align="center"
      class="pt-8"
    >
      <InternalLinkButton
        :themeColor="$vuetify.theme.currentTheme.neutral"
        pageName="Home"
        text="Zur Startseite"
      />
    </v-col>
  </v-row>
</template>

<script>
import InternalLinkButton from '@/views/components/InternalLinkButton.vue'

export default {
  name: 'Error404',
  components: {
    InternalLinkButton
  },
  data () {
    return { }
  },
  mounted () {
  },
  computed: {
    computedErrorTextSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '14rem !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '14rem !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '12rem !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '8rem !important'
      } else {
        return '5.5rem !important'
      }
    },
    computedErrorNoteSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '24px !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '24px !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '20px !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '14px !important'
      } else {
        return '10px !important'
      }
    },
    computedErrorNoteSmallSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '12px !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '12px !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '10px !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '8px !important'
      } else {
        return '7px !important'
      }
    }
  }
}
</script>

<style>
.error-container {
  position: relative;
}
.error-text {
  color: #989284 !important;
}

.error-note {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFFFCC;
  color:  #989284 !important;
  white-space: nowrap;
}
</style>
