<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M35.004,55.619c0,0.522-0.013,0.952,0.002,1.381c0.088,2.552-0.065,5.142,0.333,7.645
        c0.58,3.65,1.756,7.188,4.236,10.05c2.677,3.088,5.867,5.558,9.768,6.954c5.355,1.918,10.455,1.803,15.257-1.715
        c3.146-2.303,5.756-5.093,8.005-8.21c3.243-4.494,5.093-9.498,4.758-15.141c-0.087-1.458-0.312-2.907-0.513-4.726
        c1.036,0.786,1.867,1.404,2.685,2.04c1.016,0.791,2.354,1.385,1.926,3.087c-0.025,0.1,0.061,0.228,0.093,0.343
        c0.791,2.842,2.786,4.57,5.331,5.81c0.482,0.236,0.918,0.582,1.347,0.915c1.378,1.073,2.649,2.322,4.146,3.188
        c0.903,0.522,1.328,0.945,1.223,1.977c-0.275,2.712,0.385,5.243,1.672,7.612c0.503,0.927,1.116,1.845,2.398,1.605
        c1.285-0.24,1.324-1.318,1.438-2.321c0.003-0.034,0.045-0.064-0.085,0.107c1.119,0.212,2.228,0.762,3.104,0.51
        c1.456-0.418,0.535-1.879,0.642-3.115c0.762,0.44,1.465,0.686,1.941,1.159c0.869,0.864,1.803,0.915,2.632-0.037
        c1.375-1.581,1.439-3.605,0.122-5.264c-1.991-2.506-4.742-3.77-7.803-3.918c-2.038-0.098-3.288-1.314-4.675-2.302
        c-1.936-1.378-3.648-3.073-5.416-4.678c-0.372-0.339-0.729-0.846-0.812-1.324c-0.542-3.105-1.973-5.732-4.562-7.474
        c-1.33-0.894-2.238-1.947-2.999-3.271c-0.81-1.411-2.005-2.675-2.5-4.178c-1.157-3.51-3.38-5.987-6.447-7.88
        c-3.002-1.853-6.418-2.447-9.761-3.13c-4.155-0.85-8.405-0.717-12.337,0.952c-2.658,1.128-5.23,2.656-7.51,4.431
        c-3.775,2.94-7.082,6.367-8.274,11.276c-0.055,0.227-0.35,0.399-0.541,0.589c-1.493,1.483-2.983,2.968-4.485,4.441
        c-0.277,0.271-0.593,0.509-0.913,0.731c-1.701,1.178-3.445,2.299-5.09,3.55c-0.403,0.307-0.484,1.03-0.73,1.555
        c-0.183,0.391-0.311,0.857-0.607,1.137c-0.924,0.868-1.938,1.64-2.874,2.495c-0.567,0.519-1.027,1.153-1.587,1.68
        c-0.551,0.518-1.157,0.979-1.762,1.436c-0.442,0.334-0.882,0.761-1.391,0.903c-2.494,0.697-5.007,1.328-7.521,1.951
        c-0.78,0.193-1.385,0.521-1.311,1.419c0.072,0.884,0.656,1.331,1.505,1.441c0.339,0.044,0.683,0.047,0.801,0.055
        c-0.325,0.694-0.994,1.452-0.883,2.069c0.123,0.679,0.963,1.228,1.318,1.635c0.224,0.845,0.19,1.772,0.637,2.21
        c1.231,1.202,2.49,0.175,3.69-0.351c0.489,1.602,1.27,2.349,2.288,1.918c0.987-0.417,1.818-1.201,2.765-1.856
        c0.057,0.079,0.178,0.23,0.279,0.394c0.467,0.756,0.919,1.666,1.999,1.351c1.081-0.316,1.511-1.198,1.473-2.331
        c-0.048-1.438-0.007-2.879-0.077-4.315c-0.051-1.046-0.218-2.086-0.337-3.154c2.893-1.593,5.668-3.673,7.702-6.544
        c0.752-1.063,1.007-2.458,2.598-2.707c0.266-0.042,0.508-0.377,0.717-0.615C32.999,57.959,33.952,56.841,35.004,55.619z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M35.004,55.619c-1.053,1.222-2.006,2.34-2.975,3.445
        c-0.209,0.238-0.451,0.573-0.717,0.615c-1.591,0.249-1.846,1.644-2.598,2.707c-2.034,2.871-4.809,4.951-7.702,6.544
        c0.119,1.068,0.286,2.108,0.337,3.154c0.07,1.437,0.029,2.878,0.077,4.315c0.038,1.133-0.392,2.015-1.473,2.331
        c-1.08,0.315-1.532-0.595-1.999-1.351c-0.101-0.163-0.222-0.314-0.279-0.394c-0.947,0.655-1.778,1.439-2.765,1.856
        c-1.018,0.431-1.799-0.316-2.288-1.918c-1.2,0.525-2.459,1.553-3.69,0.351c-0.447-0.438-0.414-1.365-0.637-2.21
        c-0.354-0.407-1.195-0.956-1.318-1.635c-0.111-0.617,0.558-1.375,0.883-2.069c-0.118-0.008-0.462-0.011-0.801-0.055
        c-0.85-0.11-1.433-0.558-1.505-1.441c-0.074-0.898,0.531-1.226,1.311-1.419c2.514-0.623,5.027-1.254,7.521-1.951
        c0.509-0.143,0.949-0.569,1.391-0.903c0.604-0.456,1.21-0.918,1.762-1.436c0.561-0.526,1.02-1.161,1.587-1.68
        c0.936-0.855,1.95-1.627,2.874-2.495c0.297-0.279,0.424-0.746,0.607-1.137c0.246-0.524,0.327-1.248,0.73-1.555
        c1.646-1.251,3.389-2.373,5.09-3.55c0.32-0.222,0.636-0.459,0.913-0.731c1.502-1.473,2.993-2.958,4.485-4.441
        c0.191-0.189,0.485-0.362,0.541-0.589c1.192-4.909,4.499-8.336,8.274-11.276c2.279-1.774,4.852-3.302,7.51-4.431
        c3.932-1.669,8.182-1.802,12.337-0.952c3.343,0.683,6.759,1.278,9.761,3.13c3.067,1.893,5.29,4.37,6.447,7.88
        c0.495,1.503,1.69,2.767,2.5,4.178c0.761,1.324,1.669,2.377,2.999,3.271c2.59,1.742,4.021,4.369,4.562,7.474
        c0.084,0.479,0.44,0.985,0.812,1.324c1.768,1.604,3.48,3.3,5.416,4.678c1.387,0.987,2.637,2.204,4.675,2.302
        c3.061,0.148,5.812,1.412,7.803,3.918c1.317,1.658,1.253,3.683-0.122,5.264c-0.829,0.952-1.763,0.901-2.632,0.037
        c-0.477-0.474-1.18-0.719-1.941-1.159c-0.106,1.236,0.814,2.697-0.642,3.115c-0.877,0.252-1.985-0.298-3.104-0.51
        c0.13-0.172,0.088-0.142,0.085-0.107c-0.113,1.003-0.152,2.081-1.438,2.321c-1.282,0.239-1.896-0.679-2.398-1.605
        c-1.287-2.369-1.947-4.9-1.672-7.612c0.105-1.031-0.319-1.454-1.223-1.977c-1.496-0.866-2.768-2.115-4.146-3.188
        c-0.429-0.333-0.864-0.679-1.347-0.915c-2.545-1.24-4.54-2.968-5.331-5.81c-0.032-0.115-0.118-0.243-0.093-0.343
        c0.429-1.702-0.91-2.296-1.926-3.087c-0.817-0.636-1.648-1.254-2.685-2.04c0.201,1.819,0.426,3.268,0.513,4.726
        c0.335,5.643-1.515,10.646-4.758,15.141c-2.249,3.117-4.859,5.907-8.005,8.21c-4.803,3.518-9.902,3.633-15.257,1.715
        c-3.901-1.396-7.091-3.866-9.768-6.954c-2.48-2.861-3.656-6.399-4.236-10.05c-0.398-2.503-0.245-5.093-0.333-7.645
        C34.992,56.571,35.004,56.141,35.004,55.619z M57.004,37.926c-0.857,1.096-1.534,2.084-2.332,2.963
        c-3.869,4.262-8.605,7.27-14.006,9.137c-1.56,0.539-2.274,1.392-2.613,2.83c-0.119,0.505-0.345,1.004-0.363,1.511
        c-0.1,2.829-0.425,5.685-0.15,8.481c0.371,3.768,1.526,7.42,4.109,10.311c2.417,2.707,5.367,4.797,8.867,5.953
        c4.328,1.43,8.418,1.302,12.27-1.581c2.63-1.969,4.85-4.296,6.814-6.866c4.007-5.241,5.719-11.088,4.298-17.678
        c-0.211-0.979-0.633-1.584-1.687-1.881c-5.189-1.461-9.402-4.313-12.351-8.909C58.957,40.787,58,39.412,57.004,37.926z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M57.004,37.926c0.996,1.486,1.953,2.861,2.857,4.271
        c2.948,4.595,7.161,7.448,12.351,8.909c1.054,0.297,1.476,0.902,1.687,1.881c1.421,6.59-0.291,12.437-4.298,17.678
        c-1.965,2.57-4.185,4.897-6.814,6.866c-3.852,2.883-7.942,3.011-12.27,1.581c-3.5-1.156-6.45-3.246-8.867-5.953
        c-2.583-2.891-3.738-6.543-4.109-10.311c-0.275-2.796,0.05-5.652,0.15-8.481c0.018-0.507,0.244-1.005,0.363-1.511
        c0.338-1.438,1.053-2.291,2.613-2.83c5.401-1.868,10.137-4.875,14.006-9.137C55.47,40.01,56.147,39.022,57.004,37.926z
        M42.557,63.338c-0.095-0.026-0.19-0.053-0.285-0.079c-0.094,0.511-0.306,1.032-0.263,1.531c0.24,2.749,1.14,5.206,3.269,7.106
        c3.329,2.975,7.261,3.885,11.591,3.422c5.717-0.613,9.613-3.822,12.167-8.78c0.446-0.866,0.518-2.013,0.474-3.017
        c-0.041-0.93-0.742-1.096-1.391-0.398c-0.515,0.552-0.938,1.19-1.379,1.808c-3.469,4.861-7.773,8.183-14.152,7.548
        c-3.519-0.35-6.475-1.741-8.19-5.032C43.705,66.119,43.166,64.71,42.557,63.338z M53.133,53.791
        c0.008-0.915-0.798-1.695-1.783-1.725c-1.261-0.039-2.335,1.038-2.356,2.362c-0.018,1.104,0.754,1.929,1.819,1.946
        C51.927,56.392,53.122,55.062,53.133,53.791z M63.017,51.809c-0.968-0.009-1.778,0.898-1.788,2.003
        c-0.011,1.194,0.939,2.249,2.02,2.243c0.91-0.005,1.634-1.022,1.625-2.285C64.866,52.731,64.002,51.818,63.017,51.809z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M42.557,63.338c0.609,1.372,1.147,2.78,1.84,4.108
        c1.716,3.291,4.671,4.683,8.19,5.032c6.379,0.635,10.684-2.687,14.152-7.548c0.44-0.617,0.864-1.256,1.379-1.808
        c0.648-0.697,1.35-0.531,1.391,0.398c0.044,1.004-0.027,2.15-0.474,3.017c-2.555,4.958-6.45,8.167-12.167,8.78
        c-4.33,0.463-8.262-0.447-11.591-3.422c-2.128-1.9-3.029-4.357-3.269-7.106c-0.043-0.499,0.168-1.021,0.263-1.531
        C42.367,63.286,42.462,63.312,42.557,63.338z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M53.133,53.791c-0.011,1.271-1.206,2.601-2.32,2.583
        c-1.065-0.017-1.836-0.842-1.819-1.946c0.021-1.324,1.095-2.401,2.356-2.362C52.334,52.096,53.141,52.876,53.133,53.791z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M63.017,51.809c0.985,0.01,1.85,0.923,1.856,1.961
        c0.009,1.263-0.715,2.28-1.625,2.285c-1.08,0.005-2.03-1.049-2.02-2.243C61.238,52.707,62.049,51.799,63.017,51.809z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ElternIcon'
}
</script>
