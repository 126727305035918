<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M44.7,64.127c0.426-1.248,0.763-2.478,1.275-3.628c0.239-0.536,0.77-1.254,1.249-1.321
        c4.294-0.607,8.606-1.083,12.912-1.61c1.104-0.135,2.204-0.304,3.303-0.477c1.801-0.283,2.479-1.82,3.487-3.042
        c1.573-1.907,3.23-3.694,4.109-6.112c0.656-1.807,1.68-3.497,2.671-5.161c0.648-1.09,1.586-2.003,2.295-3.063
        c1.419-2.122,2.71-4.331,4.163-6.429c2.408-3.477,4.967-6.851,7.344-10.349c2.439-3.589,4.768-7.256,7.054-10.945
        c0.236-0.382,0.018-1.257-0.289-1.69c-0.202-0.285-1.012-0.374-1.431-0.222c-1.084,0.392-2.104,0.964-3.147,1.467
        c-3.814,1.835-7.715,3.515-11.409,5.567c-2.076,1.154-4.278,2.165-5.776,4.379c-1.06,1.565-3.114,2.437-4.66,3.696
        c-4.189,3.416-8.011,7.211-11.325,11.485c-1.634,2.107-3.126,4.328-4.638,6.527c-1.446,2.103-3.013,4.148-4.208,6.39
        c-2.1,3.94-3.942,8.016-5.922,12.021c-0.518,1.047-0.994,2.051-2.563,1.983c-2.541-0.11-5.102-0.078-7.638,0.111
        c-2.426,0.182-4.854,0.585-6.996,1.88c-0.399,0.241-0.812,0.703-0.922,1.139c-0.43,1.701-0.755,3.43-1.09,5.153
        c-0.276,1.422,0.161,2.631,1.293,3.519c0.784,0.615,1.646,1.131,2.484,1.674c0.981,0.637,1.039,1.055,0.169,1.814
        c-0.294,0.257-0.662,0.441-1.016,0.617c-2.659,1.316-4.938,3.004-5.599,6.117c-0.867,4.087-1.081,8.24-0.717,12.386
        c0.084,0.959,0.903,2.101,1.721,2.688c1.062,0.765,2.424,1.273,3.727,1.511c2.309,0.42,4.666,0.588,7.009,0.8
        c4.085,0.369,8.165,1.134,12.282,0.433c3.418-0.583,6.838-1.176,10.229-1.896c2.438-0.518,3.728-2.254,3.683-4.674
        c-0.073-3.896-0.33-7.792-0.609-11.68c-0.25-3.479-2.551-5.246-5.601-6.251c-1.096-0.361-2.334-0.399-3.329-0.925
        c-0.786-0.416-1.573-1.254-1.861-2.079c-0.333-0.953,0.568-1.488,1.447-1.877c0.471-0.207,1.009-0.646,1.169-1.102
        c0.406-1.156,0.767-2.368,0.872-3.582c0.193-2.225-0.626-3.426-2.683-4.306C46.417,64.724,45.587,64.456,44.7,64.127z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M44.7,64.127c0.888,0.328,1.718,0.597,2.516,0.938
        c2.057,0.88,2.876,2.081,2.683,4.306c-0.105,1.214-0.466,2.426-0.872,3.582c-0.16,0.456-0.698,0.895-1.169,1.102
        c-0.879,0.389-1.78,0.924-1.447,1.877c0.288,0.825,1.075,1.663,1.861,2.079c0.994,0.525,2.233,0.563,3.329,0.925
        c3.05,1.005,5.352,2.772,5.601,6.251c0.279,3.888,0.536,7.783,0.609,11.68c0.045,2.42-1.246,4.156-3.683,4.674
        c-3.391,0.72-6.811,1.312-10.229,1.896c-4.117,0.701-8.197-0.063-12.282-0.433c-2.343-0.212-4.7-0.38-7.009-0.8
        c-1.303-0.237-2.664-0.746-3.727-1.511c-0.818-0.588-1.636-1.729-1.721-2.688c-0.364-4.146-0.15-8.299,0.717-12.386
        c0.661-3.113,2.94-4.801,5.599-6.117c0.354-0.176,0.722-0.36,1.016-0.617c0.87-0.76,0.812-1.178-0.169-1.814
        c-0.838-0.543-1.701-1.059-2.484-1.674c-1.132-0.888-1.569-2.097-1.293-3.519c0.334-1.724,0.66-3.452,1.09-5.153
        c0.11-0.436,0.522-0.897,0.922-1.139c2.141-1.295,4.569-1.698,6.996-1.88c2.536-0.189,5.097-0.222,7.638-0.111
        c1.569,0.067,2.045-0.937,2.563-1.983c1.98-4.005,3.823-8.081,5.922-12.021c1.194-2.241,2.762-4.287,4.208-6.39
        c1.512-2.199,3.004-4.419,4.638-6.527c3.314-4.273,7.135-8.069,11.325-11.485c1.546-1.26,3.601-2.131,4.66-3.696
        c1.498-2.214,3.7-3.225,5.776-4.379c3.694-2.053,7.595-3.732,11.409-5.567c1.044-0.502,2.063-1.075,3.147-1.467
        c0.419-0.152,1.229-0.063,1.431,0.222c0.307,0.433,0.525,1.308,0.289,1.69c-2.286,3.689-4.614,7.356-7.054,10.945
        c-2.377,3.498-4.936,6.872-7.344,10.349c-1.453,2.098-2.744,4.307-4.163,6.429c-0.709,1.06-1.646,1.973-2.295,3.063
        c-0.991,1.665-2.015,3.354-2.671,5.161c-0.879,2.418-2.536,4.205-4.109,6.112c-1.008,1.222-1.687,2.759-3.487,3.042
        c-1.099,0.173-2.198,0.342-3.303,0.477c-4.305,0.527-8.618,1.003-12.912,1.61c-0.479,0.067-1.01,0.785-1.249,1.321
        C45.463,61.65,45.126,62.879,44.7,64.127z M20.87,91.787c0.193,2.022,0.346,3.769,0.533,5.511c0.075,0.701,0.405,1.087,1.218,1.188
        c2.131,0.269,4.236,0.763,6.37,0.991c3.404,0.364,6.82,0.7,10.239,0.824c1.934,0.069,3.901-0.203,5.823-0.51
        c2.594-0.414,5.16-1.002,7.735-1.53c1.012-0.207,1.664-0.835,1.738-1.871c0.236-3.295,0.766-6.616,0.544-9.885
        c-0.292-4.311-1.546-5.298-5.861-5.981c-0.906-0.144-1.83-0.181-2.729-0.354c-2.518-0.487-3.96-2.47-3.731-5.058
        c0.185-2.091,1.402-2.974,4.183-3.095c0.354-0.016,0.817-0.149,1.027-0.398c0.822-0.98,0.562-2.524-0.512-3.267
        c-1.544-1.068-3.252-1.702-5.138-1.793c-1.578-0.077-3.171-0.012-4.731-0.213c-3.108-0.4-6.127,0.067-9.15,0.658
        c-2.668,0.521-3.277,1.246-3.806,3.884c-0.229,1.142,0.122,1.683,1.079,2.134c1.298,0.611,2.591,1.251,3.82,1.987
        c1.17,0.702,1.419,1.859,1.208,3.146c-0.196,1.192-0.734,2.085-1.975,2.455c-1.375,0.411-2.719,0.933-4.104,1.297
        c-1.672,0.438-2.835,1.359-3.068,3.112C21.273,87.349,21.085,89.693,20.87,91.787z M52.725,48.341
        c0.237,0.093,0.317,0.157,0.38,0.143c0.271-0.059,0.54-0.134,0.807-0.213c4.436-1.32,8.919-2.395,13.555-2.734
        c1.039-0.076,1.893-0.454,2.351-1.538c0.291-0.688,0.775-1.301,1.216-1.917c0.91-1.274,1.85-2.527,2.995-4.085
        c-1.786,0.213-3.279,0.331-4.749,0.58c-3.768,0.637-7.527,1.324-11.283,2.027c-0.261,0.048-0.525,0.353-0.688,0.603
        C55.792,43.537,54.299,45.882,52.725,48.341z M80.098,28.969c-0.889,0-1.733-0.138-2.518,0.023
        c-3.82,0.786-7.627,1.647-11.435,2.497c-0.259,0.058-0.543,0.178-0.729,0.358c-0.86,0.831-1.702,1.683-2.528,2.548
        c-0.817,0.855-1.608,1.736-2.604,2.814c1.617-0.305,2.898-0.598,4.195-0.782c3.669-0.519,7.311-1.691,10.985-0.568
        C77.001,33.575,78.523,31.31,80.098,28.969z M47.809,56.051c1.315-0.122,2.287-0.176,3.249-0.308
        c2.635-0.364,5.267-0.759,7.899-1.145c1.138-0.167,2.281-0.302,3.41-0.516c1.72-0.327,2.223-2.045,3.347-3.058
        c0.819-0.739,1.428-1.712,2.267-2.75c-0.749,0-1.196-0.075-1.61,0.012c-5.027,1.049-10.05,2.121-15.073,3.192
        c-0.265,0.057-0.636,0.092-0.756,0.271C49.666,53.062,48.842,54.409,47.809,56.051z M85.414,21.568
        c-0.419-0.021-0.699-0.082-0.963-0.041c-1.765,0.274-3.563,0.433-5.277,0.897c-1.808,0.49-3.84,0.325-5.389,1.618
        c-1.771,1.479-3.499,3.009-5.235,4.529c-0.173,0.151-0.27,0.389-0.564,0.832c3.15-0.747,5.995-1.488,8.872-2.075
        c1.403-0.286,2.921-0.625,4.194,0.774C82.499,25.936,83.902,23.833,85.414,21.568z M76.631,21.251
        c2.848-0.567,5.495-1.029,8.103-1.654c0.849-0.204,1.943,0.247,2.548-0.875c0.45-0.834,1.093-1.563,1.62-2.358
        c0.446-0.672,0.85-1.373,1.272-2.061c-0.089-0.088-0.177-0.176-0.266-0.264C84.947,15.363,81.035,18.614,76.631,21.251z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M20.87,91.787c0.215-2.094,0.403-4.438,0.711-6.767
        c0.232-1.753,1.396-2.674,3.068-3.112c1.386-0.364,2.73-0.886,4.104-1.297c1.24-0.37,1.779-1.263,1.975-2.455
        c0.211-1.287-0.038-2.444-1.208-3.146c-1.229-0.736-2.522-1.376-3.82-1.987c-0.957-0.451-1.307-0.992-1.079-2.134
        c0.528-2.638,1.138-3.362,3.806-3.884c3.022-0.591,6.042-1.059,9.15-0.658c1.56,0.201,3.154,0.136,4.731,0.213
        c1.886,0.091,3.594,0.725,5.138,1.793c1.074,0.742,1.334,2.286,0.512,3.267c-0.209,0.249-0.673,0.383-1.027,0.398
        c-2.781,0.121-3.999,1.004-4.183,3.095c-0.229,2.588,1.213,4.57,3.731,5.058c0.899,0.174,1.823,0.211,2.729,0.354
        c4.314,0.684,5.568,1.671,5.861,5.981c0.222,3.269-0.308,6.59-0.544,9.885c-0.074,1.036-0.726,1.664-1.738,1.871
        c-2.575,0.528-5.142,1.116-7.735,1.53c-1.922,0.307-3.889,0.579-5.823,0.51c-3.419-0.124-6.834-0.46-10.239-0.824
        c-2.134-0.229-4.238-0.723-6.37-0.991c-0.812-0.102-1.143-0.487-1.218-1.188C21.216,95.555,21.063,93.809,20.87,91.787z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M52.725,48.341c1.575-2.459,3.068-4.804,4.583-7.134
        c0.163-0.25,0.428-0.555,0.688-0.603c3.756-0.703,7.516-1.39,11.283-2.027c1.47-0.249,2.963-0.366,4.749-0.58
        c-1.146,1.558-2.085,2.812-2.995,4.085c-0.44,0.616-0.925,1.229-1.216,1.917c-0.458,1.084-1.312,1.462-2.351,1.538
        c-4.636,0.339-9.119,1.414-13.555,2.734c-0.267,0.079-0.535,0.154-0.807,0.213C53.042,48.498,52.962,48.434,52.725,48.341z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M80.098,28.969c-1.574,2.341-3.097,4.606-4.633,6.891
        c-3.675-1.124-7.316,0.049-10.985,0.568c-1.297,0.184-2.578,0.477-4.195,0.782c0.995-1.079,1.786-1.959,2.604-2.814
        c0.826-0.865,1.668-1.717,2.528-2.548c0.187-0.18,0.471-0.3,0.729-0.358c3.808-0.849,7.614-1.711,11.435-2.497
        C78.364,28.831,79.209,28.969,80.098,28.969z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M47.809,56.051c1.033-1.643,1.856-2.989,2.732-4.301
        c0.12-0.18,0.492-0.214,0.756-0.271c5.023-1.072,10.045-2.144,15.073-3.192c0.414-0.086,0.861-0.012,1.61-0.012
        c-0.839,1.038-1.447,2.01-2.267,2.75c-1.124,1.013-1.627,2.731-3.347,3.058c-1.129,0.214-2.272,0.35-3.41,0.516
        c-2.632,0.385-5.264,0.781-7.899,1.145C50.096,55.875,49.124,55.929,47.809,56.051z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M85.414,21.568c-1.512,2.265-2.915,4.368-4.362,6.535
        c-1.273-1.399-2.791-1.061-4.194-0.774c-2.877,0.587-5.722,1.328-8.872,2.075c0.295-0.442,0.392-0.68,0.564-0.832
        c1.736-1.521,3.464-3.05,5.235-4.529c1.549-1.293,3.581-1.128,5.389-1.618c1.714-0.465,3.513-0.623,5.277-0.897
        C84.715,21.486,84.995,21.547,85.414,21.568z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M76.631,21.251c4.404-2.638,8.316-5.888,13.277-7.211
        c0.089,0.088,0.177,0.176,0.266,0.264c-0.423,0.688-0.826,1.388-1.272,2.061c-0.527,0.795-1.17,1.523-1.62,2.358
        c-0.604,1.122-1.699,0.671-2.548,0.875C82.126,20.223,79.479,20.684,76.631,21.251z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HistorischesIcon'
}
</script>
