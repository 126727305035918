var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentFrame',{attrs:{"themeColor":_vm.computedTopicColor,"title":_vm.computedTitle}},[_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Der Verein","textArray":[
      'Die seelische Gesundheit von Kindern und Jugendlichen ist zunehmend in Gefahr, sei es durch gesellschaftlich bedingte Entwicklungen oder veränderte soziale Strukturen in den Familien.',
      'Die Kinder- und Jugendpsychiatrie der Universitätsklinik Ulm hilft genau solchen jungen Menschen und deren Angehörigen.',
      'Alle Kinder und Jugendliche, die sich in stationärer oder teilstationärer Behandlung befinden, werden in der Hans-Lebrecht-Schule, einer Schule ausschließlich für Schüler*innen in längerer Krankenhausbehandlung am Universitätsklinikum Ulm, unterrichtet.',
      'Alle diese Kinder und Jugendlichen benötigen spezielle zusätzliche Angebote im täglichen Unterricht und im milieutherapeutischen Alltag, in der Kunst-, Ergo-, Musik- und Arbeitstherapie.',
      'Der 2002 gegründete KlinikSchulVerein Ulm e.V. hat es sich unter Vorsitz von Prof. Dr.Jörg M. Fegert zur Aufgabe gemacht, diese Maßnahmen zu unterstützen.'
    ],"downloadText":"Informationen als Download","downloadFileName":"KlinikSchulVerein.pdf"}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Die Schwerpunkte","textArray":[
      'Der KlinikSchulVerein Ulm e. V. hat sich folgende Schwerpunkte gesetzt:'
    ]}},[_c('ul',{attrs:{"slot":"content"},slot:"content"},[_c('li',[_vm._v(" Unterstützung von Kindern und Jugendlichen und deren Eltern bei Heimfahrten oder Besuchen in der Klinik ")]),_c('li',[_vm._v(" Unterstützung bei der Anschaffung von Kleidung ")]),_c('li',[_vm._v(" Unterstützung der Stationen und der Tagesklinik bei besonderen Anschaffungen für die Kinder und Jugendlichen ")]),_c('li',[_vm._v(" Finanzierung innovativer Fördermaterialien für den Unterricht ")]),_c('li',[_vm._v(" Bereitstellung von Arbeitsmaterial für den täglichen Unterricht ")]),_c('li',[_vm._v(" Unterstützung besonderer Projekte in den Co-Therapien ")]),_c('li',[_vm._v(" Unterstützung spezieller psychiatrischer Fort- und Weiterbildungsveranstaltungen für Lehrkräfte der Klinikschule und Co-Therapeut*innen der Klinik ")]),_c('li',[_vm._v(" Unterstützung von Informationsveranstaltungen und Öffentlichkeitsarbeit, mit dem Ziel, die Gesellschaft für die besondere Situation psychiatrisch erkrankter Kinder und Jugendlicher und ihrer Familien zu sensibilisieren ")])])]),_c('ContentImageTile',{attrs:{"imageSource":"/img/grafiken/mithilfe-1.jpg"}}),_c('ContentTile',{attrs:{"themeColor":_vm.computedTopicColor,"title":"Ihre Spende hilft","textArray":[
      'Für unsere Arbeit benötigt der Verein regelmäßige finanzielle Unterstützung. Deshalb unsere Bitte an Sie:',
      'Helfen Sie dem KlinikSchulVerein mit einer Spende oder Mitgliedschaft ab einem jährlichen Beitrag von 25 €.'
    ],"downloadText":_vm.applicationFormText,"downloadFileName":_vm.applicationFormFileName}},[_c('v-card',{attrs:{"slot":"content"},slot:"content"},[_c('v-card-text',[_c('p',{staticClass:"text-subtitle-1"},[_vm._v(" Bankverbindung ")]),_vm._v(" KlinikSchulVerein Ulm e.V. "),_c('br'),_vm._v("IBAN: DE07 6305 0000 0002 1803 06 "),_c('br'),_vm._v("BIC: SOLADES1ULM ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }