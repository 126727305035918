<template>
  <v-app>
    <v-app-bar
      app
      flat
      :height="computedAppBarHeight"
      :style="{ 'background-color': computedAppBarBg}"
    >
      <v-col
        cols="12"
        class="restricted align-self-center"
        :class="{
          'px-5': $vuetify.breakpoint.smAndUp,
          'px-2': !$vuetify.breakpoint.smAndUp
        }"
      >
        <v-row
          align="center"
          class="flex-nowrap"
        >
          <img
            src="/img/logo_rubik.svg"
            :height="computedAppBarImageHeight"
            class="mr-4"
            :class="{
              'pointer': $route.name !== 'Home'
            }"
            @click="goToStartpage"
          />
          <span
            v-if="$vuetify.breakpoint.smAndUp"
            :style="{
              'font-size': computedTitleSize,
              'color': $vuetify.theme.currentTheme.neutral,
              'opacity': scrolled
            }"
            class="flex-grow-1"
          >
            HANS LEBRECHT SCHULE
          </span>
          <v-spacer />
          <v-col
            class="pa-0"
          >
            <v-row
              class="flex-nowrap no-gutters"
              justify="end"
            >
              <span
                style="display: inline-flex;"
                :style="{
                  'background-color': $vuetify.breakpoint.mdAndUp ? 'transparent' : 'rgba(0,0,0,0.1)'
                }"
                class="pa-1 pa-sm-2 pa-md-0 pa-lg-0 pa-xl-0 flex-nowrap"
              >
                <v-btn
                  right
                  height="30"
                  :x-large="$vuetify.breakpoint.lg"
                  :large="$vuetify.breakpoint.md"
                  :small="$vuetify.breakpoint.sm"
                  :x-small="$vuetify.breakpoint.xs"
                  :elevation="computedAppBarElevation"
                  class="mr-2 my-1 font-weight-bold flex-shrink-1 flex-grow-0"
                  :color="computedAppButtonsWarningBg"
                  @click="showDialog"
                >
                  <v-icon
                    v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                  >
                    $vuetify.icons.corona
                  </v-icon>
                  <span
                    v-else
                  >
                    CORONA
                  </span>
                </v-btn>
                <v-btn
                  right
                  height="30"
                  :x-large="$vuetify.breakpoint.lg"
                  :large="$vuetify.breakpoint.md"
                  :small="$vuetify.breakpoint.sm"
                  :x-small="$vuetify.breakpoint.xs"
                  :elevation="computedAppBarElevation"
                  class="ml-2 my-1 font-weight-bold flex-shrink-1 flex-grow-0"
                  :color="computedAppButtonsNeutralBg"
                  flex-shrink="0"
                  @click="openPage('Contact')"
                >
                  <v-icon
                    v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                  >
                    $vuetify.icons.kontakt
                  </v-icon>
                  <span
                    v-else
                  >
                    KONTAKT
                  </span>
                </v-btn>
                <BreadCrumbsMenu
                  v-if="!$vuetify.breakpoint.mdAndUp"
                  class="ml-4"
                  :scrolled="scrolled"
                />
              </span>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          style="background-color:rgba(0,0,0,0.1)"
          class="pa-3 mt-8"
          justify="space-between"
        >
          <v-col
            class="pa-0"
          >
            <BreadCrumbs
              :scrolled="scrolled"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-app-bar>
    <v-main
      id="scrollingContent"
      style="overflow: hidden !important;"
    >
      <v-container
        class="fill-height mt-4 restricted"
        :class="{
          'pt-12': $route.name !== 'Home',
          'px-6': $vuetify.breakpoint.smAndUp,
          'px-3': !$vuetify.breakpoint.smAndUp
        }"
      >
        <v-row
          justify="center"
        >
          <v-col
            class="px-0"
          >
            <transition
              :name="transitionName"
              mode="out-in"
            >
              <router-view>
              </router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      color="transparent"
      class="pb-0 mb-0"
    >
      <v-row
        class="pb-0 mb-0"
      >
        <v-col
          cols="12"
          class="restricted px-2 px-sm-5 px-md-2 px-lg-2 px-xl-2 pb-0 mb-0"
        >
          <v-container
            style="background-color:rgba(0,0,0,0.1)"
          >
            <v-row>
              <v-col>
                <v-toolbar
                  flat
                  color="transparent"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="align-self-center py-1"
                      :align="$vuetify.breakpoint.mdAndUp ? 'start' : 'center'"
                      :class="{
                        'text-caption': $vuetify.breakpoint.xs
                      }"
                    >
                      © Hans-Lebrecht-Schule Ulm 2021
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                      class="py-1"
                      :align="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'"
                    >
                      <v-btn
                        text
                        @click="openPage('Imprint')"
                        :x-small="$vuetify.breakpoint.xs"
                        :small="$vuetify.breakpoint.sm"
                      >
                        Impressum
                      </v-btn>
                      <v-btn
                        text
                        @click="openPage('Privacy')"
                        :x-small="$vuetify.breakpoint.xs"
                        :small="$vuetify.breakpoint.sm"
                      >
                        Datenschutz
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-footer>
    <CoronaDialog
      :isVisible="dialogIsVisible"
      :closeFunction="closeDialog"
    />
  </v-app>
</template>

<script>
import BreadCrumbs from '@/views/components/BreadCrumbs.vue'
import BreadCrumbsMenu from '@/views/components/BreadCrumbsMenu.vue'
import CoronaDialog from '@/views/components/CoronaDialog.vue'

export default {
  name: 'App',
  components: {
    BreadCrumbs,
    BreadCrumbsMenu,
    CoronaDialog
  },
  data: () => ({
    transitionName: 'slide-l',
    scrolled: 0.0,
    appBarMaxHeight: 160,
    appBarMinHeight: 90,
    dialogIsVisible: false
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    goToStartpage () {
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' })
      }
    },
    handleScroll (event) {
      this.scrolled = Math.min(window.scrollY, this.appBarMaxHeight) / this.appBarMaxHeight
    },
    hexToRGB (hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
      } else {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.0)'
      }
    },
    showDialog () {
      this.dialogIsVisible = true
    },
    closeDialog () {
      this.dialogIsVisible = false
    },
    openPage (pageName) {
      this.$router.push({ name: pageName })
    }
  },
  computed: {
    computedAppBarBg () {
      return this.hexToRGB('#FFFFFF', this.scrolled - 0.1)
    },
    computedAppButtonsNeutralBg () {
      return this.hexToRGB(this.$vuetify.theme.currentTheme.neutral, this.scrolled)
    },
    computedAppButtonsWarningBg () {
      return this.hexToRGB(this.$vuetify.theme.currentTheme.warning, this.scrolled)
    },
    computedAppBarHeight () {
      const minMaxHeightDiff = this.appBarMaxHeight - this.appBarMinHeight
      return this.appBarMaxHeight - (this.scrolled * minMaxHeightDiff) + (this.$vuetify.breakpoint.mdAndUp ? 70 : 0)
    },
    computedAppBarImageHeight () {
      let sizeFactor
      if (this.$vuetify.breakpoint.mdAndUp) {
        sizeFactor = 110
      } else {
        const invertedScrolled = Math.abs(this.scrolled - 1)
        sizeFactor = 60 * (Math.max(0.3, Math.min(invertedScrolled, 0.8)))
      }
      return this.computedAppBarHeight - sizeFactor
    },
    computedAppBarElevation () {
      return Math.round(this.scrolled * 5).toString()
    },
    computedTitleSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '2.125rem !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '2.125rem !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '2.0rem !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '1.5rem !important'
      } else {
        return '1.1rem !important'
      }
    }
  },
  watch: {
    $route (to, from) {
      const toDepth = to.meta.depth || 0
      const fromDepth = from.meta.depth || 0
      this.transitionName = toDepth >= fromDepth ? 'slide-l' : 'slide-r'
    }
  }
}
</script>

<style>
  .v-card, .v-card .v-avatar, .pic-hover {
    transition: box-shadow .2s ease-in-out;
  }

  .restricted {
    max-width: 1200px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .v-app-bar {
    backdrop-filter: blur(10px);
  }

  #app {
    background-image: linear-gradient(to top right, #FFFFCC, #989284);
  }
  .pointer {
    cursor: pointer;
  }

  .v-sheet {
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .slide-r-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-r-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-l-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-l-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  .slide-r-enter-active,
  .slide-l-enter-active {
    transition: all 0.3s ease-out;
  }
  .slide-r-leave-active,
  .slide-l-leave-active {
    transition: all 0.3s ease-in;
  }

  .card-content {
    position: relative;
    background-color: white;
    /* padding: 56px 16px 56px 16px !important; */
  }
  .card-content-border {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  .card-content-border::before {
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 10px;
    background-color: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
</style>
