<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentTile
      :themeColor="computedTopicColor"
      title="Zur Person Hans Lebrecht"
      :textArray="[
        'Hans Lebrecht wurde am 8.11.1915 in Ulm als dritter von vier Söhnen der jüdischen Ulmer Fabrikantenfamilie Wilhelm und Rosa Lebrecht geboren. Er wuchs in der Lebrecht-Villa am Safranberg auf. Verheiratet war Hans Lebrecht seit 1938 mit Tosca Loewy, mit der er zwei Töchter hatte – Ruth und Margalith.',
        'Schon früh bekam Hans Lebrecht die antisemitische Haltung der Nationalsozialisten zu spüren. Wegen seiner jüdischen Herkunft musste er 1934 ein Jahr vor dem Abitur die Schule verlassen. Auch sein anschließender Lehrvertrag wurde 1935 fristlos gekündigt. Unter großen Schwierigkeiten konnte er dennoch in Deutschland eine dreijährige Lehre als Maschinenschlosser abschließen.',
        '1938 emigrierten er und seine Frau schließlich unter fluchtartigen Umständen nach Palästina. Nach der Gründung des Staates Israel setzte sich Hans Lebrecht zeitlebens für das friedliche und gleichberechtigte Zusammenleben von Jüdinnen und Juden  und Palästinenser*innen ein. Dafür arbeitete er politisch als Friedensaktivist und beruflich als Journalist.'
      ]"
    />
    <ContentImageTile
      :themeColor="computedTopicColor"
      imageSource="/img/hans-lebrecht/02.jpg"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Die Beziehung zu Ulm"
      :textArray="[
        'Zur Stadt Ulm pflegte er bis ins hohe Alter enge Kontakte, wobei ihm die Arbeit des Dokumentationszentrums Oberer Kuhberg mit seiner KZ-Gedenkstätte ein besonderes Anliegen war. Zum letzten Mal war Hans Lebrecht 2004 in Ulm.',
        'Im Jahr 2007 erschienen seine Lebenserinnerungen als Buch unter dem Titel „Gekrümmte Wege, doch ein Ziel“. Hans Lebrecht verstarb im September 2014 in einem Kibuzz nahe Haifa im Alter von 98 Jahren.'
      ]"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="In Gedenken"
      :textArray="[
        '2007 wurde die Schule für Kranke in Hans-Lebrecht-Schule umbenannt. Wir fühlen uns in unserem Auftrag eng verbunden mit dem Titel der Biografie von Hans Lebrecht: „Gekrümmte Wege, doch ein Ziel“. Auch die Kinder und Jugendlichen unserer Schule haben keine geradlinigen Lebenswege hinter sich, da sie selbst Leid und Fremdbestimmung im Rahmen einer Krise erfahren mussten. Daher ist es besonders wichtig, trotz solcher gekrümmten Wege, ein Ziel vor Augen zu haben und an einen Erfolg zu glauben.',
        'In gesunder Balance zwischen therapeutischem Denken und pädagogischem Handeln, zwischen Fördern und Fordern, begleiten wir unsere Schüler*innen auf den Übergängen in ihrer Entwicklung, den Übergängen in ihren Schullaufbahnen, auf ihren Umwegen und Hilfswegen, mit dem Ziel, ihre Teilhabe am kindlichen und jugendlichen Leben, besonders im Bereich schulische Bildung, zu stärken und zu sichern.'
      ]"
    />
    <ContentTile
      :themeColor="computedTopicColor"
      title="Die Stolpersteine"
      :textArray="[
        'Für die ehemals am Safranberg in der Lebrecht-Villa ansässige jüdische Ulmer Fabrikantenfamilie Wilhelm und Rosa Lebrecht sowie deren Söhne, Heinrich, Curt, Walter und Hans, die aufgrund des NS-Regimes nach Südamerika und Palästina emigrierten, wurden Stolpersteine verlegt. Sie sollen an die Familie und deren Geschichte erinnern.',
        'Die Stolpersteine wurden im geschützten Bereich im früheren Garten der Lebrecht-Villa – heute Garten der Kinder- und Jugendpsychiatrie der Universitätsklinik Ulm – eingelassen. Im Zuge der Stolpersteinverlegung wurde die Windfahne der Familie, die das Lebrecht-Quartett darstellt und ursprünglich auf dem Dach der Lebrecht-Villa thronte, aber im Nationalsozialismus abgebaut wurde, wieder an ihren Ursprungsort zurückgebracht.',
        'Sie finden hier das Buch als Download. Gerne schicken wir es Ihnen als Druckversion gegen eine kleine Spende an den KlinikSchulverein per Post zu. Bitte kontaktieren Sie uns hierfür einfach per Mail.'
      ]"
      downloadText='Buch "Stolpersteine"'
      downloadFileName="Hans-Lebrecht-Schule_Stolpersteine.pdf"
    >
      <v-col
          slot="content"
          v-if="emailRequestAddress"
          cols="12"
          class="py-2"
        >
          <ContactChip
            icon="mdi-email"
            :value="emailRequestAddress"
            :href="'mailto:' + emailRequestAddress + '?subject=' + emailRequestSubject"
          />
        </v-col>
    </ContentTile>
    <ContentGalleryTile
      v-if="photos.length > 0"
      :themeColor="computedTopicColor"
      title="Fotos"
      :photos="photos"
    />
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentTile from '@/views/components/ContentTile.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'
import ContentGalleryTile from '@/views/components/ContentGalleryTile.vue'
import ContentFrame from '@/views/components/ContentFrame.vue'
import ContactChip from '@/views/components/ContactChip.vue'

export default {
  name: 'History',
  components: {
    ContentTile,
    ContentImageTile,
    ContentGalleryTile,
    ContentFrame,
    ContactChip
  },
  data () {
    return {
      emailRequestAddress: 'info@hans-lebrecht-schule.de',
      emailRequestSubject: 'Buch Stolpersteine für Familie Lebrecht',
      photos: [
        {
          src: '/img/hans-lebrecht/01.jpg',
          description: 'Der junge Hans Lebrecht in Ulm, 1930.'
        },
        {
          src: '/img/hans-lebrecht/02.jpg',
          description: 'Die Lebrecht-Brüder, 1936: l.oben Heinrich, l. unten Walter, r. unten Curt, r. oben Hans. (Foto: Archiv des Dokumentationszentrums Oberer Kuhberg Ulm)'
        },
        {
          src: '/img/hans-lebrecht/03.jpg',
          description: 'Das letzte Familienfoto in Ulm, 1936: Walter, Curt, Heinrich, Hans, Rosa und Wilhelm Lebrecht. (Foto: Archiv des Dokumentationszentrums Oberer Kuhberg Ulm)'
        },
        {
          src: '/img/hans-lebrecht/04.jpg',
          description: 'Der letzte Besuch in Ulm, 2004: Esther Bejarano, Ruth Bar-Hay, Hans Lebrecht, Ex-OB Ivo Gönner, Margalith Pozniak. (Foto: Archiv des Dokumentationszentrums Oberer Kuhberg Ulm)'
        },
        {
          src: '/img/hans-lebrecht/05.jpg',
          description: 'Windfahne. Das Lebrecht-Quartett: Otto Wiegandt, Else Lang, Eugen Roth, Wilhelm Lebrecht. (Foto: Armin Buhl)'
        },
        {
          src: '/img/hans-lebrecht/06.jpg',
          description: 'Die Fahne auf dem Dach des Hauses der Familie in Contulmo, Chile. (Foto: Manuel Lebrecht)'
        },
        {
          src: '/img/hans-lebrecht/07.jpg',
          description: 'Nachbildung der Windfahne auf Dach der ehem. Lebrecht-Villa, heute Tagesklinik der Kinder- und Jugendpsychiatrie des Universitätsklinikums Ulm. (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/01.jpg',
          description: 'Niederlegung der Rosen am Stolperstein von Karl Rueff. (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/02.jpg',
          description: 'Künstler Gunter Demnig bei der Verlegung. (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/03.jpg',
          description: 'Stolpersteine für die Familie Lebrecht. (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/04.jpg',
          description: 'Stolpersteine bei der Skulptur "Dazugehören". (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/05.jpg',
          description: 'Niederlegung der Rosen am Stolperstein von Familie Weglein. (Foto: Armin Buhl)'
        },
        {
          src: '/img/stolpersteine/06.jpg',
          description: 'Niederlegung der Rosen am Stolperstein von Jonathan Stark. (Foto: Armin Buhl)'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.history.color
    },
    computedTitle () {
      return this.menuData.history.title
    }
  }
}
</script>
