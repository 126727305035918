<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
      <ContentTile
        :themeColor="computedTopicColor"
        title="Herausgeberin"
        :textArray="[]"
      >
        <div slot="content">
          <span class="font-weight-bold">
            Hans-Lebrecht-Schule
          </span>
          <br>
          <span class="text-caption">
            Sonderpädagogisches Bildungs- und Beratungszentrum für „Schüler in längerer Krankenhausbehandlung“ am Universitätsklinikum Ulm
          </span>
          <br><br>
          Steinhövelstraße 7<br>
          89075 Ulm<br><br>
        </div>
      </ContentTile>
      <ContentTile
        :themeColor="computedTopicColor"
        title="Vertretung"
        :textArray="[]"
      >
        <div
          slot="content"
        >
          Lucia Geitmann<br>
          <v-col
            v-if="emailAddressSchool"
            cols="12"
            class="py-2"
          >
            <ContactChip
              icon="mdi-email"
              :value="emailAddressSchool"
              :href="'mailto:' + emailAddressSchool"
            />
          </v-col>
        </div>
      </ContentTile>
      <ContentTile
        :themeColor="computedTopicColor"
        title="Schulträger"
        :textArray="[
          'Stadt Ulm'
        ]"
      />
      <ContentTile
        :themeColor="computedTopicColor"
        title="Aufsichtsbehörde"
        :textArray="[
          'Staatliches Schulamt Biberach'
        ]"
      />
      <ContentTile
        :themeColor="computedTopicColor"
        title="Website und System-Betreuung"
        :textArray="[]"
      >
      <div
        slot="content"
      >
        <span
          class="font-weight-bold"
        >
          Widerstand und Soehne GmbH
        </span>
        <br>
        Prittwitzstr. 100<br>
        89075 Ulm<br>
        <v-col
          v-if="emailAddressWus"
          cols="12"
          class="py-2"
        >
          <ContactChip
            slot="content"
            icon="mdi-email"
            :value="emailAddressWus"
            :href="'mailto:' + emailAddressWus"
          />
        </v-col>
        <v-col
          cols="12"
          align="center"
        >
          <ExternalLinkButton
            :themeColor="computedTopicColor"
            :href="homepageLinkWuS"
            :text="homepageLinkTextWuS"
          />
        </v-col>
      </div>
    </ContentTile>
    <ContentTile
      :themeColor="computedTopicColor"
      title="Copyright"
      :textArray="[
        '© Hans-Lebrecht-Schule Ulm'
      ]"
    />
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'

import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import ContactChip from '@/views/components/ContactChip.vue'
import ExternalLinkButton from '@/views/components/ExternalLinkButton.vue'

export default {
  name: 'Imprint',
  components: {
    ContentFrame,
    ContentTile,
    ContactChip,
    ExternalLinkButton
  },
  data () {
    return {
      headers: [
        {
          text: 'Tag',
          align: 'start',
          sortable: false,
          value: 'day'
        },
        {
          text: 'Zeiten',
          align: 'end',
          sortable: false,
          value: 'times'
        }
      ],
      openingTimes: [
        {
          day: 'Montag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Dienstag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Mittwoch',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Donnerstag',
          times: '08:00 - 12:00 Uhr'
        },
        {
          day: 'Freitag',
          times: '08:00 - 11:30 Uhr'
        },
        {
          day: 'Samstag',
          times: 'geschlossen'
        },
        {
          day: 'Sonntag',
          times: 'geschlossen'
        }
      ],
      emailAddressSchool: 'info@hans-lebrecht-schule.de',
      emailAddressWus: 'info@widerstand.digital',
      homepageLinkWuS: 'https://www.widerstand.digital',
      homepageLinkTextWuS: 'Zur Homepage'
    }
  },
  mounted () {
  },
  methods: {
    cleanPhoneNumber (phoneNumber) {
      let cleanPhoneNumber = phoneNumber.replace(' ', '')
      cleanPhoneNumber = phoneNumber.replace('/', '')
      cleanPhoneNumber = phoneNumber.replace('\\', '')
      cleanPhoneNumber = phoneNumber.replace('-', '')
      return cleanPhoneNumber
    }
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.imprint.color
    },
    computedTitle () {
      return this.menuData.imprint.title
    }
  }
}
</script>

<style scoped>
  .v-data-table {
    border-radius: 0px !important;
  }
</style>
