<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="10 10 93.28 93.28" enable-background="new 10 10 93.28 93.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M86.101,45.862c0-1.544,0.199-2.822-0.036-4.013c-0.754-3.808-1.866-7.51-3.627-11.005
        c-0.927-1.84-2.174-3.256-3.896-4.422c-1.47-0.995-2.717-0.946-4.092-0.073c-3.681,2.337-6.877,5.212-9.471,8.72
        c-2.559,3.46-2.709,8.255,0.646,11.169c0.782,0.68,1.274,2.165,1.219,3.244c-0.132,2.552-0.718,5.086-2.225,7.245
        c-1.551,2.223-3.206,4.375-4.82,6.555c-2.35,3.175-5.268,5.49-9.283,6.176c-2.306,0.394-4.47,0.344-6.466-1.176
        c-2.175-1.655-4.605-1.779-6.998-0.505c-1.437,0.767-2.83,1.669-4.099,2.688c-3.12,2.508-5.072,5.776-5.469,9.779
        c-0.242,2.447,0.467,4.594,2.72,6.121c1.961,1.329,4.118,1.477,6.289,1.445c2.14-0.031,4.281-0.354,6.41-0.641
        c2.68-0.361,5.411-0.582,8.009-1.279c5.825-1.563,11.084-4.45,16.255-7.492c6.004-3.532,10.269-8.728,13.62-14.664
        c1.475-2.612,2.375-5.562,3.406-8.406C85.333,52.183,86.366,48.99,86.101,45.862z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M86.101,45.862c0.266,3.127-0.768,6.32-1.908,9.467
        c-1.031,2.844-1.932,5.794-3.406,8.406c-3.352,5.937-7.616,11.132-13.62,14.664c-5.171,3.042-10.43,5.929-16.255,7.492
        c-2.598,0.697-5.329,0.918-8.009,1.279c-2.128,0.287-4.27,0.609-6.41,0.641c-2.171,0.031-4.328-0.116-6.289-1.445
        c-2.253-1.527-2.962-3.674-2.72-6.121c0.396-4.003,2.349-7.271,5.469-9.779c1.269-1.02,2.662-1.922,4.099-2.688
        c2.393-1.274,4.823-1.15,6.998,0.505c1.997,1.52,4.161,1.569,6.466,1.176c4.015-0.686,6.933-3.001,9.283-6.176
        c1.614-2.181,3.27-4.332,4.82-6.555c1.507-2.159,2.093-4.693,2.225-7.245c0.056-1.08-0.437-2.564-1.219-3.244
        c-3.354-2.914-3.204-7.709-0.646-11.169c2.594-3.507,5.79-6.383,9.471-8.72c1.375-0.873,2.622-0.922,4.092,0.073
        c1.723,1.166,2.97,2.582,3.896,4.422c1.761,3.496,2.873,7.197,3.627,11.005C86.3,43.041,86.101,44.318,86.101,45.862z
        M83.262,45.578c-0.102-1.072,0.008-2.22-0.344-3.203c-1.245-3.493-2.624-6.939-4.012-10.379c-0.244-0.605-0.738-1.125-1.176-1.632
        c-0.551-0.638-1.134-0.7-1.828-0.094c-2.284,1.997-4.671,3.88-6.883,5.952c-0.965,0.904-1.7,2.104-2.355,3.273
        c-0.661,1.18-0.329,2.336,0.738,3.129c2.342,1.738,3.346,4.087,2.894,6.907c-0.287,1.793-0.774,3.615-1.527,5.262
        c-1.981,4.333-4.91,8.064-8.396,11.247c-2.214,2.023-4.763,3.796-7.419,5.188c-2.875,1.508-6.056,2.358-9.308,0.776
        c-0.575-0.279-1.154-0.55-1.724-0.839c-0.96-0.486-1.979-0.606-2.85,0.038c-1.913,1.416-3.889,2.793-5.576,4.454
        c-1.273,1.254-1.92,3.005-2.122,4.858c-0.214,1.963,0.72,3.817,2.44,4.113c1.71,0.294,3.483,0.293,5.229,0.294
        c1.39,0.001,2.793-0.106,4.169-0.315c2.437-0.368,4.885-0.732,7.277-1.311c4.308-1.041,8.094-3.333,12.05-5.229
        c6.936-3.325,11.821-8.736,15.691-15.176c1.649-2.745,2.796-5.732,3.47-8.89C82.297,51.211,83.494,48.531,83.262,45.578z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M83.262,45.578c0.232,2.953-0.965,5.633-1.561,8.424
        c-0.674,3.158-1.82,6.145-3.47,8.89c-3.87,6.439-8.756,11.851-15.691,15.176c-3.956,1.896-7.742,4.188-12.05,5.229
        c-2.392,0.578-4.84,0.942-7.277,1.311c-1.375,0.209-2.779,0.316-4.169,0.315c-1.746-0.001-3.518,0-5.229-0.294
        c-1.72-0.296-2.654-2.15-2.44-4.113c0.202-1.854,0.849-3.604,2.122-4.858c1.687-1.661,3.663-3.038,5.576-4.454
        c0.871-0.645,1.889-0.524,2.85-0.038c0.57,0.289,1.149,0.56,1.724,0.839c3.252,1.582,6.433,0.731,9.308-0.776
        c2.657-1.393,5.206-3.165,7.419-5.188c3.485-3.183,6.414-6.914,8.396-11.247c0.753-1.646,1.24-3.468,1.527-5.262
        c0.452-2.82-0.552-5.168-2.894-6.907c-1.067-0.792-1.399-1.949-0.738-3.129c0.655-1.169,1.391-2.369,2.355-3.273
        c2.212-2.072,4.599-3.956,6.883-5.952c0.694-0.606,1.277-0.544,1.828,0.094c0.438,0.508,0.932,1.027,1.176,1.632
        c1.388,3.44,2.767,6.886,4.012,10.379C83.27,43.358,83.16,44.505,83.262,45.578z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TServiceIcon'
}
</script>
