<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
      <ContentTile
        :themeColor="computedTopicColor"
        title="Das Schulgebäude"
        :textArray="[
          'Die Stadt Ulm als Schulträger hat dankenswerterweise auf dem Gelände der Klinik für Kinder- und Jugendpsychiatrie/-psychotherapie ein neues Schulhaus für die Hans-Lebrecht-Schule gebaut, in das wir zu Beginn des Jahres 2017 eingezogen sind.'
        ]"
      />
      <ContentImageTile
        imageSource="/img/schulhaus/09.jpg"
      />
      <ContentTile
        :themeColor="computedTopicColor"
        title="Das Universitätsklinikum Ulm"
        :textArray="[
          'Wir unterrichten stationäre und teilstationäre Patient*innen am Universitätsklinikum Ulm in den Abteilungen Unterricht am Krankenbett und Kinder- und Jugendpsychiatrie/-psychotherapie. Sonderschul-, Grund- und Hauptschul-, Realschul- und Gymnasiallehrkräfte arbeiten bei uns in einem Team zusammen.'
        ]"
      />
      <ContentGalleryTile
        v-if="photos.length > 0"
        :themeColor="computedTopicColor"
        title="Impressionen"
        :photos="photos"
      />
  </ContentFrame>
</template>

<script>
import ContentTile from '@/views/components/ContentTile.vue'
import ContentImageTile from '@/views/components/ContentImageTile.vue'
import ContentGalleryTile from '@/views/components/ContentGalleryTile.vue'
import ContentFrame from '@/views/components/ContentFrame.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Building',
  components: {
    ContentTile,
    ContentImageTile,
    ContentGalleryTile,
    ContentFrame
  },
  data () {
    return {
      photos: [
        {
          src: '/img/schulhaus/01.jpg',
          description: 'Bewegungsraum. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/02.jpg',
          description: 'Flur. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/03.jpg',
          description: 'Klassenzimmer. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/04.jpg',
          description: 'Klassenzimmer. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/05.jpg',
          description: 'Kunstraum. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/06.jpg',
          description: 'Klassenzimmer. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/11.jpg',
          description: 'Lehrer*innenzimmer. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/07.jpg',
          description: 'Außenansicht des Schulgebäudes. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/08.jpg',
          description: 'Außenansicht des Schulgebäudes. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/10.jpg',
          description: 'Außenansicht des Schulgebäudes. (Foto: Armin Buhl)'
        },
        {
          src: '/img/schulhaus/12.jpg',
          description: 'Außenansicht des Schulgebäudes, mit Blick auf Projekt-Skulptur. (Foto: Armin Buhl)'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.building.color
    },
    computedTitle () {
      return this.menuData.building.title
    }
  },
  watch: { }
}
</script>
