<template>
  <v-col
    class="pa-0"
  >
    <v-row
      v-for="(item, i) in items"
      :key="i"
      class="my-0 my-sm-0 my-md-1 my-lg-2 my-xl-2 mx-0"
    >
      <v-col
        class="px-3 px-md-0"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-card
            :color="item.color"
            class="pointer py-2 py-sm-3 py-md-3 py-lg-3 py-xl-3"
            :elevation="hover ? 12 : 4"
            :class="{ 'on-hover': hover }"
            rounded="lg"
            @click="navigateTo(item.id)"
          >
            <v-card-text
              class="white--text"
            >
              <v-row>
                <v-col
                  cols="2"
                  align="center"
                >
                  <v-avatar
                    :size="computedAvatarSize"
                    color="#fff"
                    :class="hover ? 'elevation-12' : 'elevation-0'"
                  >
                    <v-icon
                      :size="computedAvatarSize - 15"
                      :color="item.color"
                    >
                      {{ getIcon(item.icon) }}
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="10"
                  align-self="center"
                  class="text-h6 text-sm-h5 text-md-h4 font-weight-bold"
                >
                  {{ item.title }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    items: Array
  },
  components: { },
  data () {
    return { }
  },
  mounted () {
  },
  methods: {
    navigateTo (routeName) {
      this.$router.push({ name: routeName })
    },
    getIcon (key) {
      return '$vuetify.icons.' + key
    }
  },
  computed: {
    computedAvatarSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return 90
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return 80
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return 70
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return 60
      } else {
        return 40
      }
    }
  },
  watch: {
  }
}
</script>

<style>
  .v-card, .v-card .v-avatar {
    transition: box-shadow .2s ease-in-out;
  }

  .slide-right-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-right-enter-active {
    transition: all .3s ease;
  }

  .slide-right-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  .slide-right-leave-active {
    transition: all 0.3s ease-in-out;
  }
</style>
