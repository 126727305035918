var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"light":"","color":_vm.embedded ? '#e0e0e0' : '#f0f0f0',"height":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",class:{
          'text-h6 text-sm-h5 text-md-h4': !_vm.embedded,
          'text-h6 text-md-h5': _vm.embedded
        },style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        })},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-row',_vm._l((_vm.photos),function(photo,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-col',{staticClass:"pic-hover pointer",class:{
              'elevation-12': hover,
              'elevation-3': !hover,
            },attrs:{"color":"#0000001A","rounded":""}},[_c('v-img',{attrs:{"cover":"","src":photo.src,"aspect-ratio":1.7778},on:{"click":function($event){_vm.clickedPic = i}}})],1)]}}],null,true)})],1)}),1)],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.showClickedPic),callback:function ($$v) {_vm.showClickedPic=$$v},expression:"showClickedPic"}},[_c('v-sheet',{staticClass:"d-flex",class:{ 'pa-4': !_vm.$vuetify.breakpoint.mdAndUp, 'pa-12': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"width":"100%","height":"100%","color":"rgba(0,0,0,0.8)"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"gallery-dialog-close-button",attrs:{"color":"red","fab":"","elevation":hover ? 12 : 3,"small":""},on:{"click":function($event){_vm.clickedPic = undefined}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}])}),_c('v-img',{attrs:{"src":_vm.clickedPic !== undefined ? _vm.photos[_vm.clickedPic].src : '',"max-width":"100%","max-height":"100%","contain":"","position":"center center"}},[_c('v-container',{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"gallery-dialog-navigaion"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","height":"36","width":"36","disabled":_vm.clickedPic === undefined || _vm.clickedPic === 0,"color":"#00000088","elevation":hover ? 12 : 3},on:{"click":function($event){_vm.clickedPic--}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1)]}}])})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","height":"36","width":"36","disabled":_vm.clickedPic === undefined || _vm.clickedPic === _vm.photos.length - 1,"color":"#00000088","elevation":hover ? 12 : 3},on:{"click":function($event){_vm.clickedPic++}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)]}}])})],1)],1),_c('v-row',{staticClass:"align-self-end"},[_c('v-col',{staticClass:"text-center",attrs:{"color":"red"}},[_c('span',{class:{
                  'text-body-2': _vm.$vuetify.breakpoint.xs
                },staticStyle:{"display":"inline-block","background-color":"#00000088","padding":"6px 12px 6px 12px"}},[_vm._v(" "+_vm._s(_vm.clickedPic !== undefined ? _vm.photos[_vm.clickedPic].description : '')+" ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }