<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M85.265,31.74c0.062,0,0.123,0,0.185,0c0.027-0.439,0.046-0.879,0.084-1.316
        c0.332-3.767,0.035-7.508-0.572-11.222c-0.094-0.571-0.608-1.289-1.115-1.509c-0.41-0.179-1.101,0.288-1.668,0.467
        c-0.588,0.187-1.188,0.562-1.763,0.529c-4.06-0.233-8.117-0.516-12.17-0.852c-4.486-0.372-8.96-0.95-13.452-1.193
        c-4.021-0.218-8.062-0.031-12.091-0.153c-4.346-0.131-8.692-0.352-13.028-0.67c-1.296-0.095-2.151,0.269-2.549,1.402
        c-0.278,0.791-0.381,1.692-0.342,2.535c0.2,4.388,0.609,8.771,0.676,13.159c0.066,4.346-0.33,8.699-0.271,13.045
        c0.08,5.793,0.413,11.584,0.617,17.376c0.034,0.955-0.037,1.914-0.053,2.871c-0.022,1.355-0.13,2.717-0.037,4.064
        c0.133,1.905,0.437,3.799,0.63,5.701c0.148,1.467,0.172,2.948,0.368,4.407c0.627,4.678,0.969,9.369,0.767,14.089
        c-0.087,2.037,0.514,2.678,2.528,2.672c3.514-0.011,7.028-0.009,10.541,0.036c2.113,0.027,4.226,0.223,6.336,0.193
        c1.427-0.02,2.847-0.373,4.277-0.447c3.582-0.187,7.174-0.202,10.75-0.463c6.369-0.465,12.689,0.344,19.032,0.562
        c0.617,0.021,1.247,0.276,1.831,0.52c1.573,0.658,2.14,0.326,2.112-1.407c-0.04-2.553-0.165-5.105-0.219-7.658
        c-0.102-4.832-0.178-9.663-0.266-14.495c-0.003-0.16-0.017-0.319-0.026-0.479c-0.37-6.45-0.907-12.896-1.068-19.353
        C85.121,46.684,85.265,39.21,85.265,31.74z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M85.265,31.74c0,7.471-0.144,14.944,0.044,22.41
        c0.161,6.456,0.698,12.902,1.068,19.353c0.01,0.16,0.023,0.319,0.026,0.479c0.088,4.832,0.164,9.663,0.266,14.495
        c0.054,2.553,0.179,5.105,0.219,7.658c0.027,1.733-0.539,2.065-2.112,1.407c-0.584-0.243-1.214-0.498-1.831-0.52
        c-6.343-0.218-12.663-1.026-19.032-0.562c-3.575,0.261-7.167,0.276-10.75,0.463c-1.43,0.074-2.85,0.428-4.277,0.447
        c-2.111,0.029-4.224-0.166-6.336-0.193c-3.513-0.045-7.027-0.047-10.541-0.036c-2.014,0.006-2.615-0.635-2.528-2.672
        c0.203-4.72-0.139-9.411-0.767-14.089c-0.195-1.459-0.219-2.94-0.368-4.407c-0.193-1.902-0.497-3.796-0.63-5.701
        c-0.094-1.348,0.014-2.709,0.037-4.064c0.016-0.957,0.087-1.916,0.053-2.871c-0.204-5.792-0.537-11.583-0.617-17.376
        c-0.06-4.346,0.336-8.699,0.271-13.045c-0.066-4.389-0.476-8.771-0.676-13.159c-0.039-0.843,0.064-1.745,0.342-2.535
        c0.398-1.134,1.253-1.498,2.549-1.402c4.336,0.319,8.682,0.539,13.028,0.67c4.029,0.122,8.07-0.065,12.091,0.153
        c4.492,0.243,8.966,0.821,13.452,1.193c4.053,0.336,8.11,0.619,12.17,0.852c0.575,0.033,1.175-0.342,1.763-0.529
        c0.567-0.18,1.258-0.646,1.668-0.467c0.507,0.221,1.021,0.938,1.115,1.509c0.607,3.713,0.904,7.455,0.572,11.222
        c-0.038,0.438-0.057,0.877-0.084,1.316C85.388,31.739,85.326,31.74,85.265,31.74z M77.395,37.793
        c0.005,0.109,0.01,0.218,0.015,0.327c-0.443,0.058-0.887,0.17-1.329,0.165c-3.713-0.048-7.426-0.18-11.138-0.161
        c-3.028,0.016-6.056,0.294-9.084,0.293c-3.388,0-6.776-0.264-10.165-0.269c-4.549-0.006-9.098,0.133-13.647,0.193
        c-0.773,0.01-1.115,0.247-1.146,1.113c-0.169,4.709-0.417,9.416-0.616,14.124c-0.045,1.067-0.007,2.139-0.007,3.21
        c1.725-0.119,3.344-0.281,4.967-0.331c3.912-0.121,7.825-0.188,11.737-0.282c0.318-0.008,0.636-0.059,0.954-0.093
        c4.643-0.509,9.276-1.124,13.931-1.501c5.684-0.461,11.384-0.723,17.078-1.072c1.418-0.087,2.836-0.179,4.275-0.27
        c0-1.439,0.036-2.793-0.007-4.145c-0.103-3.231-0.27-6.461-0.352-9.693c-0.023-0.956-0.523-1.104-1.293-1.106
        c-0.595-0.001-1.19-0.083-1.785-0.129c-0.012-0.144-0.022-0.287-0.034-0.43c0.957-0.306,1.914-0.612,2.907-0.93
        c0.015-0.139,0.049-0.294,0.044-0.449c-0.118-3.915-0.221-7.831-0.375-11.745c-0.048-1.232-0.272-2.457-0.346-3.688
        c-0.05-0.847-0.462-1.028-1.234-1.021c-2.637,0.025-5.274,0.025-7.91-0.038c-5.462-0.13-10.923-0.421-16.385-0.43
        c-4.349-0.007-8.698,0.342-13.049,0.458c-1.67,0.044-3.345-0.129-5.019-0.187c-1.915-0.066-3.829-0.121-5.744-0.169
        c-0.697-0.018-1.396-0.002-2.021-0.002c0.232,5.592,0.457,10.991,0.686,16.493c0.621-0.04,1.169-0.091,1.72-0.105
        c1.434-0.037,2.882-0.193,4.301-0.059c3.688,0.35,7.354,0.943,11.045,1.222c3.219,0.244,6.46,0.203,9.692,0.269
        c5.992,0.121,11.985,0.227,17.979,0.344C76.49,37.708,76.942,37.761,77.395,37.793z M30.894,93.765
        c0.026,0.111,0.052,0.224,0.079,0.336c3.353,0,6.711-0.124,10.056,0.04c2.536,0.124,5.048,0.824,7.582,0.898
        c4.859,0.142,9.726-0.007,14.589,0.019c3.039,0.017,6.077,0.106,9.114,0.215c1.235,0.044,2.519,0.04,3.688,0.377
        c2.533,0.729,5.104,0.899,7.705,0.954c0.935,0.019,1.373-0.289,1.332-1.32c-0.131-3.393-0.184-6.788-0.275-10.182
        c-0.073-2.714-0.177-5.428-0.227-8.142c-0.013-0.68-0.276-0.895-0.926-0.917c-1.833-0.063-3.665-0.252-5.498-0.265
        c-7.875-0.054-15.752-0.173-23.624-0.046c-4.987,0.079-9.968,0.549-14.951,0.845c-0.119,0.007-0.237,0.039-0.356,0.045
        c-2.346,0.126-4.693,0.343-7.039,0.346c-1.873,0.002-1.966-0.08-1.907,1.751c0.161,4.943,0.377,9.885,0.575,14.827
        C30.813,93.621,30.865,93.693,30.894,93.765z M30.266,57.776c-0.028,0.386-0.068,0.771-0.083,1.159
        c-0.101,2.555-0.231,5.108-0.282,7.665c-0.042,2.148-0.012,4.3,0.053,6.447c0.008,0.271,0.384,0.709,0.649,0.76
        c1.014,0.195,2.049,0.329,3.081,0.368c3.149,0.118,6.299,0.224,9.45,0.257c4.744,0.05,9.489,0.014,14.233,0.052
        c5.757,0.045,11.515,0.115,17.271,0.201c1.874,0.027,3.746,0.157,5.62,0.202c1.385,0.033,2.771,0.007,4.188,0.007
        c-0.32-6.513-0.627-12.742-0.94-19.109c-2.285,0-4.402-0.099-6.508,0.022c-3.464,0.199-6.916,0.581-10.379,0.805
        c-1.633,0.105-3.281-0.043-4.916,0.042c-4.705,0.244-9.403,0.641-14.109,0.803C41.855,57.655,36.111,57.676,30.266,57.776z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M77.395,37.793c-0.452-0.032-0.904-0.085-1.356-0.095
        c-5.993-0.118-11.986-0.223-17.979-0.344c-3.232-0.065-6.473-0.025-9.692-0.269c-3.691-0.279-7.357-0.873-11.045-1.222
        c-1.419-0.134-2.867,0.021-4.301,0.059c-0.55,0.014-1.099,0.066-1.72,0.105c-0.229-5.502-0.453-10.901-0.686-16.493
        c0.625,0,1.324-0.015,2.021,0.002c1.915,0.049,3.83,0.103,5.744,0.169c1.674,0.058,3.349,0.231,5.019,0.187
        c4.351-0.116,8.7-0.465,13.049-0.458c5.462,0.009,10.923,0.3,16.385,0.43c2.636,0.063,5.273,0.063,7.91,0.038
        c0.772-0.007,1.185,0.173,1.234,1.021c0.073,1.232,0.298,2.457,0.346,3.688c0.154,3.914,0.257,7.83,0.375,11.745
        c0.005,0.155-0.029,0.31-0.044,0.449c-0.993,0.317-1.95,0.624-2.907,0.93c0.012,0.143,0.022,0.287,0.034,0.43
        c0.595,0.046,1.19,0.128,1.785,0.129c0.77,0.002,1.27,0.15,1.293,1.106c0.082,3.231,0.249,6.461,0.352,9.693
        c0.043,1.352,0.007,2.706,0.007,4.145c-1.439,0.091-2.857,0.183-4.275,0.27c-5.694,0.349-11.395,0.611-17.078,1.072
        c-4.654,0.377-9.288,0.992-13.931,1.501c-0.317,0.035-0.635,0.085-0.954,0.093c-3.913,0.094-7.826,0.161-11.737,0.282
        c-1.623,0.05-3.242,0.212-4.967,0.331c0-1.071-0.039-2.143,0.007-3.21c0.199-4.708,0.447-9.415,0.616-14.124
        c0.031-0.867,0.373-1.103,1.146-1.113c4.549-0.06,9.098-0.199,13.647-0.193c3.389,0.005,6.777,0.268,10.165,0.269
        c3.028,0,6.056-0.278,9.084-0.293c3.712-0.02,7.425,0.113,11.138,0.161c0.442,0.006,0.886-0.107,1.329-0.165
        C77.404,38.011,77.399,37.902,77.395,37.793z M50.729,46.603c0.165-0.403,0.22-0.59,0.313-0.756
        c0.427-0.757,0.488-1.507-0.335-1.983c-0.862-0.5-1.516-0.047-2.021,0.694c-1.463,2.144-0.799,4.024,1.741,4.616
        c4.214,0.982,8.158-0.208,11.965-1.834c1.513-0.646,2.106-2.437,1.636-3.744c-0.345-0.958-1.304-1.589-2.137-1.341
        c-1.017,0.302-1.167,1.144-1.057,2.023c0.083,0.656,0.322,1.292,0.533,2.092C57.849,47.466,54.385,46.765,50.729,46.603z
        M48.892,29.283c0.43-0.524,0.659-0.894,0.973-1.166c0.636-0.555,0.818-1.201,0.391-1.914c-0.459-0.767-1.18-1.058-2.059-0.779
        c-1.456,0.462-2.232,2.372-1.536,3.724c0.638,1.238,1.719,1.735,3.039,1.776c1.791,0.056,3.584,0.112,5.375,0.091
        c2.509-0.029,5.018-0.116,7.525-0.205c0.907-0.032,1.791-0.21,2.102-1.243c0.354-1.178,0.318-2.392-0.645-3.214
        c-0.477-0.406-1.463-0.647-1.992-0.425c-0.432,0.182-0.664,1.15-0.728,1.795c-0.054,0.548,0.26,1.133,0.429,1.768
        C57.501,30.247,53.36,29.783,48.892,29.283z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M30.894,93.765c-0.029-0.072-0.08-0.145-0.083-0.219
        c-0.198-4.942-0.414-9.884-0.575-14.827c-0.06-1.831,0.034-1.749,1.907-1.751c2.346-0.003,4.693-0.22,7.039-0.346
        c0.119-0.006,0.237-0.038,0.356-0.045c4.983-0.296,9.964-0.766,14.951-0.845c7.872-0.127,15.749-0.008,23.624,0.046
        c1.833,0.013,3.665,0.201,5.498,0.265c0.649,0.022,0.913,0.237,0.926,0.917c0.05,2.714,0.153,5.428,0.227,8.142
        c0.092,3.394,0.145,6.789,0.275,10.182c0.041,1.031-0.397,1.339-1.332,1.32c-2.602-0.055-5.172-0.226-7.705-0.954
        c-1.17-0.337-2.453-0.333-3.688-0.377c-3.037-0.108-6.075-0.198-9.114-0.215c-4.863-0.025-9.73,0.123-14.589-0.019
        c-2.534-0.074-5.046-0.774-7.582-0.898c-3.345-0.164-6.704-0.04-10.056-0.04C30.946,93.989,30.919,93.876,30.894,93.765z
        M51.569,84.423c0.077-0.322,0.096-0.481,0.151-0.626c0.299-0.781,0.378-1.557-0.547-1.916c-0.923-0.357-1.51,0.208-1.911,0.99
        c-0.955,1.861-0.111,3.768,2.018,4.418c4.087,1.249,8.144,0.617,12.144-0.413c1.621-0.417,2.457-3.333,1.498-4.662
        c-0.487-0.674-1.152-1.051-1.971-0.757c-0.849,0.307-1.024,1.043-0.901,1.868c0.063,0.424,0.144,0.844,0.226,1.323
        C58.655,85.082,55.156,85.605,51.569,84.423z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M30.266,57.776c5.846-0.1,11.59-0.121,17.328-0.319
        c4.707-0.162,9.405-0.559,14.109-0.803c1.635-0.084,3.283,0.064,4.916-0.042c3.463-0.224,6.915-0.605,10.379-0.805
        c2.105-0.121,4.223-0.022,6.508-0.022c0.313,6.367,0.62,12.596,0.94,19.109c-1.417,0-2.803,0.026-4.188-0.007
        c-1.874-0.045-3.746-0.175-5.62-0.202c-5.757-0.086-11.515-0.156-17.271-0.201c-4.745-0.038-9.489-0.002-14.233-0.052
        c-3.15-0.033-6.301-0.139-9.45-0.257c-1.032-0.039-2.067-0.173-3.081-0.368c-0.265-0.051-0.642-0.489-0.649-0.76
        c-0.064-2.147-0.095-4.299-0.053-6.447c0.05-2.557,0.181-5.11,0.282-7.665C30.198,58.547,30.237,58.162,30.266,57.776z
        M50.672,66.037c0.195-0.551,0.303-0.885,0.432-1.211c0.314-0.792,0.14-1.459-0.638-1.808c-0.755-0.339-1.393-0.062-1.85,0.639
        c-1.495,2.29-0.545,4.562,2.147,5.037c0.86,0.151,1.748,0.141,2.621,0.228c2.386,0.24,4.713,0.003,6.921-0.958
        c1.299-0.565,2.477-1.324,3.048-2.725c0.442-1.085-0.014-2.225-1.015-2.576c-0.933-0.328-1.987,0.158-2.163,1.309
        c-0.29,1.907-1.619,2.277-3.097,2.304C55.016,66.311,52.95,66.132,50.672,66.037z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M50.729,46.603c3.656,0.162,7.12,0.863,10.639-0.233
        c-0.211-0.8-0.45-1.436-0.533-2.092c-0.11-0.879,0.04-1.721,1.057-2.023c0.833-0.248,1.792,0.382,2.137,1.341
        c0.471,1.308-0.123,3.099-1.636,3.744c-3.808,1.625-7.751,2.816-11.965,1.834c-2.54-0.592-3.205-2.472-1.741-4.616
        c0.506-0.741,1.16-1.194,2.021-0.694c0.823,0.477,0.762,1.226,0.335,1.983C50.949,46.013,50.894,46.2,50.729,46.603z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M48.892,29.283c4.468,0.5,8.609,0.963,12.875,0.21
        c-0.169-0.635-0.482-1.22-0.429-1.768c0.063-0.645,0.296-1.613,0.728-1.795c0.529-0.223,1.516,0.019,1.992,0.425
        c0.963,0.822,0.999,2.036,0.645,3.214c-0.311,1.033-1.194,1.211-2.102,1.243c-2.508,0.089-5.017,0.176-7.525,0.205
        c-1.792,0.021-3.584-0.035-5.375-0.091c-1.32-0.041-2.401-0.538-3.039-1.776c-0.696-1.352,0.081-3.261,1.536-3.724
        c0.878-0.279,1.6,0.012,2.059,0.779c0.427,0.713,0.245,1.359-0.391,1.914C49.551,28.39,49.322,28.759,48.892,29.283z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M51.569,84.423c3.587,1.182,7.086,0.658,10.707,0.226
        c-0.082-0.479-0.162-0.899-0.226-1.323c-0.123-0.825,0.053-1.562,0.901-1.868c0.818-0.294,1.483,0.083,1.971,0.757
        c0.959,1.329,0.123,4.245-1.498,4.662c-4,1.03-8.057,1.662-12.144,0.413c-2.128-0.65-2.972-2.557-2.018-4.418
        c0.401-0.782,0.987-1.348,1.911-0.99c0.925,0.359,0.847,1.135,0.547,1.916C51.665,83.942,51.646,84.101,51.569,84.423z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M50.672,66.037c2.278,0.096,4.344,0.274,6.405,0.238
        c1.478-0.026,2.807-0.396,3.097-2.304c0.176-1.15,1.23-1.637,2.163-1.309c1.001,0.352,1.457,1.491,1.015,2.576
        c-0.571,1.4-1.749,2.159-3.048,2.725c-2.208,0.961-4.535,1.198-6.921,0.958c-0.873-0.087-1.761-0.076-2.621-0.228
        c-2.692-0.475-3.642-2.747-2.147-5.037c0.457-0.701,1.095-0.978,1.85-0.639c0.778,0.349,0.952,1.016,0.638,1.808
        C50.975,65.152,50.867,65.486,50.672,66.037z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AbteilungenIcon'
}
</script>
