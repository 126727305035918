import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import Imprint from '../views/Imprint.vue'
import Privacy from '../views/Privacy.vue'
import Error404 from '../views/Error404.vue'

import School from '../views/School.vue'
import Staff from '../views/Staff.vue'
import Building from '../views/Building.vue'
import Departments from '../views/Departments.vue'
import History from '../views/History.vue'

import Parents from '../views/Parents.vue'
import Values from '../views/Values.vue'
import Projects from '../views/Projects.vue'
import FAQ from '../views/FAQ.vue'
import Service from '../views/Service.vue'

import Teachers from '../views/Teachers.vue'
import Goals from '../views/Goals.vue'
import TFAQ from '../views/TFAQ.vue'
import TService from '../views/TService.vue'

import Support from '../views/Support.vue'
import KlinikSchulVerein from '../views/KlinikSchulVerein.vue'
import Dazugehoeren from '../views/Dazugehoeren.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isErrorPage: false,
      depth: 0,
      pathData: []
    }
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'contact',
          pathSegment: 'kontakt'
        }
      ]
    }
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: Imprint,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'imprint',
          pathSegment: 'impressum'
        }
      ]
    }
  },
  {
    path: '/datenschutz',
    name: 'Privacy',
    component: Privacy,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'privacy',
          pathSegment: 'datenschutz'
        }
      ]
    }
  },
  {
    path: '/schule',
    name: 'School',
    component: School,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'school',
          pathSegment: 'schule'
        }
      ]
    }
  },
  {
    path: '/schule/schulgemeinschaft',
    name: 'Staff',
    component: Staff,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'school',
          pathSegment: 'schule'
        },
        {
          id: 'staff',
          pathSegment: 'schulgemeinschaft'
        }
      ]
    }
  },
  {
    path: '/schule/schulhaus',
    name: 'Building',
    component: Building,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'school',
          pathSegment: 'schule'
        },
        {
          id: 'building',
          pathSegment: 'schulhaus'
        }
      ]
    }
  },
  {
    path: '/schule/abteilungen',
    name: 'Departments',
    component: Departments,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'school',
          pathSegment: 'schule'
        },
        {
          id: 'departments',
          pathSegment: 'abteilungen'
        }
      ]
    }
  },
  {
    path: '/schule/historisches',
    name: 'History',
    component: History,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'school',
          pathSegment: 'schule'
        },
        {
          id: 'history',
          pathSegment: 'historisches'
        }
      ]
    }
  },
  {
    path: '/schuelerInnen-eltern',
    name: 'Parents',
    component: Parents,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'parents',
          pathSegment: 'schuelerInnen-eltern'
        }
      ]
    }
  },
  {
    path: '/schuelerInnen-eltern/werte',
    name: 'Values',
    component: Values,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'parents',
          pathSegment: 'schuelerInnen-eltern'
        },
        {
          id: 'values',
          pathSegment: 'werte'
        }
      ]
    }
  },
  {
    path: '/schuelerInnen-eltern/projekte',
    name: 'Projects',
    component: Projects,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'parents',
          pathSegment: 'schuelerInnen-eltern'
        },
        {
          id: 'projects',
          pathSegment: 'projekte'
        }
      ]
    }
  },
  {
    path: '/schuelerInnen-eltern/fragen-antworten',
    name: 'FAQ',
    component: FAQ,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'parents',
          pathSegment: 'schuelerInnen-eltern'
        },
        {
          id: 'faq',
          pathSegment: 'fragen-antworten'
        }
      ]
    }
  },
  {
    path: '/schuelerInnen-eltern/service',
    name: 'Service',
    component: Service,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'parents',
          pathSegment: 'schuelerInnen-eltern'
        },
        {
          id: 'service',
          pathSegment: 'service'
        }
      ]
    }
  },
  {
    path: '/lehrkraefte',
    name: 'Teachers',
    component: Teachers,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'teachers',
          pathSegment: 'lehrkraefte'
        }
      ]
    }
  },
  {
    path: '/lehrkraefte/ziele',
    name: 'Goals',
    component: Goals,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'teachers',
          pathSegment: 'lehrkraefte'
        },
        {
          id: 'goals',
          pathSegment: 'ziele'
        }
      ]
    }
  },
  {
    path: '/lehrkraefte/fragen-antworten',
    name: 'TFAQ',
    component: TFAQ,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'teachers',
          pathSegment: 'lehrkraefte'
        },
        {
          id: 'tFaq',
          pathSegment: 'fragen-antworten'
        }
      ]
    }
  },
  {
    path: '/lehrkraefte/service',
    name: 'TService',
    component: TService,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'teachers',
          pathSegment: 'lehrkraefte'
        },
        {
          id: 'tService',
          pathSegment: 'service'
        }
      ]
    }
  },
  {
    path: '/mithelfen',
    name: 'Support',
    component: Support,
    meta: {
      isErrorPage: false,
      depth: 1,
      pathData: [
        {
          id: 'support',
          pathSegment: 'mithelfen'
        }
      ]
    }
  },
  {
    path: '/mithelfen/klinikschulverein',
    name: 'KlinikSchulVerein',
    component: KlinikSchulVerein,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'support',
          pathSegment: 'mithelfen'
        },
        {
          id: 'klinikschulverein',
          pathSegment: 'klinikschulverein'
        }
      ]
    }
  },
  {
    path: '/mithelfen/dazugehoeren',
    name: 'Dazugehoeren',
    component: Dazugehoeren,
    meta: {
      isErrorPage: false,
      depth: 2,
      pathData: [
        {
          id: 'support',
          pathSegment: 'mithelfen'
        },
        {
          id: 'dazugehoeren',
          pathSegment: 'dazugehoeren'
        }
      ]
    }
  },
  {
    path: '/*',
    name: 'Error404',
    component: Error404,
    meta: {
      isErrorPage: true,
      depth: 10,
      pathData: []
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
