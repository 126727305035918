<template>
  <v-container>
    <Navigation
      :items="items"
    />
  </v-container>
</template>

<script>
import Navigation from '@/views/components/Navigation.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Support',
  components: {
    Navigation
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
    this.items = this.menuData.support.elements.map((elementId) => { return this.menuData[elementId] })
      .sort(function (a, b) {
        if (a.position < b.position) { return -1 }
        if (a.position > b.position) { return 1 }
        return 0
      })
  },
  computed: {
    ...mapGetters([
      'menuData'
    ])
  }
}
</script>

<style>
  .v-card, .v-card .v-avatar {
    transition: box-shadow .2s ease-in-out;
  }
</style>
