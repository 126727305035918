<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="15 15 83.28 83.28" enable-background="new 15 15 83.28 83.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M54.025,81.468c0.48-0.432,0.811-0.715,1.126-1.014c2.664-2.525,2.462-6.237-0.659-8.16
        c-2.857-1.76-5.97-1.771-8.898-0.093c-3.523,2.018-4.387,6.105-2.013,9.38c0.833,1.148,1.829,2.286,2.993,3.069
        c6.279,4.22,13.041,4.492,19.768,1.486c6.922-3.094,8.85-11.012,4.558-17.274c-0.76-1.107-1.614-2.15-2.452-3.261
        c1.329-0.525,2.568-1.093,3.855-1.511c3.093-1.007,5.657-5.349,5.486-8.197c-0.352-5.858-2.838-10.54-7.794-13.722
        c-3.159-2.028-6.503-3.768-9.725-5.701c-1.953-1.172-3.864-2.42-5.733-3.72c-1.637-1.139-2.314-3.571-1.21-4.599
        c1.804-1.681,4.01-2.479,6.693-2.289c-0.221,0.343-0.33,0.543-0.468,0.723c-2.016,2.628-1.746,6.151,2.24,7.576
        c2.569,0.918,4.386,0.807,6.564-1.403c1.753-1.777,2.222-4.636,1.224-6.786c-1.053-2.268-2.877-3.702-5.177-4.333
        c-6.303-1.729-12.249-0.637-17.562,3.078c-2.991,2.091-3.781,5.391-2.618,8.877c0.879,2.634,2.452,4.83,4.553,6.649
        c0.728,0.63,1.439,1.281,2.355,2.099c-0.81,0.191-1.272,0.293-1.73,0.409c-2.636,0.668-5.244,1.357-7.548,2.94
        c-4.066,2.794-5.357,6.554-3.576,10.917c1.373,3.363,3.661,5.994,6.993,7.402c3.301,1.396,6.771,2.392,10.162,3.575
        c2.757,0.963,5.532,1.884,7.703,3.992c2.135,2.074,2.714,4.941,1.357,6.93c-1.729,2.533-6.48,4.232-9.429,3.364
        C54.765,81.787,54.482,81.649,54.025,81.468z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M54.025,81.468c0.457,0.181,0.74,0.318,1.038,0.406
        c2.949,0.868,7.701-0.831,9.429-3.364c1.356-1.988,0.777-4.855-1.357-6.93c-2.171-2.108-4.946-3.029-7.703-3.992
        c-3.392-1.184-6.861-2.18-10.162-3.575c-3.332-1.408-5.62-4.039-6.993-7.402c-1.782-4.363-0.49-8.123,3.576-10.917
        c2.304-1.583,4.912-2.272,7.548-2.94c0.458-0.116,0.921-0.218,1.73-0.409c-0.916-0.818-1.627-1.469-2.355-2.099
        c-2.101-1.819-3.674-4.015-4.553-6.649c-1.164-3.486-0.373-6.786,2.618-8.877c5.313-3.715,11.26-4.807,17.562-3.078
        c2.3,0.63,4.124,2.065,5.177,4.333c0.998,2.15,0.529,5.009-1.224,6.786c-2.179,2.21-3.995,2.321-6.564,1.403
        c-3.986-1.425-4.256-4.948-2.24-7.576c0.138-0.18,0.247-0.38,0.468-0.723c-2.683-0.19-4.889,0.608-6.693,2.289
        c-1.104,1.028-0.427,3.46,1.21,4.599c1.87,1.3,3.78,2.548,5.733,3.72c3.222,1.933,6.565,3.672,9.725,5.701
        c4.956,3.182,7.442,7.864,7.794,13.722c0.171,2.848-2.394,7.19-5.486,8.197c-1.287,0.418-2.526,0.985-3.855,1.511
        c0.838,1.11,1.692,2.153,2.452,3.261c4.292,6.263,2.364,14.181-4.558,17.274c-6.728,3.006-13.489,2.733-19.768-1.486
        c-1.164-0.783-2.16-1.921-2.993-3.069c-2.374-3.274-1.51-7.362,2.013-9.38c2.929-1.678,6.042-1.667,8.898,0.093
        c3.121,1.923,3.322,5.635,0.659,8.16C54.835,80.753,54.505,81.037,54.025,81.468z M54.16,42.145
        c-0.403,0.172-0.761,0.311-1.107,0.475c-2.778,1.314-5.674,2.432-8.301,3.998c-4.186,2.495-5.379,5.993-3.042,10.24
        c1.73,3.144,4.505,4.933,7.624,6.276s6.332,2.462,9.487,3.722c2.207,0.883,4.315,1.993,5.878,3.829
        c2.778,3.266,3.046,6.487,0.725,9.216c-1.135,1.334-2.562,2.229-4.217,2.858c-3.357,1.278-6.599,0.901-9.783-0.499
        c-0.423-0.187-0.916-0.784-0.918-1.193c-0.002-0.411,0.505-0.904,0.901-1.222c0.704-0.564,1.604-0.915,2.222-1.55
        c1.128-1.157,0.944-2.743-0.281-3.789c-1.463-1.247-4.694-1.485-6.489-0.479c-2.336,1.312-2.991,3.921-1.531,6.154
        c1.005,1.537,2.328,2.716,3.965,3.569c4.848,2.529,9.839,2.581,14.875,0.756c5-1.812,7.469-7.45,5.383-12.145
        c-0.991-2.23-2.38-4.156-4.311-5.67c-0.636-0.498-1.281-0.984-2.104-1.616c3.394-0.319,6.188-1.526,8.572-3.472
        c2.315-1.889,3.593-4.343,2.963-7.5c-0.898-4.502-3.183-7.98-7.08-10.524c-4.648-3.035-9.189-6.242-13.687-9.501
        c-0.946-0.686-1.678-1.833-2.186-2.919c-0.854-1.826-0.072-3.723,1.569-4.889c2.85-2.023,5.971-2.352,9.261-1.596
        c0.482,0.111,1.134,0.672,1.198,1.101c0.074,0.49-0.307,1.176-0.695,1.58c-0.685,0.711-1.604,1.2-2.28,1.917
        c-0.807,0.855-0.772,2.396-0.087,3.085c1.619,1.625,4.852,1.554,6.398-0.143c1.828-2.006,1.621-5.377-0.521-7.046
        c-1.944-1.517-4.144-2.29-6.678-2.221c-3.769,0.103-7.402,0.606-10.719,2.56c-2.914,1.716-4.02,4.471-2.92,7.665
        c0.347,1.008,0.865,1.98,1.437,2.884C49.294,38.607,51.747,40.292,54.16,42.145z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M54.16,42.145c-2.413-1.854-4.866-3.538-6.478-6.087
        c-0.572-0.904-1.09-1.876-1.437-2.884c-1.1-3.194,0.006-5.948,2.92-7.665c3.317-1.954,6.951-2.458,10.719-2.56
        c2.534-0.069,4.733,0.704,6.678,2.221c2.142,1.669,2.349,5.041,0.521,7.046c-1.547,1.696-4.779,1.768-6.398,0.143
        c-0.686-0.689-0.72-2.23,0.087-3.085c0.677-0.717,1.596-1.206,2.28-1.917c0.389-0.404,0.77-1.09,0.695-1.58
        c-0.064-0.429-0.716-0.99-1.198-1.101c-3.29-0.756-6.411-0.428-9.261,1.596c-1.642,1.166-2.423,3.062-1.569,4.889
        c0.508,1.086,1.24,2.233,2.186,2.919c4.497,3.259,9.038,6.466,13.687,9.501c3.897,2.544,6.182,6.022,7.08,10.524
        c0.63,3.157-0.647,5.611-2.963,7.5c-2.385,1.945-5.179,3.152-8.572,3.472c0.822,0.632,1.468,1.118,2.104,1.616
        c1.931,1.514,3.319,3.439,4.311,5.67c2.086,4.694-0.383,10.332-5.383,12.145c-5.035,1.825-10.026,1.773-14.875-0.756
        c-1.637-0.854-2.96-2.032-3.965-3.569c-1.46-2.233-0.806-4.843,1.531-6.154c1.795-1.007,5.026-0.769,6.489,0.479
        c1.226,1.046,1.409,2.632,0.281,3.789c-0.618,0.635-1.518,0.985-2.222,1.55c-0.396,0.317-0.904,0.811-0.901,1.222
        c0.002,0.409,0.496,1.007,0.918,1.193c3.184,1.4,6.425,1.777,9.783,0.499c1.655-0.63,3.082-1.524,4.217-2.858
        c2.321-2.729,2.054-5.95-0.725-9.216c-1.562-1.836-3.671-2.946-5.878-3.829c-3.155-1.26-6.369-2.379-9.487-3.722
        s-5.893-3.132-7.624-6.276c-2.337-4.247-1.144-7.745,3.042-10.24c2.627-1.566,5.523-2.684,8.301-3.998
        C53.399,42.456,53.757,42.317,54.16,42.145z M68.716,55.88c-0.02-2.352-1.244-4.162-2.718-5.823
        c-2.236-2.522-5.12-4.173-8.022-5.782c-0.264-0.146-0.612-0.263-0.897-0.224c-2.755,0.375-5.407,1.081-7.705,2.734
        c-1.512,1.087-2.25,2.544-1.958,4.453c0.417,2.731,2.119,4.706,4.038,6.434c2.635,2.373,5.86,3.55,9.324,4.331
        C65.236,63.009,68.707,60.64,68.716,55.88z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M68.716,55.88c-0.009,4.76-3.479,7.129-7.938,6.123
        c-3.464-0.781-6.689-1.958-9.324-4.331c-1.919-1.729-3.622-3.703-4.038-6.434c-0.292-1.908,0.446-3.366,1.958-4.453
        c2.298-1.653,4.95-2.359,7.705-2.734c0.285-0.039,0.634,0.078,0.897,0.224c2.902,1.609,5.786,3.26,8.022,5.782
        C67.472,51.718,68.696,53.528,68.716,55.88z M63.1,60.361c0.946-0.415,2.244-0.591,2.841-1.331c1.14-1.413,1.436-3.193,0.521-4.954
        c-2.074-3.994-5.838-6.014-9.524-8.119c-0.161-0.092-0.404-0.144-0.578-0.096c-1.914,0.521-3.915,0.856-5.707,1.657
        c-2.084,0.931-2.481,2.702-1.338,4.709c1.126,1.979,2.652,3.609,4.684,4.694c1.41,0.752,2.81,1.559,4.296,2.125
        C59.768,59.605,61.344,59.893,63.1,60.361z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M63.1,60.361c-1.756-0.468-3.332-0.756-4.805-1.315
        c-1.486-0.565-2.886-1.372-4.296-2.125c-2.032-1.084-3.558-2.715-4.684-4.694c-1.143-2.007-0.746-3.778,1.338-4.709
        c1.792-0.801,3.793-1.137,5.707-1.657c0.173-0.047,0.417,0.004,0.578,0.096c3.686,2.105,7.45,4.125,9.524,8.119
        c0.914,1.761,0.618,3.541-0.521,4.954C65.344,59.77,64.046,59.946,63.1,60.361z M55.119,50.856
        c0.081,0.847,0.473,1.448,1.402,1.452c0.848,0.003,1.205-0.605,1.223-1.342c0.021-0.807-0.407-1.414-1.262-1.458
        C55.622,49.464,55.18,49.99,55.119,50.856z M60.576,50.647c-0.191-0.002-0.384-0.004-0.576-0.007
        c-0.295,0.462-0.848,0.946-0.811,1.379c0.036,0.417,0.625,1.007,1.071,1.118c0.693,0.172,1.285-0.342,1.169-1.085
        C61.352,51.552,60.875,51.114,60.576,50.647z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M55.119,50.856c0.061-0.867,0.503-1.393,1.363-1.348
        c0.854,0.044,1.282,0.651,1.262,1.458c-0.018,0.737-0.375,1.345-1.223,1.342C55.592,52.305,55.2,51.703,55.119,50.856z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M60.576,50.647c0.299,0.466,0.775,0.904,0.854,1.405
        c0.116,0.743-0.476,1.258-1.169,1.085c-0.446-0.111-1.035-0.701-1.071-1.118c-0.037-0.433,0.516-0.917,0.811-1.379
        C60.192,50.643,60.385,50.646,60.576,50.647z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ImpressumIcon'
}
</script>
