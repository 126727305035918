<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="10 10 93.28 93.28" enable-background="new 10 10 93.28 93.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M57.189,41.133c-1.039-1.714-2.002-3.364-3.024-4.977c-2.295-3.625-5.056-6.628-9.444-7.811
        c-2.845-0.767-5.602-0.816-8.321,0.21c-1.052,0.397-2.159,1.021-2.88,1.854c-3.119,3.601-4.074,7.943-3.918,12.595
        c0.058,1.717,0.095,3.438,0.261,5.146c0.191,1.972,0.319,3.982,0.82,5.885c0.942,3.578,2.063,7.115,3.241,10.626
        c1.104,3.292,2.361,6.534,3.584,9.787c0.546,1.452,1.084,2.919,1.788,4.296c0.613,1.198,1.403,2.317,2.208,3.402
        c0.731,0.985,1.449,2.069,2.418,2.768c1.528,1.101,3.327,1.708,5.178,0.71c2.242-1.208,4.587-2.312,6.596-3.843
        c3.044-2.321,5.95-4.854,8.699-7.521c2.139-2.075,4.008-4.442,5.89-6.767c2.604-3.217,5.255-6.413,7.618-9.805
        c2.889-4.147,5.014-8.731,6.377-13.605c0.579-2.07,0.482-4.25-0.946-6.067c-3.263-4.146-9.717-6.554-15.155-3.909
        C64.236,36.025,60.676,38.481,57.189,41.133z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M57.189,41.133c3.486-2.652,7.047-5.107,10.989-7.024
        c5.438-2.645,11.893-0.237,15.155,3.909c1.429,1.817,1.525,3.997,0.946,6.067c-1.363,4.874-3.488,9.458-6.377,13.605
        c-2.363,3.392-5.014,6.588-7.618,9.805c-1.882,2.324-3.751,4.691-5.89,6.767c-2.749,2.666-5.655,5.199-8.699,7.521
        c-2.009,1.531-4.354,2.635-6.596,3.843c-1.851,0.998-3.65,0.391-5.178-0.71c-0.97-0.698-1.688-1.782-2.418-2.768
        c-0.805-1.085-1.595-2.204-2.208-3.402c-0.704-1.377-1.242-2.844-1.788-4.296c-1.223-3.253-2.479-6.495-3.584-9.787
        c-1.177-3.511-2.298-7.048-3.241-10.626c-0.501-1.903-0.629-3.913-0.82-5.885c-0.166-1.708-0.204-3.43-0.261-5.146
        c-0.156-4.651,0.8-8.994,3.918-12.595c0.721-0.833,1.828-1.458,2.88-1.854c2.72-1.027,5.476-0.977,8.321-0.21
        c4.388,1.183,7.148,4.187,9.444,7.811C55.188,37.769,56.15,39.418,57.189,41.133z M31.615,48.835
        c0.135,1.105,0.177,3.084,0.65,4.954c0.898,3.549,1.851,7.108,3.129,10.533c2.117,5.671,4.625,11.185,7.919,16.296
        c0.535,0.83,1.15,1.627,1.833,2.34c0.678,0.708,1.487,1.002,2.444,0.329c1.889-1.328,3.98-2.418,5.689-3.941
        c3.365-2.998,6.594-6.158,9.74-9.388c1.494-1.534,2.653-3.391,3.986-5.084c2.486-3.161,5.147-6.198,7.442-9.491
        c2.587-3.713,4.449-7.845,5.79-12.182c0.447-1.444,0.321-2.779-0.912-3.941c-2.874-2.706-6.159-3.532-10.007-2.706
        c-4.046,0.868-7.269,3.176-10.415,5.637c-0.519,0.405-0.821,1.124-1.135,1.743c-0.465,0.919-1.23,1.472-2.119,0.999
        c-0.858-0.457-1.656-1.218-2.203-2.032c-1.593-2.374-2.943-4.915-4.582-7.254c-2.381-3.4-7.402-4.924-11.261-3.58
        c-0.479,0.167-0.947,0.433-1.348,0.746c-2.867,2.238-3.534,5.615-4.257,8.844C31.545,43.689,31.746,45.871,31.615,48.835z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M31.615,48.835c0.131-2.965-0.07-5.146,0.386-7.181
        c0.723-3.229,1.39-6.606,4.257-8.844c0.4-0.313,0.869-0.58,1.348-0.746c3.859-1.344,8.879,0.18,11.261,3.58
        c1.639,2.34,2.988,4.88,4.582,7.254c0.547,0.814,1.345,1.575,2.203,2.032c0.889,0.473,1.654-0.08,2.119-0.999
        c0.313-0.619,0.616-1.337,1.135-1.743c3.146-2.461,6.369-4.769,10.415-5.637c3.848-0.826,7.133,0,10.007,2.706
        c1.233,1.162,1.359,2.497,0.912,3.941c-1.341,4.336-3.203,8.468-5.79,12.182c-2.295,3.293-4.956,6.33-7.442,9.491
        c-1.333,1.693-2.492,3.55-3.986,5.084c-3.146,3.229-6.375,6.39-9.74,9.388c-1.709,1.523-3.801,2.613-5.689,3.941
        c-0.957,0.673-1.766,0.379-2.444-0.329c-0.683-0.713-1.298-1.51-1.833-2.34c-3.294-5.111-5.803-10.625-7.919-16.296
        c-1.279-3.425-2.231-6.983-3.129-10.533C31.792,51.919,31.75,49.94,31.615,48.835z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WerteIcon'
}
</script>
