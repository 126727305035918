<template>
  <svg version="1.1" id="abteilungen_xA0_Bild_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="113.28px" height="113.28px" viewBox="0 0 113.28 113.28" enable-background="new 0 0 113.28 113.28"
    xml:space="preserve">
    <g>
      <path fill="transparent" d="M17.333,60.951c-0.091,0.006-0.181,0.012-0.272,0.018c0.162,3.951,0.267,7.906,0.501,11.854
        c0.22,3.706,0.501,7.413,0.891,11.104c0.449,4.246,1.05,8.477,1.578,12.714c0.139,1.114,0.797,1.825,1.961,1.904
        c2.988,0.206,5.98,0.516,8.97,0.504c5.111-0.02,10.222-0.247,15.332-0.397c10.543-0.311,21.087-0.593,31.627-0.962
        c3.387-0.118,6.764-0.498,10.148-0.709c1.988-0.124,4.009-0.379,5.969-0.163c2.194,0.242,3.057-0.255,2.946-2.427
        c-0.212-4.149-0.484-8.296-0.657-12.446c-0.176-4.232-0.072-8.485-0.436-12.7c-0.534-6.2-1.379-12.375-2.069-18.562
        c-0.034-0.303,0.053-0.704,0.239-0.932c0.976-1.19,1.015-1.939-0.097-2.989c-3.139-2.966-6.213-6.012-9.5-8.805
        c-3.314-2.816-6.964-5.239-10.271-8.063c-5.348-4.568-10.544-9.313-15.784-14.006c-1.59-1.424-2.63-1.44-4.264,0.005
        c-1.853,1.639-3.684,3.311-5.621,4.845c-2.85,2.257-5.925,4.246-8.655,6.634C34.484,32.079,29.286,37,23.977,41.797
        c-2.18,1.97-4.485,3.801-6.643,5.793c-0.448,0.414-0.722,1.271-0.694,1.908C16.81,53.318,17.09,57.133,17.333,60.951z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M17.333,60.951c-0.243-3.818-0.523-7.633-0.693-11.453
        c-0.028-0.636,0.246-1.494,0.694-1.908c2.158-1.992,4.463-3.823,6.643-5.793C29.286,37,34.484,32.079,39.869,27.371
        c2.73-2.388,5.806-4.376,8.655-6.634c1.938-1.535,3.768-3.206,5.621-4.845c1.634-1.445,2.673-1.429,4.264-0.005
        c5.24,4.693,10.437,9.438,15.784,14.006c3.308,2.825,6.957,5.247,10.271,8.063c3.287,2.793,6.361,5.839,9.5,8.805
        c1.111,1.05,1.072,1.799,0.097,2.989c-0.187,0.228-0.273,0.629-0.239,0.932c0.69,6.188,1.535,12.362,2.069,18.562
        c0.363,4.215,0.26,8.468,0.436,12.7c0.173,4.15,0.445,8.297,0.657,12.446c0.11,2.172-0.752,2.669-2.946,2.427
        c-1.96-0.216-3.98,0.039-5.969,0.163c-3.385,0.211-6.762,0.591-10.148,0.709c-10.54,0.369-21.084,0.651-31.627,0.962
        c-5.11,0.15-10.221,0.378-15.332,0.397c-2.99,0.012-5.982-0.298-8.97-0.504c-1.164-0.079-1.822-0.79-1.961-1.904
        c-0.528-4.237-1.129-8.468-1.578-12.714c-0.39-3.691-0.671-7.398-0.891-11.104c-0.234-3.947-0.339-7.902-0.501-11.854
        C17.152,60.962,17.242,60.957,17.333,60.951z M69.112,95.477c0-1.295-0.074-2.529,0.015-3.752c0.202-2.789,0.545-5.567,0.717-8.357
        c0.212-3.468,0.302-6.942,0.459-10.413c0.098-2.155,0.193-4.312,0.338-6.465c0.154-2.301,0.528-2.639,2.735-2.978
        c1.93-0.296,3.848-0.673,5.77-1.021c1.729-0.312,3.45-0.69,5.189-0.938c1.518-0.215,2.574,0.5,2.741,1.845
        c0.235,1.9,0.519,3.816,0.496,5.723c-0.054,4.546-0.382,9.091-0.392,13.637c-0.008,3.107,0.327,6.217,0.524,9.324
        c0.073,1.14,0.191,2.276,0.275,3.271c1.762,0,3.41,0,5.075,0c0-0.55,0.021-0.906-0.003-1.26c-0.198-2.868-0.569-5.736-0.579-8.605
        c-0.028-8.438-0.099-16.866-0.674-25.292c-0.192-2.818,0.081-5.669,0.166-8.505c0.017-0.568,0.055-0.995-0.728-1.058
        c-2.586-0.209-5.163-0.598-7.752-0.708c-8.543-0.362-17.088-0.731-25.636-0.904c-6.031-0.122-12.069,0.064-18.104,0.127
        c-1.639,0.017-3.277,0.101-4.916,0.122c-4.149,0.053-8.298,0.099-12.448,0.121c-0.656,0.003-1.313-0.132-2.087-0.215
        c0.09,0.884,0.194,1.591,0.228,2.302c0.114,2.433,0.275,4.868,0.285,7.302c0.015,3.793-0.152,7.587-0.107,11.38
        c0.049,4.075,0.223,8.149,0.399,12.222c0.166,3.834,0.417,7.663,0.604,11.495c0.041,0.831,0.303,1.193,1.223,1.151
        c1.193-0.055,2.394,0.086,3.591,0.134c3.63,0.146,7.259,0.375,10.889,0.409c4.915,0.048,9.831-0.071,14.746-0.089
        C57.75,95.462,63.346,95.477,69.112,95.477z M22.919,45.578c3.736,0,7.132-0.044,10.525,0.011c4.109,0.068,8.22,0.163,12.326,0.355
        c2.785,0.13,5.558,0.478,8.34,0.677c4.774,0.341,9.55,0.666,14.328,0.953c6.653,0.4,13.309,0.764,19.965,1.132
        c0.686,0.038,1.375,0.005,2.233,0.005c-4.012-3.918-7.775-7.726-11.685-11.378c-4.816-4.5-9.742-8.884-14.647-13.288
        c-1.961-1.761-4.003-3.432-5.988-5.167c-0.653-0.57-1.179-0.662-1.809,0.057c-0.603,0.686-1.255,1.358-1.992,1.89
        c-3.465,2.5-7.062,4.828-10.432,7.448c-4.824,3.75-9.496,7.695-14.225,11.566C27.614,41.677,25.387,43.537,22.919,45.578z
        M86.189,95.613c-0.765-10.34-1.513-20.462-2.26-30.58c-3.542,0.158-6.971,0.355-10.401,0.438
        c-1.104,0.026-1.604,0.374-1.623,1.489c-0.075,4.229-0.287,8.46-0.243,12.688c0.045,4.231,0.314,8.46,0.531,12.688
        c0.057,1.103,0.289,2.195,0.438,3.277C77.152,95.613,81.531,95.613,86.189,95.613z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M69.112,95.477c-5.767,0-11.362-0.015-16.959,0.004
        c-4.915,0.018-9.831,0.137-14.746,0.089c-3.63-0.034-7.259-0.264-10.889-0.409c-1.198-0.048-2.398-0.188-3.591-0.134
        c-0.919,0.042-1.182-0.32-1.223-1.151c-0.187-3.832-0.438-7.661-0.604-11.495c-0.176-4.072-0.351-8.146-0.399-12.222
        c-0.045-3.793,0.122-7.587,0.107-11.38c-0.01-2.434-0.171-4.869-0.285-7.302c-0.033-0.71-0.138-1.417-0.228-2.302
        c0.774,0.083,1.431,0.218,2.087,0.215c4.149-0.022,8.299-0.067,12.448-0.121c1.639-0.021,3.277-0.105,4.916-0.122
        c6.035-0.062,12.073-0.249,18.104-0.127c8.548,0.173,17.093,0.542,25.636,0.904c2.589,0.109,5.166,0.499,7.752,0.708
        c0.782,0.063,0.744,0.49,0.728,1.058c-0.085,2.836-0.358,5.688-0.166,8.505c0.575,8.426,0.646,16.854,0.674,25.292
        c0.01,2.869,0.381,5.737,0.579,8.605c0.024,0.354,0.003,0.71,0.003,1.26c-1.665,0-3.313,0-5.075,0
        c-0.084-0.994-0.202-2.131-0.275-3.271c-0.197-3.107-0.532-6.217-0.524-9.324c0.01-4.546,0.338-9.091,0.392-13.637
        c0.022-1.906-0.261-3.822-0.496-5.723c-0.167-1.345-1.224-2.06-2.741-1.845c-1.739,0.247-3.46,0.625-5.189,0.938
        c-1.922,0.348-3.84,0.725-5.77,1.021c-2.207,0.339-2.581,0.677-2.735,2.978c-0.145,2.153-0.24,4.31-0.338,6.465
        c-0.157,3.471-0.247,6.945-0.459,10.413c-0.172,2.79-0.515,5.568-0.717,8.357C69.038,92.948,69.112,94.182,69.112,95.477z
        M30.391,60.668c0.01,0,0.02,0,0.029-0.001c0.02,0.279,0.043,0.559,0.06,0.837c0.307,5.068,0.978,10.081,2.22,15.011
        c0.263,1.041,0.577,2.091,0.656,3.152c0.145,1.955,0.611,2.553,2.54,2.402c6.244-0.488,12.482-1.053,18.724-1.575
        c1.089-0.091,1.489-0.612,1.481-1.721c-0.013-1.871,0.013-3.758,0.234-5.613c0.407-3.408,1.054-6.789,1.424-10.201
        c0.16-1.482,0.007-3.032-0.234-4.514c-0.201-1.234-1.102-1.643-2.333-1.444c-0.545,0.087-1.108,0.11-1.661,0.092
        c-1.637-0.054-3.272-0.148-4.908-0.218c-4.82-0.206-9.644-0.343-14.458-0.638c-2.499-0.153-3.707,0.753-3.772,3.233
        C30.381,59.87,30.391,60.269,30.391,60.668z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M22.919,45.578c2.468-2.042,4.695-3.901,6.94-5.738
        c4.729-3.871,9.401-7.816,14.225-11.566c3.371-2.62,6.967-4.947,10.432-7.448c0.736-0.532,1.389-1.204,1.992-1.89
        c0.63-0.719,1.156-0.627,1.809-0.057c1.985,1.735,4.027,3.406,5.988,5.167c4.905,4.404,9.831,8.789,14.647,13.288
        c3.909,3.652,7.673,7.459,11.685,11.378c-0.858,0-1.548,0.033-2.233-0.005c-6.656-0.369-13.312-0.732-19.965-1.132
        c-4.778-0.288-9.554-0.612-14.328-0.953c-2.782-0.199-5.556-0.546-8.34-0.677c-4.105-0.192-8.216-0.287-12.326-0.355
        C30.051,45.534,26.655,45.578,22.919,45.578z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M86.189,95.613c-4.658,0-9.037,0-13.559,0
        c-0.148-1.082-0.381-2.175-0.438-3.277c-0.217-4.228-0.486-8.456-0.531-12.688c-0.044-4.229,0.168-8.459,0.243-12.688
        c0.02-1.115,0.52-1.463,1.623-1.489c3.431-0.082,6.859-0.279,10.401-0.438C84.677,75.151,85.425,85.273,86.189,95.613z"/>
      <path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M30.391,60.668c0-0.399-0.009-0.799,0.001-1.198
        c0.065-2.48,1.273-3.387,3.772-3.233c4.814,0.295,9.639,0.432,14.458,0.638c1.636,0.07,3.271,0.164,4.908,0.218
        c0.553,0.018,1.116-0.004,1.661-0.092c1.231-0.199,2.132,0.21,2.333,1.444c0.241,1.481,0.395,3.031,0.234,4.514
        c-0.37,3.412-1.017,6.793-1.424,10.201c-0.221,1.855-0.247,3.742-0.234,5.613c0.008,1.108-0.392,1.63-1.481,1.721
        c-6.242,0.522-12.48,1.087-18.724,1.575c-1.929,0.15-2.395-0.447-2.54-2.402c-0.079-1.062-0.393-2.111-0.656-3.152
        c-1.242-4.93-1.913-9.942-2.22-15.011c-0.017-0.278-0.04-0.558-0.06-0.837C30.41,60.668,30.4,60.668,30.391,60.668z M43.471,59.95
        c-3.043,0-6.131,0-9.325,0c0.174,2.415,0.317,4.866,0.538,7.311c0.178,1.971,0.221,1.96,2.242,1.937
        c0.396-0.005,0.793,0.08,1.19,0.094c1.593,0.055,3.188,0.086,4.78,0.148c0.83,0.033,1.116-0.333,1.061-1.149
        C43.775,65.555,43.635,62.819,43.471,59.95z M45.831,69.242c2.651-0.192,5.272-0.371,7.888-0.596
        c0.23-0.02,0.619-0.338,0.624-0.525c0.068-2.63,0.108-5.262,0.068-7.893c-0.004-0.255-0.58-0.696-0.91-0.715
        c-1.393-0.08-2.794-0.073-4.19-0.021c-1.458,0.055-2.913,0.198-4.395,0.305C45.223,62.971,45.519,66.019,45.831,69.242z
        M45.591,78.806c3.048,0,5.705,0,8.551,0c0-3.005,0-5.806,0-8.604c-2.542,0.123-5.05,0.216-7.552,0.397
        c-0.349,0.025-0.948,0.476-0.956,0.743C45.561,73.848,45.591,76.356,45.591,78.806z M43.834,79.163c0-2.902,0-5.553,0-8.357
        c-3.007,0.205-5.877,0.4-8.877,0.604c0.124,2.782,0.238,5.357,0.344,7.754C38.244,79.163,41.051,79.163,43.834,79.163z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M43.471,59.95c0.164,2.869,0.304,5.605,0.487,8.34
        c0.055,0.816-0.231,1.183-1.061,1.149c-1.593-0.062-3.187-0.094-4.78-0.148c-0.397-0.014-0.794-0.099-1.19-0.094
        c-2.021,0.023-2.063,0.034-2.242-1.937c-0.221-2.444-0.364-4.896-0.538-7.311C37.34,59.95,40.427,59.95,43.471,59.95z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M45.831,69.242c-0.312-3.223-0.607-6.271-0.915-9.444
        c1.482-0.106,2.937-0.25,4.395-0.305c1.396-0.053,2.797-0.06,4.19,0.021c0.33,0.019,0.906,0.46,0.91,0.715
        c0.041,2.631,0,5.263-0.068,7.893c-0.005,0.188-0.394,0.506-0.624,0.525C51.103,68.871,48.482,69.049,45.831,69.242z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M45.591,78.806c0-2.45-0.03-4.958,0.044-7.463
        c0.008-0.268,0.606-0.718,0.956-0.743c2.502-0.182,5.01-0.274,7.552-0.397c0,2.798,0,5.599,0,8.604
        C51.296,78.806,48.639,78.806,45.591,78.806z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" fill="transparent" d="M43.834,79.163c-2.783,0-5.59,0-8.533,0
        c-0.106-2.396-0.221-4.972-0.344-7.754c3-0.203,5.87-0.398,8.877-0.604C43.834,73.61,43.834,76.26,43.834,79.163z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SchulhausIcon'
}
</script>
