<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentFAQTile
      v-for="(faq, i) in faqs"
      :key="i"
      :color="faq.color"
      :question="faq.question"
      :answerArray="faq.answerArray"
      :graphic="faq.graphic"
    >
      <v-col
        v-if="faq.highlightText !== null"
        slot="content"
        cols="12"
      >
        <v-card
          flat
        >
          <v-card-text
            :style="{
              'color': faq.color
            }">
            {{ faq.highlightText }}
          </v-card-text>
        </v-card>
      </v-col>
    </ContentFAQTile>
    <ContentTile
      :themeColor="computedTopicColor"
      title="Wenn du Fragen hast, stelle sie!"
      :textArray="[]"
      downloadText='Antworten auf häufige Fragen als Download'
      downloadFileName="Hans-Lebrecht-Schule_FAQ.pdf"
    >
      <v-row
        slot="content"
      >
        <v-col
          cols="12"
          align="center"
        >
          <InternalLinkButton
            :themeColor="computedTopicColor"
            :pageName="contactButtonPageName"
            :text="contactButtonText"
          />
        </v-col>
      </v-row>
    </ContentTile>
  </ContentFrame>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentFrame from '@/views/components/ContentFrame.vue'
import ContentFAQTile from '@/views/components/ContentFAQTile.vue'
import ContentTile from '@/views/components/ContentTile.vue'
import InternalLinkButton from '@/views/components/InternalLinkButton.vue'

export default {
  name: 'FAQ',
  components: {
    ContentFrame,
    ContentFAQTile,
    ContentTile,
    InternalLinkButton
  },
  data () {
    return {
      contactButtonPageName: 'Contact',
      contactButtonText: 'Zum Kontakt',
      faqs: [
        {
          color: '#015aaa',
          question: 'Was ist eigentlich die Klinikschule?',
          answerArray: [
            'Die Klinikschule ist Teil deines Klinikalltags und damit Übungsfeld für schulische Themen. Schulbesuch üben, Konzentrationsfähigkeit steigern, strukturiertes Arbeiten, Hausaufgaben machen, Teamarbeit - es gibt viele Möglichkeiten.',
            'In den ersten Tagen in der Klinikschule soll dir die Gelegenheit gegeben werden, die Schule und deine Lehrkräfte und Mitschüler*innen in Ruhe kennenzulernen.',
            'So triffst du zum Beispiel gleich an deinem ersten Tag in einer Kennenlernstunde deine Bezugslehrkraft. Sie oder er erklärt dir z.B. unseren Stundenplan, zeigt dir die Klassenzimmer und macht dich auch mit unseren Schulregeln vertraut.'
          ],
          highlightText: 'Wichtig ist: Du stehst im Mittelpunkt und wir möchten, dass du bei uns nicht nur viel lernst, sondern, dass es dir gut geht und du Freude daran hast. Deshalb darfst du all deinen Lehrkräften auch jederzeit Fragen stellen zu Unklarheiten und/oder Unsicherheiten. Eine gute Möglichkeit bietet dir die Bezugslehrkraftstunde.',
          graphic: '/img/fragen/haus.png'
        },
        {
          color: '#007ec5',
          question: 'Wie sehen meine ersten Tage dort aus?',
          answerArray: [
            'Und dann geht es auch schon los! Du hast in deinen ersten Tagen "ganz normal" Unterricht, so wie es eben für dich und deine persönliche Situation gut ist. Manchmal ist das vielleicht nur eine Stunde oder auch gleich ein ganzer Unterrichtstag, manchmal hast du Einzelunterricht oder du wirst in der Gruppe mit deinen Mitschüler*innen beschult - eben so, wie es für dich und deine Situation passt und wie es der Therapieplan und die Schulstruktur erlauben.',
            'Du lernst deine Lehrkräfte und Mitschüler*innen im Unterricht kennen, wir tauschen uns darüber aus, was du in den einzelnen Fächern schon gelernt hast oder wo du besondere Unterstützung brauchst, welche Unterrichtsmaterialien du benötigst, ob du Klassenarbeiten schreiben wirst und wie es während deines Klinikaufenthaltes schulisch weitergeht. Dafür stehen wir in engem Kontakt mit deiner Heimatschule, aber auch mit deinen Ansprechpartner*innen in der Klinik.'
          ],
          highlightText: null,
          graphic: '/img/fragen/erstetage.png'
        },
        {
          color: '#00aeef',
          question: 'Welche Lehrkräfte arbeiten an der Klinikschule?',
          answerArray: [
            'An der Klinikschule arbeiten Lehrkräfte mit unterschiedlicher Ausbildung in einem Team zusammen. So kann es sein, dass du sowohl von Sonderpädagog*innen, Grund- und Hauptschullehrkräften, Lehrkräften der Gemeinschafts-, Real- oder Gymnasialschulen unterrichtet wirst.',
            'Die Lehrkräfte nehmen regelmäßig an Fortbildungen teil. Sie kennen sich also mit seelischen Erkrankungen und deren Auswirkungen auf das Lernen in der Schule aus.',
            'Somit gehören auch die Lehrkräfte zu deinem unterstützendem Netzwerk.'
          ],
          highlightText: null,
          graphic: '/img/fragen/lehrerinnen.png'
        },
        {
          color: '#009fb4',
          question: 'Wozu gibt es die Kennenlernstunde?',
          answerArray: [
            'In dieser Stunde...',
            '• wird dir und deiner Bezugslehrkraft die Gelegenheit gegeben, euch gegenseitig kennenzulernen und Vertrauen aufzubauen.',
            '• erhältst du Informationen über schulische Abläufe und Vorgehensweisen (z.B. Stundenplan).',
            '• lernst du die Räumlichkeiten des Schulgebäudes, die Lehrkräfte und wichtige Regeln an der Schule kennen.',
            '• berichtest du deiner Bezugslehrkraft etwas über deinen schulischen Werdegang und besprichst mit ihr Erwartungen und Ziele für deinen Klinikaufenthalt.'
          ],
          highlightText: null,
          graphic: '/img/fragen/kennenlernen.png'
        },
        {
          color: '#5cc3b6',
          question: 'Was muss ich über den Stundenplan wissen?',
          answerArray: [
            'An der Klinikschule gibt es die Unter-, Mittel- und Oberstufe.',
            'Die Lehrkräfte deiner Stufe erstellen wöchentlich einen neuen Stundenplan, bei dem dein momentaner Unterstützungsbedarf berücksichtigt wird.',
            'Tagesaktuelle Informationen oder Änderungen, die sich ergeben, erhältst du von deinen Lehrkräften und Betreuer*innen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/stundenplan.png'
        },
        {
          color: '#00a895',
          question: 'Warum habe ich manchmal Einzelunterricht?',
          answerArray: [
            'Du hast Einzelunterricht...',
            '• damit deine Lehrkraft ganz gezielt mit dir arbeiten kann, zum Beispiel als intensive Vorbereitung für eine anstehende Klassenarbeit oder Prüfung.',
            '• weil du es dir z.B. selbst gewünscht hast, weil dich zusätzliche Schüler*innen im Raum oder deren Unterrichtsthemen so sehr ablenken, dass du selbst nicht konzentriert arbeiten kannst.',
            '• da es nicht möglich war, mehrere Schüler*innen in der betreffenden Stunde einzuplanen, weil sie z.B. Therapien haben oder anderweitig nicht zum Unterricht kommen können.'
          ],
          highlightText: null,
          graphic: '/img/fragen/einzel.png'
        },
        {
          color: '#80ca9c',
          question: 'Warum sind die Türen der Klassenräume in der Regel offen?',
          answerArray: [
            'Die Türen der Klassenräume in der Klinikschule sind offen...',
            '• damit du dich nicht eingeengt fühlst.',
            '• damit sich die Lehrkräfte gegenseitig unterstützen können.',
            'Manchmal passt es aber auch besser, wenn die Türen geschlossen sind, z.B. bei Präsentationen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/tueroffen.png'
        },
        {
          color: '#00a54f',
          question: 'Was ist eine Bezugslehrkraftstunde?',
          answerArray: [
            'In der Bezugslehrkraftstunde...',
            '• reflektierst du dein Lernverhalten.',
            '• planst du mit deiner Bezugslehrkraft, woran du in der Klinikschule weiter arbeiten willst.',
            '• besprichst du mit deiner Bezugslehrkraft deine Wünsche und Sorgen bezüglich der Schule.',
            '• erfährst du Neues aus deiner Heimatschule.',
            '• gibt es die Möglichkeit, dich mit deinen Mitschüler*innen auszutauschen.'
          ],
          highlightText: null,
          graphic: '/img/fragen/bericht.png'
        },
        {
          color: '#00854a',
          question: 'Wie sieht der Kontakt mit deiner Heimatschule aus und welche Informationen werden ausgetauscht?',
          answerArray: [
            'Deine Klassenlehrkraft erfährt, dass du aktuell am Unterricht der Klinikschule teilnimmst.',
            'Wir tauschen uns regelmäßig über deine schulische Situation aus, erfragen Unterrichtsinhalte und bereiten deine Rückkehr vor.'
          ],
          highlightText: 'Deine Klassenlehrkräfte werden von uns weder über deine Diagnose noch über Therapieinhalte informiert.',
          graphic: '/img/fragen/taube.png'
        },
        {
          color: '#71bf44',
          question: 'Welche Informationen werden mit den Mitarbeiter*innen der Klinik ausgetauscht?',
          answerArray: [
            'Die Lehrkräfte bzw. die Schulleitung der Klinikschule nehmen an den Visiten teil.',
            'Sie tauschen sich dort über deinen therapeutischen Prozess, deine Diagnosen und aktuellen Übungsfelder aus und bringen ihre Beobachtungen über dein Verhalten im Unterricht ein.'
          ],
          highlightText: null,
          graphic: '/img/fragen/klinik.png'
        },
        {
          color: '#bed630',
          question: 'Was ist ein Abschlussbericht und wer bekommt diesen?',
          answerArray: [
            'Nach deinem Aufenthalt verfassen deine Lehrkräfte einen Abschlussbericht. Er enthält wichtige Informationen über dein Lernverhalten, dein Sozialverhalten und die bearbeiteten Lerninhalte.',
            'Informationen zu deiner Diagnose und möglichen Medikamenten werden im Abschlussbericht nicht erwähnt.',
            'Der Abschlussbericht wird an deine Klassenlehrkraft und auch an deine Eltern/Sorgeberechtigten geschickt.'
          ],
          highlightText: null,
          graphic: '/img/fragen/bericht.png'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'menuData'
    ]),
    computedTopicColor () {
      return this.menuData.faq.color
    },
    computedTitle () {
      return this.menuData.faq.title
    }
  }
}
</script>
