var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"light":"","color":_vm.embedded ? '#e0e0e0' : '#f0f0f0',"height":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-bold",class:{
          'text-h6 text-sm-h5 text-md-h4': !_vm.embedded,
          'text-h6 text-md-h5': _vm.embedded,
          'pb-0': _vm.subtitle != null
        },style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c('v-col',{staticClass:"text-subtitle-1",class:{
          'pt-0': _vm.title != null
        },style:({
          'color': _vm.embedded ? _vm.$vuetify.theme.currentTheme.neutral : _vm.themeColor
        }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()],1),_c('v-row',[_vm._l((_vm.textArray),function(text,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(text)+" ")])}),(_vm.hasContentSlot())?_c('v-col',{attrs:{"cols":"12"}},[_vm._t("content")],2):_vm._e(),(_vm.noteText != null)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"content-tile-alert",class:{
            'text-caption': _vm.$vuetify.breakpoint.xs
          },attrs:{"type":"info","dense":"","color":_vm.themeColor,"border":"left","outlined":""}},[_vm._v(" "+_vm._s(_vm.noteText)+" ")])],1):_vm._e(),(_vm.downloadFileName != null)?_c('v-col',{attrs:{"cols":"12"}},[(Array.isArray(_vm.downloadFileName))?_vm._l((_vm.downloadFileName),function(download,i){return _c('DownloadAlert',{key:i,attrs:{"color":_vm.themeColor,"text":_vm.downloadText[i],"fileName":_vm.downloadFileName[i]}})}):[_c('DownloadAlert',{attrs:{"color":_vm.themeColor,"text":_vm.downloadText,"fileName":_vm.downloadFileName}})]],2):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }