<template>
  <ContentFrame
    :themeColor="computedTopicColor"
    :title="computedTitle"
  >
    <ContentContainer
      v-if="computedExecutives.length > 0"
      :themeColor="computedTopicColor"
      title="Die Schulleitung"
    >
      <v-col
        v-for="(executive, i) in computedExecutives"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <Person
          :person="executive"
        />
      </v-col>
    </ContentContainer>
    <ContentContainer
      v-if="computedOffice.length > 0"
      :themeColor="computedTopicColor"
      title="Das Sekretariat"
    >
      <v-col
        v-for="(office, i) in computedOffice"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        xl="3"
      >
        <Person
          :person="office"
        />
      </v-col>
    </ContentContainer>
    <ContentContainer
      v-if="computedCollegium.length > 0"
      :themeColor="computedTopicColor"
      title="Das Kollegium"
    >
      <ContentContainer
        v-for="(department, i) in computedCollegium"
        :key="i"
        :themeColor="computedTopicColor"
        :title="department.name"
        :subtitle="department.description"
        embedded
      >
        <v-col
          v-for="(teacher, i) in department.teachers"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <Person
            :person="teacher"
          />
        </v-col>
      </ContentContainer>
    </ContentContainer>
  </ContentFrame>
</template>

<script>
import Person from '@/views/components/Person.vue'
import ContentContainer from '@/views/components/ContentContainer.vue'
import ContentFrame from '@/views/components/ContentFrame.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Staff',
  components: {
    Person,
    ContentContainer,
    ContentFrame
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
  },
  methods: {
    sortPersons (persons) {
      persons.sort(function (a, b) {
        if (a.position < b.position) { return -1 }
        if (a.position > b.position) { return 1 }
        return 0
      })
      return persons
    }
  },
  computed: {
    ...mapGetters([
      'menuData',
      'staff'
    ]),
    computedTopicColor () {
      return this.menuData.staff.color
    },
    computedTitle () {
      return this.menuData.staff.title
    },
    computedExecutives () {
      const executives = this.staff.executives
      this.sortPersons(executives)
      return executives
    },
    computedOffice () {
      const office = this.staff.office
      this.sortPersons(office)
      return office
    },
    computedCollegium () {
      const collegium = this.staff.collegium
      this.sortPersons(collegium)
      return collegium
    },
    computedSortedPersons () {
      return persons => persons.sort(function (a, b) {
        if (a.position < b.position) { return -1 }
        if (a.position > b.position) { return 1 }
        return 0
      })
    }
  }
}
</script>

<style scoped>
</style>
